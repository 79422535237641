import KoobButton from '../../../../components/Koob/KoobButton';
import KoobModal from '../../../../components/Koob/KoobModal';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import KoobTitle from '../../../../components/Koob/KoobTitle';
import { useQuery } from '@apollo/client';
import { pickBy } from 'lodash';
import { GET_CONTRACTS } from '../../../Hotels/Hotel/Contracts/ContractsList';
import KoobPlaceholder from '../../../../components/Koob/KoobPlaceholder';
import moment from 'moment';
import KoobInputSelect from '../../../../components/Koob/Input/KoobInputSelect';
import { useField } from 'formik';
import { decodeId } from '../../../../utils';
import KoobParagraph from '../../../../components/Koob/KoobParagraph';

function ContractListItem({ itemField, contract }) {
  const { t } = useTranslation('toConnectionRequest');

  const [{ value: field }, , { setValue: setField }] = useField(
    `${itemField}.contracts`,
  );

  const findIndex = field?.findIndex(
    fieldItem => fieldItem.id === decodeId(contract.id),
  );
  const contractIndex = findIndex >= 0 ? findIndex : null;

  const fieldName = `${itemField}.contracts[${contractIndex}].enabled`;
  const startAt = contract?.startAt;
  const endAt = contract?.endAt;

  const addItem = () => {
    if (contractIndex !== null) {
      return;
    }

    setField([
      ...(field ?? []),
      {
        id: decodeId(contract.id),
        enabled: null,
        startAt,
        endAt,
      },
    ]);
  };

  return (
    <div className="border p-5 rounded-md">
      <div className="flex justify-between items-center">
        <div>
          <div className="font-medium text-lg text-gray-800">
            {moment(startAt).format('DD/MM/YYYY')} to{' '}
            {moment(endAt).format('DD/MM/YYYY')}
          </div>

          <div className="uppercase italic font-medium text-green-500">
            {contract?.status}
          </div>
        </div>

        <div>
          {contractIndex === null ? (
            <KoobButton onClick={() => addItem()}>
              <i className="far fa-eye mr-1" />
              {t('bulkEdit.editor.contracts.editButton')}
            </KoobButton>
          ) : (
            <>
              <KoobInputSelect
                label={t('bulkEdit.editor.visibility')}
                name={fieldName}
                options={[
                  { label: t('bulkEdit.editor.noChange'), value: null },
                  { label: t('bulkEdit.editor.visible'), value: true },
                  { label: t('bulkEdit.editor.invisible'), value: false },
                ]}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
}

function ContractList({ itemField, hotelIdRails }) {
  const { t } = useTranslation('toConnectionRequest');

  const { data, loading } = useQuery(GET_CONTRACTS, {
    variables: pickBy({
      hotelId: hotelIdRails,
      stateFilter: {
        status: 'published',
        state: 'available',
      },
    }),
    fetchPolicy: 'no-cache',
  });

  const contracts = data?.contracts?.edges ?? [];

  return (
    <>
      {loading || contracts?.length > 0 ? (
        <div className="flex-col space-y-5">
          {loading && (
            <>
              <KoobPlaceholder className="h-20 w-full rounded-md" />
              <KoobPlaceholder className="h-20 w-full rounded-md" />
              <KoobPlaceholder className="h-20 w-full rounded-md" />
            </>
          )}

          {!loading &&
            contracts?.map(contract => (
              <ContractListItem
                key={contract?.node?.id}
                itemField={itemField}
                contract={contract?.node}
              />
            ))}
        </div>
      ) : (
        <div className="h-60 flex flex-col justify-center items-center">
          <div className="text-center">
            <i className="far fa-file-signature text-4xl text-gray-300" />

            <div className="mt-3 font-medium text-gray-500">
              {t('bulkEdit.editor.contracts.noContracts')}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default function BulkEditorItemContracts({
  itemField,
  hotelIdRails,
  hotelName,
  disabled,
}) {
  const { t } = useTranslation('toConnectionRequest');

  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <KoobButton
        onClick={() => setShowModal(true)}
        size="sm"
        disabled={disabled}
      >
        <i className="fal fa-file-signature text-lg" />
      </KoobButton>

      <KoobModal open={showModal} setOpen={setShowModal}>
        <KoobTitle>{t('bulkEdit.editor.contracts.title')}</KoobTitle>
        <KoobParagraph>{hotelName}</KoobParagraph>

        <div className="mt-5">
          {showModal && (
            <ContractList itemField={itemField} hotelIdRails={hotelIdRails} />
          )}
        </div>
      </KoobModal>
    </>
  );
}
