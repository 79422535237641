import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import KoobInputSelect from '../../../../components/Koob/Input/KoobInputSelect';
import KoobInput from '../../../../components/Koob/Input/KoobInput';
import KoobButton from '../../../../components/Koob/KoobButton';
import { useField } from 'formik';
import KoobParagraph from '../../../../components/Koob/KoobParagraph';
import KoobTitle from '../../../../components/Koob/KoobTitle';

export default function BulkEditorUpdateMargin({ fieldName }) {
  const { t } = useTranslation('toConnectionRequest');
  const [{ value: items }, , { setValue: setItems }] = useField(
    `request.${fieldName}`,
  );
  const [marginType, setMarginType] = useState(null);
  const [marginValue, setMarginValue] = useState(null);
  const [visibility, setVisibility] = useState(null);

  const applyToSelected = () => {
    const newItems = items.map(item => {
      return {
        ...item,
        isMarginPercent: marginType,
        margin: marginValue,
        distribute: visibility,
      };
    });

    setItems(newItems);
  };

  return (
    <div>
      <KoobTitle>{t('bulkEdit.editor.updateSelection')}</KoobTitle>

      <div className="mt-3 grid grid-cols-4 justify-end items-end gap-3">
        <KoobInputSelect
          label={t('bulkEdit.editor.marginType')}
          value={marginType}
          onChange={setMarginType}
          options={[
            { label: t('bulkEdit.editor.noChange'), value: null },
            { label: t('bulkEdit.editor.marginPercent'), value: true },
            { label: t('bulkEdit.editor.marginFixed'), value: false },
          ]}
          disabled={visibility === 'false'}
        />

        <KoobInput
          label={t('bulkEdit.editor.marginValue')}
          value={marginValue}
          onChange={setMarginValue}
          type="number"
          min="0"
          disabled={visibility === 'false'}
        />

        <KoobInputSelect
          label={t('bulkEdit.editor.visibility')}
          value={visibility}
          onChange={setVisibility}
          options={[
            { label: t('bulkEdit.editor.noChange'), value: null },
            { label: t('bulkEdit.editor.visible'), value: true },
            { label: t('bulkEdit.editor.invisible'), value: false },
          ]}
        />

        <KoobButton onClick={() => applyToSelected()} size="sm">
          {t('bulkEdit.editor.applyToSelected')}
        </KoobButton>
      </div>

      <KoobParagraph size="sm">
        {t('bulkEdit.editor.leaveEmptyForNoChange')}
      </KoobParagraph>
    </div>
  );
}
