import styled from 'styled-components';

const VisuallyHidden = styled.span`
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  position: absolute;
  padding: 0;
  border: none;
  white-space: nowrap;
  clip-path: inset(50%);
`;

export default VisuallyHidden;
