import * as Yup from 'yup';
import i18n from 'i18n';

export const EXPERIENCE_TYPOLOGY_SCHEMA = Yup.object().shape({
  type: Yup.string().required(i18n.t('errors:required')),
});

export const EXPERIENCE_SERVICE_SCHEMA = Yup.object().shape({
  activity: Yup.object().shape({
    isExtra: Yup.bool().required(i18n.t('errors:required')),
  }),
});

export const EXPERIENCE_COMPATIBILITY_SCHEMA = Yup.object().shape({
  extras: Yup.array().of(
    Yup.object().shape({
      id: Yup.string().required(i18n.t('errors:required')),
    }),
  ),
});

export const EXPERIENCE_OPTIONS_SCHEMA = Yup.object().shape({
  extras: Yup.array().of(
    Yup.object().shape({
      id: Yup.string().required(i18n.t('errors:required')),
    }),
  ),
});

export const EXPERIENCE_CONFIG_SCHEMA = Yup.object().shape({
  dmcReference: Yup.string().nullable(),
  name: Yup.string().required(i18n.t('errors:required')),
  durationDays: Yup.number()
    .required(i18n.t('errors:required'))
    .min(0, i18n.t('errors:minValue', { min: 0 })),
  durationHours: Yup.number()
    .required(i18n.t('errors:required'))
    .min(0, i18n.t('errors:minValue', { min: 0 }))
    .max(23, i18n.t('errors:maxValue', { max: 24 })),
  durationMinutes: Yup.number()
    .required(i18n.t('errors:required'))
    .min(0, i18n.t('errors:minValue', { min: 0 }))
    .max(59, i18n.t('errors:maxValue', { max: 60 })),
  guiding: Yup.boolean().required(i18n.t('errors:required')),
  guidingLanguages: Yup.array().when('guiding', {
    is: true,
    then: schema => schema.min(1).required(i18n.t('errors:required')),
    otherwise: schema => schema.nullable(),
  }),
  distance: Yup.number().when('activity.isExtra', {
    is: true,
    then: schema => schema,
    otherwise: schema => schema.min(0, i18n.t('errors:minValue', { min: 0 })),
  }),
  inCountry: Yup.object().when('activity.isExtra', {
    is: true,
    then: schema => schema,
    otherwise: schema => schema.required(i18n.t('errors:required')),
  }),
  inCity: Yup.object().when('activity.isExtra', {
    is: true,
    then: schema => schema,
    otherwise: schema => schema.required(i18n.t('errors:required')),
  }),
  startPosition: Yup.object().when('activity.isExtra', {
    is: true,
    then: schema => schema,
    otherwise: schema => schema.required(i18n.t('errors:required')),
  }),
  endPosition: Yup.object().when('activity.isExtra', {
    is: true,
    then: schema => schema,
    otherwise: schema => schema.required(i18n.t('errors:required')),
  }),
  locationsExtraInformations: Yup.string().nullable(),
  description: Yup.string().required(i18n.t('errors:required')),
  includedServices: Yup.string().nullable(),
  excludedServices: Yup.string().nullable(),
  extraRemarks: Yup.string().nullable(),
});

export const EXPERIENCE_ACTIVITIES_SCHEMA = Yup.object().shape({
  highlights: Yup.string().nullable(),
  difficulty: Yup.string(),
  activity: Yup.object().shape({
    pickUpPoint: Yup.string().nullable(),
    dropOffPoint: Yup.string().nullable(),
    pickUpPointTimeHour: Yup.number()
      .min(0, i18n.t('errors:minValue', { min: 0 }))
      .max(23, i18n.t('errors:maxValue', { max: 24 })),
    pickUpPointTimeMin: Yup.number()
      .min(0, i18n.t('errors:minValue', { min: 0 }))
      .max(59, i18n.t('errors:maxValue', { max: 60 })),
    dropOffPointTimeHour: Yup.number()
      .min(0, i18n.t('errors:minValue', { min: 0 }))
      .max(23, i18n.t('errors:maxValue', { max: 24 })),
    dropOffPointTimeMin: Yup.number()
      .min(0, i18n.t('errors:minValue', { min: 0 }))
      .max(59, i18n.t('errors:maxValue', { max: 60 })),
    freeVisitInformations: Yup.string().nullable(),
  }),
});

export const EXPERIENCE_PROGRAMS_DAYS_SCHEMA = Yup.object().shape({
  programs: Yup.array().of(
    Yup.object().shape({
      picture: Yup.mixed(),
      title: Yup.string().required(i18n.t('errors:required')),
      startPosition: Yup.object().required(i18n.t('errors:required')),
      endPosition: Yup.object().required(i18n.t('errors:required')),
      startingTimeHour: Yup.number()
        .min(0, i18n.t('errors:minValue', { min: 0 }))
        .max(23, i18n.t('errors:maxValue', { max: 24 })),
      startingTimeMin: Yup.number()
        .min(0, i18n.t('errors:minValue', { min: 0 }))
        .max(59, i18n.t('errors:maxValue', { max: 60 })),
      endingTimeHour: Yup.number()
        .min(0, i18n.t('errors:minValue', { min: 0 }))
        .max(23, i18n.t('errors:maxValue', { max: 24 })),
      endingTimeMin: Yup.number()
        .min(0, i18n.t('errors:minValue', { min: 0 }))
        .max(59, i18n.t('errors:maxValue', { max: 60 })),
      program: Yup.string().required(i18n.t('errors:required')),
      meals: Yup.array().of(Yup.object()),
      mealExperience: Yup.object(),
      accomodationType: Yup.string().required(i18n.t('errors:required')),
      accomodationExperience: Yup.object().when(
        'accomodationType',
        accomodationType => {
          if (accomodationType === 'nightTransfer') {
            return Yup.object().required(i18n.t('errors:required'));
          }
          return Yup.object();
        },
      ),
      accomodationHotel: Yup.object().when(
        'accomodationType',
        accomodationType => {
          if (accomodationType === 'hotel') {
            return Yup.object().required(i18n.t('errors:required'));
          }
          return Yup.object();
        },
      ),
    }),
  ),
});

export const EXPERIENCE_PROGRAM_SCHEMA = Yup.object().shape({
  highlights: Yup.string().nullable(),
  difficulty: Yup.string().nullable(),
});

export const EXPERIENCE_TRANSFER_CONFIG_SCHEMA = Yup.object().shape({
  transfer: Yup.object().shape({
    type: Yup.string().required(i18n.t('errors:required')),
    departureTimeHour: Yup.number()
      .min(0, i18n.t('errors:minValue', { min: 0 }))
      .max(23, i18n.t('errors:maxValue', { max: 24 })),
    departureTimeMin: Yup.number()
      .min(0, i18n.t('errors:minValue', { min: 0 }))
      .max(59, i18n.t('errors:maxValue', { max: 60 })),
    arrivalTimeHour: Yup.number()
      .min(0, i18n.t('errors:minValue', { min: 0 }))
      .max(23, i18n.t('errors:maxValue', { max: 24 })),
    arrivalTimeMin: Yup.number()
      .min(0, i18n.t('errors:minValue', { min: 0 }))
      .max(59, i18n.t('errors:maxValue', { max: 60 })),
    time: Yup.string().nullable(),
  }),
});

export const EXPERIENCE_TRANSFER_LAND_SCHEMA = Yup.object().shape({
  transfer: Yup.object().shape({
    land: Yup.object().shape({
      pickUpPoint: Yup.string().nullable(),
      dropOffPoint: Yup.string().nullable(),
      vehicleType: Yup.string().nullable(),
    }),
  }),
});

export const EXPERIENCE_TRANSFER_AIR_SCHEMA = Yup.object().shape({
  transfer: Yup.object().shape({
    air: Yup.object().shape({
      taxesIncluded: Yup.string().nullable(),
      taxes: Yup.number()
        .nullable()
        .min(0, i18n.t('errors:minValue', { min: 0 })),
      flightNumber: Yup.string().nullable(),
      airplaneModel: Yup.string().nullable(),
      airlineCompany: Yup.string().nullable(),
      onboardServices: Yup.string().nullable(),
      seatClass: Yup.string().nullable(),
      luggagePolicies: Yup.string().nullable(),
    }),
  }),
});

export const EXPERIENCE_TRANSFER_RAIL_SCHEMA = Yup.object().shape({
  transfer: Yup.object().shape({
    rail: Yup.object().shape({
      departureStation: Yup.string().required(i18n.t('errors:required')),
      arrivalStation: Yup.string().required(i18n.t('errors:required')),
      trainName: Yup.string().nullable(),
      trainNumber: Yup.string().nullable(),
      privateCabin: Yup.string().nullable(),
      seats: Yup.number()
        .min(0, i18n.t('errors:minValue', { min: 0 }))
        .nullable(),
      class: Yup.string().nullable(),
      luggagePolicies: Yup.string().nullable(),
    }),
  }),
});

export const EXPERIENCE_TRANSFER_BOAT_SCHEMA = Yup.object().shape({
  transfer: Yup.object().shape({
    boat: Yup.object().shape({
      departureLocation: Yup.string().required(i18n.t('errors:required')),
      arrivalLocation: Yup.string().required(i18n.t('errors:required')),
      boatName: Yup.string().nullable(),
      boatNumber: Yup.string().nullable(),
      seats: Yup.number()
        .min(0, i18n.t('errors:minValue', { min: 0 }))
        .nullable(),
      cabins: Yup.number()
        .min(0, i18n.t('errors:minValue', { min: 0 }))
        .nullable(),
      class: Yup.string().nullable(),
      luggagePolicies: Yup.string().nullable(),
    }),
  }),
});

export const EXPERIENCE_MEDIA_SCHEMA = Yup.object().shape({
  mediaLinks: Yup.array().of(
    Yup.object().shape({
      title: Yup.string().required(i18n.t('errors:required')),
      link: Yup.string().required(i18n.t('errors:required')),
    }),
  ),
});

export const EXPERIENCE_PRICING_SCHEMA = Yup.object().shape({
  currency: Yup.string().required(i18n.t('errors:required')),
  periods: Yup.array().of(
    Yup.object().shape({
      periodType: Yup.string().required(i18n.t('errors:required')),
      startAt: Yup.date().required(i18n.t('errors:required')),
      endAt: Yup.date().required(i18n.t('errors:required')),
      days: Yup.object()
        .required(i18n.t('errors:required'))
        .test('days-is-valid', i18n.t('errors:required'), (value, context) => {
          return (
            Object.keys(value).filter(key => value[key] === true).length > 0
          );
        }),
      price: Yup.number()
        .required(i18n.t('errors:required'))
        .min(0, i18n.t('errors:minValue', { min: 0 })),
      perPax: Yup.string().required(i18n.t('errors:required')),
      minimumPeopleRequired: Yup.number()
        .required(i18n.t('errors:required'))
        .min(0, i18n.t('errors:minValue', { min: 0 })),
      maximumPeopleRequired: Yup.number()
        .required(i18n.t('errors:required'))
        .min(0, i18n.t('errors:minValue', { min: 0 })),
      adultPax: Yup.array()
        .of(
          Yup.object().shape({
            price: Yup.number()
              .required(i18n.t('errors:required'))
              .min(0, i18n.t('errors:minValue', { min: 0 })),
            paxRange: Yup.array().test(
              'pax-range-is-valid',
              i18n.t('errors:rangeNotValid'),
              value => {
                return (
                  value &&
                  value.length === 2 &&
                  value[0] <= value[1] &&
                  value[0] > 0
                );
              },
            ),
          }),
        )
        .nullable(),
      childrenPax: Yup.array()
        .of(
          Yup.object().shape({
            price: Yup.number()
              .required(i18n.t('errors:required'))
              .min(0, i18n.t('errors:minValue', { min: 0 })),
            ageRange: Yup.array().test(
              'age-range-is-valid',
              i18n.t('errors:rangeNotValid'),
              value => {
                return (
                  value &&
                  value.length === 2 &&
                  value[0] < value[1] &&
                  value[0] >= 0
                );
              },
            ),
          }),
        )
        .nullable(),
    }),
  ),
});

export const EXPERIENCE_CONDITION_SCHEMA = Yup.object().shape({
  cancellationPolicies: Yup.string().required(i18n.t('errors:required')),
});

export const EXPERIENCE_TRANSFERS_SCHEMA = Yup.object().shape({});
