import React from 'react';
import { useTranslation } from 'react-i18next';
import { EditorField, TextField } from '../../../../../../components/Fields';
import TextFieldButton from '../../../../../../components/Fields/TextFieldButton';

export default function SpecificationFormBoat() {
  const { t } = useTranslation('experiences');

  return (
    <>
      <TextField
        label={t('form.transfer.boat.locationDeparture')}
        name="transfer.boat.departureLocation"
        required
      />

      <TextField
        label={t('form.transfer.boat.locationArrival')}
        name="transfer.boat.arrivalLocation"
        required
      />

      <TextField
        label={t('form.transfer.boat.boatName')}
        name="transfer.boat.boatName"
      />

      <TextField
        label={t('form.transfer.boat.boatNumber')}
        name="transfer.boat.boatNumber"
      />

      <TextFieldButton
        type="number"
        step="1"
        label={t('form.transfer.seats')}
        name="transfer.boat.seats"
        content={''}
        min="0"
      />

      <TextFieldButton
        type="number"
        step="1"
        label={t('form.transfer.boat.cabins')}
        name="cabins"
        content={''}
        min="0"
      />

      <TextField
        label={t('form.transfer.boatClass')}
        name="transfer.boat.class"
      />

      <EditorField
        name="transfer.boat.luggagePolicies"
        label={t('form.transfer.luggagePolicies')}
      />
    </>
  );
}
