import { useField } from 'formik';
import moment from 'moment-timezone';
import DateRangePicker from './DateRangePicker';
import { FormLabel, ErrorMessageWrapper, FormField } from 'ui/forms';
import { Stack } from '@tymate/margaret';
import { formatDate } from 'utils';

const DateRangePickerField = ({
  placeholder,
  label,
  name,
  isDateTime = false,
  utc = false,
  updateOnlyDate = false,
  ...props
}) => {
  const [field, meta, { setValue }] = useField({ name });
  const hasError = Boolean(meta.error && meta.touched);

  return (
    <FormField>
      <Stack direction="column" size="full" alignX="stretch" gutterSize={0.5}>
        {Boolean(label) && <FormLabel>{label}</FormLabel>}

        <DateRangePicker
          hasError={hasError}
          placeholder={placeholder}
          convertToUTC={utc}
          {...field}
          onChange={value => {
            if (updateOnlyDate) {
              value[0] = moment(value[0]);
              value[1] = moment(value[1]);
              const start = moment(field.value[0]);
              const end = moment(field.value[1]);
              start.year(value[0].year());
              start.month(value[0].month());
              start.date(value[0].date());

              end.year(value[1].year());
              end.month(value[1].month());
              end.date(value[1].date());

              setValue([start.toDate(), end.toDate()]);
              return;
            }
            const newValues =
              value?.length >= 1 ? value : [new Date(), new Date()];
            if (isDateTime) {
              if (
                field.value &&
                field.value[0] &&
                moment.isMoment(field.value[0])
              ) {
                const start = moment(field.newValues?.[0]);
                start.year(newValues?.[0].getFullYear());
                start.month(newValues?.[0].getMonth());
                start.date(newValues?.[0].getDate());
                const end = moment(field.value[1]);
                try {
                  end.year(newValues?.[1].getFullYear());
                  end.month(newValues?.[1].getMonth());
                  end.date(newValues?.[1].getDate());
                } catch (e) {}

                setValue([start, end]);
              } else {
                setValue(newValues);
              }
            } else {
              const start = newValues?.[0]
                ? formatDate(newValues?.[0], 'yyyy-MM-dd')
                : null;
              const end = newValues?.[1]
                ? formatDate(newValues?.[1], 'yyyy-MM-dd')
                : null;
              setValue([start, end]);
            }
          }}
          {...props}
        />

        {hasError && <ErrorMessageWrapper>{meta.error}</ErrorMessageWrapper>}
      </Stack>
    </FormField>
  );
};

export default DateRangePickerField;
