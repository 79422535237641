import React from 'react';

export default function KoobProgressBar({ percentage }) {
  return (
    <div className="text-sm block">
      <div className="h-2 overflow-hidden flex rounded bg-gray-200">
        <div
          style={{ width: percentage + '%' }}
          className="bg-orange-500 shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center"
        />
      </div>
    </div>
  );
}
