import { fontStyles, Text } from 'ui';
import styled, { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { useSearchParams } from 'hooks';
import { useQuery } from '@apollo/client';
import PromotionsListTable from 'components/Promotions/PromotionsListTable';
import { useEffect, useMemo, useState } from 'react';
import { Button, Stack } from '@tymate/margaret';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { GET_PROMOTIONS } from 'api/promotions';
import { Button as UIButton } from 'components';
import { formatOrderCriteria } from 'utils';
import { isNil, pickBy } from 'lodash';

export const CustomNavLink = styled(Stack).attrs({
  as: Link,
  gutterSize: 0.5,
  alignY: 'center',
})`
  ${fontStyles.bodySmall}

  color: ${({ theme }) => theme.textLight};
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing()};
  text-decoration: none;
  border-radius: ${({ theme }) => theme.borderRadius.default};
  transition: background 150ms ease, color 150ms ease;
  margin-top: 8px;

  &:hover {
    background-color: ${({ theme }) => theme.sidebarBackgroundHoverColor};
    color: ${({ theme }) => theme.primary};
  }

  &.active {
    color: ${({ theme }) => theme.primary};
  }

  &:hover,
  &.active {
    color: ${({ theme }) => theme.primary};
    background-color: ${({ theme }) => theme.sidebarBackgroundHoverColor};
  }

  svg {
    font-size: 1.5rem;
  }
`;

export const SectionWrapper = styled.section`
  margin-block-start: ${({ theme }) => theme.spacing(1.5)};
  font-size: 1rem;
`;

export const LinkSectionWrapper = styled.div`
  margin-block-start: ${({ theme }) => theme.spacing(1.5)};
  flex: 0 0 200px;
  margin-right: 10px;
`;

export const ToggleButton = styled(Button).attrs({
  variant: 'simple',
  role: 'switch',
})`
  font-size: 0.9em;
  border: solid 1px rgba(0, 0, 0, 0.12);
  &[aria-checked='true'] {
    border-color: ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.primary};
    background-color: ${({ theme }) => theme.orangeLight};
  }
`;

export const LinkButton = styled(UIButton).attrs({ variant: 'primary' })`
  font-size: 0.9em;
`;

export const TotalItemsByState = ({ value = 0 }) => {
  if (value === 0) return null;
  return <small>({value})</small>;
};

const PromotionState = {
  Draft: 'draft',
  Operational: 'operational',
  Archived: 'archived',
};

const PromotionsList = ({ applicableTo }) => {
  const { t } = useTranslation(['promotions', 'hotel', 'misc']);
  const [searchParams] = useSearchParams();
  const { hotelId } = useParams();
  const theme = useTheme();
  const [promotionState, setPromotionState] = useState(
    PromotionState.Operational,
  );

  const [promotions, setPromotions] = useState([]);

  const { data: dataTotal } = useQuery(GET_PROMOTIONS, {
    variables: { hotelId, applicableTo },
  });

  const sortParams = { ...searchParams };
  if (searchParams.order === 'status') {
    sortParams.order = 'kind';
  }
  const { data, loading } = useQuery(GET_PROMOTIONS, {
    variables: pickBy(
      {
        hotelId,
        state: promotionState,
        applicableTo,
        order: formatOrderCriteria(sortParams),
      },
      value => !isNil(value),
    ),
  });

  useEffect(() => {
    let isMounted = true;
    if (isMounted && !loading) {
      const promoArray = [...data?.promotions?.nodes];
      if (promoArray.length >= 2 && searchParams.order === 'status') {
        promoArray.sort((a, b) => {
          const res = a.status.localeCompare(b.status);
          return searchParams.direction === 'asc' ? res : -res;
        });
      }

      setPromotions(promoArray);
    }

    return () => {
      isMounted = false;
    };
  }, [
    data?.promotions?.nodes,
    loading,
    searchParams.direction,
    searchParams.order,
  ]);

  const byStateTotal = useMemo(() => {
    return (dataTotal?.promotions?.nodes ?? []).reduce(
      (acc, curr) => {
        if (curr?.state) {
          if (curr?.state === 'draft') {
            acc.draft = acc.draft + 1;
          }
          if (curr?.state === 'archived') {
            acc.archived = acc.archived + 1;
          }
          if (curr?.state === 'operational') {
            acc.operational = acc.operational + 1;
          }
        }
        return acc;
      },
      { draft: 0, archived: 0, operational: 0 },
    );
  }, [dataTotal]);

  const headers = [
    { slug: 'kind', label: t('dealType'), width: '14%' },
    { slug: 'name', label: t('promotionName'), width: '20%' },
    {
      slug: 'minNumberOfRooms',
      label: t('minRooms'),
      width: '12%',
      cannotBeReordered: true,
    },
    {
      slug: 'benefit',
      label: t('benefits'),
      width: '12%',
      cannotBeReordered: true,
    },
    {
      slug: 'booking',
      label: t('bookings'),
      cannotBeReordered: true,
      width: '10%',
    },
    {
      slug: 'status',
      label: t('status'),
      width: '12%',
    },
    {
      slug: 'action',
      label: '',
      cannotBeReordered: true,
      width: '5%',
    },
  ];

  const handleStateSelection = value => {
    setPromotionState(() => value);
  };

  return (
    <Stack direction="row">
      <LinkSectionWrapper>
        <CustomNavLink
          to={`/hotels/catalog/${hotelId}/promotions`}
          className={applicableTo === 'dmc' ? 'active' : ''}
        >
          <span>{t('dmctitle')}</span>
        </CustomNavLink>
        <CustomNavLink
          to={`/hotels/catalog/${hotelId}/promotions?applicableTo=to`}
          className={applicableTo === 'to' ? 'active' : ''}
        >
          <span>{t('totitle')}</span>
        </CustomNavLink>
      </LinkSectionWrapper>
      <SectionWrapper aria-labelledby="promotion-title-heading">
        <Text id="promotion-title-heading" as="h2">
          {t('promotions', { ns: 'hotel' })}
        </Text>
        <Stack
          gutterSize={0.5}
          alignY="center"
          style={{
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(0.25),
          }}
        >
          <ToggleButton
            aria-checked={PromotionState.Operational === promotionState}
            onClick={() => handleStateSelection(PromotionState.Operational)}
          >
            {t('operationalStatus')}
            <TotalItemsByState value={byStateTotal?.operational} />
          </ToggleButton>
          <ToggleButton
            aria-checked={PromotionState.Draft === promotionState}
            onClick={() => handleStateSelection(PromotionState.Draft)}
          >
            {t('draftStatus')} <TotalItemsByState value={byStateTotal?.draft} />
          </ToggleButton>
          <ToggleButton
            aria-checked={PromotionState.Archived === promotionState}
            onClick={() => handleStateSelection(PromotionState.Archived)}
          >
            {t('archivedStatus')}{' '}
            <TotalItemsByState value={byStateTotal?.archived} />
          </ToggleButton>
          <LinkButton
            to={`./new?applicableTo=${applicableTo}`}
            style={{ marginLeft: 'auto' }}
            icon={<AiOutlinePlusCircle size={18} aria-hidden="true" />}
          >
            {t('new')}
          </LinkButton>
        </Stack>
        {loading ? (
          <p>{t('loadingMessage', { ns: 'misc' })}</p>
        ) : (
          <PromotionsListTable
            data={promotions}
            applicableTo={applicableTo}
            type="hotel"
            headers={headers}
          />
        )}
      </SectionWrapper>
    </Stack>
  );
};

export default PromotionsList;
