import { get, put } from './index';

export const getDefaultIncludedServices = ({
  organizationId,
  locale = 'en',
}) => {
  return get(`/organizations/${organizationId}/defaults/included-services`, {
    headers: {
      'Accept-Language': locale,
    },
  });
};

export const saveDefaultIncludedServices = (
  organizationId,
  locale = 'en',
  payload,
) => {
  return put(
    `/organizations/${organizationId}/defaults/included-services`,
    payload,
    {
      headers: {
        'Accept-Language': locale,
      },
    },
  );
};

export const getDefaultExcludedServices = ({
  organizationId,
  locale = 'en',
}) => {
  return get(`/organizations/${organizationId}/defaults/excluded-services`, {
    headers: {
      'Accept-Language': locale,
    },
  });
};

export const saveDefaultExcludedServices = (
  organizationId,
  locale = 'en',
  payload,
) => {
  return put(
    `/organizations/${organizationId}/defaults/excluded-services`,
    payload,
    {
      headers: {
        'Accept-Language': locale,
      },
    },
  );
};
