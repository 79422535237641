import { useTranslation } from 'react-i18next';
import { FieldArray, Form, Formik } from 'formik';
import { Stack } from '@tymate/margaret';
import { Button } from 'components';
import { useAsync } from 'react-async';
import {
  modifyCurrencyExchangeRates,
  getCurrencyExchangeRates,
} from 'api/node/currencyExchangeRates';
import { MdAddCircleOutline } from 'react-icons/md';
import {
  Table,
  TableWrapper,
  TBody,
  Td,
  Th,
  THead,
  Tr,
} from '../../../../ui/tables';
import React from 'react';
import { FastTextField, TogglableField } from '../../../../components/Fields';
import { useQuery } from '@apollo/client';
import { GET_CURRENCIES } from '../../../../api/contracts';
import { formatCurrencyLabel, formatDate } from '../../../../utils';
import FastCurrencyField from '../../../../components/Fields/FastCurrencyField';
import { IcArchive, IcRemoveCircle } from '../../../../components/icons';
import { useError, useSnack } from '../../../../hooks';
import * as Yup from 'yup';

const ExchangeRatesForm = ({ dmcCurrency, isAllowedToManage }) => {
  const { t } = useTranslation('currencyExchangeRateModal');
  const { sendErrorSnack } = useError();
  const { notify } = useSnack();

  const { data, reload } = useAsync({
    promiseFn: getCurrencyExchangeRates,
  });
  const exchangeRates = data?.data ?? [];

  const { data: currenciesData } = useQuery(GET_CURRENCIES);
  const currenciesOptions = (currenciesData?.__type?.enumValues || [])
    .filter(item => item.name !== dmcCurrency)
    .map(({ name: currency }) => ({
      value: currency,
      label: formatCurrencyLabel(currency),
    }));

  const initialValues = {
    rates: exchangeRates,
  };

  const disableExchangeRate = async applyChange => {
    if (!window.confirm(t('form.confirmArchivingCurrencyRate'))) {
      return;
    }
    applyChange();
  };

  const handleCurrencyFormSubmit = async (values, { resetForm }) => {
    try {
      await modifyCurrencyExchangeRates(values.rates);
      await resetForm();
      await reload();
      notify(t('form.currenciesModifiedSuccessfully'));
    } catch (e) {
      if (e?.response?.data?.message) {
        notify(e?.response?.data?.message, { type: 'error' });
      } else {
        sendErrorSnack(e);
      }
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleCurrencyFormSubmit}
      enableReinitialize={true}
      validationSchema={Yup.object().shape({
        rates: Yup.array()
          .of(
            Yup.object().shape({
              toCurrency: Yup.string().required(t('errors:required')),
              rate: Yup.number().required(t('errors:required')),
            }),
          )
          .min(1),
      })}
    >
      {({ values, isLoading, dirty, isValid, setFieldValue }) => (
        <Stack direction="row" gutterSize={1} size="full">
          <Form style={{ width: '100%' }}>
            <FieldArray
              name={`rates`}
              render={({ push, remove }) => {
                return (
                  <>
                    <div class="mb-3">
                      <Button
                        icon={<MdAddCircleOutline />}
                        variant="simple"
                        onClick={() => {
                          push({ fromCurrency: dmcCurrency, archived: false });
                        }}
                        disabled={!isAllowedToManage}
                      >
                        {t('form.newExchangeRate')}
                      </Button>
                    </div>

                    <Stack
                      direction={'column'}
                      size="full"
                      alignX="center"
                      gutterSize={1}
                    >
                      <TableWrapper
                        variant="bordered"
                        style={{ overflow: 'visible' }}
                      >
                        <Table variant="bordered">
                          <THead>
                            <Tr>
                              <Th>{t('form.myCurrency')}</Th>
                              <Th>{t('form.partnersCurrency')}</Th>
                              <Th>{t('form.rate')}</Th>
                              <Th>{t('form.createdAt')}</Th>
                              <Th>{t('form.archivedAt')}</Th>
                              <Th>{t('form.actions')}</Th>
                            </Tr>
                          </THead>

                          <TBody>
                            {values.rates?.map((rate, idx) => (
                              <Tr key={idx}>
                                <Td allowOverflow={true}>
                                  <TogglableField
                                    disabled={true}
                                    name={`rates.${idx}.fromCurrency`}
                                    activeElement={
                                      <Stack
                                        direction="column"
                                        size="full"
                                        gutterSize={0.5}
                                      >
                                        <FastTextField
                                          name={`rates.${idx}.fromCurrency`}
                                          disabled={true}
                                        />
                                      </Stack>
                                    }
                                    idleElement={
                                      <Stack
                                        direction="column"
                                        gutterSize={0.5}
                                        size="full"
                                      >
                                        <Stack gutterSize={0.25} size="full">
                                          <span>{rate?.fromCurrency}</span>
                                        </Stack>
                                      </Stack>
                                    }
                                  />
                                </Td>

                                <Td allowOverflow={true}>
                                  <TogglableField
                                    disabled={!isAllowedToManage}
                                    name={`rates.${idx}.toCurrency`}
                                    activeElement={
                                      <Stack
                                        direction="column"
                                        size="full"
                                        gutterSize={0.5}
                                      >
                                        <FastCurrencyField
                                          currenciesOptions={currenciesOptions}
                                          name={`rates.${idx}.toCurrency`}
                                        />
                                      </Stack>
                                    }
                                    idleElement={
                                      <Stack
                                        direction="column"
                                        gutterSize={0.5}
                                        size="full"
                                      >
                                        <Stack gutterSize={0.25} size="full">
                                          <span>
                                            {rate?.toCurrency ?? 'N/A'}
                                          </span>
                                        </Stack>
                                      </Stack>
                                    }
                                  />
                                </Td>

                                <Td allowOverflow={true}>
                                  <TogglableField
                                    disabled={
                                      Boolean(rate?.id) || !isAllowedToManage
                                    }
                                    name={`rates.${idx}.rate`}
                                    activeElement={
                                      <Stack
                                        direction="column"
                                        size="full"
                                        gutterSize={0.5}
                                      >
                                        <FastTextField
                                          name={`rates.${idx}.rate`}
                                          disabled={Boolean(rate?.id)}
                                          type={'number'}
                                          min="0"
                                          step={0.01}
                                        />
                                      </Stack>
                                    }
                                    idleElement={
                                      <Stack
                                        direction="column"
                                        gutterSize={0.5}
                                        size="full"
                                      >
                                        <Stack gutterSize={0.25} size="full">
                                          <span>
                                            {rate?.rate && rate?.rate !== ''
                                              ? rate?.rate
                                              : 'N/A'}
                                          </span>
                                        </Stack>
                                      </Stack>
                                    }
                                  />
                                </Td>

                                <Td allowOverflow={true}>
                                  <TogglableField
                                    disabled={true}
                                    name={`rates.${idx}.createdAt`}
                                    activeElement={
                                      <Stack
                                        direction="column"
                                        size="full"
                                        gutterSize={0.5}
                                      >
                                        <FastTextField
                                          name={`rates.${idx}.createdAt`}
                                          disabled={true}
                                        />
                                      </Stack>
                                    }
                                    idleElement={
                                      <Stack
                                        direction="column"
                                        gutterSize={0.5}
                                        size="full"
                                      >
                                        <Stack gutterSize={0.25} size="full">
                                          <span>
                                            {rate?.createdAt
                                              ? formatDate(
                                                  rate?.createdAt,
                                                  'dd/MM/yyyy',
                                                )
                                              : ''}
                                          </span>
                                        </Stack>
                                      </Stack>
                                    }
                                  />
                                </Td>

                                <Td allowOverflow={true}>
                                  <TogglableField
                                    disabled={true}
                                    name={`rates.${idx}.discardedAt`}
                                    activeElement={
                                      <Stack
                                        direction="column"
                                        size="full"
                                        gutterSize={0.5}
                                      >
                                        <FastTextField
                                          name={`rates.${idx}.discardedAt`}
                                          disabled={true}
                                        />
                                      </Stack>
                                    }
                                    idleElement={
                                      <Stack
                                        direction="column"
                                        gutterSize={0.5}
                                        size="full"
                                      >
                                        <Stack gutterSize={0.25} size="full">
                                          <span>
                                            {rate?.discardedAt
                                              ? formatDate(
                                                  rate?.discardedAt,
                                                  'dd/MM/yyyy',
                                                )
                                              : ''}
                                          </span>
                                        </Stack>
                                      </Stack>
                                    }
                                  />
                                </Td>

                                <Td allowOverflow={true}>
                                  {rate?.id ? (
                                    <Button
                                      icon={<IcArchive />}
                                      variant="simple"
                                      disabled={
                                        Boolean(rate?.archived) ||
                                        !isAllowedToManage
                                      }
                                      onClick={async e => {
                                        e.preventDefault();
                                        disableExchangeRate(() => {
                                          setFieldValue(
                                            `rates.${idx}.archived`,
                                            true,
                                          );
                                        });
                                      }}
                                    />
                                  ) : (
                                    <Button
                                      icon={<IcRemoveCircle />}
                                      variant="simple"
                                      onClick={e => {
                                        e.preventDefault();
                                        remove(idx);
                                      }}
                                      disabled={!isAllowedToManage}
                                    />
                                  )}
                                </Td>
                              </Tr>
                            ))}
                          </TBody>
                        </Table>
                      </TableWrapper>
                    </Stack>
                  </>
                );
              }}
            />

            <Stack marginTop={2} size="full" alignX="flex-end">
              <Button
                type="submit"
                variant="primary"
                style={{ minWidth: 120, minHeight: 40, marginLeft: 25 }}
                disabled={isLoading || !dirty || !isValid}
              >
                {t('misc:save')}
              </Button>
            </Stack>
          </Form>
        </Stack>
      )}
    </Formik>
  );
};

export default ExchangeRatesForm;
