import { useAuth, useSnack } from 'hooks';
import { useQuery, gql } from '@apollo/client';
import { AppContext } from '../contexts';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useAsync } from 'react-async';
import { getToConnectionRequest } from 'api/node/toConnectionRequest';

const USER_QUERY = gql`
  query {
    currentUser {
      id
      firstName
      lastName
      email
      role
      toRequest
      organizations {
        nodes {
          id
          displayName
          avatarUrl(size: MEDIUM)
          organizationId
        }
      }
    }
  }
`;

const GET_LOCALES = gql`
  query getLocales {
    __type(name: "LocaleValues") {
      name
      enumValues {
        name
        description
      }
    }
  }
`;

const permissions = {
  inviteUser: ['koob_admin', 'dmc_admin'],
  manageOrganizations: ['koob_admin', 'dmc_admin'],
  readSelfOrganization: ['other'],
  manageSelfOrganization: ['dmc_admin'],
  manageSelfOrganizationUsers: ['koob_admin', 'dmc_admin'],
  discardOrganizations: ['koob_admin'],
  readUsers: ['koob_admin'],
  manageUsers: ['koob_admin'],
  readLocations: ['koob_admin', 'dmc_admin', 'other'],
  manageLocations: ['koob_admin'],
  manageExperienceSettings: ['koob_admin'],
  manageHotelSettings: ['koob_admin'],
  readExperienceSettings: ['koob_admin', 'dmc_admin', 'other'],
  readHotelSettings: ['koob_admin', 'dmc_admin', 'other'],
  readTravelerSettings: ['koob_admin', 'dmc_admin', 'other'],
  manageTravelerSettings: ['koob_admin'],
  createHotel: ['dmc_admin', 'other'],
  readHotel: ['dmc_admin', 'other'],
  readBookings: ['koob_admin', 'dmc_admin', 'other'],
  manageBookings: ['koob_admin', 'dmc_admin', 'other'],
  deleteBookings: ['dmc_admin'],
  manageCities: ['koob_admin'],
  canManageToRequestAccess: ['koob_admin'],
  canManageToConnectionRequest: ['koob_admin', 'dmc_admin'],
  manageKoobAdmin: ['koob_admin'],
  canShowcaseHotels: ['koob_admin', 'dmc_admin'],
  impersonate: ['koob_admin'],
};

const AppProvider = ({ children }) => {
  const { accessToken, logout } = useAuth();
  const { t } = useTranslation();
  const { notify } = useSnack();

  const { data: toConnectionRequestData } = useAsync({
    promiseFn: getToConnectionRequest,
  });

  const allowedConnectionRequests = toConnectionRequestData?.data;

  const { data, error } = useQuery(USER_QUERY, {
    fetchPolicy: 'network-only',
    skip: !Boolean(accessToken),
  });

  useEffect(() => {
    if (
      error &&
      error?.graphQLErrors?.some(
        e =>
          e.message ===
          'Cannot return null for non-nullable field Query.currentUser',
      )
    ) {
      logout();
      notify(t('errors:userNotAuthorized'), { type: 'error' });
    }
  }, [error]);

  const currentUserRole = data?.currentUser?.role;

  if (currentUserRole && currentUserRole.startsWith('to_')) {
    logout();
    notify(t('errors:userNotAuthorized'), { type: 'error' });
  }

  const { data: localesData } = useQuery(GET_LOCALES);

  const locales = localesData?.__type?.enumValues.map(
    ({ name, description }) => ({
      value: name,
      label: name.toUpperCase(),
      alpha2: description.toLowerCase(),
    }),
  );

  // const handleUpdateQueryLocale = locale => {
  //   localStorage.setItem('queryLocale', locale);
  //   setQueryLocale(locale);
  // };

  const canManageUser = targetUser => {
    const targetUserRole = targetUser?.role || 'other';

    if (currentUserRole === 'koob_admin') return true;

    if (targetUser?.id === data?.currentUser?.id) {
      return false;
    }

    switch (currentUserRole) {
      case 'super_admin':
        switch (targetUserRole) {
          case 'koob_admin':
            return true;
          default:
            return false;
        }
      case 'dmc_admin':
        switch (targetUserRole) {
          case 'other':
            return true;
          default:
            return false;
        }
      case 'other':
      default:
        return false;
    }
  };

  const isAllowedTo = name =>
    (permissions[name] || []).indexOf(currentUserRole) !== -1;

  // useDeepCompareEffect(() => {
  //   client.resetStore();
  // }, [{ queryLocale }]);

  if (!Boolean(data?.currentUser)) {
    return null;
  }

  const canManageToRequestAccess = () => {
    if (currentUserRole === 'koob_admin') {
      return true;
    }
  };

  const canManageToConnectionRequest = targetUser => {
    if (currentUserRole === 'koob_admin') {
      return true;
    }
    if (currentUserRole === 'dmc_admin' && targetUser.toRequest) {
      return true;
    }
    if (currentUserRole === 'other' && allowedConnectionRequests?.length >= 1) {
      return true;
    }
  };

  return (
    <AppContext.Provider
      value={{
        user: data?.currentUser,
        currentUserRole,
        currentOrganizationId: data?.currentUser?.organizations?.nodes?.[0]?.id,
        currentOrganization: data?.currentUser?.organizations?.nodes?.[0],
        currentOrganizationIdInt:
          data?.currentUser?.organizations?.nodes?.[0]?.organizationId,
        canManageUser,
        canManageToRequestAccess,
        canManageToConnectionRequest,
        isAllowedTo,
        locales,
        // queryLocale,
        // updateQueryLocale: handleUpdateQueryLocale,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
