import styled from 'styled-components';
import { Stack } from '@tymate/margaret';
import { formatAmount } from 'ui/tables';
import '@fontsource/roboto-mono';

const Price = styled(Stack).attrs({
  size: 'full',
  alignX: 'space-between',
})`
  color: ${({ theme }) => theme.textLight};
  font-family: 'Roboto Mono', monospace;
  & > *:first-child {
    white-space: nowrap;
  }
`;

const GitAndFitPrices = ({
  gitPrice,
  fitPrice,
  shouldShowGitPrice,
  currency,
}) => (
  <Stack direction="column" gutterSize={0.5} size="full">
    <Price gutterSize={0.25}>
      <span>{formatAmount({ amount: fitPrice, currency })}</span>
      <span>F</span>
    </Price>

    {shouldShowGitPrice && (
      <Price>
        <span>{formatAmount({ amount: gitPrice, currency })}</span>
        <span>G</span>
      </Price>
    )}
  </Stack>
);

export default GitAndFitPrices;
