import { memo } from 'react';

const IcHotelTower = props => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    style={{ ...props.style, color: props.color, fontSize: props.size }}
  >
    <path
      d="M9.708 8H4.292c-.576 0-1.042.466-1.042 1.042v12.083c0 .345.28.625.625.625h6.25c.345 0 .625-.28.625-.625V9.042C10.75 8.466 10.284 8 9.708 8zM9.5 9.25V20.5h-5V9.25h5z"
      fill="currentColor"
    />
    <path
      d="M8.875 5.5h-3.75a.625.625 0 00-.625.625v2.5c0 .345.28.625.625.625h3.75c.345 0 .625-.28.625-.625v-2.5a.625.625 0 00-.625-.625zM8.25 6.75V8h-2.5V6.75h2.5z"
      fill="currentColor"
    />
    <path
      d="M6.442 2.654c-.243.121-.44.318-.56.56L4.566 5.845a.625.625 0 00.559.905h3.75a.625.625 0 00.559-.905L8.118 3.214a1.25 1.25 0 00-1.56-.611l-.116.051zM6.136 5.5L7 3.773 7.863 5.5H6.136zm1.489 5a.625.625 0 01.092 1.243l-.092.007h-1.25a.625.625 0 01-.092-1.243l.092-.007h1.25zm0 2.5a.625.625 0 01.092 1.243l-.092.007h-1.25a.625.625 0 01-.092-1.243L6.375 13h1.25zm0 2.5a.625.625 0 01.092 1.243l-.092.007h-1.25a.625.625 0 01-.092-1.243l.092-.007h1.25zm0 2.5a.625.625 0 01.092 1.243l-.092.007h-1.25a.625.625 0 01-.092-1.243L6.375 18h1.25zM19.708 8h-5.416c-.576 0-1.042.466-1.042 1.042v12.083c0 .345.28.625.625.625h6.25c.345 0 .625-.28.625-.625V9.042c0-.576-.466-1.042-1.042-1.042zM19.5 9.25V20.5h-5V9.25h5z"
      fill="currentColor"
    />
    <path
      d="M18.875 5.5h-3.75a.625.625 0 00-.625.625v2.5c0 .345.28.625.625.625h3.75c.345 0 .625-.28.625-.625v-2.5a.625.625 0 00-.625-.625zm-.625 1.25V8h-2.5V6.75h2.5z"
      fill="currentColor"
    />
    <path
      d="M16.442 2.654c-.243.121-.44.318-.56.56l-1.316 2.631a.625.625 0 00.559.905h3.75a.625.625 0 00.559-.905l-1.316-2.631a1.25 1.25 0 00-1.56-.611l-.116.051zM16.136 5.5L17 3.773l.863 1.727h-1.727zm1.489 5a.625.625 0 01.092 1.243l-.092.007h-1.25a.625.625 0 01-.092-1.243l.092-.007h1.25zm0 2.5a.625.625 0 01.092 1.243l-.092.007h-1.25a.625.625 0 01-.092-1.243l.092-.007h1.25zm0 2.5a.625.625 0 01.092 1.243l-.092.007h-1.25a.625.625 0 01-.092-1.243l.092-.007h1.25zm0 2.5a.625.625 0 01.092 1.243l-.092.007h-1.25a.625.625 0 01-.092-1.243l.092-.007h1.25zm3.75 2.5a.625.625 0 01.092 1.243l-.092.007H2.625a.625.625 0 01-.092-1.243l.092-.007h18.75zm-7.5-10h-3.75a.625.625 0 00-.625.625v2.5c0 .345.28.625.625.625h3.75c.345 0 .625-.28.625-.625v-2.5a.625.625 0 00-.625-.625zm-.625 1.25V13h-2.5v-1.25h2.5z"
      fill="currentColor"
    />
  </svg>
);

export default memo(IcHotelTower);
