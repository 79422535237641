import { gql } from '@apollo/client';

export const GET_DMC_REQUEST_ACCESS = gql`
  query getDmcRequestAccess(
    $order: DmcRequestAccessOrderCriteria
    $endCursor: String
    $tripDesignerRequestState: String
  ) {
    dmcRequestAccess(
      first: 195
      after: $endCursor
      order: $order
      tripDesignerRequestState: $tripDesignerRequestState
    ) {
      edges {
        node {
          id
          comment
          user {
            id
          }
          tripDesignerRequestState
        }
      }
      totalCount
      pageInfo {
        hasPreviousPage
        startCursor
        hasNextPage
        endCursor
      }
    }
  }
`;
