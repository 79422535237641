import { gql } from '@apollo/client';

export const GET_PROMOTIONS = gql`
  query getPromotions(
    $hotelId: ID
    $state: PromotionStateValues
    $order: PromotionOrderCriteria
    $applicableTo: PromotionApplicableToValues
    $except: ID
    $isExpired: Boolean
    $mandatorySupplement: Boolean
  ) {
    promotions(
      hotelId: $hotelId
      state: $state
      order: $order
      except: $except
      applicableTo: $applicableTo
      isExpired: $isExpired
      mandatorySupplement: $mandatorySupplement
    ) {
      nodes {
        id
        kind
        name
        minNumberOfRooms
        benefit {
          kind
          currency
          value
          unit
          promoPerRoom
          amenities {
            nodes {
              displayName
            }
          }
          benefitAgeGroups {
            nodes {
              age
              value
            }
          }
        }
        state
        status
      }
    }
  }
`;

export const GET_HOTEL_ROOMS = gql`
  query getHotelRooms($id: ID!, $search: String) {
    node(id: $id) {
      ... on Hotel {
        rooms(displayName: $search) {
          nodes {
            displayName
            count
            id
          }
        }
      }
    }
  }
`;

export const GET_HOTEL_AMENITIES = gql`
  query getHotelAmenities($id: ID!, $displayName: String) {
    amenities(hotelId: $id, displayName: $displayName) {
      nodes {
        displayName
        id
      }
    }
  }
`;

export const CREATE_AMOUNT_PROMOTION = gql`
  mutation createAmountPromotion($input: CreateAmountPromotionInput!) {
    createAmountPromotion(input: $input) {
      promotion {
        id
      }
      errors {
        message
      }
    }
  }
`;

export const CREATE_FREENIGHTS_PROMOTION = gql`
  mutation createFreeNightPromotion($input: CreateFreeNightPromotionInput!) {
    createFreeNightPromotion(input: $input) {
      errors {
        message
      }
      promotion {
        id
      }
    }
  }
`;

export const CREATE_AMENITIES_PROMOTION = gql`
  mutation createAmenityPromotion($input: CreateAmenityPromotionInput!) {
    createAmenityPromotion(input: $input) {
      errors {
        message
      }
      promotion {
        id
      }
    }
  }
`;

export const CREATE_UPGRADED_ROOM_PROMOTION = gql`
  mutation createUpgradedRoomPromotion(
    $input: CreateUpgradedRoomPromotionInput!
  ) {
    createUpgradedRoomPromotion(input: $input) {
      errors {
        message
      }
      promotion {
        id
      }
    }
  }
`;

export const CREATE_PERCENT_PROMOTION = gql`
  mutation createPercentPromotion($input: CreatePercentPromotionInput!) {
    createPercentPromotion(input: $input) {
      errors {
        message
      }
      promotion {
        id
      }
    }
  }
`;

export const CREATE_SUPPLEMENT_PROMOTION = gql`
  mutation createSupplementPromotion($input: CreateSupplementPromotionInput!) {
    createSupplementPromotion(input: $input) {
      promotion {
        id
      }
      errors {
        message
      }
    }
  }
`;

export const CREATE_SUPPLEMENT_PERCENT_PROMOTION = gql`
  mutation createSupplementPercentPromotion(
    $input: CreateSupplementPercentPromotionInput!
  ) {
    createSupplementPercentPromotion(input: $input) {
      errors {
        message
      }
      promotion {
        id
      }
    }
  }
`;

export const GET_PROMOTION_BY_ID = gql`
  query getPromotionById($id: ID!) {
    node(id: $id) {
      ... on Promotion {
        id
        kind
        availablePeriods {
          nodes {
            availableDays
            startDate
            endDate
            promotionBookingPeriods {
              nodes {
                availableDays
                startDate
                endDate
              }
            }
          }
        }
        mandatorySupplement
        minNumberOfRooms
        name
        numberOfNights
        state
        status
        conditionalDaysCount
        cancelationPolicies
        customCancelationPolicies
        cumulativePromotions {
          nodes {
            id
            name
            applicableTo
          }
        }
        organizations {
          nodes {
            id
            displayName
          }
        }
        benefit {
          kind
          value
          promoPerRoom
          includeExtraBeds
          unit
          amenities {
            nodes {
              id
              displayName
            }
          }
          applicableRooms {
            nodes {
              id
              displayName
              count
            }
          }
          upgradedToRoom {
            id
            displayName
            count
          }
          upgradedFromRoom {
            count
            id
            displayName
          }
          benefitAgeGroups {
            nodes {
              operand
              age
              value
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_AMOUNT_PROMOTION = gql`
  mutation updateAmountPromotion($input: UpdateAmountPromotionInput!) {
    updateAmountPromotion(input: $input) {
      errors {
        message
      }
      promotion {
        id
      }
    }
  }
`;

export const UPDATE_FREENIGHTS_PROMOTION = gql`
  mutation updateFreeNightPromotion($input: UpdateFreeNightPromotionInput!) {
    updateFreeNightPromotion(input: $input) {
      errors {
        message
      }
      promotion {
        id
      }
    }
  }
`;

export const UPDATE_AMENITIES_PROMOTION = gql`
  mutation updateAmenityPromotion($input: UpdateAmenityPromotionInput!) {
    updateAmenityPromotion(input: $input) {
      errors {
        message
      }
      promotion {
        id
      }
    }
  }
`;

export const UPDATE_UPGRADED_ROOM_PROMOTION = gql`
  mutation updateUpgradedRoomPromotion(
    $input: UpdateUpgradedRoomPromotionInput!
  ) {
    updateUpgradedRoomPromotion(input: $input) {
      errors {
        message
      }
      promotion {
        id
      }
    }
  }
`;

export const UPDATE_PERCENT_PROMOTION = gql`
  mutation updatePercentPromotion($input: UpdatePercentPromotionInput!) {
    updatePercentPromotion(input: $input) {
      errors {
        message
      }
      promotion {
        id
      }
    }
  }
`;

export const UPDATE_SUPPLEMENT_PROMOTION = gql`
  mutation updateSupplementPromotion($input: UpdateSupplementPromotionInput!) {
    updateSupplementPromotion(input: $input) {
      errors {
        message
      }
      promotion {
        id
      }
    }
  }
`;

export const UPDATE_SUPPLEMENT_PERCENT_PROMOTION = gql`
  mutation updateSupplementPercentPromotion(
    $input: UpdateSupplementPercentPromotionInput!
  ) {
    updateSupplementPercentPromotion(input: $input) {
      errors {
        message
      }
      promotion {
        id
      }
    }
  }
`;
