import { FastField } from 'formik';
import { FormField, FormLabel, TextArea, ErrorMessageWrapper } from 'ui/forms';

const TextAreaField = ({ label, name, ...props }) => (
  <FormField>
    <FormLabel>{label}</FormLabel>
    <FastField name={name}>
      {({ field, meta }) => {
        const hasError = Boolean(meta.touched) && Boolean(meta.error);
        return (
          <>
            <TextArea {...props} {...field} hasError={hasError} />
            {hasError && (
              <ErrorMessageWrapper>{meta.error}</ErrorMessageWrapper>
            )}
          </>
        );
      }}
    </FastField>
  </FormField>
);

export default TextAreaField;
