import { Button as MgtButton } from '@tymate/margaret';
import { Link } from 'react-router-dom';

const Button = props => (
  <div className={props.disabled ? 'pointer-events-none' : ''}>
    <MgtButton
      {...props}
      to={props.to}
      as={Boolean(props.as) ? props.as : Boolean(props.to) ? Link : null}
    />
  </div>
);

export default Button;
