import { useRef, useState, forwardRef, useImperativeHandle } from 'react';
import { useField } from 'formik';
import styled from 'styled-components';
import { useDeepCompareEffect } from 'react-use';
import { Input as BaseInput } from 'ui/forms';
import { ActionButton } from 'ui';
import { MdArrowForward } from 'react-icons/md';

const Input = styled(BaseInput)`
  min-height: 24px;
  border: 0;

  &:disabled {
    border: 0;
    cursor: initial;
    background-color: transparent;
    box-shadow: none;
  }

  &:not([disabled]):hover,
  &:not([disabled]):focus {
    border: 0;
  }

  &:not([disabled]),
  &:not([disabled]):hover,
  &:not([disabled]):focus {
    box-shadow: inset 0 0 0 1px ${({ theme }) => theme.separator};
    padding-right: ${({ theme }) => theme.spacing(2)};
  }
`;

const Wrapper = styled.div`
  position: relative;

  > ${ActionButton} {
    position: absolute;
    right: ${({ theme }) => theme.spacing(0.25)};
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }
`;

const GalleryItemFileNameField = ({ name, onBlur, isEditing }, ref) => {
  const [field, , { setValue }] = useField({ name });
  const [previousValue, setPreviousValue] = useState(field.value);
  const inputRef = useRef();
  const wrapperRef = useRef();

  const handleCancel = () => {
    setValue(previousValue);
    onBlur();
  };

  const handleBlur = e => {
    if (Boolean(e)) {
      e.preventDefault();
    }

    if (field.value === '') {
      handleCancel();
      return;
    }

    setPreviousValue(field.value);
    onBlur();
  };

  const handleKeyDown = e => {
    if (e.keyCode === 27) {
      e.stopPropagation();
      handleCancel();
      return;
    }
    if (e.keyCode === 13) {
      e.stopPropagation();
      handleBlur();
      return;
    }
  };

  useImperativeHandle(ref, () => ({
    blur: () => {
      handleCancel();
    },
  }));

  useDeepCompareEffect(() => {
    if (isEditing) {
      try {
        inputRef.current.focus();
        inputRef.current.select();
        setPreviousValue(field.value);
      } catch (err) {}
    }
  }, [{ isEditing }]);

  return (
    <Wrapper ref={wrapperRef}>
      <Input
        {...field}
        ref={inputRef}
        disabled={!isEditing}
        onKeyDown={handleKeyDown}
      />
      {isEditing && (
        <ActionButton type="button" onClick={handleBlur}>
          <MdArrowForward size={20} />
        </ActionButton>
      )}
    </Wrapper>
  );
};

export default forwardRef(GalleryItemFileNameField);
