import { memo } from 'react';

const IcUsers = props => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    style={{ ...props.style, color: props.color, fontSize: props.size }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.25 6.25a2.813 2.813 0 100 5.626 2.813 2.813 0 000-5.626zM4.187 9.063a4.063 4.063 0 118.126 0 4.063 4.063 0 01-8.126 0z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.25 14.375a5 5 0 00-5 5 .625.625 0 11-1.25 0 6.25 6.25 0 016.25-6.25 6.25 6.25 0 016.25 6.25.625.625 0 11-1.25 0 5 5 0 00-5-5zM16.773 8.75a2.188 2.188 0 100 4.376 2.188 2.188 0 000-4.376zm-3.438 2.188a3.438 3.438 0 116.876 0 3.438 3.438 0 01-6.876 0z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.505 18.001a3.98 3.98 0 00-5.11-2.358.625.625 0 01-.433-1.172 5.23 5.23 0 016.715 3.098c.214.579.323 1.19.323 1.806a.625.625 0 11-1.25 0c0-.469-.083-.933-.245-1.374"
      fill="currentColor"
    />
  </svg>
);

export default memo(IcUsers);
