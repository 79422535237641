import { post, get } from './index';

export const exportExperience = ({
  experienceId,
  locale,
  exportFormat,
  options,
}) => {
  const headers = {
    'Accept-Language': locale,
  };
  return post(
    `experiences/${experienceId}/export/${exportFormat}`,
    options,
    { headers, responseType: 'blob' },
    false,
  );
};

export const exportExperiencesCompatibilities = ({ filters }) => {
  return post(
    'experiences/export/experiencesCompatibilities',
    filters,
    {
      responseType: 'blob',
    },
    false,
  );
};

export const getExperiences = async ({ filters, lang, skip = false }) => {
  if (skip) {
    return Promise.resolve(null);
  }

  const headers = {
    'Accept-Language': lang,
  };

  return await post('/experiences/liste', filters, { headers });
};

export const getExperience = ({ experienceId, lang = 'en' }) => {
  if (!experienceId) {
    return Promise.resolve(null);
  }

  const headers = {
    'Accept-Language': lang,
  };

  return get(`/experiences/${experienceId}`, { headers });
};

export const bookExperiences = data => {
  return post(`/api/v1/experiences/book`, data);
};
