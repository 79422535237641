import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { NavLink, Routes, Route, useLocation } from 'react-router-dom';
import { IoEarthOutline, IoBriefcaseOutline } from 'react-icons/io5';
import { Scrollbars } from 'react-custom-scrollbars';
import { RiBook3Line, RiLogoutBoxLine } from 'react-icons/ri';
import { MainNav, Stack } from '@tymate/margaret';
import { IcHotel, IcUsers, IcOrganization, IcTicket } from 'components/icons';
import Logo from 'components/icons/Logo';
import { useApp, useAuth, useSearchParams, useTripDesigner } from 'hooks';
import { CounterBadge, fontStyles, Text } from 'ui';
import { GET_SETTING_REQUEST } from 'api/settingRequest';
import { useQuery } from '@apollo/client';
import { isNil, pickBy } from 'lodash';
import { useEffect, useState } from 'react';
import { GET_TO_REQUEST_ACCESS } from 'api/toAccesRequest';
import { GET_DMC_REQUEST_ACCESS } from 'api/dmcAccesRequest';
import { VscTools } from 'react-icons/vsc';
import { BsBriefcase } from 'react-icons/bs';
import { AiOutlineAppstore } from 'react-icons/ai';
import { useAsync } from 'react-async';
import { getRequestAccess } from 'api/node/dmcRequestAccess';
import { getNotifications } from 'api/node/notifications';
import { permissionsEnum, stateEnum } from 'utils';

const SectionTitle = styled(Text).attrs({
  color: 'textLight',
  type: 'bodySmall',
  paddingHorizontal: 2,
})`
  text-transform: uppercase;
  font-weight: 600;
`;

const MainNavItems = styled(MainNav.Items).attrs({ gutterSize: 0.5 })`
  ${({ variant }) =>
    variant === 'nested' &&
    css`
      padding-right: 0;
      padding-left: 0;
      padding-bottom: 0;
    `}
`;

export const SidebarNavLink = styled(Stack).attrs({
  as: NavLink,
  gutterSize: 0.5,
  alignY: 'center',
})`
  ${fontStyles.bodySmall}

  color: ${({ theme }) => theme.textLight};
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing()};
  text-decoration: none;
  border-radius: ${({ theme }) => theme.borderRadius.default};
  transition: background 150ms ease, color 150ms ease;

  &:hover {
    background-color: ${({ theme }) => theme.sidebarBackgroundHoverColor};
    color: ${({ theme }) => theme.primary};
  }

  ${({ variant }) =>
    variant !== 'accordion' &&
    css`
      &:hover,
      &.active {
        color: ${({ theme }) => theme.primary};
        background-color: ${({ theme }) => theme.sidebarBackgroundHoverColor};
      }
    `}

  ${({ variant }) =>
    variant === 'accordion' &&
    css`
      margin-bottom: ${({ theme }) => theme.spacing(-0.5)};
    `}

  ${({ variant }) =>
    variant === 'nested' &&
    css`
      padding-left: ${({ theme }) => theme.spacing(3)};
    `}

  svg {
    font-size: 1.5rem;
  }
`;

const MenuSetting = styled(Stack).attrs({
  gutterSize: 2,
  size: 'full',
  alignY: 'center',
  alignX: 'space-between',
})``;

const useFilteredQuery = kind => {
  return useQuery(GET_SETTING_REQUEST, {
    variables: pickBy(
      {
        discarded: false,
        state: stateEnum.PENDING,
        kind: kind,
      },
      value => !isNil(value),
    ),
  });
};

const MainSidebar = () => {
  const { isAllowedTo, currentOrganizationId, user, canManageToRequestAccess } =
    useApp();
  const { resetContext } = useTripDesigner();
  const { t } = useTranslation('mainSidebar');
  const { logout } = useAuth();
  const [{ state, messageReaded }] = useSearchParams();
  const { pathname } = useLocation();
  const [isValidTripDesignerRequest, setIsValidTripDesignerRequest] = useState(
    localStorage.getItem('tripDesignerRequestState') === stateEnum.VALIDATED ??
      false,
  );
  const canDisplayTripNotification =
    pathname.split('/').indexOf('trip-designer') === -1;

  const { data, refetch } = useFilteredQuery(undefined);
  const { data: hotelSettingsPending, refetch: refetchHotelSettingCount } =
    useFilteredQuery('hotel');

  const { data: requestAccess, refetch: requestAccessRefetch } = useQuery(
    GET_TO_REQUEST_ACCESS,
    {
      variables: pickBy(
        {
          tripDesignerRequestState: stateEnum.PENDING,
          state: stateEnum.VALIDATED,
        },
        value => !isNil(value),
      ),
    },
  );

  const { data: dmcRequestAccess, refetch: dmcRequestAccessRefetch } = useQuery(
    GET_DMC_REQUEST_ACCESS,
    {
      variables: pickBy(
        {
          tripDesignerRequestState: stateEnum.PENDING,
        },
        value => !isNil(value),
      ),
    },
  );

  const { data: dmcRequest } = useAsync({
    promiseFn: getRequestAccess,
  });

  const {
    data: experienceSettingsPending,
    refetch: refetchExperienceSettingCount,
  } = useFilteredQuery('experience');

  const { data: tripsNotifications, reload: refetchTripNotifications } =
    useAsync({
      promiseFn: getNotifications,
      filters: {
        type: [
          'trip_help_requested',
          'trip_help_assistant',
          'trip_new_version_available',
        ],
        read: false,
      },
    });

  const tripsNotificationsCounter = tripsNotifications?.data?.length;

  useEffect(() => {
    refetch();
    requestAccessRefetch();
    dmcRequestAccessRefetch();
    refetchHotelSettingCount();
    refetchExperienceSettingCount();
  }, [
    dmcRequestAccessRefetch,
    refetch,
    refetchExperienceSettingCount,
    refetchHotelSettingCount,
    requestAccessRefetch,
    state,
  ]);

  useEffect(() => {
    refetchTripNotifications();
  }, [refetchTripNotifications, messageReaded]);

  useEffect(() => {
    if (dmcRequest?.data?.tripDesignerRequestState) {
      setIsValidTripDesignerRequest(dmcRequest?.data?.tripDesignerRequestState);
      localStorage.setItem(
        'tripDesignerRequestState',
        dmcRequest?.data?.tripDesignerRequestState,
      );
    }
  }, [dmcRequest?.data?.tripDesignerRequestState]);

  const toRequestAccesCount = requestAccess?.toRequestAccess?.totalCount ?? 0;
  const dmcRequestAccesCount =
    dmcRequestAccess?.dmcRequestAccess?.totalCount ?? 0;

  const requestAccesCount = toRequestAccesCount + dmcRequestAccesCount;

  const settingCounts = {};
  const tab = data?.settingRequests.edges ?? [];
  for (const item of tab) {
    const settingName = item.node.settingName;
    settingCounts[settingName] = (settingCounts[settingName] || 0) + 1;
  }

  const totalExperienceSettingPending =
    experienceSettingsPending?.settingRequests?.totalCount === 0
      ? ''
      : experienceSettingsPending?.settingRequests?.totalCount;

  const totalPending =
    hotelSettingsPending?.settingRequests?.totalCount === 0
      ? ''
      : hotelSettingsPending?.settingRequests?.totalCount;

  const facility_group =
    (settingCounts['facility'] ?? 0) + (settingCounts['group'] ?? 0);
  const facilityCount = facility_group === 0 ? '' : facility_group;

  return (
    <MainNav style={{ zIndex: 11, paddingRight: 16 }}>
      <MainNav.Header alignX="center" size="full" marginTop={1}>
        <Logo />
      </MainNav.Header>

      <Scrollbars autoHide>
        <MainNav.MainContent
          style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
        >
          <MainNavItems gutterSize={0.25}>
            {/* <MainNav.Item>
              <SidebarNavLink to="/" end>
                <IcDashboard />
                <span>{t('dashboard')}</span>
              </SidebarNavLink>
            </MainNav.Item> */}
            {/* <MainNav.Item>
              <SidebarNavLink to="billing">
                <IcCreditCard />
                <span>{t('billing')}</span>
              </SidebarNavLink>
            </MainNav.Item> */}

            {isAllowedTo('manageOrganizations') && (
              <MainNav.Item>
                <SidebarNavLink to="organizations">
                  <IcOrganization />
                  <MenuSetting>
                    <span>{t('organizations')}</span>
                    {isAllowedTo('manageHotelSettings') && (
                      <CounterBadge>
                        {requestAccesCount > 0 ? requestAccesCount : ''}
                      </CounterBadge>
                    )}
                  </MenuSetting>
                </SidebarNavLink>
              </MainNav.Item>
            )}
            {isAllowedTo('readSelfOrganization') && (
              <MainNav.Item>
                <SidebarNavLink to={`organizations/${currentOrganizationId}`}>
                  <IcOrganization />
                  <span>{t('myOrganization')}</span>
                </SidebarNavLink>
              </MainNav.Item>
            )}

            {isAllowedTo('readBookings') && (
              <MainNav.Item>
                <SidebarNavLink to="bookings/my-bookings" variant="accordion">
                  <IcTicket />
                  <span>{t('bookings')}</span>
                </SidebarNavLink>
                <Routes>
                  <Route
                    path="bookings/*"
                    element={
                      <MainNavItems variant="nested">
                        <MainNav.Item>
                          <SidebarNavLink to="my-bookings" variant="nested">
                            <span>{t('booking')}</span>
                          </SidebarNavLink>
                        </MainNav.Item>

                        <MainNav.Item>
                          <SidebarNavLink
                            to="partner-bookings"
                            variant="nested"
                          >
                            <span>{t('bookingPartners')}</span>
                          </SidebarNavLink>
                        </MainNav.Item>
                      </MainNavItems>
                    }
                  />
                </Routes>
              </MainNav.Item>
            )}

            <MainNav.Item>
              <SidebarNavLink to="hotels" variant="accordion">
                <IcHotel />
                <span>{t('hotels')}</span>
              </SidebarNavLink>
              <Routes>
                <Route
                  path="hotels/*"
                  element={
                    <MainNavItems variant="nested">
                      <MainNav.Item>
                        <SidebarNavLink to="catalog" variant="nested">
                          <span>{t('myCatalog')}</span>
                        </SidebarNavLink>
                      </MainNav.Item>
                      {isAllowedTo('createHotel') && (
                        <MainNav.Item>
                          <SidebarNavLink to="new" variant="nested">
                            <span>{t('searchNewHotel')}</span>
                          </SidebarNavLink>
                        </MainNav.Item>
                      )}
                    </MainNavItems>
                  }
                />
              </Routes>
            </MainNav.Item>

            <MainNav.Item>
              <SidebarNavLink to="experiences">
                <div className="p-0.5">
                  <svg className="h-5 w-5" viewBox="0 0 512 512">
                    <path d="M234.2 4.672C241 1.592 248.5 0 256 0C263.5 0 270.1 1.592 277.8 4.672L495.2 102.1C505.4 106.7 512 116.8 512 128C512 139.2 505.4 149.3 495.2 153.9L277.8 251.3C270.1 254.4 263.5 256 256 256C248.5 256 241 254.4 234.2 251.3L16.76 153.9C6.561 149.3 .0003 139.2 .0003 128C.0003 116.8 6.561 106.7 16.76 102.1L234.2 4.672zM256 32C252.1 32 249.1 32.64 247.2 33.87L37.27 128L247.2 222.1C249.1 223.4 252.1 224 256 224C259 224 262 223.4 264.8 222.1L474.7 128L264.8 33.87C262 32.64 259 32 256 32V32zM78.6 219.9C82.22 228 78.61 237.5 70.55 241.1L37.27 256L247.2 350.1C249.1 351.4 252.1 352 256 352C259 352 262 351.4 264.8 350.1L474.7 256L441.5 241.1C433.4 237.5 429.8 228 433.4 219.9C437 211.9 446.5 208.3 454.5 211.9L495.2 230.1C505.4 234.7 512 244.8 512 256C512 267.2 505.4 277.3 495.2 281.9L277.8 379.3C270.1 382.4 263.5 384 256 384C248.5 384 241 382.4 234.2 379.3L16.76 281.9C6.561 277.3 0 267.2 0 256C0 244.8 6.561 234.7 16.76 230.1L57.46 211.9C65.52 208.3 74.99 211.9 78.6 219.9H78.6zM37.27 384L247.2 478.1C249.1 479.4 252.1 480 256 480C259 480 262 479.4 264.8 478.1L474.7 384L441.5 369.1C433.4 365.5 429.8 356 433.4 347.9C437 339.9 446.5 336.3 454.5 339.9L495.2 358.1C505.4 362.7 512 372.8 512 384C512 395.2 505.4 405.3 495.2 409.9L277.8 507.3C270.1 510.4 263.5 512 256 512C248.5 512 241 510.4 234.2 507.3L16.76 409.9C6.561 405.3 0 395.2 0 384C0 372.8 6.561 362.7 16.76 358.1L57.46 339.9C65.52 336.3 74.99 339.9 78.6 347.9C82.21 356 78.61 365.5 70.54 369.1L37.27 384z" />
                  </svg>
                </div>
                <span>{t('experiences')}</span>
              </SidebarNavLink>
            </MainNav.Item>

            {isAllowedTo(permissionsEnum.READ_BOOKINGS) && (
              <MainNav.Item>
                <SidebarNavLink
                  to={
                    !isValidTripDesignerRequest
                      ? 'trip-designer/'
                      : 'trip-designer/trips'
                  }
                  variant="accordion"
                >
                  <AiOutlineAppstore />
                  <Stack direction="column">
                    <div className="flex items-center">
                      <span>{t('tripDesigner')}</span>
                      {canDisplayTripNotification &&
                        tripsNotificationsCounter > 0 && (
                          <CounterBadge>
                            {tripsNotificationsCounter}
                          </CounterBadge>
                        )}
                    </div>
                    {!isValidTripDesignerRequest && (
                      <div className={'text-sm text-orange-500'}>
                        {t('discover')}
                      </div>
                    )}
                  </Stack>
                </SidebarNavLink>
                {isValidTripDesignerRequest && (
                  <Routes>
                    <Route
                      path="trip-designer/*"
                      element={
                        <MainNavItems variant="nested">
                          <MainNav.Item>
                            <SidebarNavLink
                              to="design-trip"
                              variant="nested"
                              onClick={() => resetContext()}
                            >
                              <VscTools />
                              <span>{t('designTrip')}</span>
                            </SidebarNavLink>
                          </MainNav.Item>

                          <MainNav.Item>
                            <SidebarNavLink to="trips" variant="nested">
                              <BsBriefcase />
                              <span>{t('trips')}</span>
                            </SidebarNavLink>
                          </MainNav.Item>

                          <MainNav.Item>
                            <SidebarNavLink to="library/trips" variant="nested">
                              <div className="w-full flex items-center space-x-2">
                                <RiBook3Line />
                                <span className="whitespace-nowrap">
                                  {t('library')}
                                </span>
                                {tripsNotificationsCounter > 0 && (
                                  <CounterBadge>
                                    {tripsNotificationsCounter}
                                  </CounterBadge>
                                )}
                              </div>
                            </SidebarNavLink>
                          </MainNav.Item>
                        </MainNavItems>
                      }
                    />
                  </Routes>
                )}
              </MainNav.Item>
            )}

            {isAllowedTo('readUsers') && (
              <MainNav.Item>
                <SidebarNavLink to="users">
                  <IcUsers />
                  <span>{t('users')}</span>
                </SidebarNavLink>
              </MainNav.Item>
            )}
            {isAllowedTo('readLocations') && (
              <MainNav.Item>
                <SidebarNavLink to="locations" variant="accordion">
                  <IoEarthOutline />
                  <span>{t('locations')}</span>
                </SidebarNavLink>
                <Routes>
                  <Route
                    path="locations/*"
                    element={
                      <MainNavItems variant="nested">
                        <MainNav.Item>
                          <SidebarNavLink to="countries" variant="nested">
                            <span>{t('countries')}</span>
                          </SidebarNavLink>
                        </MainNav.Item>
                        <MainNav.Item>
                          <SidebarNavLink to="cities" variant="nested">
                            <span>{t('cities')}</span>
                          </SidebarNavLink>
                        </MainNav.Item>
                        <MainNav.Item>
                          <SidebarNavLink to="markets" variant="nested">
                            <span>{t('markets')}</span>
                          </SidebarNavLink>
                        </MainNav.Item>
                      </MainNavItems>
                    }
                  />
                </Routes>
              </MainNav.Item>
            )}
          </MainNavItems>

          <SectionTitle paddingTop={2}>{t('settings')}</SectionTitle>

          <MainNavItems gutterSize={0.25}>
            {isAllowedTo('readHotelSettings') && (
              <MainNav.Item>
                <SidebarNavLink
                  to="settings/hotels/categories"
                  variant="accordion"
                >
                  <IcHotel />
                  <MenuSetting>
                    <span>{t('hotelsSettings')}</span>
                    {isAllowedTo('manageHotelSettings') && (
                      <CounterBadge>{totalPending}</CounterBadge>
                    )}
                  </MenuSetting>
                </SidebarNavLink>

                <Routes>
                  <Route
                    path="settings/hotels/*"
                    element={
                      <MainNavItems variant="nested">
                        <MainNav.Item>
                          <SidebarNavLink to="categories" variant="nested">
                            <MenuSetting>
                              <span>{t('categories')}</span>
                              {isAllowedTo('manageHotelSettings') && (
                                <CounterBadge>
                                  {settingCounts['category']}
                                </CounterBadge>
                              )}
                            </MenuSetting>
                          </SidebarNavLink>
                        </MainNav.Item>
                        <MainNav.Item>
                          <SidebarNavLink to="styles" variant="nested">
                            <MenuSetting>
                              <span>{t('styles')}</span>
                              {isAllowedTo('manageHotelSettings') && (
                                <CounterBadge>
                                  {settingCounts['style']}
                                </CounterBadge>
                              )}
                            </MenuSetting>
                          </SidebarNavLink>
                        </MainNav.Item>
                        <MainNav.Item>
                          <SidebarNavLink to="locations" variant="nested">
                            <MenuSetting>
                              <span>{t('locations')}</span>
                              {isAllowedTo('manageHotelSettings') && (
                                <CounterBadge>
                                  {settingCounts['location']}
                                </CounterBadge>
                              )}
                            </MenuSetting>
                          </SidebarNavLink>
                        </MainNav.Item>
                        <MainNav.Item>
                          <SidebarNavLink to="accreditations" variant="nested">
                            <MenuSetting>
                              <span>{t('accreditations')}</span>
                              {isAllowedTo('manageHotelSettings') && (
                                <CounterBadge>
                                  {settingCounts['accreditations']}
                                </CounterBadge>
                              )}
                            </MenuSetting>
                          </SidebarNavLink>
                        </MainNav.Item>
                        <MainNav.Item>
                          <SidebarNavLink
                            to="general-categories"
                            variant="nested"
                          >
                            <MenuSetting>
                              <span>{t('descriptions')}</span>
                              {isAllowedTo('manageHotelSettings') && (
                                <CounterBadge>
                                  {settingCounts['descriptions']}
                                </CounterBadge>
                              )}
                            </MenuSetting>
                          </SidebarNavLink>
                        </MainNav.Item>
                        <MainNav.Item>
                          <SidebarNavLink to="dmc-categories" variant="nested">
                            <MenuSetting>
                              <span>{t('dmcCategories')}</span>
                              {isAllowedTo('manageHotelSettings') && (
                                <CounterBadge>
                                  {settingCounts['dmc-category']}
                                </CounterBadge>
                              )}
                            </MenuSetting>
                          </SidebarNavLink>
                        </MainNav.Item>
                        <MainNav.Item>
                          <SidebarNavLink to="room-types" variant="nested">
                            <MenuSetting>
                              <span>{t('roomTypes')}</span>
                              {isAllowedTo('manageHotelSettings') && (
                                <CounterBadge>
                                  {settingCounts['room']}
                                </CounterBadge>
                              )}
                            </MenuSetting>
                          </SidebarNavLink>
                        </MainNav.Item>
                        <MainNav.Item>
                          <SidebarNavLink to="bed-types" variant="nested">
                            <MenuSetting>
                              <span>{t('bedTypes')}</span>
                              {isAllowedTo('manageHotelSettings') && (
                                <CounterBadge>
                                  {settingCounts['bed']}
                                </CounterBadge>
                              )}
                            </MenuSetting>
                          </SidebarNavLink>
                        </MainNav.Item>
                        <MainNav.Item>
                          <SidebarNavLink
                            to="sustainable-clusters"
                            variant="nested"
                          >
                            <MenuSetting>
                              <span>{t('sustainableClusters')}</span>
                              {isAllowedTo('manageHotelSettings') && (
                                <CounterBadge>
                                  {settingCounts['sustainable']}
                                </CounterBadge>
                              )}
                            </MenuSetting>
                          </SidebarNavLink>
                        </MainNav.Item>
                        <MainNav.Item>
                          <SidebarNavLink
                            to="facilities-groups"
                            variant="nested"
                          >
                            <MenuSetting>
                              <span>{t('facilitiesGroups')}</span>
                              {isAllowedTo('manageHotelSettings') && (
                                <CounterBadge>{facilityCount}</CounterBadge>
                              )}
                            </MenuSetting>
                          </SidebarNavLink>
                        </MainNav.Item>
                        <MainNav.Item>
                          <SidebarNavLink to="meal-plans" variant="nested">
                            <MenuSetting>
                              <span>{t('mealPlans')}</span>
                              {isAllowedTo('manageHotelSettings') && (
                                <CounterBadge>
                                  {settingCounts['meal-plan']}
                                </CounterBadge>
                              )}
                            </MenuSetting>
                          </SidebarNavLink>
                        </MainNav.Item>
                      </MainNavItems>
                    }
                  />
                </Routes>
              </MainNav.Item>
            )}

            {isAllowedTo('readExperienceSettings') && (
              <MainNav.Item>
                <SidebarNavLink to="settings/experiences" variant="accordion">
                  <div className="p-0.5">
                    <svg className="h-5 w-5" viewBox="0 0 512 512">
                      <path d="M234.2 4.672C241 1.592 248.5 0 256 0C263.5 0 270.1 1.592 277.8 4.672L495.2 102.1C505.4 106.7 512 116.8 512 128C512 139.2 505.4 149.3 495.2 153.9L277.8 251.3C270.1 254.4 263.5 256 256 256C248.5 256 241 254.4 234.2 251.3L16.76 153.9C6.561 149.3 .0003 139.2 .0003 128C.0003 116.8 6.561 106.7 16.76 102.1L234.2 4.672zM256 32C252.1 32 249.1 32.64 247.2 33.87L37.27 128L247.2 222.1C249.1 223.4 252.1 224 256 224C259 224 262 223.4 264.8 222.1L474.7 128L264.8 33.87C262 32.64 259 32 256 32V32zM78.6 219.9C82.22 228 78.61 237.5 70.55 241.1L37.27 256L247.2 350.1C249.1 351.4 252.1 352 256 352C259 352 262 351.4 264.8 350.1L474.7 256L441.5 241.1C433.4 237.5 429.8 228 433.4 219.9C437 211.9 446.5 208.3 454.5 211.9L495.2 230.1C505.4 234.7 512 244.8 512 256C512 267.2 505.4 277.3 495.2 281.9L277.8 379.3C270.1 382.4 263.5 384 256 384C248.5 384 241 382.4 234.2 379.3L16.76 281.9C6.561 277.3 0 267.2 0 256C0 244.8 6.561 234.7 16.76 230.1L57.46 211.9C65.52 208.3 74.99 211.9 78.6 219.9H78.6zM37.27 384L247.2 478.1C249.1 479.4 252.1 480 256 480C259 480 262 479.4 264.8 478.1L474.7 384L441.5 369.1C433.4 365.5 429.8 356 433.4 347.9C437 339.9 446.5 336.3 454.5 339.9L495.2 358.1C505.4 362.7 512 372.8 512 384C512 395.2 505.4 405.3 495.2 409.9L277.8 507.3C270.1 510.4 263.5 512 256 512C248.5 512 241 510.4 234.2 507.3L16.76 409.9C6.561 405.3 0 395.2 0 384C0 372.8 6.561 362.7 16.76 358.1L57.46 339.9C65.52 336.3 74.99 339.9 78.6 347.9C82.21 356 78.61 365.5 70.54 369.1L37.27 384z" />
                    </svg>
                  </div>
                  <MenuSetting>
                    <span>{t('experiencesSettings')}</span>
                    {isAllowedTo('manageHotelSettings') && (
                      <CounterBadge>
                        {totalExperienceSettingPending}
                      </CounterBadge>
                    )}
                  </MenuSetting>
                </SidebarNavLink>

                <Routes>
                  <Route
                    path="settings/experiences/*"
                    element={
                      <MainNavItems variant="nested">
                        <MainNav.Item>
                          <SidebarNavLink to="segments" variant="nested">
                            <MenuSetting>
                              <span>{t('segments')}</span>
                              {isAllowedTo('manageHotelSettings') && (
                                <CounterBadge>
                                  {settingCounts['segment']}
                                </CounterBadge>
                              )}
                            </MenuSetting>
                          </SidebarNavLink>
                        </MainNav.Item>

                        <MainNav.Item>
                          <SidebarNavLink to="themes" variant="nested">
                            <MenuSetting>
                              <span>{t('themes')}</span>
                              {isAllowedTo('manageHotelSettings') && (
                                <CounterBadge>
                                  {settingCounts['theme']}
                                </CounterBadge>
                              )}
                            </MenuSetting>
                          </SidebarNavLink>
                        </MainNav.Item>
                      </MainNavItems>
                    }
                  />
                </Routes>
              </MainNav.Item>
            )}

            {isAllowedTo('readTravelerSettings') && (
              <MainNav.Item>
                <SidebarNavLink to="settings/travelers" variant="accordion">
                  <IoBriefcaseOutline />
                  <span>{t('travelersSettings')}</span>
                </SidebarNavLink>
                <Routes>
                  <Route
                    path="settings/travelers/*"
                    element={
                      <MainNavItems variant="nested">
                        <MainNav.Item>
                          <SidebarNavLink to="traveler-kinds" variant="nested">
                            <span>{t('categories')}</span>
                          </SidebarNavLink>
                        </MainNav.Item>
                      </MainNavItems>
                    }
                  />
                </Routes>
              </MainNav.Item>
            )}
            {(isAllowedTo('canManageToRequestAccess') ||
              canManageToRequestAccess(user)) && (
              <MainNav.Item>
                <SidebarNavLink to="to-request-access">
                  <IcUsers />
                  <span>{t('requestAccess')}</span>
                </SidebarNavLink>
              </MainNav.Item>
            )}
            <MainNav.Item>
              <SidebarNavLink to="to-connection-request">
                <IcUsers />
                <span>{t('connectionRequest')}</span>
              </SidebarNavLink>
            </MainNav.Item>
          </MainNavItems>
          <div className="mt-auto self-start">
            <MainNavItems>
              <MainNav.Item>
                <SidebarNavLink
                  to="traveler-kinds"
                  onClick={logout}
                  variant="accordion"
                >
                  <RiLogoutBoxLine />
                  <span>{t('signOut')}</span>
                </SidebarNavLink>
              </MainNav.Item>
            </MainNavItems>

            <MainNavItems>
              <MainNav.Item>
                <a
                  href="https://docs.google.com/document/d/1GGkzrbXcatrITETOAxuE1f5Y_rV52IX7/edit?usp=sharing&ouid=115736253249552984829&rtpof=true&sd=true"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="flex justify-center items-center space-x-2">
                    <svg className="w-[20px] h-[20px]" viewBox="0 0 448 512">
                      <path d="M448 352V48C448 21.53 426.5 0 400 0h-320C35.89 0 0 35.88 0 80v352C0 476.1 35.89 512 80 512h344c13.25 0 24-10.75 24-24s-10.75-24-24-24H416v-66.95C434.6 390.4 448 372.8 448 352zM368 464h-288c-17.64 0-32-14.34-32-32s14.36-32 32-32h288V464zM400 352h-320c-11.38 0-22.2 2.375-32 6.688V80c0-17.66 14.36-32 32-32h320V352zM152 160h176C341.3 160 352 149.3 352 136S341.3 112 328 112h-176C138.8 112 128 122.8 128 136S138.8 160 152 160zM152 240h176C341.3 240 352 229.3 352 216S341.3 192 328 192h-176C138.8 192 128 202.8 128 216S138.8 240 152 240z" />
                    </svg>
                    <span>{t('userGuide')}</span>
                  </div>
                </a>
              </MainNav.Item>
            </MainNavItems>
          </div>
        </MainNav.MainContent>
      </Scrollbars>
    </MainNav>
  );
};

export default MainSidebar;
