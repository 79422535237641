import { Stack } from '@tymate/margaret';
import { Button } from 'components';
import { FormGroup } from 'ui/forms';
import { gql, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { TextField } from 'components/Fields';
import { useAuth, useSnack } from 'hooks';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import AuthLayout from 'components/AuthLayout';
import { Navigate } from 'react-router-dom';
import { camelCase } from 'lodash';

const FORGOT_PASSWORD = gql`
  mutation forgotPassword($input: ForgotPasswordInput!) {
    forgotPassword(input: $input) {
      success
      errors {
        id
        message
      }
    }
  }
`;

const LostAccess = () => {
  const { t } = useTranslation(['auth', 'errors']);
  const [forgotPassword] = useMutation(FORGOT_PASSWORD);
  const { accessToken } = useAuth();
  const { notify } = useSnack();

  const handleForgotPassword = async values => {
    try {
      const { data } = await forgotPassword({
        variables: { input: { email: values.email } },
      });

      if (Boolean(data?.forgotPassword?.success)) {
        notify(t('forgotPassword.succeeded'));
      }
    } catch (err) {
      const error = camelCase(err?.message);
      notify(t(`errors:${error}`), { type: 'error' });
    }
  };

  if (Boolean(accessToken)) {
    return <Navigate to="/" />;
  }

  return (
    <AuthLayout
      title={t('forgotPassword.title')}
      subtitle={t('forgotPassword.subtitle')}
    >
      <Formik
        initialValues={{ email: '' }}
        validationSchema={Yup.object().shape({
          email: Yup.string().email().required(t('errors: required')),
        })}
        onSubmit={handleForgotPassword}
      >
        {({ status, isSubmitting }) => (
          <Form>
            <FormGroup>
              <TextField
                label={t('email')}
                id="login-username"
                autoComplete="email"
                name="email"
                type="email"
              />
            </FormGroup>
            <Stack direction="column" marginTop={1.5} gutterSize={2}>
              <Button variant="primaryBig" size="full" isLoading={isSubmitting}>
                {t('forgotPassword.button')}
              </Button>
              <Button to={-1} type="button">
                {t('forgotPassword.goBack')}
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    </AuthLayout>
  );
};

export default LostAccess;
