import { InMemoryCache } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        accreditations: relayStylePagination(),
        adminBookings: relayStylePagination(),
        bookings: relayStylePagination(),
        categories: relayStylePagination(),
        cities: relayStylePagination(),
        contracts: relayStylePagination(),
        countries: relayStylePagination(),
        descriptions: relayStylePagination(),
        dmcCategories: relayStylePagination(),
        facilities: relayStylePagination(),
        groups: relayStylePagination(),
        hotels: relayStylePagination(),
        kinds: relayStylePagination(),
        locations: relayStylePagination(),
        organizations: relayStylePagination(),
        regionsSearch: relayStylePagination(),
        rooms: relayStylePagination(),
        styles: relayStylePagination(),
        sustainableClusters: relayStylePagination(),
        users: relayStylePagination(),
      },
    },
  },
});

export default cache;
