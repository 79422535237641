import { gql } from '@apollo/client';

export const GET_SETTING_REQUEST = gql`
  query getSettingRequest(
    $endCursor: String
    $order: SettingRequestOrderCriteria
    $search: String
    $discarded: Boolean
    $state: String
    $kind: String
    $settingName: String
    $userId: String
  ) {
    settingRequests(
      after: $endCursor
      order: $order
      displayName: $search
      discarded: $discarded
      state: $state
      kind: $kind
      settingName: $settingName
      userId: $userId
    ) {
      edges {
        node {
          id
          displayName
          settingName
        }
      }
      totalCount
      pageInfo {
        hasPreviousPage
        startCursor
        hasNextPage
        endCursor
      }
    }
  }
`;

export const SETTING_REQUEST = gql`
  query getSettingRequest($id: ID!) {
    node(id: $id) {
      ... on SettingRequest {
        id
        state
        displayName
        settingName
        kind
        refusedExplaination
        createdAt
      }
    }
  }
`;
