import { Routes, Route, Navigate } from 'react-router-dom';
import EditTheme from './EditTheme';
import ArchiveTheme from './ArchiveTheme';
import CreateTheme from './CreateTheme';
import ThemesList from './ThemesList';
import ThemeBreadcrumb from './ThemeBreadcrumb';
import { useApp } from 'hooks';
import DetailsSettingRequest from 'components/SettingRequest/DetailsSettingRequest';

const Styles = () => {
  const { isAllowedTo } = useApp();

  return (
    <>
      <Routes>
        <Route path=":themeId/*" element={<ThemeBreadcrumb />} />
      </Routes>

      <Routes>
        {isAllowedTo('manageExperienceSettings') && (
          <>
            <Route path="new" element={<CreateTheme />} />
            <Route path=":themeId/edit" element={<EditTheme />} />
            <Route path=":themeId/archive" element={<ArchiveTheme />} />
          </>
        )}
        <Route
          path=":settingRequestId/details"
          element={<DetailsSettingRequest />}
        />
        <Route path=":themeId" element={<Navigate to=".." />} />
        <Route element={<ThemesList />} />
      </Routes>
    </>
  );
};

export default Styles;
