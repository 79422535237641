import { css } from 'styled-components';

export * from './auth';
export * from './base';
export * from './forms';
export * from './theme';

export const VIEWPORT_SIZES = {
  desktop: 1100,
  medium: 1000,
  tabletprod: 900,
  tablet: 750,
};

const mediaQuery =
  (...query) =>
  (...rules) =>
    css`
      @media ${css(...query)} {
        ${css(...rules)};
      }
    `;

export const media = {
  tablet: mediaQuery`(min-width: ${VIEWPORT_SIZES.tablet / 16}em)`,
  medium: mediaQuery`(min-width: ${VIEWPORT_SIZES.medium / 16}em)`,
  tabletprod: mediaQuery`(min-width: ${VIEWPORT_SIZES.tabletprod / 16}em)`,
  desktop: mediaQuery`(min-width: ${VIEWPORT_SIZES.desktop / 16}em)`,
  mobile: mediaQuery`(max-width: ${VIEWPORT_SIZES.tablet / 16}em)`,
};
