import React from 'react';
import { Route, Routes } from 'react-router-dom';
import PromotionsList from './PromotionsList';
import { useParams } from 'react-router-dom';
import { getExperience } from '../Form/ExperienceEdit';
import { useAsync } from 'react-async';
import ExperienceHeader from '../Form/Partials/ExperienceHeader';
import PromotionCreate from './PromotionCreate';
import PromotionDetails from './PromotionDetails';
import PromotionDuplicate from './PromotionDuplicate';
import ExperienceLayout from '../Form/Partials/ExperienceLayout';

const PromotionsContainer = () => {
  const { experienceId } = useParams();
  const { data: experienceData, isLoading } = useAsync({
    promiseFn: getExperience,
    experienceId: experienceId,
  });

  return (
    <ExperienceLayout>
      <ExperienceHeader values={experienceData?.data} />
      <Routes>
        <Route path="" element={!isLoading && <PromotionsList />} />
        <Route
          path="new"
          element={<PromotionCreate experience={experienceData?.data} />}
        />
        <Route
          path=":promotionId"
          element={<PromotionDetails experience={experienceData?.data} />}
        />
        <Route
          path="duplicate/:promotionId"
          element={<PromotionDuplicate experience={experienceData?.data} />}
        />
      </Routes>
    </ExperienceLayout>
  );
};

export default PromotionsContainer;
