import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from '../../../../../../components/Fields';

export default function SpecificationFormLand() {
  const { t } = useTranslation('experiences');

  return (
    <>
      <TextField
        label={t('form.pickUpPoint')}
        name="transfer.land.pickUpPoint"
      />

      <TextField
        label={t('form.dropOffPoint')}
        name="transfer.land.dropOffPoint"
      />

      <TextField
        label={t('form.transfer.land.vehicleType')}
        name="transfer.land.vehicleType"
      />
    </>
  );
}
