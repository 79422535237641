import { useRef, useState } from 'react';
import { FormField, FormLabel } from 'ui/forms';
import { pickBy } from 'lodash';
import {
  Dropdown,
  PopoverContainer,
  PopoverMenu,
  Stack,
} from '@tymate/margaret';
import { useField } from 'formik';
import { gql, useQuery } from '@apollo/client';
import ToggleSection from 'components/ToggleSection';
import RegionPopoverItem from 'components/RegionPopoverItem';
import { useApp } from 'hooks';
import { MdKeyboardArrowDown } from 'react-icons/md';
import styled, { css } from 'styled-components';

const Trigger = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 ${({ theme }) => theme.shadow};
  padding: ${({ theme }) => theme.spacing(0.25)}
    ${({ theme }) => theme.spacing(0.5)};
  border: 1px solid ${({ theme }) => theme.separator};
  border-radius: 8px;
  line-height: 15px;
  min-width: 176px;
  width: 100%;
  min-height: 36px;
  position: relative;
  transition: border-color 100ms ease;

  ${({ disabled }) =>
    Boolean(disabled) &&
    css`
      cursor: not-allowed;
      background-color: ${({ theme }) => theme.disabled};
      color: #6d6d6d;
    `}

  svg {
    margin-left: ${({ theme }) => theme.spacing(0.5)};
  }
`;

const SearchInput = styled.input`
  outline: none;
  border: 0;
  width: 100%;
`;

const GET_COUNTRIES = gql`
  query getCountries($organizationId: ID) {
    countries(organizationId: $organizationId, first: 195) {
      edges {
        node {
          id
          countryId
          title
          regions(first: 100) {
            nodes {
              id
              regionId
              title
            }
          }
        }
      }
    }
  }
`;

const formatRegionsToGroupsCountry = countries => {
  if (countries?.length === 0) {
    return [];
  }

  const regions = (countries ?? []).map(item => ({
    group: {
      displayName: item.node.title,
      id: item.node.countryId,
    },
    regions: item.node.regions?.nodes.map(region => ({
      label: region.title,
      value: region.regionId,
      countryId: item.node.countryId,
    })),
  }));

  return regions;
};

const CountryGroup = ({ group, regions, onToggleRegion }) => {
  const toggleRef = useRef();

  return (
    <ToggleSection ref={toggleRef} title={group.displayName || ''}>
      {regions.map(region => (
        <RegionPopoverItem
          key={region.value}
          region={region}
          onClick={() => onToggleRegion(region)}
        />
      ))}
    </ToggleSection>
  );
};

const RegionsGroupsSelectField = ({ label, placeholder, name, disabled }) => {
  const { currentOrganizationId } = useApp();
  const [{ value }, , { setValue }] = useField({ name });
  const [debouncedSearch] = useState('');
  const { data } = useQuery(GET_COUNTRIES, {
    variables: pickBy({ organizationId: currentOrganizationId }),
  });

  const groups = formatRegionsToGroupsCountry(data?.countries?.edges);

  const handleToggleRegion = targetFacility => {
    setValue([{ region: targetFacility }]);
  };

  return (
    <FormField>
      {Boolean(label) && <FormLabel>{label}</FormLabel>}

      {!disabled && (
        <Stack direction="column" gutterSize={1} size="full" alignX="stretch">
          <Dropdown
            trigger={
              <Trigger disabled={disabled}>
                <SearchInput
                  value={value && value[0]?.region.label}
                  placeholder={placeholder}
                />
                <MdKeyboardArrowDown size={24} />
              </Trigger>
            }
          >
            <PopoverContainer style={{ maxHeight: '100px' }}>
              <PopoverMenu>
                {groups.map(
                  ({ group, regions }) =>
                    regions.length > 0 && (
                      <CountryGroup
                        key={group.id}
                        group={group}
                        regions={regions}
                        hasSearch={Boolean(debouncedSearch)}
                        onToggleRegion={handleToggleRegion}
                      />
                    ),
                )}
              </PopoverMenu>
            </PopoverContainer>
          </Dropdown>
        </Stack>
      )}
    </FormField>
  );
};

export default RegionsGroupsSelectField;
