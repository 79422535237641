import { useState } from 'react';
import { FastField } from 'formik';
import { Stack } from '@tymate/margaret';
import {
  FormField,
  FormLabel,
  Input,
  ErrorMessageWrapper,
  PasswordButton,
} from 'ui/forms';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { uniqueId } from 'lodash';

const FastTextField = ({
  label,
  labelSuffix,
  name,
  variant,
  validate,
  ...props
}) => {
  const [id] = useState(uniqueId());
  const isPassword = props.type === 'password';
  const [type, setType] = useState(props.type || 'text');

  if (variant === 'inline') {
    return (
      <FastField name={name} validate={validate}>
        {({ field, meta }) => {
          const hasError = Boolean(meta.touched) && Boolean(meta.error);

          return (
            <Stack alignY="center" gutterSize={0.5}>
              <FormLabel variant="bare" htmlFor={id}>
                {label}
              </FormLabel>
              <Stack>
                <Input
                  {...field}
                  {...props}
                  hasError={hasError}
                  type={type}
                  id={id}
                />
              </Stack>
            </Stack>
          );
        }}
      </FastField>
    );
  }

  return (
    <FormField>
      {(Boolean(label) || Boolean(labelSuffix)) && (
        <Stack
          alignX="space-between"
          alignY="center"
          marginBottom={0.5}
          size="full"
        >
          {Boolean(label) && (
            <FormLabel variant="bare" htmlFor={id}>
              {label}
            </FormLabel>
          )}
          {labelSuffix}
        </Stack>
      )}

      <Stack size="full">
        <FastField name={name}>
          {({ field, meta }) => {
            const hasError = Boolean(meta.touched) && Boolean(meta.error);

            return (
              <>
                <div style={{ position: 'relative', width: '100%' }}>
                  <Input
                    {...field}
                    {...props}
                    type={type}
                    hasError={hasError}
                    id={id}
                  />
                  {isPassword && (
                    <PasswordButton
                      disabled={props.disabled}
                      type="button"
                      onClick={() =>
                        setType(type === 'password' ? 'text' : 'password')
                      }
                      tabIndex={-1}
                    >
                      {type === 'text' ? <MdVisibility /> : <MdVisibilityOff />}
                    </PasswordButton>
                  )}
                </div>
                {hasError && (
                  <ErrorMessageWrapper>{meta.error}</ErrorMessageWrapper>
                )}
              </>
            );
          }}
        </FastField>
      </Stack>
    </FormField>
  );
};

export default FastTextField;
