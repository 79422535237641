import { memo } from 'react';

const IcBin = props => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    style={{ ...props.style, color: props.color, fontSize: props.size }}
  >
    <path
      d="M18.525 5.7H6.37501C6.00201 5.7 5.70001 6.002 5.70001 6.375V18.525L5.70701 18.687C5.80801 19.754 6.67901 20.55 7.72501 20.55H17.175C18.293 20.55 19.2 19.643 19.2 18.525V6.375C19.2 6.002 18.898 5.7 18.525 5.7V5.7ZM7.04901 7.05H17.849L17.85 18.525C17.85 18.898 17.548 19.2 17.175 19.2H7.72501C7.37701 19.2 7.08601 18.934 7.05301 18.59L7.04901 18.493V7.05V7.05Z"
      fill="currentColor"
    />
    <path
      d="M10.425 9.75C10.764 9.75 11.045 10 11.093 10.325L11.1 10.425V15.825C11.1 16.198 10.798 16.5 10.425 16.5C10.086 16.5 9.805 16.25 9.757 15.925L9.75 15.825V10.425C9.75 10.052 10.052 9.75 10.425 9.75V9.75ZM14.475 9.75C14.814 9.75 15.095 10 15.143 10.325L15.15 10.425V15.825C15.15 16.198 14.848 16.5 14.475 16.5C14.136 16.5 13.855 16.25 13.807 15.925L13.8 15.825V10.425C13.8 10.052 14.102 9.75 14.475 9.75V9.75ZM21.225 5.7C21.598 5.7 21.9 6.002 21.9 6.375C21.9 6.714 21.65 6.995 21.325 7.043L21.225 7.05H3.675C3.302 7.05 3 6.748 3 6.375C3 6.036 3.25 5.755 3.575 5.707L3.675 5.7H21.225V5.7Z"
      fill="currentColor"
    />
    <path
      d="M14.475 3H10.425C9.37902 3 8.50802 3.796 8.40902 4.83L8.40102 4.993L8.40002 6.375C8.40002 6.748 8.70202 7.05 9.07502 7.05H15.825C16.198 7.05 16.5 6.748 16.5 6.375V5.025C16.5 3.907 15.593 3 14.475 3V3ZM14.475 4.35L14.575 4.357C14.9 4.406 15.15 4.687 15.15 5.025L15.149 5.7H9.74902L9.75002 5.025L9.75502 4.927C9.78602 4.616 10.077 4.35 10.425 4.35H14.475V4.35Z"
      fill="currentColor"
    />
  </svg>
);

export default memo(IcBin);
