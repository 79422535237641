import { useAsync } from 'react-async';
import { Stack } from '@tymate/margaret';
import KoobTitle from 'components/Koob/KoobTitle';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Button, ToggleSection, Tooltip } from 'components';
import { Field, FieldArray, Form, Formik } from 'formik';
import { TextField, ToggleSwitchField } from 'components/Fields';
import { format, parseISO } from 'date-fns';
import styled, { useTheme } from 'styled-components';
import { MdRemoveRedEye } from 'react-icons/md';
import { parseBoolean } from 'utils';
import { getHotelsContracts } from 'api/node/contracts';
import KoobPlaceholder from 'components/Koob/KoobPlaceholder';

const EyeContainer = styled(Stack)`
  color: ${({ selected, theme }) => (selected ? theme.orange : theme.gray)};
  font-size: 24px;
`;

export const getRoomsDetail = (
  data,
  periods,
  dmcorganization,
  toorganization,
) => {
  const rooms = [];
  data?.data.forEach(contract => {
    contract.rooms.forEach(room => {
      let roomInArray = rooms.find(elem => elem.id === room.id);
      if (!roomInArray) {
        roomInArray = {
          id: room.id,
          name: room.name,
          periods: [],
          enabled: false,
        };
        rooms.push(roomInArray);
      }
      const periodsWithAllotment = room.periods;
      periodsWithAllotment.forEach(period => {
        let periodInArray = roomInArray.periods.find(
          elem => elem.id === period.id,
        );
        if (!periodInArray) {
          periodInArray = {
            id: period.id,
            startAt: period.startAt,
            endAt: period.endAt,
            freeSale: period.freeSale,
            totalAllotmentsAvailable: period.totalAllotmentsAvailable,
            toElement: {
              totalAllotmentsAvailableForDistribution:
                period.totalAllotmentsAvailable -
                period.periodOrganizations
                  .filter(
                    elem =>
                      elem.organization.id !== dmcorganization?.id &&
                      elem.organization.id !== toorganization?.id,
                  )
                  .reduce((acc, value) => acc + value.allotmentCount, 0),
            },
            allAllocations: period.periodOrganizations.filter(
              elem => elem.organization.id !== toorganization?.id,
            ),
          };
          roomInArray.periods.push(periodInArray);
        }
        const periodInHotel = periods.find(elem => elem.id === period.id);
        if (periodInHotel) {
          periodInArray.toElement = {
            ...periodInArray.toElement,
            allotmentCount: periodInHotel.allotmentCount,
            freeSale: periodInHotel.freeSale,
            isMarginPercent: periodInHotel?.isMarginPercent,
            margin: periodInHotel?.margin,
          };
        } else {
          const periodOrgaTo = period.periodOrganizations.find(
            elem => elem.organization.id === toorganization?.id,
          );
          if (!periodOrgaTo) {
            periodInArray.toElement = {
              ...periodInArray.toElement,
              allotmentCount: 0,
              freeSale: false,
              isPeriodAllowedForBooking: false,
            };
          } else {
            periodInArray.toElement = {
              ...periodInArray.toElement,
              allotmentCount: periodOrgaTo.allotmentCount,
              freeSale: periodOrgaTo.freeSale,
              isPeriodAllowedForBooking: periodOrgaTo.isPeriodAllowedForBooking,
              isMarginPercent: periodOrgaTo?.isMarginPercent,
              margin: periodOrgaTo?.margin,
            };
          }
        }
      });
      roomInArray.enabled =
        roomInArray.periods.findIndex(elem => {
          return elem.toElement.isPeriodAllowedForBooking;
        }) !== -1;
    });
  });
  return rooms;
};

const EyeButton = ({ room, roomIndex, setFieldValue }) => {
  return (
    <EyeContainer selected={room.enabled}>
      <MdRemoveRedEye
        onClick={() => {
          setFieldValue(`rooms.${roomIndex}.enabled`, !room.enabled);
        }}
      />
    </EyeContainer>
  );
};

const MarginFields = ({ name }) => {
  const { t } = useTranslation('toConnectionRequest');

  return (
    <div className="flex items-center space-x-3">
      <TextField name={`${name}.margin`} label={t('margin')} type="number" />
      <div className="self-end">
        <Field type="checkbox" name={`${name}.isMarginPercent`} />
        <span className="ml-2 text-sm">{t('percent')}</span>
      </div>
    </div>
  );
};

const HotelDetailForm = ({
  hotel,
  onSelectionFinished,
  dmcorganization,
  toorganization,
  contracts,
}) => {
  const theme = useTheme();
  const { data, isLoading } = useAsync({
    promiseFn: getHotelsContracts,
    hotelId: hotel.id,
  });
  const { t } = useTranslation('toConnectionRequest');

  const canDisplayPeriod = period => {
    if (!contracts) {
      return true;
    }
    const contract = contracts?.find(contract => {
      return (
        new Date(contract.startAt) <= new Date(period.startAt) &&
        new Date(contract.endAt) >= new Date(period.endAt)
      );
    });
    if (!contract?.enabled || contract?.enabled === 'true') {
      return true;
    }
    return false;
  };

  const onSubmit = values => {
    const rooms = values.rooms;
    const periods = [];
    rooms.forEach(room => {
      room.periods.forEach(period => {
        const newPeriod = {
          id: period.id,
          allotmentCount: period.toElement.allotmentCount,
          freeSale: period.toElement.freeSale,
          isPeriodAllowedForBooking: true,
          isMarginPercent: period.toElement?.isMarginPercent,
          margin: period.toElement?.margin || null,
        };
        newPeriod.isPeriodAllowedForBooking = parseBoolean(room.enabled);
        if (!room.enabled) {
          newPeriod.allotmentCount = 0;
          newPeriod.freeSale = false;
        }
        periods.push(newPeriod);
      });
    });
    hotel.periods = periods;
    onSelectionFinished();
  };
  return (
    <>
      {isLoading && (
        <div className="flex-col space-y-5">
          <KoobPlaceholder className="h-20 w-full rounded-md" />
          <KoobPlaceholder className="h-20 w-full rounded-md" />
          <KoobPlaceholder className="h-20 w-full rounded-md" />
        </div>
      )}

      {!isLoading && (
        <>
          <div className="text-center max-w-lg mx-auto">
            <KoobTitle size="text-xl">
              {t('hotelModalTitle', {
                hotelName: hotel.displayName,
                interpolation: { escapeValue: false },
              })}
            </KoobTitle>

            {data?.data.length === 0 && (
              <KoobTitle className="m-4" size="text-sm">
                {t('roomModalInformation')}
              </KoobTitle>
            )}
          </div>

          <Formik
            initialValues={{
              rooms: getRoomsDetail(
                data,
                hotel.periods ?? [],
                dmcorganization,
                toorganization,
              ),
            }}
            validationSchema={Yup.object().shape({
              rooms: Yup.array().of(
                Yup.object().shape({
                  periods: Yup.array().of(
                    Yup.object().shape({
                      id: Yup.string().required(
                        t('required', { ns: 'errors' }),
                      ),
                      toElement: Yup.object().shape({
                        allotmentCount: Yup.number()
                          .required(t('required', { ns: 'errors' }))
                          .test(
                            'is-allotment-valid',
                            () => t('allotmentNotValid'),
                            async (allotmentCount, testContext) => {
                              return (
                                allotmentCount >= 0 &&
                                allotmentCount <=
                                  testContext.parent
                                    .totalAllotmentsAvailableForDistribution
                              );
                            },
                          ),
                        freeSale: Yup.boolean().required(
                          t('required', { ns: 'errors' }),
                        ),
                        isPeriodAllowedForBooking: Yup.boolean().required(
                          t('required', { ns: 'errors' }),
                        ),
                      }),
                    }),
                  ),
                }),
              ),
            })}
            enableReinitialize
            onSubmit={onSubmit}
          >
            {({ values, setFieldValue, isValid, isSubmitting }) => (
              <Form>
                <FieldArray
                  name="rooms"
                  render={arrayHelpers =>
                    values.rooms.map((room, roomIndex) => (
                      <div className="border m-5 p-2 rounded-md">
                        <ToggleSection
                          key={room.id}
                          title={room.name}
                          variant="full"
                          disabled={!parseBoolean(room.enabled)}
                          rightElement={
                            <div>
                              <EyeButton
                                room={room}
                                roomIndex={roomIndex}
                                setFieldValue={setFieldValue}
                              />
                            </div>
                          }
                        >
                          <div>
                            {room.periods.map((period, periodIndex) => (
                              <>
                                {canDisplayPeriod(period) && (
                                  <div className="grid grid-cols-5 gap-5 mb-10">
                                    <h3
                                      className={`text-base font-bold text-k-h`}
                                      style={{ color: theme.gray }}
                                    >
                                      {t('periodDates', {
                                        start: format(
                                          parseISO(period.startAt),
                                          'dd-MM-yyyy',
                                        ),
                                        end: format(
                                          parseISO(period.endAt),
                                          'dd-MM-yyyy',
                                        ),
                                      })}
                                    </h3>

                                    <Tooltip
                                      tip={period.allAllocations
                                        .sort(
                                          (a, b) =>
                                            b.allotmentCount - a.allotmentCount,
                                        )
                                        .sort((a, b) =>
                                          a.organization.scope.localeCompare(
                                            b.organization.scope,
                                          ),
                                        )
                                        .map(allocation => (
                                          <p>
                                            {t('allocation', {
                                              name: allocation.organization
                                                .displayName,
                                              count: allocation.allotmentCount,
                                              totalCount:
                                                period.totalAllotmentsAvailable,
                                            })}
                                          </p>
                                        ))}
                                      position="bottom"
                                    >
                                      <TextField
                                        name={`rooms.${roomIndex}.periods.${periodIndex}.toElement.allotmentCount`}
                                        label={t('allotment')}
                                        type="number"
                                      />
                                    </Tooltip>

                                    <div className="w-2/5">
                                      <ToggleSwitchField
                                        name={`rooms.${roomIndex}.periods.${periodIndex}.toElement.freeSale`}
                                        disabled={!period.freeSale}
                                        label={t('freeSale')}
                                      />
                                    </div>

                                    <div className="col-span-2">
                                      <MarginFields
                                        name={`rooms.${roomIndex}.periods.${periodIndex}.toElement`}
                                      />
                                    </div>
                                  </div>
                                )}
                              </>
                            ))}
                          </div>
                        </ToggleSection>
                      </div>
                    ))
                  }
                />

                <Stack
                  alignX="flex-end"
                  style={{
                    marginTop: '1rem',
                  }}
                  gutterSize={0.5}
                >
                  <Button
                    style={{ marginLeft: 'auto' }}
                    variant="simple"
                    type="button"
                    onClick={onSelectionFinished}
                  >
                    {t('misc:cancel')}
                  </Button>

                  <Button
                    type="submit"
                    disabled={!isValid}
                    variant="primary"
                    isLoading={isSubmitting}
                  >
                    {t('misc:save')}
                  </Button>
                </Stack>
              </Form>
            )}
          </Formik>
        </>
      )}
    </>
  );
};

export default HotelDetailForm;
