import React from 'react';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';

export default function DocumentListItem({ document }) {
  const { t } = useTranslation('bookings');

  return (
    <div className="py-5 flex justify-between items-center">
      <div className="font-semibold flex space-x-1">
        {document.private && (
          <svg className="h-5 w-5 text-blue-500" viewBox="0 0 640 512">
            <path d="M48.4 14.8L29.4 .1 0 38 19 52.7 591.5 497.2l19 14.7L639.9 474l-19-14.7L524 384.1c41.9-44 70.2-93.9 84-128.1C578 181.3 478.4 32 320 32c-66.9 0-123.2 26.6-168.3 63L48.4 14.8zM190.8 125.4C227.6 98 270.8 80 320 80c63 0 116.2 29.5 158.9 70.6c35.6 34.3 61.5 74.5 76.6 105.4c-14.1 28.9-37.6 65.8-69.6 98.5L434 314.2c8.9-17.5 14-37.2 14-58.2c0-70.7-57.3-128-128-128c-8.6 0-17 .8-25.1 2.5c-22.5 4.5-42.9 14.9-59.5 29.5l-44.6-34.6zM395 283.9l-82.2-63.8-8.5-42.6c5.1-1 10.3-1.5 15.7-1.5c44.2 0 80 35.8 80 80c0 9.8-1.8 19.2-5 27.9zm8.4 129.9C377.9 425.3 350.1 432 320 432c-63.1 0-116.2-29.5-158.9-70.6C125.6 327.2 99.7 286.9 84.5 256c9.1-18.7 22.2-40.7 38.9-62.8L85.7 163.5C60.2 197.1 42.1 230.8 32 256c30 74.7 129.6 224 288 224c46.9 0 88.6-13.1 124.9-33.4l-41.6-32.7zM192 256c0 70.7 57.3 128 128 128c13.3 0 26.1-2 38.2-5.8L302 334c-23.5-5.4-43.1-21.2-53.7-42.3l-56.1-44.2c-.2 2.8-.3 5.6-.3 8.5z" />
          </svg>
        )}
        <span>
          {document.document?.metadata?.filename ??
            t('bookings.documents.noFilename')}
        </span>
      </div>

      <div className="flex space-x-3 items-center">
        <div className="text-gray-500 text-sm">
          {t('documents.uploadedBy', {
            when: formatDistanceToNow(parseISO(document.createdAt)),
            who: document.user
              ? `${document.user?.firstName} ${document.user?.lastName}`
              : t('chat.anonymous'),
          })}
        </div>

        {document?.documentUrl ? (
          <a className="border-none" href={document?.documentUrl} download>
            <svg className="h-5 w-5 text-gray-600" viewBox="0 0 512 512">
              <path d="M280 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V294.1l-95-95c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9L239 369c9.4 9.4 24.6 9.4 33.9 0L409 233c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-95 95V24zM128.8 304H64c-35.3 0-64 28.7-64 64v80c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V368c0-35.3-28.7-64-64-64H383.2l-48 48H448c8.8 0 16 7.2 16 16v80c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V368c0-8.8 7.2-16 16-16H176.8l-48-48zM432 408a24 24 0 1 0 -48 0 24 24 0 1 0 48 0z" />
            </svg>
          </a>
        ) : (
          <svg className="h-5 w-5 text-red-400" viewBox="0 0 384 512">
            <path d="M365.3 125.3l-106.5-106.5C246.7 6.742 230.5 0 213.5 0H64C28.65 0 0 28.65 0 64l.0065 384c0 35.35 28.65 64 64 64H320c35.35 0 64-28.65 64-64V170.5C384 153.5 377.3 137.3 365.3 125.3zM224 34.08c4.477 1.566 8.666 3.846 12.12 7.299l106.5 106.5C346.1 151.3 348.4 155.5 349.9 160H240C231.2 160 224 152.8 224 144V34.08zM352 448c0 17.64-14.36 32-32 32H64c-17.64 0-32-14.36-32-32V64c0-17.64 14.36-32 32-32h128v112C192 170.5 213.5 192 240 192H352V448zM192 392c-13.25 0-24 10.75-24 24s10.75 24 24 24c13.26 0 24-10.75 24-24S205.3 392 192 392zM191.1 368C200.8 368 208 360.8 208 352V240C208 231.2 200.8 224 192 224S176 231.2 176 240V352C176 360.8 183.1 368 191.1 368z" />
          </svg>
        )}
      </div>
    </div>
  );
}
