import { Stack } from '@tymate/margaret';
import { get } from 'api/node';

import KoobCard from 'components/Koob/KoobCard';
import KoobTitle from 'components/Koob/KoobTitle';
import PromotionsListTable from 'components/Promotions/PromotionsListTable';
import {
  LinkButton,
  SectionWrapper,
  ToggleButton,
  TotalItemsByState,
} from 'containers/Hotels/Hotel/Promotions/PromotionsList';
import { useSearchParams } from 'hooks';
import { isEqual } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useAsync } from 'react-async';
import { useTranslation } from 'react-i18next';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { useParams } from 'react-router-dom';
import { useDeepCompareEffect } from 'react-use';
import { useTheme } from 'styled-components';
import { constantCase } from 'utils';
const getPromotions = ({
  experienceId,
  state = '',
  direction = 'DESC',
  column = 'id',
}) =>
  get(
    `/experiences/${experienceId}/promotions?state=${state}&column=${column}&direction=${direction}`,
  );

const PromotionState = {
  Draft: 'draft',
  Operational: 'operational',
  Archived: 'archived',
};
const PromotionsList = () => {
  const theme = useTheme();
  const { experienceId } = useParams();
  const [searchParams] = useSearchParams();
  const [promotions, setPromotions] = useState([]);
  const [promotionState, setPromotionState] = useState(
    PromotionState.Operational,
  );

  const sortParams = { ...searchParams };
  if (searchParams.order === 'status') {
    sortParams.order = 'state';
  }

  const { data } = useAsync({
    promiseFn: getPromotions,
    experienceId: experienceId,
    state: promotionState,
    column: sortParams?.order,
    direction: sortParams?.direction && constantCase(sortParams.direction),
    watchFn: (props, prevProps) => {
      return (
        !isEqual(props.state, prevProps.state) ||
        !isEqual(props.direction, prevProps.direction) ||
        !isEqual(props.column, prevProps.column)
      );
    },
  });
  const { data: dataTotal } = useAsync({
    promiseFn: getPromotions,
    experienceId: experienceId,
  });

  const { t } = useTranslation(['experiences', 'promotions', 'misc']);
  const headers = [
    {
      slug: 'name',
      label: t('promotionName', { ns: 'promotions' }),
      width: '20%',
    },
    {
      slug: 'benefit',
      label: t('benefits', { ns: 'promotions' }),
      width: '12%',
      cannotBeReordered: true,
    },
    {
      slug: 'status',
      label: t('status', { ns: 'promotions' }),
      width: '12%',
    },
    {
      slug: 'action',
      label: '',
      width: '5%',
    },
  ];

  const byStateTotal = useMemo(() => {
    return (dataTotal?.data ?? []).reduce(
      (acc, curr) => {
        if (curr?.state) {
          if (curr?.state === 'draft') {
            acc.draft = acc.draft + 1;
          }
          if (curr?.state === 'archived') {
            acc.archived = acc.archived + 1;
          }
          if (curr?.state === 'operational') {
            acc.operational = acc.operational + 1;
          }
        }
        return acc;
      },
      { draft: 0, archived: 0, operational: 0 },
    );
  }, [dataTotal]);

  const handleStateSelection = value => {
    setPromotionState(() => value);
  };

  useDeepCompareEffect(() => {
    if (data?.data) {
      setPromotions(data?.data);
    }
  }, [{ data, promotionState, searchParams }]);

  return (
    <div>
      <KoobCard>
        <div className="flex justify-center">
          <div className="text-center">
            <KoobTitle>{t('promotionTitle')}</KoobTitle>
            <div className="text-lg font-medium text-gray-600">
              {t('promotionDetails')}
            </div>
          </div>
        </div>
        <SectionWrapper>
          <Stack
            gutterSize={0.5}
            alignY="center"
            style={{
              marginTop: theme.spacing(1),
              marginBottom: theme.spacing(0.25),
            }}
          >
            <ToggleButton
              aria-checked={PromotionState.Operational === promotionState}
              onClick={() => handleStateSelection(PromotionState.Operational)}
            >
              {t('operationalStatus', { ns: 'promotions' })}
              <TotalItemsByState value={byStateTotal?.operational} />
            </ToggleButton>
            <ToggleButton
              aria-checked={PromotionState.Draft === promotionState}
              onClick={() => handleStateSelection(PromotionState.Draft)}
            >
              {t('draftStatus', { ns: 'promotions' })}{' '}
              <TotalItemsByState value={byStateTotal?.draft} />
            </ToggleButton>
            <ToggleButton
              aria-checked={PromotionState.Archived === promotionState}
              onClick={() => handleStateSelection(PromotionState.Archived)}
            >
              {t('archivedStatus', { ns: 'promotions' })}{' '}
              <TotalItemsByState value={byStateTotal?.archived} />
            </ToggleButton>
            <LinkButton
              to={`./new?applicableTo=to`}
              style={{ marginLeft: 'auto' }}
              icon={<AiOutlinePlusCircle size={18} aria-hidden="true" />}
            >
              {t('new', { ns: 'promotions' })}
            </LinkButton>
          </Stack>
          <PromotionsListTable
            isExperience
            data={promotions}
            applicableTo="to"
            type="experience"
            headers={headers}
          />
        </SectionWrapper>
      </KoobCard>
    </div>
  );
};

export default PromotionsList;
