import styled from 'styled-components';
import { Container } from 'ui';
import { Wrapper } from 'ui/auth';
import background from 'images/bg-chhose-platform.png';
import logo from 'images/logo-koob.svg';
import imgTo from 'images/img-to.png';
import imgDmc from 'images/img-dmc.png';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { KOOB_TO_URL } from '../../constants';

const Background = styled.div`
  background-image: url(${background});
  flex: 1;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  height: 100vh;
`;

const Logo = styled.img`
  align-self: center;
  margin-bottom: ${({ theme }) => theme.spacing(2.5)};
`;

const Content = styled.div`
  flex: 1;
`;

const Sidebar = styled.div`
  background-color: #ffffff;
  width: 420px;
  border-top: solid ${({ theme }) => theme.primary} 10px;
`;

const BoxTitle = styled.h1`
  font-size: ${({ theme }) => theme.spacing(1.6)};
  margin-top: 0;
  font-weight: 600;
  line-height: 1.3;
  text-align: center;
  margin-bottom: 0px;
`;

const BoxSubtitle = styled.p`
  color: ${({ theme }) => theme.textLight};
  text-align: center;
  font-size: ${({ theme }) => theme.spacing(1)};
  margin-top: 0px;
`;

const BoxChoice = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
`;

const SelectBox = styled.div`
  border: solid ${({ theme }) => theme.textLight} 3px;
  height: 150px;
  width: 45%;
  border-radius: 20px;
  cursor: pointer;
`;

const ImgInside = styled.img`
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  margin-top: 10%;
`;

const TextInside = styled.h3`
  text-align: center;
  margin-top: 10px;
  color: ${({ theme }) => theme.textLight};
`;

const SelectPlatform = () => {
  const { t } = useTranslation('choosePlatform');
  const navigate = useNavigate();

  return (
    <Wrapper>
      <Sidebar>
        <Container hasVerticalPadding>
          <Logo src={logo} alt="KOOB" />

          <Content>
            <BoxTitle>{t('title')}</BoxTitle>
            <BoxSubtitle>{t('subtitle')}</BoxSubtitle>
            <BoxChoice>
              <SelectBox onClick={() => navigate('/login')}>
                <ImgInside src={imgDmc} alt="KOOB" />
                <TextInside>{t('dmc')}</TextInside>
              </SelectBox>
              <SelectBox
                onClick={() => (window.location.href = KOOB_TO_URL + '/login')}
              >
                <ImgInside src={imgTo} alt="KOOB" />
                <TextInside>{t('to')}</TextInside>
              </SelectBox>
            </BoxChoice>
          </Content>
        </Container>
      </Sidebar>
      <Background />
    </Wrapper>
  );
};

export default SelectPlatform;
