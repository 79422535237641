import KoobInputCheckbox from '../../../../components/Koob/Input/KoobInputCheckbox';
import { useTranslation } from 'react-i18next';

function RequestState({ state }) {
  const { t } = useTranslation('toConnectionRequest');

  const states = {
    confirmed: {
      text: t('bulkEdit.requestSelector.state.confirmed'),
      color: 'text-green-500',
    },
    pending: {
      text: t('bulkEdit.requestSelector.state.pending'),
      color: 'text-k-orange',
    },
    refused: {
      text: t('bulkEdit.requestSelector.state.refused'),
      color: 'text-red-500',
    },
  };

  return (
    <div className={`italic text-sm ${states[state]?.color}`}>
      {states[state]?.text}
    </div>
  );
}

function RequestTypeIcon({ type }) {
  const types = {
    hotel: 'far fa-bed',
    experience: 'far fa-layer-group',
    template: 'far fa-copy',
  };

  return <i className={`${types[type]} text-blue-500`} />;
}

export default function BulkEditRequestsSelectorCheckbox({
  request,
  selected,
  setSelected,
}) {
  return (
    <div className="w-full truncate">
      <KoobInputCheckbox
        key={request.id}
        value={selected}
        setValue={setSelected}
      >
        <div className="flex space-x-2 items-center">
          <RequestTypeIcon type={request.type} />

          <div className="leading-none text-sm text-gray-600 font-medium">
            <div>{request?.toorganization?.displayName}</div>

            <div className="mt-1 flex space-x-1.5 items-center">
              <RequestState state={request.state} />

              <a
                href={`/to-connection-request/${request.id}/${request.type}s`}
                className="text-xs text-gray-300 hover:text-gray-500 transition"
                target="_blank"
                rel="noreferrer"
              >
                <i className="far fa-pen" />
              </a>
            </div>
          </div>
        </div>
      </KoobInputCheckbox>
    </div>
  );
}
