import { gql } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useSetBreadcrumbName } from 'hooks';

const EXPERIENCE = gql`
  query ($id: ID!) {
    node(id: $id) {
      ... on ExperienceSegment {
        id
        displayName
      }
    }
  }
`;

const SegmentBreadcrumb = () => {
  const { segmentId } = useParams();

  useSetBreadcrumbName({
    path: `/settings/experiences/segments/${segmentId}`,
    query: EXPERIENCE,
    variables: { id: segmentId },
    formatData: data =>
      Boolean(data?.node) ? `${data?.node?.displayName} ` : null,
  });

  return null;
};

export default SegmentBreadcrumb;
