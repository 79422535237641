import ExperienceHeader from '../Form/Partials/ExperienceHeader';
import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useAsync } from 'react-async';
import { getExperience } from '../Form/ExperienceEdit';
import { useTranslation } from 'react-i18next';
import { Formik, useField } from 'formik';
import { get, post, put } from '../../../api/node';
import KoobPlaceholder from '../../../components/Koob/KoobPlaceholder';
import { useSearchParams, useSnack } from '../../../hooks';
import { isEqual } from 'lodash';
import KoobButton from '../../../components/Koob/KoobButton';
import { MultiSearchableSelectField, Select } from '../../../components/Fields';
import { GET_SEGMENTS } from '../../ExperiencesSettings/Segments/SegmentsList';
import { GET_THEMES } from '../../ExperiencesSettings/Themes/ThemesList';
import { Spinner as Loader } from '@tymate/margaret';
import LocationSearchableSelect from 'containers/Experiences/List/partials/LocationSearchableSelect';
import { useCallback } from 'react';
import LocationAwareSearch from 'components/LocationAwareSearch';

export const getExperiencesList = async ({
  state = '',
  type = '',
  search = '',
  except,
  contractYear,
  themes,
  segments,
  cities,
  countries,
  startingPoint,
  endingPoint,
}) => {
  return await post('/experiences/liste', {
    state,
    type,
    search,
    except,
    contractYear,
    themes,
    segments,
    cities,
    countries,
    startingPoint,
    endingPoint,
  });
};

function ExperienceCompatibilityListItem({ id, name, type }) {
  const { data: experienceData, isLoading } = useAsync({
    promiseFn: getExperience,
    experienceId: id,
  });

  const [{ value: bestMatches }, , { setValue: setBestMatches }] =
    useField('bestMatches');
  const [{ value: incompatibilities }, , { setValue: setIncompatibilities }] =
    useField('incompatibilities');

  const remove = () => {
    if (type === 'bestMatch') {
      setBestMatches(bestMatches.filter(v => v.id !== id));
    } else {
      setIncompatibilities(incompatibilities.filter(v => v.id !== id));
    }
  };

  return (
    <div className="bg-gray-100 border rounded-md py-1 px-2">
      <div className="flex justify-between items-start space-x-2">
        <div className="text-sm text-gray-500">
          <div className="font-medium">{name}</div>
          {!isLoading ? (
            <div className="text-xs">
              {experienceData?.data?.inCity
                ? `${experienceData?.data?.inCity?.title}, ${experienceData?.data?.inCity?.country?.title}`
                : 'No location'}
            </div>
          ) : (
            <KoobPlaceholder className="w-20 h-4" />
          )}
        </div>

        <button onClick={() => remove()} className="pt-2.5 border-none">
          <svg
            className="text-gray-500 hover:text-red-500 transition"
            height="1em"
            viewBox="0 0 576 512"
          >
            <path d="M205.3 64c-17 0-33.3 6.7-45.3 18.7L9.4 233.4c-6 6-9.4 14.1-9.4 22.6s3.4 16.6 9.4 22.6L160 429.3c12 12 28.3 18.7 45.3 18.7H512c35.3 0 64-28.7 64-64V128c0-35.3-28.7-64-64-64H205.3zM528 128V384c0 8.8-7.2 16-16 16H205.3c-4.2 0-8.3-1.7-11.3-4.7L54.6 256 193.9 116.7c3-3 7.1-4.7 11.3-4.7H512c8.8 0 16 7.2 16 16zm-95 47c-9.4-9.4-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9z" />
          </svg>
        </button>
      </div>
    </div>
  );
}

function ExperienceCompatibilityList({ type, experiences }) {
  const { t } = useTranslation('experiences');
  const [, , { setValue: setBestMatches }] = useField('bestMatches');
  const [, , { setValue: setIncompatibilities }] =
    useField('incompatibilities');

  const emptyColumn = () => {
    if (type === 'bestMatch') {
      setBestMatches([]);
    } else {
      setIncompatibilities([]);
    }
  };

  return (
    <div
      className={[
        'w-full p-5 border border-t-4 bg-white rounded-lg',
        type === 'bestMatch' && 'border-green-500',
        type === 'incompatible' && 'border-red-500',
      ].join(' ')}
    >
      <div className="text-lg font-medium">
        {type === 'bestMatch' && (
          <div className="flex space-x-2 items-center text-green-600">
            <div>
              <svg className="h-5 w-5" height="1em" viewBox="0 0 512 512">
                <path d="M243.5 37.3c8-3.4 17-3.4 25 0l176.7 75c11.3 4.8 18.9 15.5 18.8 27.6c-.5 94-39.4 259.8-195.5 334.5c-7.9 3.8-17.2 3.8-25.1 0C87.3 399.6 48.5 233.8 48 139.8c-.1-12.1 7.5-22.8 18.8-27.6l176.7-75zM281 7.8c-16-6.8-34-6.8-50 0L54.3 82.8c-22 9.3-38.4 31-38.3 57.2c.5 99.2 41.3 280.7 213.6 363.2c16.7 8 36.1 8 52.8 0C454.7 420.7 495.5 239.2 496 140c.1-26.2-16.3-47.9-38.3-57.2L281 7.8zm82.3 195.5c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L224 297.4l-52.7-52.7c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6l64 64c6.2 6.2 16.4 6.2 22.6 0l128-128z" />
              </svg>
            </div>
            <div>{t('steps.compatibility.bestMatch.title')}</div>
          </div>
        )}
        {type === 'incompatible' && (
          <div className="flex space-x-2 items-center text-red-600">
            <div>
              <svg className="h-5 w-5" height="1em" viewBox="0 0 512 512">
                <path d="M243.5 37.3c8-3.4 17-3.4 25 0l176.7 75c11.3 4.8 18.9 15.5 18.8 27.6c-.5 94-39.4 259.8-195.5 334.5c-7.9 3.8-17.2 3.8-25.1 0C87.3 399.6 48.5 233.8 48 139.8c-.1-12.1 7.5-22.8 18.8-27.6l176.7-75zM281 7.8c-16-6.8-34-6.8-50 0L54.3 82.8c-22 9.3-38.4 31-38.3 57.2c.5 99.2 41.3 280.7 213.6 363.2c16.7 8 36.1 8 52.8 0C454.7 420.7 495.5 239.2 496 140c.1-26.2-16.3-47.9-38.3-57.2L281 7.8zM180.7 180.7c-6.2 6.2-6.2 16.4 0 22.6L233.4 256l-52.7 52.7c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0L256 278.6l52.7 52.7c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6L278.6 256l52.7-52.7c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L256 233.4l-52.7-52.7c-6.2-6.2-16.4-6.2-22.6 0z" />
              </svg>
            </div>
            <div>{t('steps.compatibility.incompatible.title')}</div>
          </div>
        )}
      </div>

      <div className="pl-7 text-gray-500 text-sm font-medium">
        {t(`steps.compatibility.${type}.description`)}
      </div>

      <div className="my-3 flex justify-end">
        <KoobButton onClick={() => emptyColumn()} size="sm" color="light">
          {t('steps.compatibility.removeEverything')}
        </KoobButton>
      </div>

      <div className="flex flex-col space-y-1">
        {experiences?.map(experience => (
          <ExperienceCompatibilityListItem
            key={experience.id}
            name={experience.name}
            id={experience.id}
            type={type}
          />
        ))}
      </div>
    </div>
  );
}

function ExperienceSearchItem({ experience }) {
  const { t } = useTranslation('experiences');
  const [{ value: bestMatches }, , { setValue: setBestMatches }] =
    useField('bestMatches');
  const [{ value: incompatibilities }, , { setValue: setIncompatibilities }] =
    useField('incompatibilities');
  const type = bestMatches?.filter(e => e.id === experience.id)?.length
    ? 'bestMatch'
    : incompatibilities?.filter(e => e.id === experience.id)?.length
    ? 'incompatible'
    : null;

  const remove = () => {
    if (type === 'bestMatch') {
      setBestMatches(bestMatches.filter(v => v.id !== experience.id));
    } else if (type === 'incompatible') {
      setIncompatibilities(
        incompatibilities.filter(v => v.id !== experience.id),
      );
    }
  };

  const addToBestMatches = () => {
    setBestMatches([...bestMatches, experience]);
  };

  const addToIncompatibilities = () => {
    setIncompatibilities([...incompatibilities, experience]);
  };

  return (
    <div
      className={[
        'bg-gray-100 border rounded-md py-1 px-2',
        bestMatches?.filter(e => e.id === experience.id)?.length &&
          'border-l-4 border-l-green-500',
        incompatibilities?.filter(e => e.id === experience.id)?.length &&
          'border-l-4 border-l-red-500',
      ].join(' ')}
    >
      <div className="flex justify-between items-start space-x-2">
        <div className="text-sm text-gray-500">
          <div className="font-medium">{experience.name}</div>
          <div className="text-xs">
            {experience?.inCity
              ? `${experience?.inCity?.title}, ${experience?.inCity?.country?.title}`
              : t('steps.compatibility.search.noLocation')}
          </div>
          <div className="flex mt-2">
            <div className="flex gap-0.5 items-center">
              {experience?.startPosition?.name && (
                <i className="far fa-flag"></i>
              )}
              <p className="text-textLighter text-bodySmall">
                {' '}
                {experience?.startPosition?.name}{' '}
              </p>
            </div>
            <div className="flex gap-0.5 items-center ml-3">
              {experience?.endPosition?.name && (
                <i className="far fa-flag-checkered"></i>
              )}
              <p className="text-textLighter text-bodySmall">
                {' '}
                {experience?.endPosition?.name}{' '}
              </p>
            </div>
          </div>
        </div>

        {type ? (
          <button onClick={() => remove()} className="pt-2.5 border-none">
            <svg
              className="text-gray-500 hover:text-red-500 transition"
              height="1em"
              viewBox="0 0 576 512"
            >
              <path d="M205.3 64c-17 0-33.3 6.7-45.3 18.7L9.4 233.4c-6 6-9.4 14.1-9.4 22.6s3.4 16.6 9.4 22.6L160 429.3c12 12 28.3 18.7 45.3 18.7H512c35.3 0 64-28.7 64-64V128c0-35.3-28.7-64-64-64H205.3zM528 128V384c0 8.8-7.2 16-16 16H205.3c-4.2 0-8.3-1.7-11.3-4.7L54.6 256 193.9 116.7c3-3 7.1-4.7 11.3-4.7H512c8.8 0 16 7.2 16 16zm-95 47c-9.4-9.4-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9z" />
            </svg>
          </button>
        ) : (
          <div className="flex space-x-3">
            <button
              onClick={() => addToBestMatches()}
              className="pt-2.5 border-none"
            >
              <svg
                className="text-green-500 hover:text-green-600 transition"
                height="1em"
                viewBox="0 0 512 512"
              >
                <path d="M73 127L256 49.4 439 127c5.9 2.5 9.1 7.8 9 12.8c-.4 91.4-38.4 249.3-186.3 320.1c-3.6 1.7-7.8 1.7-11.3 0C102.4 389 64.5 231.2 64 139.7c0-5 3.1-10.2 9-12.8zM457.7 82.8L269.4 2.9C265.2 1 260.7 0 256 0s-9.2 1-13.4 2.9L54.3 82.8c-22 9.3-38.4 31-38.3 57.2c.5 99.2 41.3 280.7 213.6 363.2c16.7 8 36.1 8 52.8 0C454.8 420.7 495.5 239.2 496 140c.1-26.2-16.3-47.9-38.3-57.2zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" />
              </svg>
            </button>

            <button
              onClick={() => addToIncompatibilities()}
              className="pt-2.5 border-none"
            >
              <svg
                className="text-red-500 hover:text-red-600 transition"
                height="1em"
                viewBox="0 0 512 512"
              >
                <path d="M73 127L256 49.4 439 127c5.9 2.5 9.1 7.8 9 12.8c-.4 91.4-38.4 249.3-186.3 320.1c-3.6 1.7-7.8 1.7-11.3 0C102.4 389 64.5 231.2 64 139.7c0-5 3.1-10.2 9-12.8zM457.7 82.8L269.4 2.9C265.2 1 260.7 0 256 0s-9.2 1-13.4 2.9L54.3 82.8c-22 9.3-38.4 31-38.3 57.2c.5 99.2 41.3 280.7 213.6 363.2c16.7 8 36.1 8 52.8 0C454.8 420.7 495.5 239.2 496 140c.1-26.2-16.3-47.9-38.3-57.2zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z" />
              </svg>
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

const searchLocations = ({ search }) => {
  return get(`/locations?search=${search}`);
};

function ExperienceCompatibilitySearch({ experienceId }) {
  const { t } = useTranslation('experiences');
  const [{ value: bestMatches }, , { setValue: setBestMatches }] =
    useField('bestMatches');
  const [{ value: incompatibilities }, , { setValue: setIncompatibilities }] =
    useField('incompatibilities');
  const [{ value: themes }] = useField('themes');
  const [{ value: segments }] = useField('segments');
  const [showFilters, setShowFilters] = useState(false);
  const [contractYear] = useState(null);
  const [state, setState] = useState(null);
  const [filters, setFilters] = useState({});
  const [searchParams] = useSearchParams();

  const { data } = useAsync({
    promiseFn: getExperiencesList,
    except: experienceId,
    search:
      filters?.location?.kind === 'experience' ? filters?.location?.title : '',
    cities: filters?.location?.kind === 'city' ? [filters?.location?.id] : [],
    startingPoint: searchParams.startingPoint ?? '',
    endingPoint: searchParams.endingPoint ?? '',
    countries:
      filters?.location?.kind === 'country' ? [filters?.location?.id] : [],
    contractYear: contractYear?.value ? `${contractYear.value}` : null,
    state: state?.value,
    themes: themes?.map(t => t.id),
    segments: segments?.map(s => s.id),
    watchFn: (props, prevProps) => {
      return (
        !isEqual(props.type, prevProps.type) ||
        !isEqual(props.search, prevProps.search) ||
        !isEqual(props.contractYear, prevProps.contractYear) ||
        !isEqual(props.state, prevProps.state) ||
        !isEqual(props.themes, prevProps.themes) ||
        !isEqual(props.countries, prevProps.countries) ||
        !isEqual(props.segments, prevProps.segments) ||
        !isEqual(props.cities, prevProps.cities) ||
        !isEqual(props.startingPoint, prevProps.startingPoint) ||
        !isEqual(props.endingPoint, prevProps.endingPoint)
      );
    },
  });

  const results = data?.data || [];

  const bulkAddBestMatches = () => {
    const toAdd = results
      .filter(e => !bestMatches?.find(b => b.id === e.id))
      .filter(e => !incompatibilities?.find(b => b.id === e.id));
    setBestMatches([...bestMatches, ...toAdd]);
  };

  const bulkAddIncompatibilities = () => {
    const toAdd = results
      .filter(e => !bestMatches?.find(b => b.id === e.id))
      .filter(e => !incompatibilities?.find(b => b.id === e.id));
    setIncompatibilities([...incompatibilities, ...toAdd]);
  };

  const updateFilterValue = useCallback(
    (property, value) => {
      setFilters(current => ({
        ...current,
        [property]: value,
      }));
    },
    [setFilters],
  );

  return (
    <div
      className={[
        'w-full p-5 border border-t-4 border-orange-500 bg-white rounded-lg',
      ].join(' ')}
    >
      <div className="text-lg font-medium">
        <div className="flex space-x-2 items-center text-orange-600">
          <div>
            <svg className="h-5 w-5" height="1em" viewBox="0 0 512 512">
              <path d="M368 208A160 160 0 1 0 48 208a160 160 0 1 0 320 0zM337.1 371.1C301.7 399.2 256.8 416 208 416C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208c0 48.8-16.8 93.7-44.9 129.1L505 471c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0L337.1 371.1z" />
            </svg>
          </div>
          <div>{t('steps.compatibility.search.title')}</div>
        </div>
      </div>

      <div className="pl-7 text-gray-500 text-sm font-medium">
        {t('steps.compatibility.search.description')}
      </div>

      <div className="my-3 flex flex-col space-y-3 w-full">
        <LocationSearchableSelect
          placeholder={t('locationsSearch', { ns: 'experiences' })}
          query={searchLocations}
          pathToEdges={['locationsSearch']}
          renderSelectedOption={value => value?.title}
          onChange={value => updateFilterValue('location', value)}
          wrapperStyle={{
            marginLeft: 0,
            width: '100%',
            height: 42,
            '--location-search-trigger-height': `${42}px`,
          }}
          inputStyle={{ width: '100%', height: 42 }}
          deletable={true}
          kind={'experience'}
          source={'distribution'}
          fullWidth={true}
        />

        <div className="flex flex-row justify-between gap-3">
          <LocationAwareSearch
            name="startingPoint"
            placeholder={t('startingPoint')}
          />
          <LocationAwareSearch
            name="endingPoint"
            placeholder={t('endingPoint')}
          />
        </div>

        {showFilters && (
          <>
            <div>
              <div className="text-black font-medium">{t('state.title')}</div>
              <Select
                value={state}
                onChange={value => setState(value)}
                options={[
                  { value: null, label: t('steps.compatibility.search.all') },
                  { value: 'available', label: t('state.available') },
                  { value: 'stand_by', label: t('state.standBy') },
                  { value: 'in_progress', label: t('state.inProgress') },
                ]}
              />
            </div>

            <MultiSearchableSelectField
              label={t('form.segments')}
              name="segments"
              query={GET_SEGMENTS}
              pathToEdges={['experienceSegments', 'edges']}
              renderOption={({ displayName }) => displayName}
              renderSelectedOption={({ displayName }) => displayName}
            />

            <MultiSearchableSelectField
              label={t('form.themes')}
              name="themes"
              query={GET_THEMES}
              pathToEdges={['experienceThemes', 'edges']}
              renderOption={({ displayName }) => displayName}
              renderSelectedOption={({ displayName }) => displayName}
            />

            {/*<div>*/}
            {/*  <div className="text-black font-medium">*/}
            {/*    {t('steps.compatibility.search.contractYear')}*/}
            {/*  </div>*/}
            {/*  <Select*/}
            {/*    value={contractYear}*/}
            {/*    onChange={value => setContractYear(value)}*/}
            {/*    options={[*/}
            {/*      { value: null, label: t('steps.compatibility.search.all') },*/}
            {/*      ...Array.from(*/}
            {/*        { length: 5 },*/}
            {/*        (_, i) => new Date().getFullYear() - i,*/}
            {/*      ).map(year => ({ value: year, label: year })),*/}
            {/*    ]}*/}
            {/*  />*/}
            {/*</div>*/}
          </>
        )}

        <button
          onClick={() => setShowFilters(!showFilters)}
          className="border-none text-sm text-gray-500 font-medium"
        >
          {showFilters
            ? t('steps.compatibility.search.hideFilters')
            : t('steps.compatibility.search.showFilters')}
        </button>

        <div className="text-gray-500 font-medium">
          {t('steps.compatibility.search.bulkActions')}
        </div>

        <div className="flex space-x-1">
          <KoobButton size="sm" onClick={() => bulkAddBestMatches()}>
            {t('steps.compatibility.search.addToBestMatches')}
          </KoobButton>

          <KoobButton size="sm" onClick={() => bulkAddIncompatibilities()}>
            {t('steps.compatibility.search.addToBlackList')}
          </KoobButton>
        </div>
      </div>

      <div className="flex flex-col space-y-1">
        {results?.map(experience => (
          <ExperienceSearchItem key={experience.id} experience={experience} />
        ))}
      </div>
    </div>
  );
}

export function ExperienceCompatibility() {
  const { t } = useTranslation('experiences');
  const { notify, notifyError } = useSnack();
  const { experienceId } = useParams();
  const [saving, setSaving] = useState(false);

  const { data: experienceData, isLoading } = useAsync({
    promiseFn: getExperience,
    experienceId: experienceId,
  });
  const experience = experienceData?.data;

  const save = async values => {
    try {
      setSaving(true);
      await put(`/experiences/${experienceId}`, {
        ...experience,
        bestMatches: values.bestMatches,
        incompatibilities: values.incompatibilities,
      });
      notify(t('editSuccess'));
      setSaving(false);
      return experienceId;
    } catch (err) {
      setSaving(false);
      if (err?.response?.data?.message) {
        notify(err?.response?.data?.message, { type: 'error' });
      } else {
        notifyError(err);
      }
    }
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8 py-6">
      <ExperienceHeader values={experienceData?.data} />

      <div className="relative">
        {saving && (
          <div className="bg-gray-100/75 backdrop-blur-sm rounded-lg border z-20 absolute h-full w-full flex justify-center items-center">
            <Loader />
          </div>
        )}

        {!isLoading ? (
          <>
            <Formik
              initialValues={{
                bestMatches: experience?.bestMatches ?? [],
                incompatibilities: experience?.incompatibilities ?? [],
              }}
              onSubmit={save}
            >
              {({ values, submitForm }) => (
                <>
                  <div className="mb-5">
                    <div className="bg-white border rounded-md p-5">
                      <div className="flex justify-center space-x-3">
                        <KoobButton onClick={submitForm}>
                          {t('misc:save')}
                        </KoobButton>

                        <Link to={`/experiences/edit/${experienceId}`}>
                          <KoobButton color="secondary">
                            {t('misc:cancel')}
                          </KoobButton>
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="grid grid-cols-3 gap-3">
                    <ExperienceCompatibilitySearch
                      experienceId={experienceId}
                    />

                    <ExperienceCompatibilityList
                      type="bestMatch"
                      experiences={values?.bestMatches ?? []}
                    />

                    <ExperienceCompatibilityList
                      type="incompatible"
                      experiences={values?.incompatibilities ?? []}
                    />
                  </div>
                </>
              )}
            </Formik>
          </>
        ) : (
          <>
            <div className="mb-5">
              <KoobPlaceholder className="rounded-lg h-20 w-full" />
            </div>

            <div className="grid grid-cols-3 gap-3">
              <KoobPlaceholder className="rounded-lg h-screen w-full" />
              <KoobPlaceholder className="rounded-lg h-64 w-full" />
              <KoobPlaceholder className="rounded-lg h-64 w-full" />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
