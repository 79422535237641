import { memo } from 'react';

const IcHistory = props => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
    style={{ ...props.style, color: props.color, fontSize: props.size }}
  >
    <path
      d="M13.5 3a9 9 0 00-9 9h-3l3.89 3.89.07.14L9.5 12h-3c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42A8.954 8.954 0 0013.5 21a9 9 0 000-18zm-1 5v5l4.25 2.52.77-1.28L14 12.15V8h-1.5z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export default memo(IcHistory);
