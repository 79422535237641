import { memo } from 'react';

const IcView = props => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    style={{ ...props.style, color: props.color, fontSize: props.size }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.243 2.991L4.98 8.256a.426.426 0 01-.602-.602L9.64 2.389a.935.935 0 011.325 0l5.263 5.264a.426.426 0 11-.602.602l-5.264-5.264h-.001a.083.083 0 00-.119 0zm-5.866 8.753a.426.426 0 01.602 0l5.264 5.264a.085.085 0 00.12 0l5.264-5.264a.426.426 0 01.602.602l-5.263 5.264a.937.937 0 01-1.325 0l-5.264-5.264a.426.426 0 010-.602z"
      fill="currentColor"
    />
  </svg>
);

export default memo(IcView);
