import { useState } from 'react';
import { useDeepCompareEffect } from 'react-use';
import { useDebounce } from 'react-use';
import Search from './Search';
import { useSearchParams } from 'hooks';

const LocationAwareSearch = ({
  placeholder,
  debounceTime = 500,
  name,
  options,
  ...props
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [value, setValue] = useState(searchParams[name] || '');

  useDeepCompareEffect(() => {
    if (typeof searchParams[name] === 'undefined') {
      setValue('');
    }
  }, [{ searchParams }]);

  useDebounce(
    () => {
      setSearchParams({ ...searchParams, [name]: value });
    },
    debounceTime,
    [value],
  );

  return (
    <Search
      value={value}
      placeholder={placeholder}
      onChange={value => setValue(value)}
      onClear={() => setValue('')}
      {...props}
    />
  );
};

export default LocationAwareSearch;
