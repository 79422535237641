import { Input } from 'ui/forms';
import { useField } from 'formik';
import VisuallyHidden from 'components/VisuallyHidden';
import styled, { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { memo } from 'react';

const DisplayLabel = styled.span.attrs({ 'aria-hidden': true })`
  font-weight: 600;
`;

const FormInputWrapper = ({
  children,
  label,
  beforeLabel,
  afterLabel,
  id,
  kind,
}) => (
  <>
    <VisuallyHidden as={'label'} for={id}>
      {label}
    </VisuallyHidden>
    <DisplayLabel>{beforeLabel}</DisplayLabel>
    {children}
    <DisplayLabel>{afterLabel}</DisplayLabel>
  </>
);

const PromotionsNightsNumberField = ({ kind, name }) => {
  const theme = useTheme();
  const { t } = useTranslation('promotionForm');

  const [field, { value }] = useField(name);
  if (!kind) return null;
  const id = `${kind}_guests`;
  return (
    <>
      <FormInputWrapper
        kind={kind}
        id={id}
        label={t(`nightsContraints.${kind}.label`)}
        beforeLabel={t(`nightsContraints.${kind}.splittedLabel.before`)}
        afterLabel={t(`nightsContraints.${kind}.splittedLabel.after`)}
      >
        <Input
          id={id}
          step="1"
          style={{
            width: '7ch',
            marginLeft: theme.spacing(0.375),
            marginRight: theme.spacing(0.375),
          }}
          min={0}
          type="number"
          value={value}
          {...field}
        />
      </FormInputWrapper>
    </>
  );
};

export default memo(PromotionsNightsNumberField);
