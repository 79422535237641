import React, { useEffect, useRef } from 'react';
import KoobCard from '../../../../../components/Koob/KoobCard';
import ChatboxInput from './ChatboxInput';
import ChatboxMessage from './ChatboxMessage';

export default function Chatbox({
  messages,
  refetch,
  bookingId,
  secret,
  displayAs,
  privateMode = false,
}) {
  const messageEndRef = useRef(null);

  useEffect(() => {
    messageEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  return (
    <KoobCard padding="">
      <div className="h-[36rem] overflow-scroll">
        <div className="py-5 px-[6%] flex flex-col space-y-5">
          {messages?.map(message => (
            <ChatboxMessage
              key={message.id}
              message={message}
              displayAs={displayAs}
            />
          ))}
          <div ref={messageEndRef} />
        </div>
      </div>

      <ChatboxInput
        bookingId={bookingId}
        secret={secret}
        refetch={refetch}
        privateMode={privateMode}
      />
    </KoobCard>
  );
}
