import { gql } from '@apollo/client';

export const GET_TO_REQUEST_ACCESS = gql`
  query getToRequestAccess(
    $order: ToRequestAccessOrderCriteria
    $search: String
    $endCursor: String
    $tripDesignerRequestState: String
    $state: String
  ) {
    toRequestAccess(
      after: $endCursor
      order: $order
      companyName: $search
      tripDesignerRequestState: $tripDesignerRequestState
      state: $state
    ) {
      edges {
        node {
          id
          email
          firstName
          lastName
          companyName
          state
          createdAt
          country {
            id
            title
          }
          city {
            id
            title
          }
        }
      }
      totalCount
      pageInfo {
        hasPreviousPage
        startCursor
        hasNextPage
        endCursor
      }
    }
  }
`;
