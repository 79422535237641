import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { Container, Card } from 'ui';
import { useSnack, useError } from 'hooks';
import { useTranslation } from 'react-i18next';
import { Header } from 'components';
import { initialLocale } from 'utils';
import ExperienceSegmentForm from '../../../components/Forms/ExperienceSegmentForm';

const CREATE_SEGMENT = gql`
  mutation ($input: CreateExperienceSegmentInput!) {
    createExperienceSegment(input: $input) {
      experienceSegment {
        id
        displayName
      }
      errors {
        attribute
        message
      }
    }
  }
`;

const CreateSegment = () => {
  const { t } = useTranslation('experienceSegment');
  const [currentLocale, setCurrentLocale] = useState(initialLocale);
  const navigate = useNavigate();
  const { notify } = useSnack();
  const { sendErrorSnack } = useError();
  const [createSegment] = useMutation(CREATE_SEGMENT);

  const handleCreateSegment = async values => {
    try {
      const { data } = await createSegment({ variables: { input: values } });

      if (data?.createExperienceSegment?.errors?.length > 0) {
        throw data?.createExperienceSegment?.errors?.[0];
      }

      navigate(-1);
      notify(t('createSegmentSuccess'));
    } catch (err) {
      sendErrorSnack(err);
    }
  };

  return (
    <>
      <Header />

      <Container size="narrow">
        <Card>
          <ExperienceSegmentForm
            onSubmit={handleCreateSegment}
            queryLocale={currentLocale}
            updateQueryLocale={setCurrentLocale}
            disabledCreate={currentLocale.value !== initialLocale.value}
          />
        </Card>
      </Container>
    </>
  );
};

export default CreateSegment;
