import { useState } from 'react';
import { useField } from 'formik';
import Editor from './Editor';
import { FormField, FormLabel, ErrorMessageWrapper } from 'ui/forms';
import { uniqueId } from 'lodash';
import { useDeepCompareEffect } from 'react-use';

// Unfortunately, React Quill pre-fill its editor's content with '<p><br></p>'
// React-Quill surround the content with this -> '<p>'content'</p>'
// and even if the editor and its content looks empty from a user point of view
// '<p></p>' will be send to the back-end
// and the formik's form will be triggered as 'dirty'.
// That's why we need to add a use case in the onChange()

export const REACT_QUILL_INIT_CONTENT = '<p><br></p>';
const REACT_QUILL_EMPTY_CONTENT = '<p></p>';

const EditorField = ({ label, touchRequired = true, required, ...props }) => {
  const [field, meta, helper] = useField(props);
  const [id] = useState(uniqueId());
  const [isMounted, setIsMounted] = useState(true);
  const [currentName, setCurrentName] = useState(props.name);

  const hasError =
    (!touchRequired || Boolean(meta.touched)) && Boolean(meta.error);

  // When switching the name of the editor, React-Quill has the tendency to
  // set the new value to the old name.
  //
  // To tackle this, we need to:
  // - store the current edited name, and reset upon change [1]
  // - render null during the transition, to prevent this from happening [2]

  useDeepCompareEffect(() => {
    // [1]
    setIsMounted(false);
    setTimeout(() => {
      setIsMounted(true);
      setCurrentName(props.name);
    });
  }, [{ name: props.name }]);

  if (!isMounted || props.name !== currentName) {
    // [2]
    return null;
  }

  return (
    <FormField>
      {label && (
        <FormLabel>
          {label}
          {required && <span className="ml-1 text-red-500">*</span>}
        </FormLabel>
      )}

      <Editor
        {...field}
        {...props}
        id={id}
        hasError={hasError}
        onFocus={() => helper.setTouched(true)}
        onBlur={() => helper.setTouched(true)}
        onChange={(value, _delta, source) => {
          if (source !== 'user') {
            return;
          }
          const newValue =
            value === REACT_QUILL_INIT_CONTENT ||
            value === REACT_QUILL_EMPTY_CONTENT
              ? ''
              : value;
          return field.onChange(field.name)(newValue);
        }}
      />

      {hasError && <ErrorMessageWrapper>{meta.error}</ErrorMessageWrapper>}
    </FormField>
  );
};

export default EditorField;
