import { memo } from 'react';

const IcArrowDown = ({ size, color, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    style={{ ...props.style, fontSize: props.size, color }}
  >
    <path
      d="M19.5 8.207l-7.147 7.146a.498.498 0 01-.706 0L4.5 8.207"
      stroke="currentColor"
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default memo(IcArrowDown);
