import { Suspense, StrictMode } from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import { MargaretProvider } from '@tymate/margaret';
import GQLProvider from 'providers/GQLProvider';
import AuthProvider from 'providers/AuthProvider';
import SnackProvider from 'providers/SnackProvider';
import { theme } from 'ui/theme';
import { fontStyles } from 'ui';
import App from './App';
import Login from 'containers/Auth/Login';
import Signup from 'containers/Auth/Signup';
import LostAccess from 'containers/Auth/LostAccess';
import ResetPassword from 'containers/Auth/ResetPassword';
import HotelKeeper from 'containers/HotelKeeper';
import AccountType from './containers/Auth/AccountType';
import 'sanitize.css';
import 'sanitize.css/typography.css';
import 'sanitize.css/forms.css';
import 'flag-icon-css/css/flag-icon.css';
import './index.css';
import './i18n';

const GlobalStyles = createGlobalStyle`
  html {
    -webkit-font-smoothing: antialiased;
  }

  nav li::before {
    content: initial;
  }

  body {
    color: ${({ theme }) => theme.text};
    ${fontStyles.body}
  }

  .ReactModal__Overlay {
    backdrop-filter: blur(10px);
  }

  .ReactModal__Content {
    min-height: 300px;
  }
`;

ReactDOM.render(
  <StrictMode>
    <Suspense fallback={<div />}>
      <MargaretProvider theme={theme}>
        <SnackProvider>
          <AuthProvider>
            <GQLProvider>
              <Router>
                <GlobalStyles />
                <Routes>
                  <Route path="account-type" element={<AccountType />} />
                  <Route path="login" element={<Login />} />
                  <Route path="lost-access" element={<LostAccess />} />
                  <Route path="password-reset" element={<ResetPassword />} />
                  <Route path="validate-account" element={<Signup />} />
                  <Route path="hotelkeeper/:secret" element={<HotelKeeper />} />
                  <Route path="/*" element={<App />} />
                </Routes>
              </Router>
            </GQLProvider>
          </AuthProvider>
        </SnackProvider>
      </MargaretProvider>
    </Suspense>
  </StrictMode>,
  document.getElementById('root'),
);

serviceWorker.unregister();

reportWebVitals();
