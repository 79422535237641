import { cva } from 'class-variance-authority';

export default function KoobTabs({ tabs, currentTab, setCurrentTab }) {
  const button = cva(
    [
      'w-full border-b-2 py-4 px-1 text-center text-sm font-medium border-t-0 border-x-0',
    ],
    {
      variants: {
        active: {
          true: 'border-orange-500 text-orange-600',
          false:
            'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
        },
      },
    },
  );

  return (
    <div className="w-full">
      <div className="border-b border-gray-200">
        <nav className="-mb-px flex" aria-label="Tabs">
          {tabs.map(tab => (
            <button
              key={tab.id}
              type="button"
              onClick={() => setCurrentTab(tab?.id)}
              className={button({ active: currentTab === tab.id })}
            >
              {tab.label}
            </button>
          ))}
        </nav>
      </div>
    </div>
  );
}
