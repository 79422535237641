import { Container } from 'ui';
import { Stack } from '@tymate/margaret';
import { GalleryField, TextField } from '../../../../components/Fields';
import KoobTabs from '../../../../components/Koob/KoobTabs';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldArray, useField } from 'formik';
import VisuallyHidden from '../../../../components/VisuallyHidden';
import { IoAddCircleOutline, IoTrashOutline } from 'react-icons/io5';
import { Button } from '../../../../components';
import styled, { useTheme } from 'styled-components';
import { EXPERIENCE_MEDIA_SCHEMA } from '../Utilities/ExperienceValidationSchemas';
import { initialLocale } from '../../../../utils';

const AddLinkButton = styled(Button).attrs({ type: 'button' })`
  display: inline-flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing(0.75)};
  color: ${({ theme }) => theme.textLight};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.primaryLight};
  color: ${({ theme }) => theme.primary};
  &:hover,
  &:focus,
  &:active {
    background-color: ${({ theme }) => theme.orangeLighter};
  }
`;

export function ExperienceMedia({ currentLocale }) {
  const { t } = useTranslation('experiences');
  const [{ value: mediaLinks }] = useField('mediaLinks');
  const theme = useTheme();

  const tabs = [
    {
      id: 'images',
      label: t('form.images'),
    },
    {
      id: 'links',
      label: t('form.links'),
    },
  ];

  const [currentTab, setCurrentTab] = useState(tabs[0]?.id);

  return (
    <Container size="full">
      <KoobTabs
        tabs={tabs}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
      />

      <div className="my-5">
        <Stack gutterSize={1.5}>
          {currentTab === 'images' && (
            <div className="w-full relative">
              {currentLocale.value !== initialLocale.value && (
                <div className="absolute z-10 h-full w-full flex justify-center items-center">
                  <div className="text-orange-500 px-3 py-2 bg-white border border-orange-500 rounded">
                    {t('form.media.selectEnglish')}
                  </div>
                </div>
              )}
              <div
                className={
                  currentLocale.value !== initialLocale.value &&
                  'pointer-events-none opacity-50'
                }
              >
                <GalleryField
                  name="pictures"
                  maximumColumnsCount={3}
                  canSelectMainPicture={true}
                />
              </div>
            </div>
          )}

          {currentTab === 'links' && (
            <>
              <FieldArray
                name="mediaLinks"
                render={({ push, remove }) => (
                  <Stack direction="column">
                    {(mediaLinks ?? []).map((link, index) => (
                      <Stack
                        className="border rounded p-10 m-5"
                        gutterSize={1}
                        alignY="end"
                      >
                        <TextField
                          label={t('form.title')}
                          name={`mediaLinks[${index}].title`}
                          required
                        />
                        <TextField
                          label={t('form.linkIndex', { index: index + 1 })}
                          name={`mediaLinks[${index}].link`}
                          required
                        />
                        <Button
                          type="button"
                          variant="secondary"
                          onClick={() => remove(index)}
                          style={{
                            padding: theme.spacing(0.25),
                            margin: 'auto',
                          }}
                        >
                          <VisuallyHidden>Delete</VisuallyHidden>
                          <IoTrashOutline color={theme.textLighter} size={20} />
                        </Button>
                      </Stack>
                    ))}
                    <AddLinkButton
                      style={{ marginTop: theme.spacing(0.75) }}
                      onClick={() => push('')}
                    >
                      <IoAddCircleOutline size={20} aria-hidden="true" />{' '}
                      {t('form.addLink')}
                    </AddLinkButton>
                  </Stack>
                )}
              />
            </>
          )}
        </Stack>
      </div>
    </Container>
  );
}

export const getSchema = () => {
  return EXPERIENCE_MEDIA_SCHEMA;
};
