import { memo } from 'react';

const LastMinuteIcon = ({ size = 20, color = 'currentColor', style = {} }) => {
  return (
    <svg
      viewBox="0 0 20 21"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size + 1}
      aria-hidden="true"
      focusable="true"
      {...style}
    >
      <g fill={color}>
        <path d="M10 0C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10C19.994 4.48 15.52.006 10 0zm0 18.333A8.333 8.333 0 1118.333 10 8.342 8.342 0 0110 18.333z"></path>
        <path d="M14.278 13.175l-3.861-3.54V5.416a.833.833 0 00-1.667 0V10c0 .234.098.457.27.615l4.133 3.787a.842.842 0 001.177-.05.833.833 0 00-.052-1.177z"></path>
      </g>
    </svg>
  );
};

export default memo(LastMinuteIcon);
