import { memo } from 'react';

const BasicDealIcon = ({ size = 22, color = 'currentColor', style = {} }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      aria-hidden="true"
      focusable="false"
      viewBox="0 0 22 22"
      {...style}
    >
      <g fill={color}>
        <path d="M.26 1.843l-.009.153.006 5.795c-.002.473.188.93.526 1.265l12.17 12.17a1.786 1.786 0 002.528 0l5.745-5.742c.28-.276.46-.64.51-1.032l.013-.186a1.81 1.81 0 00-.535-1.316L8.782.76a1.786 1.786 0 00-1.25-.51H2.036C1.123.25.359.94.26 1.843zm1.497 5.949L1.75 2.058l.005-.094c.013-.103.135-.214.282-.214H7.53c.075 0 .147.03.2.082l12.433 12.19a.287.287 0 01.086.203l-.007.086-.007.015-.038.061a.325.325 0 01-.03.034l-5.748 5.744a.287.287 0 01-.406 0L1.842 7.994a.281.281 0 01-.085-.201z"></path>
        <path d="M6 3.25a2.75 2.75 0 100 5.5 2.75 2.75 0 000-5.5zm0 1.5a1.25 1.25 0 110 2.5 1.25 1.25 0 010-2.5z"></path>
      </g>
    </svg>
  );
};

export default memo(BasicDealIcon);
