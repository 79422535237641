import { useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Container, Card } from 'ui';
import { useSnack, useError } from 'hooks';
import { useTranslation } from 'react-i18next';
import { Header } from 'components';
import ExperienceThemeForm from '../../../components/Forms/ExperienceThemeForm';

const THEME = gql`
  query getExperienceTheme($id: ID!) {
    node(id: $id) {
      ... on ExperienceTheme {
        id
        displayName
      }
    }
  }
`;

const UPDATE_THEME = gql`
  mutation updateExperienceTheme($input: UpdateExperienceThemeInput!) {
    updateExperienceTheme(input: $input) {
      experienceTheme {
        id
        displayName
      }
      errors {
        attribute
        message
      }
    }
  }
`;

const EditTheme = () => {
  const { themeId } = useParams();
  const { state: selectedLocale } = useLocation();
  const { t } = useTranslation('experienceTheme');
  const navigate = useNavigate();
  const { notify } = useSnack();
  const { sendErrorSnack } = useError();

  const [currentLocale, setCurrentLocale] = useState(selectedLocale);

  const { data } = useQuery(THEME, {
    variables: { id: themeId, locale: currentLocale?.value },
    fetchPolicy: 'no-cache',
  });

  const [updateTheme] = useMutation(UPDATE_THEME);

  const handleUpdateTheme = async values => {
    try {
      const { data } = await updateTheme({
        variables: {
          locale: currentLocale?.value,
          input: { experienceThemeId: themeId, ...values },
        },
      });

      if (data?.updateExperienceTheme?.errors?.length > 0) {
        throw data?.updateExperienceTheme?.errors?.[0];
      }

      navigate('/settings/experiences/themes', { state: currentLocale });
      notify(t('editThemeSuccess'));
    } catch (err) {
      sendErrorSnack(err);
    }
  };

  return (
    <>
      <Header />

      <Container size="narrow">
        <Card>
          <ExperienceThemeForm
            onSubmit={handleUpdateTheme}
            theme={data?.node}
            queryLocale={currentLocale}
            updateQueryLocale={setCurrentLocale}
          />
        </Card>
      </Container>
    </>
  );
};

export default EditTheme;
