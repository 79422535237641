import React from 'react';

export default function TypologyButton({
  selected,
  onSelect,
  icon,
  name,
  small = false,
}) {
  return (
    <div
      onClick={() => onSelect()}
      className={[
        'flex flex-col items-center justify-center cursor-pointer border-2 rounded-lg p-5',
        small ? 'h-24 w-24' : 'h-40 w-40',
        selected
          ? 'text-orange-500 border-3 border-orange-500'
          : 'text-gray-400 border-gray-300',
      ].join(' ')}
    >
      <div className="flex flex-col items-center text-center">
        {icon}
        <div className="mt-3 font-bold">{name}</div>
      </div>
    </div>
  );
}
