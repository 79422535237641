import styled from 'styled-components';
import { media, Stack } from '@tymate/margaret';

const BannerBase = styled(Stack).attrs({
  paddingHorizontal: 2,
  paddingVertical: 1.125,
  gutterSize: 0.5,
  alignY: 'center',
})`
  position: fixed;
  z-index: 3;
  background-color: ${({ theme }) => theme.orangeLighter};
  color: ${({ theme }) => theme.primary};
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  box-shadow: 0 0 4px ${({ theme }) => theme.separator};

  ${media.tablet`
    left: ${({ theme }) => theme.mainNav.width};

    ${({ leftPosition }) =>
      leftPosition &&
      `
      left: ${leftPosition}px;
    `}
  `}
`;

const InfoBanner = ({ children, leftPosition }) => {
  return <BannerBase leftPosition={leftPosition}>{children}</BannerBase>;
};

export default InfoBanner;
