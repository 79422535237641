import { useTranslation } from 'react-i18next';
import { Button } from 'components';
import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import Header from './Partials/Header';
import HotelsSelected from './Partials/HotelsSelected';
import HotelsBrowser from './Partials/HotelsBrowser';
import { get, put } from '../../../api/node';
import { useApp, useError, useSnack } from '../../../hooks';
import { useAsync } from 'react-async';
import { useNavigate } from 'react-router-dom';
import { Spinner } from '@tymate/margaret';

const getRequest = async ({ requestId }) => {
  return await get(`ToConnectionRequest/${requestId}`);
};

const ToConnectionRequestDetail = () => {
  const { t } = useTranslation('toConnectionRequest');
  const { isAllowedTo, canManageToConnectionRequest, user } = useApp();
  const { notify } = useSnack();
  const { requestId } = useParams();
  const { sendErrorSnack } = useError();
  const navigate = useNavigate();

  const { data, isLoading } = useAsync({
    promiseFn: getRequest,
    requestId: requestId,
  });

  const request = data?.data;

  useEffect(() => {
    if (request) {
      setGlobalMargin(request.margin ?? 0);
      setIsGlobalMarginPercent(request.isMarginPercent ?? false);
    }
    if (request?.requestHotels) {
      let formattedHotels = request?.requestHotels.map(node => ({
        id: node.hotel?.id,
        displayName: node.hotel?.displayName,
        country: node.hotel?.city?.country?.alpha2,
        city: node.hotel?.city?.title,
        margin: node.margin,
        isMarginPercent: node.isMarginPercent,
        periods: [],
      }));

      setSelectedHotels(formattedHotels);
    }
  }, [request]);

  const [hotels, setHotels] = useState([]);
  const [selectedHotels, setSelectedHotels] = useState(request?.hotels || []);
  const [globalMargin, setGlobalMargin] = useState(0);
  const [isGlobalMarginPercent, setIsGlobalMarginPercent] = useState(false);
  const [saving, setSaving] = useState(false);

  const isAllowedToManage = () => {
    return (
      isAllowedTo('canManageToConnectionRequest') ||
      canManageToConnectionRequest(user)
    );
  };

  const save = async (state, comment) => {
    try {
      setSaving(true);
      await put(`ToConnectionRequest/${requestId}`, {
        margin: globalMargin ? parseInt(globalMargin) : 0,
        isMarginPercent: isGlobalMarginPercent,
        state: state ?? request.state,
        explaination: comment?.reason,
        toConnectionRequestHotels: selectedHotels.map(hotel => ({
          id: hotel.id,
          margin: hotel.margin ? parseInt(hotel.margin) : 0,
          isMarginPercent: hotel.isMarginPercent,
          periods: hotel.periods,
        })),
      });
      notify(t('updated'));
      navigate(-1);
    } catch (error) {
      sendErrorSnack(error);
    } finally {
      setSaving(false);
    }
  };

  const setHotelAttribute = (hotelId, attribute, value) => {
    setSelectedHotels(current =>
      current.map(hotel => {
        if (hotel.id === hotelId) {
          return {
            ...hotel,
            [attribute]: value,
          };
        }
        return hotel;
      }),
    );
  };

  const isHotelSelected = hotelId => {
    return selectedHotels.some(h => h.id === hotelId);
  };

  const unselectHotel = hotelId => {
    setSelectedHotels(selectedHotels.filter(h => h.id !== hotelId));
  };

  const selectHotel = hotel => {
    if (!isHotelSelected(hotel.id)) {
      hotel.margin = globalMargin;
      hotel.isMarginPercent = isGlobalMarginPercent;
      hotel.periods = [];
      setSelectedHotels([...selectedHotels, hotel]);
    } else {
      unselectHotel(hotel.id);
    }
  };

  const selectAllShownHotels = hotelsList => {
    const hotelsNotSelected = hotelsList.filter(
      hotel => !isHotelSelected(hotel.id),
    );
    hotelsNotSelected.forEach(hotel => {
      hotel.margin = globalMargin;
      hotel.isMarginPercent = isGlobalMarginPercent;
      hotel.periods = [];
    });
    setSelectedHotels([...selectedHotels, ...hotelsNotSelected]);
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="py-4 px-4">
          <Header
            request={request}
            save={save}
            isLoading={saving}
            isAllowedToManage={isAllowedToManage()}
          />

          <div className="my-5 flex justify-end gap-5">
            {isAllowedToManage() && (
              <>
                <Button type="button" variant="simple" to={-1}>
                  {t('misc:cancel')}
                </Button>
                <Button
                  onClick={() => save()}
                  variant="primary"
                  disabled={saving}
                >
                  {t('misc:save')}
                </Button>
              </>
            )}
          </div>

          <div className="grid sm:grid-cols-2 gap-5">
            <HotelsSelected
              selectedHotels={selectedHotels}
              setHotelAttribute={setHotelAttribute}
              onRemoveHotel={unselectHotel}
              dmcorganization={request?.dmcorganization}
              toorganization={request?.toorganization}
              isAllowedToManage={isAllowedToManage()}
            />

            {isAllowedToManage() && (
              <HotelsBrowser
                hotels={hotels}
                setHotels={setHotels}
                isHotelSelected={isHotelSelected}
                selectAllShownHotels={selectAllShownHotels}
                onAddHotel={selectHotel}
                globalMargin={globalMargin}
                setGlobalMargin={setGlobalMargin}
                isGlobalMarginPercent={isGlobalMarginPercent}
                setIsGlobalMarginPercent={setIsGlobalMarginPercent}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ToConnectionRequestDetail;
