import { Stack } from '@tymate/margaret';
import { DataTable } from 'components';
import { useTranslation } from 'react-i18next';
import PillStatus from './PillStatus';
import VisuallyHidden from 'components/VisuallyHidden';
import { Link } from 'react-router-dom';
import { AiOutlineRight } from 'react-icons/ai';
import styled, { useTheme } from 'styled-components';
import IcBasicDeal from 'components/icons/IcBasicDeal';
import IcEarlyBooker from 'components/icons/IcEarlyBooker';
import IcLastMinute from 'components/icons/IcLastMinute';
import { formatCurrency } from 'utils';
import DropdownPromotionCopy from 'components/DropdownPromotionCopy';

const ActionLink = styled(Link)`
  color: ${({ theme }) => theme.textLight};
  &:hover,
  &:focus,
  &:active {
    color: ${({ theme }) => theme.orange};
  }
`;

const PromotionsListTable = ({ data = [], applicableTo, headers, type }) => {
  const { t } = useTranslation(['promotions', 'promotionForm']);
  const theme = useTheme();

  const formatBenefit = promotion => {
    let amount;
    const benefit = promotion?.benefit;
    if (
      promotion.isExperience ||
      benefit?.promoPerRoom ||
      benefit?.benefitAgeGroups?.nodes.length === 0
    ) {
      amount = benefit.value;
    } else {
      amount = benefit?.benefitAgeGroups?.nodes.reduce((prev, current) => {
        return prev.age > current.age ? prev : current;
      }, {}).value;
    }

    if (benefit?.kind === 'percent' || benefit?.kind === 'Benefits::Percent') {
      return <span>-{amount}%</span>;
    }
    if (
      benefit?.kind === 'supplement_percent' ||
      benefit?.kind === 'Benefits::SupplementPercent'
    ) {
      return <span>+{amount}%</span>;
    }
    if (
      benefit?.kind === 'supplement' ||
      benefit?.kind === 'Benefits::Supplement'
    ) {
      return (
        <span>
          +
          {formatCurrency({
            amount: amount,
            currency: benefit?.currency?.toUpperCase(),
          })}
        </span>
      );
    }
    if (benefit?.kind === 'amount' || benefit?.kind === 'Benefits::Amount') {
      return (
        <span>
          -
          {formatCurrency({
            amount: amount,
            currency: benefit?.currency?.toUpperCase(),
          })}
        </span>
      );
    }
    if (benefit?.kind === 'upgrade_room') {
      return (
        <span>{t('promotionTypes.upgrade_room', { ns: 'promotionForm' })}</span>
      );
    }
    if (benefit?.kind === 'free_night') {
      const amount = benefit?.value;
      const label = amount > 1 ? 'freeNights.plural' : 'freeNights.single';
      return (
        <span>
          {amount} {t(label)}
        </span>
      );
    }
    if (benefit?.kind === 'amenity') {
      const amount = benefit?.amenities?.nodes?.length;
      const label = amount > 1 ? 'amenities.plural' : 'amenities.single';
      return (
        <span>
          {amount} {t(label)}
        </span>
      );
    }
    return 'N/A';
  };

  const DealsMap = new Map([
    [
      'basic_deal',
      <Stack gutterSize={0.5} as="span" alignY="center">
        <IcBasicDeal size={18} color={theme.textLighter} />
        <span>{t(['deal.basic_deal'])}</span>
      </Stack>,
    ],
    [
      'early_booker',
      <Stack gutterSize={0.5} as="span" alignY="center">
        <IcEarlyBooker size={18} color={theme.textLighter} />
        <span>{t(['deal.early_booker'])}</span>
      </Stack>,
    ],
    [
      'last_minute',
      <Stack gutterSize={0.5} as="span" alignY="center">
        <IcLastMinute size={18} color={theme.textLighter} />
        <span>{t(['deal.last_minute'])}</span>
      </Stack>,
    ],
  ]);

  const StatusMap = new Map([
    ['planned', <PillStatus value="planned" emptyValue="-" />],
    ['expired', <PillStatus value="expired" emptyValue="-" />],
    ['active', <PillStatus value="active" emptyValue="-" />],
  ]);

  const mappedPromotions = data?.length
    ? data?.map(node => {
        return {
          kind: {
            value: node?.kind,
            render: () =>
              DealsMap.has(node?.kind) ? DealsMap.get(node?.kind) : 'N/A',
          },
          name: {
            value: node?.name,
            render: () => node?.name || 'N/A',
          },
          minNumberOfRooms: {
            value: node?.minNumberOfRooms,
            render: () => node?.minNumberOfRooms || 'N/A',
          },
          benefit: {
            value: node?.benefit,
            render: () => formatBenefit(node, t),
          },
          booking: {
            value: node?.booking,
            render: () => node?.booking || 'N/A',
          },
          status: {
            value: node?.status,
            render: () =>
              node?.status === 'not operational'
                ? '-'
                : StatusMap.has(node?.status)
                ? StatusMap.get(node?.status)
                : '-',
          },
          action: {
            value: null,
            render: () =>
              node?.id ? (
                <div className="absolute flex flex-row space-x-5 items-center -mt-3 -ml-7">
                  <ActionLink to={node?.id + '?applicableTo=' + applicableTo}>
                    <VisuallyHidden>{t('checkoutPromotion')}</VisuallyHidden>
                    <AiOutlineRight aria-hidden="true" />
                  </ActionLink>
                  <DropdownPromotionCopy
                    links={
                      type === 'experience' || node?.state === 'archived'
                        ? [
                            {
                              to:
                                './duplicate/' +
                                node?.id +
                                '?applicableTo=' +
                                applicableTo,
                              title: t('duplicate'),
                            },
                          ]
                        : [
                            {
                              to:
                                './duplicate/' + node?.id + '?applicableTo=dmc',
                              title: t('dmcduplicate'),
                            },
                            {
                              to:
                                './duplicate/' + node?.id + '?applicableTo=to',
                              title: t('toduplicate'),
                            },
                          ]
                    }
                  />
                </div>
              ) : null,
          },
        };
      })
    : data;

  return (
    <DataTable
      isEmpty={data?.length === 0}
      isSearchable={false}
      headings={headers}
      data={mappedPromotions}
      minWidth={900}
    />
  );
};

export default PromotionsListTable;
