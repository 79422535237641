const { gql } = require('@apollo/client');

export const UPDATE_ORGANIZATION = gql`
  mutation updateOrganization($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      organization {
        id
        displayName
        website
        phoneNumber
        zipCode
        canBeRequestedForToAccess
        adress
        city {
          id
          title
        }
        country {
          id
          title
        }
        region {
          id
          title
        }
        mainContact {
          id
          email
          phoneNumber
          firstName
          lastName
          job
        }
        countriesCount
        regions {
          totalCount
        }
        technicalContact {
          id
          email
          phoneNumber
          firstName
          lastName
          job
        }
      }
      errors {
        attribute
        message
      }
    }
  }
`;

export const GET_ORGANIZATION_CANCELLATION_POLICIES = gql`
  query organization($id: ID!) {
    node(id: $id) {
      ... on Organization {
        id
        canBeRequestedForToAccess
        cancellationPolicies
      }
    }
  }
`;
