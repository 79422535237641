import ExperienceHeader from '../Form/Partials/ExperienceHeader';
import React, { useCallback, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useAsync } from 'react-async';
import { getExperience } from '../Form/ExperienceEdit';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { get, put } from '../../../api/node';
import KoobPlaceholder from '../../../components/Koob/KoobPlaceholder';
import { useSearchParams, useSnack } from '../../../hooks';
import { Spinner as Loader, Stack } from '@tymate/margaret';
import KoobButton from 'components/Koob/KoobButton';
import { Container } from 'ui';
import { isEqual } from 'lodash';
import { getExperiencesList } from '../Compatibility/Compatibility';
import LocationAwareSearch from 'components/LocationAwareSearch';
import LocationSearchableSelect from '../List/partials/LocationSearchableSelect';
import { IcBestMatch } from 'components/icons';
import LocalMultiSearchableSelectField from 'components/Fields/LocalMultiSearchableSelectField';

const searchLocations = ({ search }) => {
  return get(`/locations?search=${search}`);
};

function ExperienceOption({ index, recommandableExperienceList, extraId }) {
  const { t } = useTranslation('experiences');

  const options = (recommandableExperienceList ?? [])
    .map(experience => {
      return {
        label: experience.name,
        value: experience.id,
      };
    })
    .filter(item => item?.value !== extraId);

  return (
    <Stack
      direction="column"
      gutterSize={1.5}
      size="full"
      className="p-4 border rounded-lg"
    >
      <Stack size="full">
        <LocalMultiSearchableSelectField
          label={t('extraRecommendation')}
          name={`extras.${index}.recommendations`}
          options={options}
          renderOption={option => option.label}
          renderSelectedOption={option => option.label}
        />
      </Stack>
    </Stack>
  );
}

function ExperienceSearchItem({ experience, isBestMatch, children }) {
  const { t } = useTranslation('experiences');
  return (
    <div
      className={['bg-gray-100 border rounded-md py-1 px-2 w-full'].join(' ')}
    >
      <div className="flex justify-between items-start space-x-2 w-full">
        <div className="text-sm text-gray-500">
          <div className="flex items-center font-medium gap-2 z-1">
            {experience.name} {isBestMatch && <IcBestMatch />}
          </div>
          <div className="text-xs">
            {experience?.inCity
              ? `${experience?.inCity?.title}, ${experience?.inCity?.country?.title}`
              : t('steps.compatibility.search.noLocation')}
          </div>
          <div className="flex mt-2">
            <div className="flex gap-0.5 items-center">
              {experience?.startPosition?.name && (
                <i className="far fa-flag"></i>
              )}
              <p className="text-textLighter text-bodySmall">
                {' '}
                {experience?.startPosition?.name}{' '}
              </p>
            </div>
            <div className="flex gap-0.5 items-center ml-3">
              {experience?.endPosition?.name && (
                <i className="far fa-flag-checkered"></i>
              )}
              <p className="text-textLighter text-bodySmall">
                {' '}
                {experience?.endPosition?.name}{' '}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div>{children}</div>
    </div>
  );
}

export function ExtraRecommandation() {
  const { t } = useTranslation('experiences');
  const { notify, notifyError } = useSnack();
  const { experienceId } = useParams();
  const [saving, setSaving] = useState(false);
  const [filters, setFilters] = useState({});
  const [searchParams] = useSearchParams();

  const {
    data: experienceData,
    isLoading,
    reload,
  } = useAsync({
    promiseFn: getExperience,
    experienceId: experienceId,
  });

  const { data } = useAsync({
    promiseFn: getExperiencesList,
    except: experienceId,
    search:
      filters?.location?.kind === 'experience' ? filters?.location?.title : '',
    cities: filters?.location?.kind === 'city' ? [filters?.location?.id] : [],
    countries:
      filters?.location?.kind === 'country' ? [filters?.location?.id] : [],
    startingPoint: searchParams.startingPoint ?? '',
    endingPoint: searchParams.endingPoint ?? '',
    type: 'Program',
    watchFn: (props, prevProps) => {
      return (
        !isEqual(props.search, prevProps.search) ||
        !isEqual(props.cities, prevProps.cities) ||
        !isEqual(props.countries, prevProps.countries) ||
        !isEqual(props.startingPoint, prevProps.startingPoint) ||
        !isEqual(props.endingPoint, prevProps.endingPoint)
      );
    },
  });

  const results = data?.data || [];
  const updateFilterValue = useCallback(
    (property, value) => {
      setFilters(current => ({
        ...current,
        [property]: value,
      }));
    },
    [setFilters],
  );

  const experience = experienceData?.data;

  const programRecommended = results
    .filter(item => {
      return !experienceData?.data?.incompatibilities.some(
        incompatItem => incompatItem.id === item.id,
      );
    })
    .sort((a, b) => {
      const aBestIndex = experience?.bestMatches.findIndex(
        item => item.id === a.id,
      );
      const bBestIndex = experience?.bestMatches.findIndex(
        item => item.id === b.id,
      );

      if (aBestIndex !== -1 && bBestIndex === -1) {
        return -1;
      } else if (aBestIndex === -1 && bBestIndex !== -1) {
        return 1;
      } else {
        return 0;
      }
    });
  const incluedIds = [];
  experience?.programs?.forEach(item => {
    item.includedExperiences.forEach(exp => {
      incluedIds.push(exp.id);
    });
  });
  const optionsRecommandables = experience?.extras?.filter(
    item => !incluedIds?.includes(item?.id),
  );

  function groupExtrasByRecommendations(extrasList) {
    const recommendationsMap = new Map();
    extrasList?.forEach(extra => {
      extra.recommendations.forEach(recommendation => {
        const { id, name } = recommendation;

        if (!recommendationsMap.has(id)) {
          recommendationsMap.set(id, {
            id: id,
            name: name,
            recommendations: [],
          });
        }

        recommendationsMap.get(id).recommendations.push({
          value: extra.id,
          label: extra.name,
          cancellationPolicies: extra.cancellationPolicies,
        });
      });
    });
    return Array.from(recommendationsMap.values());
  }

  const groupedByRecommendations = recommendationsList => {
    return recommendationsList.reduce((acc, recommendation) => {
      const { id, name, recommendations } = recommendation;

      recommendations.forEach(extra => {
        if (!acc[extra.id]) {
          acc[extra.id] = {
            id: extra.id,
            name: extra.name,
            recommendations: [],
          };
        }
        acc[extra.id].recommendations.push({
          id,
          name,
        });
      });
      return acc;
    }, {});
  };

  const save = async values => {
    const recommendation = programRecommended?.map((extra, index) => {
      const recommendation = values?.extras[index];
      return {
        id: extra?.id,
        name: extra?.name,
        recommendations: (recommendation?.recommendations || []).map(item => ({
          id: item.value,
          name: item?.label,
        })),
      };
    });

    const groupedByRecommendationsExtras =
      groupedByRecommendations(recommendation);
    const groupedRecommendationsArray = Object.values(
      groupedByRecommendationsExtras,
    );

    try {
      setSaving(true);
      await put(`/experiences/${experienceId}`, {
        ...experience,
        extras: groupedRecommendationsArray,
      });
      setSaving(false);
      reload();
      notify(t('editSuccess'));
      return experienceId;
    } catch (err) {
      setSaving(false);
      if (err?.response?.data?.message) {
        notify(err?.response?.data?.message, { type: 'error' });
      } else {
        notifyError(err);
      }
    }
  };

  const formatDatas = programRecommended?.map(item => {
    const groupedExtras = groupExtrasByRecommendations(experience?.extras);
    const extra = groupedExtras?.find(extra => extra.id === item?.id);
    return {
      id: item?.id,
      name: item?.name,
      recommendations: extra?.recommendations,
    };
  });
  const excludedExperienceIds = experienceData?.data?.incompatibilities?.map(
    item => item.id,
  );

  return (
    <div className="px-4 sm:px-6 lg:px-8 py-6">
      <ExperienceHeader values={experienceData?.data} />

      <div className="relative">
        {saving && (
          <div className="bg-gray-100/75 backdrop-blur-sm rounded-lg border z-20 absolute h-full w-full flex justify-center items-center">
            <Loader />
          </div>
        )}

        {!isLoading ? (
          <>
            <Formik
              initialValues={{
                extras: formatDatas,
              }}
              onSubmit={save}
            >
              {({ submitForm }) => (
                <>
                  <div className="mb-5">
                    <div className="bg-white border rounded-md p-5">
                      <div className="flex justify-center space-x-3">
                        <KoobButton onClick={submitForm}>
                          {t('misc:save')}
                        </KoobButton>

                        <Link to={`/experiences/edit/${experienceId}`}>
                          <KoobButton color="secondary">
                            {t('misc:cancel')}
                          </KoobButton>
                        </Link>
                      </div>
                    </div>
                  </div>

                  <Container size="full">
                    <div className="flex flex-row justify-between mb-10 gap-4">
                      <div className="my-3 flex flex-col space-y-4 w-3/5">
                        <LocationSearchableSelect
                          placeholder={t('locationsSearch', {
                            ns: 'experiences',
                          })}
                          query={searchLocations}
                          pathToEdges={['locationsSearch']}
                          renderSelectedOption={value => value?.title}
                          onChange={value =>
                            updateFilterValue('location', value)
                          }
                          excludedExperienceIds={excludedExperienceIds}
                          wrapperStyle={{
                            marginLeft: 0,
                            width: '100%',
                            height: 42,
                            zIndex: 60,
                            '--location-search-trigger-height': `${42}px`,
                          }}
                          inputStyle={{ width: '100%', height: 42 }}
                          deletable={true}
                          kind={'experience'}
                          source={'distribution'}
                          fullWidth={true}
                        />
                      </div>
                      <div className="my-3 flex flex-col space-y-3">
                        <LocationAwareSearch
                          name="startingPoint"
                          placeholder={t('startingPoint')}
                        />
                      </div>

                      <div className="my-3 flex flex-col space-y-3">
                        <LocationAwareSearch
                          name="endingPoint"
                          placeholder={t('endingPoint')}
                        />
                      </div>
                    </div>

                    <Stack direction="column" gutterSize={1.5} size="full">
                      {(programRecommended || []).map((program, index) => (
                        <ExperienceSearchItem
                          key={index}
                          experience={program}
                          isBestMatch={Boolean(
                            experience?.bestMatches.some(
                              item => item.id === program.id,
                            ),
                          )}
                        >
                          <ExperienceOption
                            recommandableExperienceList={optionsRecommandables}
                            experience={program}
                            index={index}
                            extraId={program?.id}
                          />
                        </ExperienceSearchItem>
                      ))}
                    </Stack>
                  </Container>
                </>
              )}
            </Formik>
          </>
        ) : (
          <>
            <div className="mb-5">
              <KoobPlaceholder className="rounded-lg h-20 w-full" />
            </div>

            <div className="grid grid-cols-1 gap-3">
              <KoobPlaceholder className="rounded-lg h-14 mt-2 w-full" />
              <KoobPlaceholder className="rounded-lg h-14 mt-2 w-full" />
              <KoobPlaceholder className="rounded-lg h-14 mt-2 w-full" />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
