import { useContext } from 'react';
import {
  AppContext,
  AuthContext,
  RoutesContext,
  SnackContext,
  BookingContext,
  TripDesignerContext,
} from 'contexts';

export const useApp = () => {
  const context = useContext(AppContext);

  if (context === undefined) {
    throw new Error(`useApp must be used within an AppProvider`);
  }

  return context;
};

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error(`useAuth must be used within an AuthProvider`);
  }

  return context;
};

export const useRoutes = () => {
  const context = useContext(RoutesContext);

  if (context === undefined) {
    throw new Error(`useRoutes must be used within an RoutesProvider`);
  }

  return context;
};

export const useSnack = () => {
  const context = useContext(SnackContext);

  if (context === undefined) {
    throw new Error(`useSnack must be used within an SnackContext`);
  }

  return context;
};

export const useBooking = () => {
  const context = useContext(BookingContext);

  if (context === undefined) {
    throw new Error(`useBooking must be used within an BookingContext`);
  }

  return context;
};

export const useTripDesigner = () => {
  const context = useContext(TripDesignerContext);

  if (context === undefined) {
    throw new Error(`useBooking must be used within an BookingContext`);
  }

  return context;
};
