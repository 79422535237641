import { gql } from '@apollo/client';

export const GET_CONTRACTS = gql`
  query getContracts(
    $hotelId: ID!
    $stateFilter: StateFilter
    $encapsulateDates: DateRangeTypeFilter
    $endCursor: String
  ) {
    contracts(
      hotelId: $hotelId
      stateFilter: $stateFilter
      encapsulateDates: $encapsulateDates
      first: 10
      after: $endCursor
    ) {
      edges {
        node {
          id
          status
          startAt
          endAt
          updatedAt
          lastModifier {
            id
            avatarUrl(size: MEDIUM)
            firstName
            lastName
          }
          extraBeds {
            nodes {
              id
              age
              operand
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export const GET_CONTRACT = gql`
  query getContract($id: ID!) {
    node(id: $id) {
      ... on Contract {
        id
        startAt
        endAt
        enableGroupPrices
        enableExtraBedPrices
        allotment
        freeSale
        minimumStay
        status
        allotmentReleaseDays
        extraBeds {
          nodes {
            id
            age
            operand
          }
        }
        minimumGroupRoomsCount
        hotel {
          id
          roomCount
        }
        mealPlan {
          id
          displayName
        }
        hotelRoomContracts {
          nodes {
            id
            hotelRoom {
              id
              attachments {
                imageUrl(size: ICON)
              }
              count
              displayName
              isExternalSupplierOnly
            }
            periods {
              nodes {
                id
                startAt
                endAt
                fitPrice
                gitPrice
                includedServices
                allotmentCount
                freeSale
                minimumStay
                mealPlan {
                  id
                  displayName
                }
                periodOrganizations {
                  nodes {
                    organization {
                      id
                      displayName
                      scope
                    }
                    allotmentCount
                  }
                }
                extraBedPrices {
                  nodes {
                    extraBed {
                      id
                      age
                      operand
                    }
                    fitPrice
                    gitPrice
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const CREATE_CONTRACT = gql`
  mutation createContract($input: CreateContractInput!) {
    createContract(input: $input) {
      contract {
        id
      }
      errors {
        attribute
        message
      }
    }
  }
`;

export const DUPLICATE_CONTRACT = gql`
  mutation DuplicateContract($input: DuplicateContractInput!) {
    duplicateContract(input: $input) {
      contract {
        id
      }
      errors {
        attribute
        message
      }
    }
  }
`;

export const UPDATE_CONTRACT = gql`
  mutation updateContract($input: UpdateContractInput!) {
    updateContract(input: $input) {
      errors {
        attribute
        message
      }
      contract {
        id
        startAt
        endAt
        hotel {
          id
          roomCount
        }
        hotelRoomContracts {
          edges {
            node {
              id
              hotelRoom {
                id
                displayName
                count
              }
              periods {
                edges {
                  node {
                    id
                    startAt
                    endAt
                    fitPrice
                    gitPrice
                    includedServices
                    allotmentCount
                    freeSale
                    minimumStay
                    extraBedPrices {
                      nodes {
                        extraBed {
                          id
                          age
                          operand
                        }
                        fitPrice
                        gitPrice
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const PUBLISHED_DRAFT_CONTRACT = gql`
  mutation publishedDraftContract($input: PublishedDraftContractInput!) {
    publishedDraftContract(input: $input) {
      contract {
        id
        status
      }
      errors {
        attribute
        message
      }
    }
  }
`;

export const GET_CURRENCIES = gql`
  query {
    __type(name: "CurrencyValues") {
      enumValues {
        name
      }
    }
  }
`;
