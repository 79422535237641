import React, { useState } from 'react';
import { Caption, Text } from 'ui';
import { useTranslation } from 'react-i18next';
import { Avatar, Button, Modal } from 'components';
import { useApp } from '../../../../hooks';
import { useAsync } from 'react-async';
import { getOrganization } from 'api/node/organization';
import { Stack } from '@tymate/margaret';
import { formatCurrencyLabel, formatImageLinkForCss } from 'utils';
import TosMaximumTimeBeforeBooking from 'components/TosMaximumTimeBeforeBooking/TosMaximumTimeBeforeBooking';
import { IcCog } from 'components/icons';
import ExchangeRatesForm from './ExchangeRatesForm';

export default function Header({ isAllowedToManage }) {
  const { currentOrganizationIdInt } = useApp();
  const { t } = useTranslation('toConnectionRequest');
  const [isCurrencyExchangeModalOpened, setIsCurrencyExchangeModalOpened] =
    useState(false);

  const { data } = useAsync({
    promiseFn: getOrganization,
    id: currentOrganizationIdInt,
  });
  const currentOrganization = data?.data;

  return (
    <div className="bg-white border p-10">
      <Stack
        direction="row"
        alignY="center"
        alignX="space-between"
        gutterSize={2}
      >
        <Stack direction="column" gutterSize={0.5}>
          <Stack direction="row" gutterSize={0.5} alignY="center">
            <Stack direction="column" gutterSize={0.5}>
              <Avatar
                imageUrl={formatImageLinkForCss(currentOrganization?.avatarUrl)}
              />
            </Stack>
            <Stack direction="column" gutterSize={2} alignY="center">
              <Stack>
                <Text type="h1">{currentOrganization?.displayName}</Text>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <Stack direction="column" gutterSize={2}>
          <Stack
            direction="row"
            gutterSize={0.5}
            alignX="flex-end"
            alignY="center"
          >
            <Caption>
              <div>
                {currentOrganization?.currency &&
                  t('defaultCurrency', {
                    currency: formatCurrencyLabel(
                      currentOrganization?.currency,
                    ),
                  })}
              </div>
            </Caption>
            <Button
              onClick={() => setIsCurrencyExchangeModalOpened(true)}
              variant="simple"
              icon={<IcCog />}
            >
              {t('exchangeRates')}
            </Button>
          </Stack>
        </Stack>
      </Stack>

      <Stack direction="row" marginTop={1.5} gutterSize={1.5} alignX="center">
        <TosMaximumTimeBeforeBooking isAllowedToManage={isAllowedToManage} />
      </Stack>

      {isCurrencyExchangeModalOpened && (
        <Modal
          isOpen
          onRequestClose={() => {
            setIsCurrencyExchangeModalOpened(false);
          }}
          title={
            <Stack size="full" alignY="center" gutterSize={1}>
              <p>{t('currencyExchangeRateModal:title')}</p>
            </Stack>
          }
          size={'large'}
        >
          <ExchangeRatesForm
            dmcCurrency={currentOrganization?.currency}
            isAllowedToManage={isAllowedToManage}
          />
        </Modal>
      )}
    </div>
  );
}
