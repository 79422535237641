import styled from 'styled-components';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import { MdAddCircleOutline } from 'react-icons/md';
import { Stack, Text, ButtonReset } from '@tymate/margaret';
import { IcCancel } from 'components/icons';
import { FormLabel, FormField } from 'ui/forms';
import BedsSelectField from './BedsSelectField';
import BedsEditField from './BedsEditField';

const AddHotelRoomComposition = styled(ButtonReset).attrs({ type: 'button' })`
  max-width: max-content;
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing(0.75)};
  background-color: ${({ theme }) => theme.orangeLight};
  color: ${({ theme }) => theme.primary};
  border-radius: 4px;
  border: solid 1px transparent;

  &:hover {
    border-color: ${({ theme }) => theme.primary};
    box-shadow: 0 1px 2px 0 ${({ theme }) => theme.primaryBackground};
  }

  ${({ disabled, theme }) =>
    disabled &&
    `
      background-color: ${theme.disabled};
      border: solid 1px ${theme.disabled};
      box-shadow: revert;
      color: ${theme.textLight};
  `}
`;

const HotelRoomCompositionsField = ({ name }) => {
  const { t } = useTranslation('hotelRoom');
  const [{ value }, , { setValue }] = useField({ name });
  const hotelRoomCompositions = value;

  const handleDeleteComposition = hotelRoomCompositionIndex => {
    const newHotelRoomCompositions = [...hotelRoomCompositions];

    newHotelRoomCompositions.splice(hotelRoomCompositionIndex, 1);
    setValue([...newHotelRoomCompositions]);
  };

  return (
    <FormField gutterSize={1.25}>
      <FormLabel style={{ margin: 0 }}>{t('room_composition')}</FormLabel>

      {hotelRoomCompositions.map((_, index) => (
        <Stack direction="column" gutterSize={0.375} size="full">
          <Stack
            alignY="center"
            alignX="space-between"
            gutterSize={0.375}
            size="full"
          >
            <Text type="bodySmall" color="textLight">
              Composition {index + 1}
            </Text>
            <ButtonReset
              type="button"
              onClick={() => {
                handleDeleteComposition(index);
              }}
            >
              <IcCancel size={16} />
            </ButtonReset>
          </Stack>
          <BedsSelectField
            placeholder={t('selectBed')}
            indexRoomComposition={index}
            name={`${name}[${index}].hotelRoomCompositionBeds`}
          />
          <BedsEditField name={`${name}[${index}].hotelRoomCompositionBeds`} />
        </Stack>
      ))}

      <AddHotelRoomComposition
        onClick={() =>
          setValue([...hotelRoomCompositions, { hotelRoomCompositionBeds: [] }])
        }
      >
        <Stack alignY="center" gutterSize={0.25}>
          <MdAddCircleOutline size={16} />
          <Text type="bodySmall">{t('addComposition')}</Text>
        </Stack>
      </AddHotelRoomComposition>
    </FormField>
  );
};

export default HotelRoomCompositionsField;
