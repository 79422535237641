import { useQuery } from '@apollo/client';
import { SelectField } from 'components/Fields';
import { GET_CURRENCIES } from 'api/contracts';
import { formatCurrencyLabel } from 'utils';

const SelectCurrencyField = ({ ...props }) => {
  const { data: currenciesData } = useQuery(GET_CURRENCIES);
  const currenciesOptions = (currenciesData?.__type?.enumValues || []).map(
    ({ name: currency }) => ({
      value: currency,
      label: formatCurrencyLabel(currency),
    }),
  );

  return (
    <SelectField
      options={currenciesOptions}
      {...props}
      renderSelectedValue={value => value?.label || formatCurrencyLabel(value)}
    />
  );
};

export default SelectCurrencyField;
