import React from 'react';
import { useQuery } from '@apollo/client';
import DocumentUpload from '../../Bookings/Booking/Documents/Partials/DocumentUpload';
import DocumentList from '../../Bookings/Booking/Documents/Partials/DocumentList';
import Chatbox from '../../Bookings/Booking/Chat/Partials/Chatbox';
import {
  HOTEL_KEEPER_GET_ADMIN_BOOKING_DOCUMENTS,
  HOTEL_KEEPER_GET_ADMIN_BOOKING_MESSAGES,
} from '../../../api/hotelKeeper';
import { CardTitle } from '../../../ui';
import { useTranslation } from 'react-i18next';

export default function HotelKeeperDocuments({ bookingId, secret }) {
  const { data: docs, refetch: refetchDocs } = useQuery(
    HOTEL_KEEPER_GET_ADMIN_BOOKING_DOCUMENTS,
    {
      variables: { secret },
    },
  );

  const { data: messages, refetch: refetchMessages } = useQuery(
    HOTEL_KEEPER_GET_ADMIN_BOOKING_MESSAGES,
    {
      variables: { secret },
    },
  );

  const { t } = useTranslation('bookings');

  return bookingId ? (
    <div className="py-5">
      <CardTitle>{t('chat.title')}</CardTitle>
      <div className="grid grid-cols-2 space-x-5 mt-4">
        <Chatbox
          messages={messages?.hotelKeeperAdminBookingMessages?.nodes}
          refetch={refetchMessages}
          bookingId={bookingId}
          secret={secret}
          privateMode={true}
          displayAs="hotel"
        />

        <div className="flex flex-col space-y-5">
          <DocumentUpload
            bookingId={bookingId}
            onUpload={refetchDocs}
            privateMode={true}
            secret={secret}
          />

          <DocumentList
            documents={docs?.hotelKeeperAdminBookingDocuments?.nodes}
          />
        </div>
      </div>
    </div>
  ) : null;
}
