import { useDeepCompareEffect } from 'react-use';

const UnsavedFormTrigger = ({ setIsUnsaved, dirty }) => {
  useDeepCompareEffect(() => {
    setIsUnsaved(dirty);
  }, [{ dirty }]);

  return null;
};

export default UnsavedFormTrigger;
