import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  lng: 'en',
  defaultNS: 'shared',
  resources: {
    fr: require('./locales/fr.json'),
    en: require('./locales/en.json'),
  },
});

export default i18n;
