import { useRef, useImperativeHandle, forwardRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { MdSearch, MdHighlightOff } from 'react-icons/md';
import { ButtonReset, Spinner } from '@tymate/margaret';
import { fontSize, Input } from 'ui';
import { useTranslation } from 'react-i18next';
import { uniqueId } from 'lodash';

const CancelableInputContainer = styled.div`
  position: relative;
  svg {
    color: ${({ theme }) => theme.separator};
  }
`;

const SearchIconButton = styled(ButtonReset)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: ${({ theme }) => theme.spacing(0.25)};
  padding: ${({ theme }) => theme.spacing(0.25)};
  line-height: 0.8;
  z-index: 1;
`;

const SearchInput = styled(Input)`
  ${fontSize.bodySmall}
  min-height: 38px;
  padding-left: ${({ theme }) => theme.spacing(2)};
  padding-right: ${({ theme }) => theme.spacing(2)};
`;

const InputIcon = styled.div`
  display: flex;
  position: absolute;
  transition: opacity 150ms ease;
  top: 50%;
  right: ${({ theme }) => theme.spacing(0.5)};
  transform: translateY(-50%);

  ${({ variant, theme }) =>
    variant === 'search' &&
    `
      right: -7px;
    `}
`;

const ClearSearchButton = styled(InputIcon)`
  opacity: 0;

  ${({ isShown }) =>
    isShown &&
    css`
      z-index: 3;
      opacity: 0.8;
    `}
`;

const Search = (
  { variant, onChange, placeholder, size, value, isLoading, onClear = () => {}, ...props },
  ref,
) => {
  const inputRef = useRef();
  const { t } = useTranslation('misc');
  const [id] = useState(uniqueId());

  useImperativeHandle(ref, () => ({
    focus: () => {
      if (Boolean(inputRef.current)) {
        inputRef.current.focus();
      }
    },
  }));

  return (
    <CancelableInputContainer size={size} variant={variant}>
      <SearchIconButton as="label" htmlFor={id} aria-label={t('search')}>
        <MdSearch size="20" />
      </SearchIconButton>

      <SearchInput
        id={id}
        ref={inputRef}
        type="text"
        onChange={e => onChange(e.target.value)}
        value={value}
        placeholder={placeholder || t('search')}
        variant={variant}
        autoComplete="none"
        {...props}
      />

      {Boolean(value) && !isLoading && (
        <ClearSearchButton
          as={ButtonReset}
          isShown={Boolean(value)}
          onClick={() => {
            onChange('');
            onClear();
          }}
          type="button"
        >
          <MdHighlightOff size={20} />
        </ClearSearchButton>
      )}

      {isLoading && (
        <InputIcon variant="search">
          <Spinner variant="button" />
        </InputIcon>
      )}
    </CancelableInputContainer>
  );
};

export default forwardRef(Search);
