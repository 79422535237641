import { NetworkStatus, useQuery } from '@apollo/client';
import { DataTable, Button } from 'components';
import { ActionsLinks, ActionLink } from 'ui';
import { useTranslation } from 'react-i18next';
import { formatOrderCriteria, getActionsCellWidth } from 'utils';
import { Stack } from '@tymate/margaret';
import { AiOutlineRight } from 'react-icons/ai';
import { GET_SETTING_REQUEST } from 'api/settingRequest';
import { isNil, pickBy } from 'lodash';
import { useApp, useSearchParams } from 'hooks';

const SettingRequestsByStateAndTheme = ({ state, settingName, kind }) => {
  const { t } = useTranslation('settingRequest');
  const { user } = useApp();

  const [{ search, ...searchParams }] = useSearchParams();

  const { data, fetchMore, networkStatus } = useQuery(GET_SETTING_REQUEST, {
    variables: pickBy(
      {
        search,
        order: formatOrderCriteria(searchParams),
        discarded: state === 'archived',
        state,
        kind,
        settingName,
        userId: user?.role === 'dmc_admin' ? user?.id : undefined,
      },
      value => !isNil(value),
    ),
  });

  const isEmpty =
    networkStatus === NetworkStatus.ready &&
    (data?.settingRequests?.edges || []).length === 0;

  const headings = [
    {
      slug: 'displayName',
      label: (
        <Stack gutterSize={0.375} alignY="baseline">
          <span>{t('name')}</span>
        </Stack>
      ),
      orderCriteria: 'displayName',
    },
    {
      slug: 'settingName',
      label: (
        <Stack gutterSize={0.375} alignY="baseline">
          <span>{t('settingName')}</span>
        </Stack>
      ),
    },
    { slug: 'actions', cannotBeReordered: true, width: getActionsCellWidth(2) },
  ];

  const settingRequestsData = (data?.settingRequests?.edges || []).map(
    ({ node }) => ({
      displayName: { value: node?.displayName },
      settingName: { value: node?.settingName },
      actions: {
        render: () => (
          <ActionsLinks>
            <ActionLink to={`${node?.id}/details`}>
              <AiOutlineRight />
            </ActionLink>
          </ActionsLinks>
        ),
      },
    }),
  );

  const handleLoadNextPage = () => {
    try {
      fetchMore({
        variables: {
          endCursor: data?.settingRequests?.pageInfo?.endCursor,
        },
      });
    } catch (err) {}
  };

  return (
    <Stack direction="column" size="full" alignX="center" gutterSize={1}>
      <DataTable
        headings={headings}
        data={settingRequestsData}
        isEmpty={isEmpty}
      />

      {data?.settingRequests?.pageInfo?.hasNextPage && (
        <Button variant="simple" onClick={handleLoadNextPage}>
          {t('misc:seeMore')}
        </Button>
      )}
    </Stack>
  );
};

export default SettingRequestsByStateAndTheme;
