import KoobInput from '../../../../components/Koob/Input/KoobInput';
import KoobInputSelect from '../../../../components/Koob/Input/KoobInputSelect';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import BulkEditorItemContracts from './BulkEditorItemContracts';
import { useField } from 'formik';
import KoobInputCheckbox from '../../../../components/Koob/Input/KoobInputCheckbox';
import BulkEditorItemRooms from './BulkEditorItemRooms';

export default function BulkEditorItem({ item, type }) {
  const { t } = useTranslation('toConnectionRequest');

  const fieldName = useMemo(() => {
    switch (type) {
      case 'hotel':
        return 'toConnectionRequestHotels';
      case 'experience':
        return 'toConnectionRequestExperiences';
      case 'template':
        return 'toConnectionRequestTemplates';
      default:
        return '';
    }
  }, [type]);

  const [{ value: field }, , { setValue: setField }] = useField(
    `request.${fieldName}`,
  );

  const findIndex = field?.findIndex(fieldItem => fieldItem.id === item.id);
  const itemIndex = findIndex >= 0 ? findIndex : null;
  const itemField = `request.${fieldName}[${itemIndex}]`;

  const [{ value: itemFieldValue }] = useField(itemField);

  const addItem = () => {
    if (itemIndex !== null) {
      return;
    }

    setField([
      ...(field ?? []),
      {
        id: item.id,
        isMarginPercent: null,
        margin: null,
        distribute: null,
      },
    ]);
  };

  const removeItem = () => {
    if (itemIndex === null) {
      return;
    }

    const newField = [...field];
    newField.splice(itemIndex, 1);
    setField(newField ?? []);
  };

  return (
    <div
      className={[
        'bg-white border border-l-4 rounded-md py-5 px-6',
        itemIndex !== null && 'border-l-k-orange',
      ].join(' ')}
    >
      <div className="grid grid-cols-3 items-center">
        <div className="flex space-x-3 items-center">
          <KoobInputCheckbox
            value={itemIndex !== null}
            setValue={value => {
              value ? addItem() : removeItem();
            }}
          />

          <div className="flex-shrink-0 bg-gray-50 w-12 h-12 rounded-md overflow-hidden">
            {item.image && (
              <img
                src={item.image}
                alt=""
                className="w-full h-full object-cover border-none"
              />
            )}
          </div>

          <div className="truncate">
            <div className="font-medium leading-none text-gray-700">
              {item.name}
            </div>

            <div>
              {item.stars && (
                <div>
                  {[...Array(5)].map((_, i) => (
                    <i
                      key={i}
                      className={[
                        'fas fa-star text-xs',
                        i < item?.stars ? 'text-yellow-400' : 'text-gray-300',
                      ].join(' ')}
                    />
                  ))}
                </div>
              )}
            </div>

            {item.description && (
              <div className="text-gray-500 text-xs">{item.description}</div>
            )}
          </div>
        </div>

        <div className="col-span-2 flex justify-end space-x-3 items-center">
          <KoobInputSelect
            label={t('bulkEdit.editor.marginType')}
            name={`${itemField}.isMarginPercent`}
            options={[
              { label: t('bulkEdit.editor.noChange'), value: null },
              { label: t('bulkEdit.editor.marginPercent'), value: true },
              { label: t('bulkEdit.editor.marginFixed'), value: false },
            ]}
            disabled={
              itemIndex === null || itemFieldValue.distribute === 'false'
            }
          />

          <KoobInput
            label={t('bulkEdit.editor.marginValue')}
            name={`${itemField}.margin`}
            type="number"
            min="0"
            disabled={
              itemIndex === null || itemFieldValue.distribute === 'false'
            }
          />

          <KoobInputSelect
            label={t('bulkEdit.editor.visibility')}
            name={`${itemField}.distribute`}
            options={[
              { label: t('bulkEdit.editor.noChange'), value: null },
              { label: t('bulkEdit.editor.visible'), value: true },
              { label: t('bulkEdit.editor.invisible'), value: false },
            ]}
            disabled={itemIndex === null}
          />

          {type === 'hotel' && (
            <div className="flex items-center space-x-3 mt-4">
              <BulkEditorItemRooms
                hotel={item}
                itemField={itemField}
                disabled={
                  itemIndex === null || itemFieldValue.distribute === 'false'
                }
              />
              <BulkEditorItemContracts
                itemField={itemField}
                hotelIdRails={item.idRails}
                hotelName={item.name}
                disabled={
                  itemIndex === null || itemFieldValue.distribute === 'false'
                }
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
