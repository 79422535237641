import { Stack } from '@tymate/margaret';
import { useField } from 'formik';
import styled from 'styled-components';
import { FormField, FormLabel, TextArea, ErrorMessageWrapper } from 'ui/forms';

const FormLabelRight = styled(FormLabel)`
  color: ${({ theme }) => theme.textLighter};
  font-weight: normal;
`;

const TextAreaField = ({ label, labelRight = '', ...props }) => {
  const [field, meta] = useField(props);

  const hasError = Boolean(meta.touched) && Boolean(meta.error);

  return (
    <FormField>
      <Stack alignX="space-between" alignY="center">
        <FormLabel>{label}</FormLabel>
        <FormLabelRight>{labelRight}</FormLabelRight>
      </Stack>
      <TextArea {...props} {...field} hasError={hasError} />
      {hasError && <ErrorMessageWrapper>{meta.error}</ErrorMessageWrapper>}
    </FormField>
  );
};

export default TextAreaField;
