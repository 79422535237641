import React from 'react';

const KoobTitle = ({
  className,
  size = 'text-xl',
  color = 'text-k-h',
  children,
}) => {
  return (
    <h3 className={`${size} font-bold ${color} ${className}`}>{children}</h3>
  );
};

export default KoobTitle;
