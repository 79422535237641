import { useField } from 'formik';
import { FormLabel, ErrorMessageWrapper, FormField } from 'ui/forms';
import Stepper from 'components/Stepper';

const StepperField = ({ label, name, required, ...props }) => {
  const [{ value }, meta, { setValue }] = useField(name);

  const hasError = Boolean(meta.touched) && Boolean(meta.error);

  return (
    <FormField>
      {label && (
        <FormLabel>
          {label}
          {required && <span className="ml-1 text-red-500">*</span>}
        </FormLabel>
      )}
      <Stepper value={value} onChange={setValue} {...props} />
      {hasError && <ErrorMessageWrapper>{meta.error}</ErrorMessageWrapper>}
    </FormField>
  );
};

export default StepperField;
