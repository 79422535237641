import { memo } from 'react';

const IcMail = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    {...props}
    style={{ ...props.style, color: props.color, fontSize: props.size }}
  >
    <g
      transform="translate(2 5)"
      stroke="currentColor"
      strokeWidth={1.364}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <rect width={19.091} height={13.636} x={0.455} y={0.682} rx={1.364} />
      <path d="M19.237 1.182l-7.409 5.694v-.009a3.026 3.026 0 01-3.673 0L.745 1.164" />
    </g>
  </svg>
);

export default memo(IcMail);
