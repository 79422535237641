import { gql, useMutation } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Card } from 'ui';
import { useSnack, useError, useApp } from 'hooks';
import { useTranslation } from 'react-i18next';
import { Header } from 'components';
import SettingRequestForm from 'components/Forms/SettingRequestForm';

const CREATE_SETTING_REQUEST = gql`
  mutation createSettingRequest($input: CreateSettingRequestInput!) {
    createSettingRequest(input: $input) {
      settingRequest {
        id
      }
      errors {
        message
        attribute
      }
    }
  }
`;

const CreateSettingRequest = ({ kind }) => {
  const { t } = useTranslation('settingRequest');
  const navigate = useNavigate();
  const { notify } = useSnack();
  const { sendErrorSnack } = useError();
  const [createSettingRequestType] = useMutation(CREATE_SETTING_REQUEST);
  const { user } = useApp();
  const { settingName } = useParams();

  const handleCreateSettingRequest = async values => {
    try {
      const userId = user?.id;
      const input = {
        ...values,
        settingName,
        kind,
        userId,
      };

      const { data } = await createSettingRequestType({
        variables: { input: input },
      });

      if (data?.createSettingRequest?.errors?.length > 0) {
        throw data?.createSettingRequest?.errors?.[0];
      }

      navigate(-1);
      notify(t('createSettingRequestSuccess'));
    } catch (err) {
      sendErrorSnack(err);
    }
  };

  return (
    <>
      <Header />

      <Container size="narrow">
        <Card>
          <SettingRequestForm onSubmit={handleCreateSettingRequest} />
        </Card>
      </Container>
    </>
  );
};

export default CreateSettingRequest;
