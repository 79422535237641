const { default: styled } = require('styled-components');
const { default: TextField } = require('./TextField');

const Field = styled(TextField)`
  width: 100px;
`;

const TextFieldButton = ({ type, name, content, label, ...props }) => {
  return (
    <div className="relative">
      {!label && <div className="mt-[26px]" />}
      <Field name={name} label={label} type={type} {...props} />
      <div className="pointer-events-none absolute inset-y-0 top-6 right-5 flex items-center pr-2">
        <span className="text-gray-500 sm:text-sm">{content}</span>
      </div>
    </div>
  );
};

export default TextFieldButton;
