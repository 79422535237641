import { Stack } from '@tymate/margaret';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Text } from 'ui';
import { get, put } from 'api/node';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnack } from 'hooks';
import { useAsync } from 'react-async';
import { formatPromotionApiData } from '../Form/Utilities/ExperienceHelpers';
import { useState } from 'react';
import PromotionWizard from './PromotionWizard';
import { InfoBanner } from 'components';
import { PromotionTitleInfo } from 'components/Promotions/PromotionTitleInfo';

export const getPromotion = async ({ promotionId }) => {
  return await get(`/promotions/${promotionId}`);
};

const TopSection = styled(Stack).attrs({
  size: 'full',
})`
  padding-top: ${({ theme }) => theme.spacing()};
  padding-bottom: ${({ theme }) => theme.spacing()};
`;

const PromotionDetails = ({ experience, onSubmit, isDuplicated }) => {
  const { t } = useTranslation('promotions');
  const { experienceId, promotionId } = useParams();
  const [hasUnsavedData, setHasUnsavedData] = useState(false);
  const navigate = useNavigate();
  const { notify, notifyError } = useSnack();
  const { data, isLoading } = useAsync({
    promiseFn: getPromotion,
    promotionId: promotionId,
  });
  const promotion = formatPromotionApiData(data?.data) ?? [];
  const handleSubmit = async (promotion, methods) => {
    try {
      methods.setSubmitting(true);
      await put(`/promotions/${promotionId}`, promotion);
      notify(t('updateSuccess'));
      navigate(`/experiences/edit/${experienceId}/promotions`, {
        replace: true,
      });
    } catch (error) {
      if (error?.response?.data?.message) {
        notify(error?.response?.data?.message, { type: 'error' });
      } else {
        notifyError(error);
      }
    } finally {
      methods.setSubmitting(false);
    }
  };

  return (
    <>
      <TopSection alignY="center">
        <Text type="h1">{t('totitle')}</Text>
        <PromotionTitleInfo name={promotion?.name} status={promotion?.state} />
      </TopSection>
      {!isLoading && (
        <PromotionWizard
          experience={experience}
          promotion={promotion}
          handleSave={onSubmit ?? handleSubmit}
          hasUnsavedData={hasUnsavedData}
          setHasUnsavedData={setHasUnsavedData}
          isDuplicated={isDuplicated}
        />
      )}
      {hasUnsavedData && (
        <InfoBanner>{t('misc:unsavedForm', { ns: undefined })}</InfoBanner>
      )}
    </>
  );
};

export default PromotionDetails;
