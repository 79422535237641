import { memo } from 'react';

const IcLightning = props => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    style={{ ...props.style, color: props.color, fontSize: props.size }}
  >
    <g fill="#29CC88" fillRule="evenodd">
      <rect fillOpacity={0.12} width={24} height={24} rx={12} />
      <path
        d="m15.709 8.405-2.723.73 1.717-5.612a.344.344 0 0 0-.418-.432L8.972 4.515a.344.344 0 0 0-.255.338l.143 8.504a.344.344 0 0 0 .433.326l2.222-.595-.033 7.388c-.001.17.12.314.287.341a.35.35 0 0 0 .382-.229l3.972-11.742a.344.344 0 0 0-.414-.441Z"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default memo(IcLightning);
