import { useState } from 'react';
import {
  ButtonReset,
  PopoverItem,
  PopoverItemButton,
  PopoverMenu,
  Stack,
} from '@tymate/margaret';
import { Button } from 'components';
import { MdDelete } from 'react-icons/md';
import { useFormikContext } from 'formik';
import { useDeepCompareEffect } from 'react-use';
import DropdownRooms from 'components/DropdownRooms';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Title = styled.strong`
  font-size: 17px;
  margin: auto;
  padding: ${({ theme }) => theme.spacing(0.75)};
`;

const TogglableField = ({
  idleElement,
  activeElement,
  onDelete,
  onCopy,
  contract,
  currentPeriod,
  disabled,
}) => {
  const [isToggled, setIsToggled] = useState(false);
  const { initialValues } = useFormikContext();
  const { t } = useTranslation('contracts');

  const hotelRoom = contract?.hotelRoomContracts?.nodes
    .filter(item => item)
    .sort((room, comparedRoom) => {
      return room?.hotelRoom?.id.localeCompare(comparedRoom?.hotelRoom?.id);
    });

  const handleKeyPress = event => {
    if (event.charCode === 13) {
      setIsToggled(false);
      event.preventDefault();
    }
  };

  useDeepCompareEffect(() => {
    if (isToggled) {
      setIsToggled(false);
    }
  }, [{ initialValues }]);

  if (isToggled) {
    return (
      <Stack
        direction="column"
        size="full"
        gutterSize={0.5}
        onKeyPress={handleKeyPress}
      >
        {activeElement}
        <Stack size="full" alignX="flex-end">
          <Button onClick={() => setIsToggled(false)} variant="primary">
            OK
          </Button>
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack gutterSize={0.5}>
      <ButtonReset
        disabled={disabled}
        type="button"
        onClick={() => setIsToggled(true)}
        style={{ flex: 1 }}
      >
        {idleElement}
      </ButtonReset>

      {Boolean(onDelete) && !disabled && (
        <ButtonReset onClick={onDelete}>
          <MdDelete />
        </ButtonReset>
      )}

      {Boolean(onCopy) && !disabled && (
        <>
          <DropdownRooms
            popoverElement={
              <PopoverMenu>
                <Title>{t('CopyPeriodInto')}</Title>
                <PopoverItem>
                  {(hotelRoom || []).map((hotel, index) => (
                    <PopoverItemButton
                      type="button"
                      key={index}
                      onClick={() => onCopy(currentPeriod, index)}
                    >
                      <Stack gutterSize={0} alignY="center">
                        <span>{hotel.hotelRoom.displayName}</span>
                      </Stack>
                    </PopoverItemButton>
                  ))}
                  <PopoverItemButton
                    type="button"
                    onClick={() => onCopy(currentPeriod, -1)}
                  >
                    <Stack gutterSize={0} alignY="center">
                      <span>{t('CopyInAllRooms')}</span>
                    </Stack>
                  </PopoverItemButton>
                </PopoverItem>
              </PopoverMenu>
            }
          />
        </>
      )}
    </Stack>
  );
};

export default TogglableField;
