import React from 'react';

export default function KoobBadge({
  className,
  size = 'md',
  color = 'bg-gray-300 text-gray-50',
  children,
}) {
  const colorClass = {
    gray: 'bg-gray-200 text-gray-500',
    green: 'bg-green-200 text-green-500',
    blue: 'bg-blue-200 text-blue-500',
    orange: 'bg-orange-200 text-orange-500',
    red: 'bg-red-200 text-red-500',
    purple: 'bg-purple-200 text-purple-500',
  };

  const sizeClass = {
    sm: 'py-0.5 px-2 text-xs',
    md: 'md',
  };

  return (
    <div
      className={`rounded-md flex items-center ${colorClass[color] ?? color} ${
        sizeClass[size]
      } ${className}`}
    >
      <div>{children}</div>
    </div>
  );
}
