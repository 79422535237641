import { memo } from 'react';

const IcFlag = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      d="M2.625 21.375V2.625m0 14.132l2.628-.809h-.008a8.013 8.013 0 016.55.825l-.008-.008a8.008 8.008 0 006.391.883l2.484-.716v-.009a.94.94 0 00.675-.908V6.233h0a.946.946 0 00-1.2-.908l-1.975.558h-.009a8.025 8.025 0 01-6.4-.883h0a8.036 8.036 0 00-6.558-.842l-2.633.8"
      stroke="currentColor"
      strokeWidth={1.25}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default memo(IcFlag);
