import styled, { css } from 'styled-components';
import { Box, media, useMargaret } from '@tymate/margaret';
import { useLocation } from 'react-router-dom';
import MainSidebar from 'components/MainSidebar';
import { useDeepCompareEffect } from 'react-use';
import { useSearchParams } from 'hooks';
import UserImpersonationAlert from './UserImpersonationAlert';

const Page = styled(Box)`
  position: relative;

  ${({ variant }) =>
    variant !== 'full' &&
    media.tablet`
      margin-left: ${({ theme }) => theme.mainNav.width};
    `}
`;

const Content = styled.main`
  background-color: ${({ theme }) => theme.background};
  min-height: 100vh;

  ${({ variant }) =>
    variant === 'bare' &&
    css`
      padding: 0;

      ${media.tablet`
        padding: 0;
      `}
    `}

  ${({ variant, theme }) =>
    variant === 'archived' &&
    css`
      background: ${theme.backgroundArchived};
    `}
`;

const Layout = ({ children }) => {
  const { key, pathname } = useLocation();
  const { collapseMainNav } = useMargaret();
  const [{ state }] = useSearchParams();

  const shouldHideSidebar = Boolean(pathname.match(/^\/bookings\/new/));

  useDeepCompareEffect(() => {
    collapseMainNav();
  }, [{ key }]);

  return (
    <>
      <UserImpersonationAlert />

      {!shouldHideSidebar && <MainSidebar />}

      <Page variant={shouldHideSidebar ? 'full' : undefined}>
        <Content variant={state === 'archived' ? 'archived' : null}>
          {children}
        </Content>
      </Page>
    </>
  );
};

export default Layout;
