import React from 'react';
import PromotionDetails from './PromotionDetails';
import { post } from 'api/node';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnack } from 'hooks';
import { useTranslation } from 'react-i18next';

const PromotionDuplicate = ({ experience }) => {
  const { t } = useTranslation('promotions');
  const { experienceId } = useParams();
  const navigate = useNavigate();
  const { notify, notifyError } = useSnack();
  const handleSubmit = async (promotion, methods) => {
    try {
      methods.setSubmitting(true);
      await post(`/promotions/`, promotion);
      navigate(`/experiences/edit/${experienceId}/promotions`, {
        replace: true,
      });
      notify(t('createSuccess'));
    } catch (error) {
      if (error?.response?.data?.message) {
        notify(error?.response?.data?.message, { type: 'error' });
      } else {
        notifyError(error);
      }
    } finally {
      methods.setSubmitting(false);
    }
  };
  return (
    <PromotionDetails
      experience={experience}
      onSubmit={handleSubmit}
      isDuplicated={true}
    />
  );
};

export default PromotionDuplicate;
