import { Stack } from '@tymate/margaret';
import styled from 'styled-components';
import { Link } from 'ui';

const Wrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.separator};
  border-radius: 4px;
  padding: ${({ theme }) => theme.spacing()};
  margin-bottom: ${({ theme }) => theme.spacing(1.5)};
`;

const Email = styled.p`
  font-size: 14px;
  font-weight: bold;
  margin-top: 0;
`;

const Company = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.textLighter};
  font-weight: 600;
`;

const Name = styled.span`
  color: ${({ theme }) => theme.textLight};
  font-size: 14px;
`;

const UserInfo = ({ firstName, lastName, company, email }) => {
  return (
    <Wrapper>
      <Stack direction="row" alignY="center" alignX="space-between">
        <Name>
          {firstName} {lastName}
        </Name>
        <Link to="/login">This is not me</Link>
      </Stack>
      <Email>{email}</Email>
      <Company>{company}</Company>
    </Wrapper>
  );
};

export default UserInfo;
