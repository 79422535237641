import { Routes, Route } from 'react-router-dom';
import ToConnectionRequestList from './List/ToConnectionRequestList';
import ToConnectionRequestDetail from './Edit';
import ToConnectionRequestExperience from './Edit/ToConnectionRequestExperience';
import ToConnectionRequestTemplate from './Edit/ToConnectionRequestTemplate';
import ToConnectionRequestBulkEdit from './BulkEdit';

const Hotels = () => {
  return (
    <>
      <Routes>
        <Route path="bulk-edit" element={<ToConnectionRequestBulkEdit />} />
        <Route
          path=":requestId/hotels"
          element={<ToConnectionRequestDetail />}
        />
        <Route
          path=":requestId/experiences"
          element={<ToConnectionRequestExperience />}
        />
        <Route
          path=":requestId/templates"
          element={<ToConnectionRequestTemplate />}
        />
        <Route path="/" element={<ToConnectionRequestList />} />
      </Routes>
    </>
  );
};

export default Hotels;
