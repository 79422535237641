import { useState } from 'react';
import { RoutesContext } from 'contexts';

const pathsNames = {};

const RoutesProvider = ({ children }) => {
  const [routesNames, setRoutesNames] = useState(pathsNames);

  const pathsRegexesWithPlaceholder = [
    /bookings\/(my-bookings|partner-bookings)\/(by-day|by-hotel)(\/[^/]+){1}$/,
    /users(\/[^/]+){1}$/,
    /categories(\/[^/]+){1}$/,
    /traveler-kinds(\/[^/]+){1}$/,
    /organizations(\/[^/]+){1}$/,
    /to-request-access(\/[^/]+){1}$/,
    /hotels\/(catalog)(\/[^/]+){1}$/,
    /settings\/hotels\/(locations|styles|categories|dmc-categories|accreditations|general-categories|room-types|bed-types|sustainable-clusters)(\/[^/]+){1}$/,
    /settings\/hotels\/facilities-groups\/(facilities|groups)(\/[^/]+){1}$/,
    //
  ];

  const pathsRegexesWithoutBreadcrumb = [
    /^hotels$/,
    /^\/?hotels\/catalog\/.+\/promotions\/.+/,
    /contracts(\/[^/]+){1}$/,
  ];

  const setRouteName = ({ path, name }) => {
    if (typeof name === 'function') {
      setRoutesNames({ ...routesNames, [path]: name() });
    } else {
      setRoutesNames({ ...routesNames, [path]: name });
    }
  };

  return (
    <RoutesContext.Provider
      value={{
        routesNames,
        setRouteName,
        pathsRegexesWithPlaceholder,
        pathsRegexesWithoutBreadcrumb,
      }}
    >
      {children}
    </RoutesContext.Provider>
  );
};

export default RoutesProvider;
