import { Box, ButtonReset, Stack } from '@tymate/margaret';
import ReactModal from 'react-modal';
import { useLockBodyScroll } from 'react-use';
import { Scrollbars } from 'react-custom-scrollbars';
import styled from 'styled-components';
import { IcCancel } from './icons';
import { Text } from 'ui';
import { isNil } from 'lodash';

const ModalWrapper = styled(ReactModal)`
  position: absolute;
  background-color: #ffffff;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  z-index: 2147483646;
  max-height: calc(100vh - 32px);
  overflow-y: auto;
  border-radius: 24px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  outline: none;
  border: 0;
  width: 600px;
  max-width: calc(100vw - 32px);
  display: flex;
  flex-direction: column;

  ${({ size }) =>
    size === 'small' &&
    `
      width: 400px;
    `}

  ${({ size }) =>
    size === 'medium' &&
    `
      width: 800px;
    `}

  ${({ size }) =>
    size === 'large' &&
    `
      width: 1200px;
    `}
`;

const ModalHeader = styled(Stack).attrs({
  size: 'full',
  alignX: 'center',
  alignY: 'center',
})`
  min-height: 5em;
  padding: ${({ theme }) => theme.spacing(1.5)};
  background-color: ${({ theme }) => theme.background};
  border-bottom: 1px solid ${({ theme }) => theme.separator};
  border-radius: 24px 24px 0 0;

  h2 {
    margin: 0;
  }
`;

const ModalFooter = styled.div`
  background-color: ${({ theme }) => theme.background};
  padding: ${({ theme }) => theme.spacing(1.5)};
  border-top: 1px solid ${({ theme }) => theme.separator};
  border-radius: 0 0 24px 24px;
`;

const ModalContent = styled(Box).attrs(({ padding }) => ({
  padding: isNil(padding) ? 1.5 : padding,
}))`
  color: ${({ theme }) => theme.textLight};
  text-align: ${({ textAlign }) => textAlign || 'center'};
  flex: 1;
`;

const CloseModalTriggerButton = styled(ButtonReset)`
  position: relative;
  z-index: 2;
  color: #c3b8c6;
`;

const overlayStyles = {
  zIndex: 2147483646,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
};

const Modal = ({
  children,
  isOpen,
  title,
  onRequestClose,
  header,
  footer,
  size,
  modalContentProps,
  contentHasOwnScrollbars,
}) => {
  useLockBodyScroll(isOpen);

  return (
    <ModalWrapper
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={{
        overlay: { ...overlayStyles },
        content: { overflowY: 'visible' },
      }}
      ariaHideApp={false}
      size={size}
    >
      <ModalHeader>
        <Stack direction="column" gutterSize={1} size="full">
          <Stack
            gutterSize={1}
            alignX="space-between"
            alignY="center"
            size="full"
          >
            <div style={{ width: 36 }} />
            <Text type="h2">{title}</Text>
            <CloseModalTriggerButton
              onClick={onRequestClose}
              children="&times;"
            >
              <IcCancel size={36} />
            </CloseModalTriggerButton>
          </Stack>
          {header && <div>{header}</div>}
        </Stack>
      </ModalHeader>
      {contentHasOwnScrollbars ? (
        <ModalContent {...modalContentProps}>{children}</ModalContent>
      ) : (
        <Scrollbars
          autoHeight
          autoHeightMax="calc(100vh - 300px)"
          style={{ flex: 1 }}
        >
          <ModalContent {...modalContentProps}>{children}</ModalContent>
        </Scrollbars>
      )}
      {footer && <ModalFooter>{footer}</ModalFooter>}
    </ModalWrapper>
  );
};

export default Modal;
