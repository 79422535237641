import { get, post, putFile } from './index';

export const getTripConversations = ({ tripId, filters }) => {
  return post(`/trip/${tripId}/conversation`, filters);
};

export const getTripConversation = ({ conversationId }) => {
  return get(`/trip/conversations/${conversationId}`);
};

export const getTripCoversationMessages = ({ tripId, conversationId }) => {
  return get(`/trip/${tripId}/conversation/${conversationId}/message`);
};

export const sendTripMessage = ({ tripId, conversationId, body }) => {
  return post(`/trip/${tripId}/conversation/${conversationId}/message`, body);
};

export const getTripConversationDocuments = ({ tripId, conversationId }) => {
  return get(`/trip/${tripId}/conversation/${conversationId}/document`);
};

export const updloadTripDocument = ({ tripId, conversationId, payload }) => {
  return putFile(
    `/trip/${tripId}/conversation/${conversationId}/document`,
    payload,
  );
};
