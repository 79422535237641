import { Stack } from '@tymate/margaret';
import { MdChevronRight } from 'react-icons/md';
import { useTheme } from 'styled-components';
import { Text } from 'ui';
import PillStatus from './PillStatus';

export const PromotionTitleInfo = ({ name, status }) => {
  const theme = useTheme();
  if (!Boolean(name) || !Boolean(status)) return null;
  return (
    <Stack
      style={{ marginLeft: theme.spacing(0.5) }}
      direction="row"
      alignY="center"
      gutterSize={0.5}
    >
      <MdChevronRight aria-hidden="true" size={18} />
      <Text type="h1" as="span" style={{ fontWeight: 'normal' }}>
        {name}
      </Text>
      <PillStatus value={status} emptyValue={null} />
    </Stack>
  );
};
