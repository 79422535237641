import { gql } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useSetBreadcrumbName } from 'hooks';

const EXPERIENCE = gql`
  query ($id: ID!) {
    node(id: $id) {
      ... on ExperienceTheme {
        id
        displayName
      }
    }
  }
`;

const ThemeBreadcrumb = () => {
  const { themeId } = useParams();

  useSetBreadcrumbName({
    path: `/settings/experiences/themes/${themeId}`,
    query: EXPERIENCE,
    variables: { id: themeId },
    formatData: data =>
      Boolean(data?.node) ? `${data?.node?.displayName} ` : null,
  });

  return null;
};

export default ThemeBreadcrumb;
