import { useTranslation } from 'react-i18next';
import { Stack } from '@tymate/margaret';
import { useField } from 'formik';
import {
  EditorField,
  MultiSearchableSelectField,
  SearchableSelectField,
  SegmentedControlField,
  SelectField,
  TextField,
} from 'components/Fields';
import TextFieldButton from 'components/Fields/TextFieldButton';
import { GET_CITIES, GET_COUNTRIES } from 'api/locations';
import { GET_ACCREDITATIONS } from 'containers/HotelsSettings/Accreditations/AccreditationsList';
import { Container } from '../../../../ui';
import { EXPERIENCE_CONFIG_SCHEMA } from '../Utilities/ExperienceValidationSchemas';
import React, { useEffect } from 'react';
import { getLanguages } from '../../../../utils/language';
import { useApp } from '../../../../hooks';
import LocalMultiSearchableSelectField from '../../../../components/Fields/LocalMultiSearchableSelectField';
import { useAsync } from 'react-async';
import {
  getDefaultExcludedServices,
  getDefaultIncludedServices,
} from '../../../../api/node/organizationDefaults';
import SearchablePlaceField from '../../../../components/Fields/SearchablePlaceField';

export function ExperienceConfig() {
  const { t } = useTranslation('experiences');
  const { currentOrganizationId } = useApp();

  const [{ value }, ,] = useField('inCountry');
  const [{ value: experienceType }, ,] = useField('type');
  const [, , { setValue }] = useField('inCity');
  const [
    { value: includedServicesValue },
    ,
    { setValue: setIncludedServices },
  ] = useField('includedServices');
  const [
    { value: excludedServicesValue },
    ,
    { setValue: setExcludedServices },
  ] = useField('excludedServices');
  const [{ value: guidingValue }, , { setValue: setGuidingValue }] =
    useField('guiding');
  const [, , { setValue: setGuidingLanguagesValue }] =
    useField('guidingLanguages');
  const [isExtra] = useField('activity.isExtra');
  const reset = () => {
    setValue(null);
  };

  const { data: includedServicesData } = useAsync({
    promiseFn: getDefaultIncludedServices,
    organizationId: currentOrganizationId,
    locale: 'en',
  });
  const includedServices = includedServicesData?.data;

  const { data: excludedServicesData } = useAsync({
    promiseFn: getDefaultExcludedServices,
    organizationId: currentOrganizationId,
    locale: 'en',
  });
  const excludedServices = excludedServicesData?.data;

  useEffect(() => {
    if (includedServices || excludedServices) {
      const includedServicesContent =
        includedServices?.find(
          s => s.experienceType === experienceType?.toLowerCase(),
        )?.content ?? '';

      const excludedServicesContent =
        excludedServices?.find(
          s => s.experienceType === experienceType?.toLowerCase(),
        )?.content ?? '';

      if (includedServicesContent) {
        setIncludedServices(includedServicesValue ?? includedServicesContent);
      }

      if (excludedServicesContent) {
        setExcludedServices(excludedServicesValue ?? excludedServicesContent);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [includedServices, excludedServices]);

  return (
    <>
      <Container size="full">
        <Stack direction="column" gutterSize={1.5} size="full">
          <Stack
            gutterSize={3}
            size="full"
            direction={{ default: 'column', tablet: 'row' }}
          >
            <TextField label={t('form.koobReference')} name="id" disabled />
            <TextField label={t('form.dmcReference')} name="dmcReference" />
          </Stack>
          <Stack
            gutterSize={1.5}
            size="full"
            direction={{ default: 'column', tablet: 'row' }}
          >
            <TextField label={t('form.name')} name="name" required />
          </Stack>

          <EditorField
            name="description"
            label={t('form.description')}
            required
          />

          {(isExtra?.value === false || isExtra?.value === undefined) && (
            <>
              <Stack gutterSize={1} size="full">
                <SearchableSelectField
                  name="inCountry"
                  label={t('form.country')}
                  query={GET_COUNTRIES}
                  pathToEdges={['countries', 'edges']}
                  renderOption={({ title }) => title}
                  renderSelectedOption={({ title }) => title}
                  onAfterChange={() => reset()}
                  required
                  decodeId
                  variables={{ organizationId: currentOrganizationId }}
                />

                <SearchableSelectField
                  name="inCity"
                  label={t('form.city')}
                  query={GET_CITIES}
                  pathToEdges={['cities', 'edges']}
                  renderOption={({ title }) => title}
                  renderSelectedOption={({ title }) => title}
                  required
                  disabled={!value}
                  variables={{
                    organizationId: currentOrganizationId,
                    countryId: value?.idRails ?? value?.id,
                  }}
                />

                <SearchableSelectField
                  name="inCity"
                  label={t('form.region')}
                  query={GET_CITIES}
                  pathToEdges={['cities', 'edges']}
                  renderOption={({ region }) => region?.title ?? '-'}
                  renderSelectedOption={({ region }) => region?.title ?? '-'}
                  required
                  disabled
                  variables={{
                    organizationId: currentOrganizationId,
                    countryId: value?.idRails ?? value?.id,
                  }}
                />
              </Stack>

              <>
                <div className="w-full grid grid-cols-2 space-x-5">
                  <SearchablePlaceField
                    label={t('form.startingPoint')}
                    name="startPosition"
                    required
                    block
                  />

                  <SearchablePlaceField
                    label={t('form.endingPoint')}
                    name="endPosition"
                    required
                    block
                  />
                </div>

                <Stack
                  gutterSize={1.5}
                  size="full"
                  direction={{ default: 'column', tablet: 'row' }}
                >
                  <EditorField
                    name={'locationsExtraInformations'}
                    label={t('form.locationsExtraInformations')}
                  />
                </Stack>
              </>
            </>
          )}

          <Stack
            gutterSize={2.5}
            size="full"
            style={{
              flexWrap: 'wrap',
              alignItems: 'stretch',
            }}
          >
            <Stack
              gutterSize={1}
              style={{
                flexWrap: 'wrap',
                alignItems: 'stretch',
              }}
            >
              <TextFieldButton
                type="number"
                step="1"
                label={t('form.durationDays')}
                name="durationDays"
                content={'D'}
                min="0"
                required
              />
              <TextFieldButton
                type="number"
                step="1"
                label={t('form.durationHours')}
                name="durationHours"
                content={'H'}
                min="0"
                max="23"
                required
              />
              <TextFieldButton
                type="number"
                step="15"
                label={t('form.durationMinutes')}
                name="durationMinutes"
                content={'min'}
                min="0"
                max="59"
                required
              />
            </Stack>

            {(isExtra?.value === false || isExtra?.value === undefined) && (
              <TextFieldButton
                type="number"
                label={t('form.distance')}
                name="distance"
                content={'km'}
              />
            )}

            <Stack direction="column" gutterSize={0.5}>
              <SegmentedControlField
                required
                name="guiding"
                options={[
                  { label: t('form.no'), value: false },
                  { label: t('form.yes'), value: true },
                ]}
                label={t('form.guiding')}
                onSelect={value => {
                  setGuidingValue(value);
                  if (!value) {
                    setGuidingLanguagesValue([], false);
                  }
                }}
              />
            </Stack>
          </Stack>

          {guidingValue && (
            <LocalMultiSearchableSelectField
              label={t('form.language')}
              name="guidingLanguages"
              options={getLanguages()}
              renderOption={option => option.label}
              renderSelectedOption={option => option.label}
              required
            />
          )}

          {(isExtra?.value === false || isExtra?.value === undefined) && (
            <Stack
              gutterSize={1.5}
              size="full"
              direction={{ default: 'column', tablet: 'row' }}
            >
              <EditorField
                name={'includedServices'}
                label={t('form.includedServices')}
              />
              <EditorField
                name={'excludedServices'}
                label={t('form.excludedServices')}
              />
            </Stack>
          )}

          <Stack gutterSize={1.5} size="full">
            <Stack
              direction="column"
              gutterSize={1}
              size="full"
              alignX="stretch"
              marginTop={1}
            >
              {(isExtra?.value === false || isExtra?.value === undefined) && (
                <SelectField
                  label={t('form.sustainableLevel')}
                  name="sustainableLevel"
                  valueField={'value'}
                  options={[
                    { label: t('form.zero'), value: 1 },
                    { label: t('form.low'), value: 2 },
                    { label: t('form.medium'), value: 3 },
                    { label: t('form.high'), value: 4 },
                  ]}
                />
              )}
              <MultiSearchableSelectField
                label={t('form.accreditations')}
                name="accreditations"
                query={GET_ACCREDITATIONS}
                pathToEdges={['accreditations', 'edges']}
                renderOption={({ displayName }) => displayName}
                renderSelectedOption={({ displayName }) => displayName}
              />
            </Stack>
          </Stack>

          {(isExtra?.value === false || isExtra?.value === undefined) && (
            <Stack
              size="full"
              gutterSize={1.5}
              direction={{ default: 'column', tablet: 'row' }}
            >
              <Stack direction="column" size="full">
                <EditorField
                  name={'extraRemarks'}
                  label={t('form.extraRemarks')}
                />
              </Stack>
            </Stack>
          )}
        </Stack>
      </Container>
    </>
  );
}

export const getSchema = () => EXPERIENCE_CONFIG_SCHEMA;
