import KoobTabs from '../../../../components/Koob/KoobTabs';
import KoobCard from '../../../../components/Koob/KoobCard';
import { useTranslation } from 'react-i18next';

export default function BulkEditTypeSelector({ type, setType, children }) {
  const { t } = useTranslation('toConnectionRequest');

  const tabs = [
    {
      label: t('bulkEdit.hotels'),
      id: 'hotels',
    },
    {
      label: t('bulkEdit.experiences'),
      id: 'experiences',
    },
    {
      label: t('bulkEdit.templates'),
      id: 'templates',
    },
  ];

  return (
    <KoobCard padding="" className="overflow-hidden">
      <KoobTabs tabs={tabs} currentTab={type} setCurrentTab={setType} />

      <div className="p-5">{children}</div>
    </KoobCard>
  );
}
