import { Navigate, Route, Routes } from 'react-router-dom';
import Themes from './Themes';
import Segments from './Segments';
import SettingRequests from './SettingRequests';

const ExperienceSettingsRoutes = () => {
  return (
    <Routes>
      <Route path="segments/*" element={<Segments />} />
      <Route path="themes/*" element={<Themes />} />
      <Route path="setting-request/*" element={<SettingRequests />} />
      <Navigate to="segments" />
    </Routes>
  );
};

export default ExperienceSettingsRoutes;
