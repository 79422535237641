import React from 'react';
import { Stack } from '@tymate/margaret';
import {
  EditorField,
  SegmentedControlField,
  TextField,
} from '../../../../../../components/Fields';
import TextFieldButton from '../../../../../../components/Fields/TextFieldButton';
import SelectCurrencyField from '../../../../../../components/Fields/SelectCurrencyField';
import { useTranslation } from 'react-i18next';

export default function SpecificationFormAir() {
  const { t } = useTranslation('experiences');

  return (
    <>
      <TextField
        label={t('form.transfer.air.flightNumber')}
        name="transfer.air.flightNumber"
      />

      <TextField
        label={t('form.transfer.air.airplaneModel')}
        name="transfer.air.airplaneModel"
      />

      <TextField
        label={t('form.transfer.air.airlineCompany')}
        name="transfer.air.airlineCompany"
      />

      <EditorField
        name="transfer.air.onboardServices"
        label={t('form.transfer.air.onboardServices')}
      />

      <TextField
        label={t('form.transfer.airClass')}
        name="transfer.air.seatClass"
      />

      <EditorField
        name="transfer.air.luggagePolicies"
        label={t('form.transfer.luggagePolicies')}
      />

      <Stack direction="column" gutterSize={0.5}>
        <div className="font-medium">
          {t('form.transfer.air.taxesIncluded')}
        </div>

        <SegmentedControlField
          name="transfer.air.taxesIncluded"
          options={[
            { label: t('form.no'), value: false },
            { label: t('form.yes'), value: true },
          ]}
        />
      </Stack>

      <div className="flex">
        <TextFieldButton
          type="number"
          step="1"
          label={t('form.transfer.air.taxes')}
          name="transfer.air.taxes"
          content={''}
          min="0"
        />

        <SelectCurrencyField
          label={t('form.currency')}
          valueField="value"
          name="transfer.air.taxesCurrency"
        />
      </div>
    </>
  );
}
