import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { usePrompt } from 'react-router-dom';

const FormNavigationBlocker = () => {
  const { dirty, isSubmitting } = useFormikContext();
  const { t } = useTranslation();

  usePrompt(t('misc:exitWithoutSave'), dirty && !isSubmitting);

  return null;
};

export default FormNavigationBlocker;
