import React from 'react';
import { useState } from 'react';
import ExperienceForm from './Partials/ExperienceForm';
import ExperienceLayout from './Partials/ExperienceLayout';
import { post } from '../../../api/node';
import { useTranslation } from 'react-i18next';
import { useSnack } from '../../../hooks';
import { Spinner as Loader } from '@tymate/margaret';
import { initialLocale } from '../../../utils';

export default function ExperienceCreate() {
  const { t } = useTranslation('experiences');
  const { notify, notifyError } = useSnack();
  const [saveIsLoading, setSaveIsLoading] = useState(false);

  const handleSave = async experience => {
    try {
      setSaveIsLoading(true);
      const exp = await post(`/experiences`, experience);
      notify(t('createSuccess'));
      setSaveIsLoading(false);
      return exp?.data.id;
    } catch (err) {
      setSaveIsLoading(false);
      if (err?.response?.data?.message) {
        notify(err?.response?.data?.message, { type: 'error' });
      } else {
        notifyError(err);
      }
    }
  };

  return (
    <ExperienceLayout>
      <div className="relative">
        {saveIsLoading && (
          <div className="bg-gray-100/75 backdrop-blur-sm rounded-lg border z-20 absolute h-full w-full flex justify-center items-center">
            <Loader />
          </div>
        )}
        <ExperienceForm handleSave={handleSave} currentLocale={initialLocale} />
      </div>
    </ExperienceLayout>
  );
}
