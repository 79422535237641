import { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { get, debounce, pickBy, includes } from 'lodash';
import { Dropdown, /*Spinner,*/ Stack, ButtonReset } from '@tymate/margaret';
import {
  PopoverContainer,
  PopoverMenu as RawPopoverMenu,
  PopoverItem,
  PopoverItemButton,
  Box,
} from '@tymate/margaret';
import { IoCloseCircleOutline } from 'react-icons/io5';
import { IcArrowDown } from 'components/icons';
import { useQuery } from '@apollo/client';
import Scrollbars from 'react-custom-scrollbars';
import { useDebounce, useDeepCompareEffect } from 'react-use';
import Search from 'components/Search';

const PopoverMenuInner = styled(Scrollbars)``;

const PopoverMenu = styled(RawPopoverMenu)`
  max-height: none;
  overflow-y: initial;
`;

const PopoverFooter = styled(Stack)``;

const Icon = styled(ButtonReset)`
  display: flex;

  ${({ disabled, theme }) =>
    disabled &&
    `
      color: ${theme.disabled};
    `}
`;

const TagWrapper = styled(Stack).attrs({ alignY: 'center', gutterSize: 0.25 })`
  border: 1px solid ${({ theme }) => theme.textLighter};
  border-radius: 999em;
  padding: ${({ theme }) => theme.spacing(0.125)}
    ${({ theme }) => theme.spacing(0.75)};
  color: ${({ theme }) => theme.textLight};
  font-size: 14px;

  ${({ disabled, theme }) =>
    disabled &&
    `
      color: ${theme.disabled};
      border-color: ${theme.disabled};
    `}
`;

const Trigger = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  max-width: 100%;
  min-width: 176px;
  min-height: 36px;
  box-shadow: 0 1px 2px 0 ${({ theme }) => theme.shadow};
  padding: ${({ theme }) => theme.spacing(0.25)}
    ${({ theme }) => theme.spacing(0.5)};
  border: 1px solid ${({ theme }) => theme.separator};
  flex: 1;
  border-radius: 4px;
  transition: border-color 100ms ease;
  z-index: 1;

  &:disabled {
    background-color: ${({ theme }) => theme.disabled};
    color: #6d6d6d;
  }

  ${({ disabled }) =>
    Boolean(disabled) &&
    css`
      background-color: ${({ theme }) => theme.disabled};
      color: #6d6d6d;
    `}

  ${({ hasError }) =>
    hasError &&
    css`
      &,
      &:hover,
      &:active {
        border-color: ${({ theme }) => theme.danger};
      }
    `}
`;

const Value = styled.div`
  ${({ isEmpty, theme }) => css`
    width: 100%;
    color: ${({ theme }) => theme.text};
    font-weight: 400;
    font-style: normal;

    &,
    + svg {
      color: ${isEmpty ? theme.textLighten : theme.text};
    }
  `};
`;

const SelectedValues = styled(Stack).attrs({ alignY: 'center' })`
  margin: ${({ theme }) => theme.spacing(-0.25)};
  flex-wrap: wrap;

  > * {
    margin: ${({ theme }) => theme.spacing(0.25)};
    width: auto;
  }
`;

const MultiSearchableSelect = ({
  onChange,
  disabled,
  variant,
  wrapperStyle,
  query,
  isSearch,
  pathToEdges,
  popoverFooter,
  variables,
  renderOption,
  renderSelectedOption,
  emptyState,
  hasError,
  excludedIds,
  value,
  selectedValue,
  onSelectOption,
  onUnselectOption,
  includedIconList,
  shouldShowSelectedValues = true,
}) => {
  const dropdownRef = useRef();
  const popoverRef = useRef();
  const searchRef = useRef();
  const [isOpen, setIsOpen] = useState();
  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');

  const pathToPageInfo = pathToEdges.slice(0, -1).concat('pageInfo');

  useDebounce(
    () => {
      setDebouncedSearch(search);
    },
    500,
    [search],
  );

  const { loading, data, fetchMore } = useQuery(query, {
    variables: pickBy({ search: debouncedSearch, ...variables }),
    skip: !isOpen,
    fetchPolicy: 'network-only',
  });

  const options = (
    isSearch && search.length === 0 ? [] : get(data, pathToEdges, [])
  ).filter(
    ({ node }) =>
      !includes(
        excludedIds,
        node?.id === 'string'
          ? node?.id.replace('\n', '')
          : node?.id.toString(),
      ) && !includes(excludedIds, node?.id),
  );

  if (includedIconList?.list && includedIconList?.list?.length > 0) {
    options.sort((a, b) => {
      const aValue = a.node.id;
      const bValue = b.node.id;

      const aBestIndex = includedIconList?.list.findIndex(
        item => item.value === aValue,
      );
      const bBestIndex = includedIconList?.list.findIndex(
        item => item.value === bValue,
      );

      if (aBestIndex !== -1 && bBestIndex === -1) {
        return -1;
      } else if (aBestIndex === -1 && bBestIndex !== -1) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  const handleScroll = debounce(({ top, scrollHeight }) => {
    const pageInfo = get(data, pathToPageInfo, {});

    if (
      (top > 0.85 || scrollHeight < 250) &&
      !loading &&
      pageInfo?.hasNextPage
    ) {
      fetchMore({
        variables: {
          after: pageInfo?.endCursor,
        },
      });
    }
  }, 500);

  useDeepCompareEffect(() => {
    if (!Boolean(selectedValue) || !onChange) {
      return;
    }

    onChange(selectedValue);
    if (dropdownRef.current) {
      dropdownRef.current.close();
    }
  }, [{ value }]);

  useEffect(() => {
    setSearch('');

    if (!isOpen || !Boolean(searchRef.current)) {
      return;
    }

    searchRef.current.focus();
  }, [isOpen]);

  return (
    <Dropdown
      disabled={disabled}
      ref={dropdownRef}
      wrapperStyle={isOpen ? wrapperStyle : undefined}
      onToggle={setIsOpen}
      trigger={
        <Trigger disabled={disabled} hasError={hasError} isOpen={isOpen}>
          <SelectedValues>
            {shouldShowSelectedValues &&
              (value ?? []).map((item, index) => (
                <Value key={index} variant={variant}>
                  <TagWrapper>
                    <span>{renderSelectedOption(item)}</span>
                    {!disabled && (
                      <Icon
                        type="button"
                        onClick={() => {
                          onUnselectOption(item);
                        }}
                      >
                        <IoCloseCircleOutline size={18} />
                      </Icon>
                    )}
                  </TagWrapper>
                </Value>
              ))}
          </SelectedValues>
          <Stack>
            <IcArrowDown size={24} />
          </Stack>
        </Trigger>
      }
    >
      <PopoverContainer variant={variant}>
        <PopoverMenu>
          <PopoverMenuInner
            ref={popoverRef}
            autoHeight
            autoHeightMax={250}
            onUpdate={handleScroll}
          >
            <PopoverItem>
              <Box
                paddingTop={1}
                paddingHorizontal={1}
                paddingBottom={options.length === 0 ? 1 : 0.5}
              >
                <Search
                  ref={searchRef}
                  value={search}
                  onChange={setSearch}
                  isLoading={loading}
                />
              </Box>
              {/* <SearchInput
                value={search}
                onChange={e => setSearch(e.target.value)}
              /> */}
              {/* {isSearch && <MdSearch size={24} />} */}
            </PopoverItem>
            {options.map(({ node }, index) => (
              <PopoverItem key={index}>
                <PopoverItemButton
                  type="button"
                  onClick={() => onSelectOption(node)}
                  isActive={includes(value, node)}
                >
                  <Stack gutterSize={0.5} alignY="center" size="full">
                    <Stack
                      size="full"
                      alignY="center"
                      gutterSize={0.5}
                      paddingBottom={0.5}
                    >
                      {renderOption(node)}
                    </Stack>
                    <Stack size="full" alignX="flex-end" alignY="center">
                      {includedIconList?.list?.length > 0 &&
                        includedIconList?.list?.map(({ value }) => (
                          <>
                            {value === node.id ? (
                              <>{includedIconList?.includedIcon}</>
                            ) : (
                              <>{includedIconList?.excludedIcon}</>
                            )}
                          </>
                        ))}
                    </Stack>
                  </Stack>
                </PopoverItemButton>
              </PopoverItem>
            ))}
          </PopoverMenuInner>

          {(Boolean(popoverFooter) ||
            (Boolean(emptyState) && !loading && options.length === 0)) && (
            <PopoverFooter>{popoverFooter}</PopoverFooter>
          )}
        </PopoverMenu>
      </PopoverContainer>
    </Dropdown>
  );
};

MultiSearchableSelect.defaultProps = {
  placeholder: '',
  excludedIds: [],
  renderOption: () => null,
  renderSelectedValue: () => null,
};

export default MultiSearchableSelect;
