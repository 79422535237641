import { memo } from 'react';

const IcCancel = ({ color, size, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 36 36"
    {...props}
    style={{ ...props.style, color, fontSize: size }}
  >
    <g transform="translate(-540 -24)" fill="none" fillRule="evenodd">
      <path d="M540 24h36v36h-36z" />
      <g
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.875}
        transform="translate(543 27)"
      >
        <path d="M18.975 11.025l-7.95 7.95m0-7.95l7.95 7.95" />
        <circle cx={15} cy={15} r={14.063} />
      </g>
    </g>
  </svg>
);

export default memo(IcCancel);
