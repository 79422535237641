import { memo } from 'react';

const IcOrganization = props => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    style={{ ...props.style, color: props.color, fontSize: props.size }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.25 15.624a1.25 1.25 0 100 2.5 1.25 1.25 0 000-2.5zm-2.5 1.25a2.5 2.5 0 115 0 2.5 2.5 0 01-5 0z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.653 3.48L5.75 7.418V12.5a.625.625 0 11-1.25 0V8.25L2.972 9.27a.625.625 0 01-.694-1.04l8.683-5.79a1.877 1.877 0 012.078 0l8.683 5.79a.625.625 0 11-.694 1.04L19.5 8.25v4.25a.625.625 0 11-1.25 0V7.417l-5.903-3.936a.628.628 0 00-.694 0zM11.375 15c0-.345.28-.625.625-.625h5.208a2.292 2.292 0 012.292 2.292v4.583a.625.625 0 11-1.25 0v-1.875H5.75v1.874a.625.625 0 11-1.25 0v-2.5a.625.625 0 01.625-.625h6.25V15zm1.25 3.125h5.625v-1.458c0-.576-.467-1.042-1.042-1.042h-4.583v2.5z"
      fill="currentColor"
    />
  </svg>
);

export default memo(IcOrganization);
