import React from 'react';
import { Field } from 'formik';

export default function ChatboxInputField() {
  return (
    <Field
      type="text"
      name="message"
      className="bg-gray-100 px-2 rounded h-full w-full border-none outline-none"
      as="textarea"
      rows="4"
    />
  );
}
