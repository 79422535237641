import { cva } from 'class-variance-authority';
import { Field } from 'formik';

function KoobInputSelectOption({ option }) {
  return <option value={option.value ?? ''}>{option.label}</option>;
}

export default function KoobInputSelect({
  label,
  name,
  placeholder,
  options = [],
  value,
  onChange,
  disabled,
}) {
  const isFormik = name && !onChange;

  const select = cva(
    [
      'form-select',
      'block w-full rounded border border-gray-300 hover:border-gray-400 py-1.5 pl-3 pr-10 text-gray-900 focus:ring-0 ring-offset-0',
      'focus:border-gray-400 transition',
      'sm:text-sm',
    ],
    {
      variants: {
        disabled: {
          true: 'opacity-50 pointer-events-none cursor-not-allowed',
        },
      },
    },
  );

  return (
    <div>
      {label && (
        <label className="mb-1.5 block text-sm font-medium leading-none text-gray-900">
          {label}
        </label>
      )}

      <div>
        {isFormik ? (
          <Field
            as="select"
            name={name}
            className={select({ disabled })}
            placeholder={placeholder}
            style={{
              backgroundImage:
                'url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 fill=%27none%27 viewBox=%270 0 20' +
                ' 20%27%3e%3cpath stroke=%27%236b7280%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%271.5%27 d=%27M6 8l4 4 4-4%27/%3e%3c/svg%3e")',
            }}
            disabled={disabled}
          >
            {options.map(option => (
              <KoobInputSelectOption key={option.value} option={option} />
            ))}
          </Field>
        ) : (
          <select
            name={name}
            value={value}
            onChange={e => onChange(e.target.value)}
            className={select({ disabled })}
            placeholder={placeholder}
            style={{
              backgroundImage:
                'url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 fill=%27none%27 viewBox=%270 0 20' +
                ' 20%27%3e%3cpath stroke=%27%236b7280%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%271.5%27 d=%27M6 8l4 4 4-4%27/%3e%3c/svg%3e")',
            }}
            disabled={disabled}
          >
            {options.map(option => (
              <KoobInputSelectOption key={option.value} option={option} />
            ))}
          </select>
        )}
      </div>
    </div>
  );
}
