import { useState } from 'react';
import BulkEditRequestsSelector from './Partials/BulkEditRequestsSelector';
import BulkEditTypeSelector from './Partials/BulkEditTypeSelector';
import { Form, Formik } from 'formik';
import BulkEditorHotels from './Partials/BulkEditorHotels';
import BulkEditorExperiences from './Partials/BulkEditorExperiences';
import BulkEditorTemplates from './Partials/BulkEditorTemplates';
import BulkEditorBottomBar from './Partials/BulkEditorBottomBar';
import { patch } from '../../../api/node';
import { useSnack } from '../../../hooks';
import { useTranslation } from 'react-i18next';
import { parseBoolean } from 'utils';

export default function ToConnectionRequestBulkEdit() {
  const { t } = useTranslation();
  const { notify } = useSnack();

  const [type, setType] = useState('hotels');

  const editorComponents = {
    hotels: <BulkEditorHotels />,
    experiences: <BulkEditorExperiences />,
    templates: <BulkEditorTemplates />,
  };

  const parseMargin = value => {
    if (value === null || value === undefined) {
      return null;
    }

    return parseFloat(value);
  };

  const submitForm = async values => {
    await patch('/ToConnectionRequest/bulkEdit', {
      toConnectionRequestIds: values.toConnectionRequestIds,
      request: {
        state: values.request.state ?? undefined,
        margin: parseMargin(values.request.margin),
        isMarginPercent: parseBoolean(values.request.isMarginPercent),
        toConnectionRequestHotels:
          values.request.toConnectionRequestHotels?.map(hotel => ({
            id: `${hotel.id}`,
            margin: parseMargin(hotel.margin),
            isMarginPercent: parseBoolean(hotel.isMarginPercent),
            distribute: parseBoolean(hotel.distribute),
            contracts: hotel.contracts?.map(contract => ({
              id: `${contract.id}`,
              enabled: parseBoolean(contract.enabled),
            })),
            periods: hotel.rooms
              ?.map(room => {
                if (parseBoolean(room.enabled) === undefined) {
                  return null;
                }

                return room.periods?.map(period => ({
                  id: `${period.id}`,
                  isPeriodAllowedForBooking: parseBoolean(room.enabled),
                  isMarginPercent: parseBoolean(period.isMarginPercent),
                  margin: parseMargin(period.margin),
                }));
              })
              .flat()
              .filter(Boolean),
          })),
        toConnectionRequestExperiences:
          values.request.toConnectionRequestExperiences?.map(experience => ({
            id: `${experience.id}`,
            margin: parseMargin(experience.margin),
            isMarginPercent: parseBoolean(experience.isMarginPercent),
            distribute: parseBoolean(experience.distribute),
          })),
        toConnectionRequestTemplates:
          values.request.toConnectionRequestTemplates?.map(template => ({
            id: `${template.id}`,
            margin: parseMargin(template.margin),
            isMarginPercent: parseBoolean(template.isMarginPercent),
            distribute: parseBoolean(template.distribute),
          })),
      },
    })
      .then(() => {
        notify(t('misc:saveSuccessful'));
      })
      .catch(() => {
        notify(t('misc:saveError'), { type: 'error' });
      });
  };

  return (
    <div className="max-w-7xl mx-auto min-h-[150vh] px-4 py-8">
      <>
        <Formik
          initialValues={{
            toConnectionRequestIds: [],
            request: {
              state: null,
              margin: null,
              isMarginPercent: null,
              toConnectionRequestHotels: [],
              toConnectionRequestExperiences: [],
              toConnectionRequestTemplates: [],
            },
          }}
          onSubmit={submitForm}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="flex-col space-y-5">
                <BulkEditRequestsSelector />

                {/* <BulkEditGlobalMargin /> */}

                <BulkEditTypeSelector type={type} setType={setType}>
                  {editorComponents[type]}
                </BulkEditTypeSelector>

                <BulkEditorBottomBar loading={isSubmitting} />
              </div>
            </Form>
          )}
        </Formik>
      </>
    </div>
  );
}
