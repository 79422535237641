import React from 'react';
import KoobContainer from '../../../../components/Koob/KoobContainer';

export default function ExperienceLayout({ children }) {
  return (
    <KoobContainer>
      <div className="max-w-5xl mx-auto">{children}</div>
    </KoobContainer>
  );
}
