import { useEffect } from 'react';
import { FormField, FormLabel } from 'ui/forms';
import styled from 'styled-components';
import {
  PopoverContainer,
  PopoverItem,
  PopoverItemButton,
  PopoverMenu,
  Stack,
} from '@tymate/margaret';
import { Button, Modal } from 'components';
import { useState } from 'react';
import { IcMessages, IcArrowDown } from 'components/icons';
import { Text } from 'ui';
import Editor from './Editor';
import { IoTrashOutline } from 'react-icons/io5';

const WrapperList = styled(PopoverContainer)`
  position: static;
`;

const WrapperElement = styled(PopoverMenu)`
  position: static;
  border: solid 1px ${({ theme }) => theme.separator};
  box-shadow: 0 1px 2px 0 ${({ theme }) => theme.shadow};
  border-radius: 8px;
`;

const Separator = styled.div`
  width: 98%;
  height: 1px;
  margin-left: auto;
  margin-right: auto;
  background-color: ${({ theme }) => theme.separatorLight};
`;

const ModalFacility = ({ isOpen, setOpen, facilityInfo, setValues }) => {
  const [isEdit, setEdit] = useState(false);
  const [details, setDetails] = useState(facilityInfo?.details);

  useEffect(() => {
    setDetails(facilityInfo?.details);
  }, [isOpen, facilityInfo]);

  return (
    <Modal
      isOpen={isOpen}
      title={facilityInfo?.label}
      onRequestClose={() => setOpen(!isOpen)}
      footer={
        <Stack alignX="flex-end" gutterSize={1}>
          <Button
            onClick={() => {
              if (isEdit) {
                setValues({
                  type: 'modifyFacility',
                  groupIndex: facilityInfo?.groupIndex,
                  facilityIndex: facilityInfo?.facilityIndex,
                  key: 'details',
                  value: details,
                });
                setOpen(false);
              }
              setEdit(!isEdit);
            }}
            variant="primary"
          >
            {isEdit ? 'Save' : 'Edit'}
          </Button>
          <Button onClick={() => setOpen(!isOpen)} variant="simple">
            Cancel
          </Button>
        </Stack>
      }
    >
      {isEdit ? (
        <Editor
          value={details}
          onChange={newDetails => setDetails(newDetails)}
        />
      ) : (
        /* TODO find a safer solution,  */
        <div dangerouslySetInnerHTML={{ __html: details }} />
      )}
    </Modal>
  );
};

const Facility = ({
  facilityIndex,
  groupIndex,
  label,
  details,
  onEdit,
  onDelete,
}) => {
  return (
    <PopoverItem>
      <PopoverItemButton type="button">
        <Stack size="full" alignX="space-between" alignY="center">
          <Text
            type="body"
            style={{ marginLeft: '48px' }}
            onClick={() => {
              onEdit({ facilityIndex, groupIndex, label, details });
            }}
          >
            {label}
          </Text>
          <Stack alignX="space-between" alignY="center" gutterSize={1}>
            <IcMessages
              type="button"
              onClick={() => {
                onEdit({ facilityIndex, groupIndex, label, details });
              }}
            />
            <IoTrashOutline
              type="button"
              style={{ marginTop: '-2px' }}
              onClick={() => {
                onDelete({
                  type: 'modifyFacility',
                  groupIndex,
                  facilityIndex,
                  key: 'checked',
                  value: false,
                });
              }}
            />
          </Stack>
        </Stack>
      </PopoverItemButton>
    </PopoverItem>
  );
};

const Group = ({
  groupIndex,
  groupLabel,
  facilities,
  handleEdit,
  handleDelete,
  initChecked = false,
}) => {
  const [checked, setChecked] = useState(initChecked);

  return (
    <>
      <PopoverItem>
        <PopoverItemButton
          type="button"
          onClick={() => {
            setChecked(!checked);
          }}
        >
          <Stack size="full" alignY="center">
            <Stack>
              {checked ? (
                <IcArrowDown style={{ marginLeft: 0 }} />
              ) : (
                <IcArrowDown
                  style={{ marginLeft: 0, transform: 'rotate(-90deg)' }}
                />
              )}
            </Stack>
            <Text type="body">{groupLabel}</Text>
          </Stack>
        </PopoverItemButton>
        {checked && <Separator />}
      </PopoverItem>
      {checked &&
        facilities.map(
          ({ label, details, checked }, index) =>
            checked && (
              <Facility
                key={index}
                facilityIndex={index}
                groupIndex={groupIndex}
                label={label}
                details={details}
                onEdit={handleEdit}
                onDelete={handleDelete}
              />
            ),
        )}
    </>
  );
};

const ListRoomFacilities = ({ label, values, setValues }) => {
  const [isOpen, setOpen] = useState(false);

  const [facilityInfo, setFacilityInfo] = useState();

  const handleEdit = newFacilityInfo => {
    setFacilityInfo(newFacilityInfo);
    setOpen(!isOpen);
  };

  const handleDelete = newFacilityInfo => {
    setValues(newFacilityInfo);
  };

  return (
    <FormField>
      {Boolean(label) && <FormLabel>{label}</FormLabel>}

      {values.find(value =>
        value.facilities.find(facility => facility.checked),
      ) && (
        <Stack direction="column" gutterSize={1} size="full" alignX="stretch">
          <WrapperList>
            <WrapperElement>
              {values.map(
                ({ groupLabel, facilities }, index) =>
                  facilities.find(facility => facility.checked) && (
                    <Group
                      key={index}
                      maxIndex={values.length - 1}
                      groupIndex={index}
                      groupLabel={groupLabel}
                      facilities={facilities}
                      handleEdit={handleEdit}
                      handleDelete={handleDelete}
                    />
                  ),
              )}
            </WrapperElement>
          </WrapperList>
        </Stack>
      )}
      <ModalFacility
        isOpen={isOpen}
        setOpen={setOpen}
        facilityInfo={facilityInfo}
        setValues={setValues}
      />
    </FormField>
  );
};

export default ListRoomFacilities;
