import { useField } from 'formik';
import { Stack } from '@tymate/margaret';
import StarsPicker from './StarsPicker';
import { FormField, FormLabel, ErrorMessageWrapper } from 'ui/forms';

const StarsPickerField = ({ label, labelSuffix, ...props }) => {
  const [{ value }, { touched, error }, { setValue, setTouched }] = useField(
    props,
  );
  const hasError = Boolean(touched) && Boolean(error);

  return (
    <FormField>
      <Stack
        alignX="space-between"
        alignY="center"
        marginBottom={0.5}
        size="full"
      >
        {label && <FormLabel variant="bare">{label}</FormLabel>}
        {labelSuffix}
      </Stack>

      <Stack direction="column">
        <StarsPicker
          value={value}
          onChange={value => {
            setTouched(true);
            setValue(value);
          }}
          {...props}
        />
        {hasError && <ErrorMessageWrapper>{error}</ErrorMessageWrapper>}
      </Stack>
    </FormField>
  );
};

export default StarsPickerField;
