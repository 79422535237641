import React from 'react';
import { Text } from '../../../../ui';
import { DataTable } from '../../../../components';
import { useTranslation } from 'react-i18next';

export default function DistributionSelected({
  selectedDatasHeadings,
  selectedDatas,
  tabTitle,
}) {
  const { t } = useTranslation('toConnectionRequest');

  return (
    <div>
      <Text type="h3">{t(tabTitle)}</Text>

      <DataTable
        headings={selectedDatasHeadings}
        data={selectedDatas}
        isSearchable={false}
      />
    </div>
  );
}
