import { Container } from 'ui';
import { Stack } from '@tymate/margaret';
import { EXPERIENCE_OPTIONS_SCHEMA } from '../Utilities/ExperienceValidationSchemas';
import { useTranslation } from 'react-i18next';
import { useField } from 'formik';
import LocalMultiSearchableSelectField from '../../../../components/Fields/LocalMultiSearchableSelectField';
import React, { useEffect } from 'react';
import { useAsync } from 'react-async';
import { post } from '../../../../api/node';
import { DataTable } from '../../../../components';
import { MdClose } from 'react-icons/md';

const getExperiencesList = async ({ startAt, endAt, except }) => {
  return await post('/experiences/liste', {
    state: 'available',
    startAt,
    endAt,
    except,
  });
};

function FilteredOptions(optionList) {
  const [{ value: programs }, ,] = useField(`programs`);
  const combinedIncludedAndSuggestedIds = programs?.flatMap(program => {
    const suggestedIds =
      program.suggestedExperiences?.map(option => option.id) || [];
    const includedIds =
      program.includedExperiences?.map(option => option.id) || [];
    return [...suggestedIds, ...includedIds];
  });

  const filteredOptions = optionList?.filter(
    option => !combinedIncludedAndSuggestedIds?.includes(option.id),
  );

  return filteredOptions;
}

function ExperienceOptionsPerPeriod({ periodIndex, currentLocale }) {
  const { t } = useTranslation('experiences');

  const [{ value: experienceId }] = useField(`id`);
  const [{ value: extras }, , { setValue: setExtras }] = useField(`extras`);
  const [{ value: period }, , { setValue: setPeriod }] = useField(
    `periods[${periodIndex}]`,
  );

  const { data, reload } = useAsync({
    promiseFn: getExperiencesList,
    startAt: period.startAt,
    endAt: period.endAt,
    except: experienceId,
    locale: currentLocale.value,
  });

  useEffect(() => {
    if (data) {
      reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLocale?.value]);

  const filteredOptions = FilteredOptions(data?.data);

  const options = (filteredOptions ?? []).map(experience => {
    return {
      label: experience.name,
      value: experience.id,
    };
  });

  return (
    <Stack
      direction="column"
      gutterSize={1.5}
      size="full"
      className="p-4 border rounded-lg"
    >
      <span style={{ flex: '0 0 auto', marginBottom: '5px' }}>
        {t('steps.options.period', {
          startAt: period.startAt,
          endAt: period.endAt,
        })}
      </span>
      <LocalMultiSearchableSelectField
        label={''}
        name={`periods[${periodIndex}].extras`}
        options={options}
        renderOption={option => option.label}
        renderSelectedOption={option => option.label}
        onSelectOption={option => {
          if (extras.findIndex(extra => extra.id === option.value) === -1) {
            setExtras(
              [
                ...extras,
                {
                  id: option.value,
                  name: option.label,
                },
              ],
              true,
            );
          }
          setPeriod({
            ...period,
            extras: [],
          });
        }}
      />
    </Stack>
  );
}

function ExtrasList() {
  const { t } = useTranslation('experiences');
  const [{ value }, , { setValue }] = useField(`extras`);

  const filteredOptions = FilteredOptions(value);

  const headings = [
    {
      slug: 'name',
      label: t('steps.options.optionsName'),
      width: '50%',
      cannotBeReordered: true,
    },
    {
      slug: 'actions',
      cannotBeReordered: true,
      width: '10%',
    },
  ];
  const extras = filteredOptions?.map(extra => ({
    id: { value: extra?.id },
    name: {
      value: extra?.name,
      render: () => (
        <Stack direction="column" style={{ flex: '1' }}>
          <span>{extra.name}</span>
        </Stack>
      ),
    },
    actions: {
      render: () => (
        <MdClose
          size={20}
          className="cursor-pointer"
          onClick={() => setValue(value.filter(elem => elem.id !== extra.id))}
        />
      ),
    },
  }));
  return (
    <Stack direction="column" style={{ flex: '1' }}>
      <div>{t('steps.options.options')}</div>
      <DataTable
        headings={headings}
        data={extras || []}
        minWidth={900}
        isSearchable={false}
      />
    </Stack>
  );
}

export function ExperienceOptions({ currentLocale }) {
  const [{ value: periods }] = useField(`periods`);
  return (
    <Container size="full">
      <Stack direction="column" gutterSize={1.5} size="full">
        {periods.map((period, index) => (
          <ExperienceOptionsPerPeriod
            periodIndex={index}
            currentLocale={currentLocale}
          />
        ))}
        <ExtrasList></ExtrasList>
      </Stack>
    </Container>
  );
}

export const getSchema = () => EXPERIENCE_OPTIONS_SCHEMA;
