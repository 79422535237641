import { useState } from 'react';
import { useDebounce } from 'react-use';
import { find } from 'lodash';
import Select from './Fields/Select';
import { useBooking, useSearchParams } from 'hooks';

const LocationAwareSelect = ({
  placeholder,
  debounceTime = 500,
  name,
  options,
  shouldStoreWholeObject,
  onChangeSearchParams,
  mode,
  ...props
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { filters, setFilters } = useBooking();
  const [value, setValue] = useState(
    mode === 'state' ? filters[name] || [] : searchParams[name] || [],
  );

  useDebounce(
    () => {
      if (mode === 'state') {
        if (value.length !== 0 && filters[name] !== value) {
          setFilters(filters => ({ ...filters, [name]: value }));
        }
      } else {
        setSearchParams({ ...searchParams, [name]: value });
      }
    },
    debounceTime,
    [value],
  );

  return (
    <Select
      value={value}
      placeholder={placeholder}
      onChange={newValue => {
        setValue(shouldStoreWholeObject ? newValue : newValue.value);
      }}
      renderSelectedValue={value =>
        find(options, option => option.value === value)?.label
      }
      options={options}
      {...props}
    />
  );
};

export default LocationAwareSelect;
