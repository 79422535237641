import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  EditorField,
  SegmentedControlField,
  TextField,
} from '../../../../../../components/Fields';
import { Stack } from '@tymate/margaret';
import TextFieldButton from '../../../../../../components/Fields/TextFieldButton';

export default function SpecificationFormRail() {
  const { t } = useTranslation('experiences');

  return (
    <>
      <TextField
        label={t('form.transfer.rail.stationDeparture')}
        name="transfer.rail.departureStation"
        required
      />

      <TextField
        label={t('form.transfer.rail.stationArrival')}
        name="transfer.rail.arrivalStation"
        required
      />

      <TextField
        label={t('form.transfer.rail.trainName')}
        name="transfer.rail.trainName"
      />

      <TextField
        label={t('form.transfer.rail.trainNumber')}
        name="transfer.rail.trainNumber"
      />

      <Stack direction="column" gutterSize={0.5}>
        <div className="font-medium">
          {t('form.transfer.rail.privateCabin')}
        </div>

        <SegmentedControlField
          name="transfer.rail.privateCabin"
          options={[
            { label: t('form.no'), value: false },
            { label: t('form.yes'), value: true },
          ]}
          required
        />
      </Stack>

      <TextFieldButton
        type="number"
        step="1"
        label={t('form.transfer.seats')}
        name="transfer.rail.seats"
        content={''}
        min="0"
      />

      <TextField
        label={t('form.transfer.railClass')}
        name="transfer.rail.class"
      />

      <EditorField
        name="transfer.rail.luggagePolicies"
        label={t('form.transfer.luggagePolicies')}
      />
    </>
  );
}
