import { gql, useQuery } from '@apollo/client';
import { useDeepCompareEffect } from 'react-use';
import { useRoutes } from 'hooks';

const useSetBreadcrumbName = ({
  path,
  query,
  variables,
  formatData,
  fromNode,
}) => {
  const { setRouteName } = useRoutes();

  const { data } = useQuery(
    fromNode
      ? gql`
          query {
            dummyQuery
          }
        `
      : query,
    {
      variables,
    },
  );

  const breadcrumbData = fromNode ? undefined : data;

  useDeepCompareEffect(() => {
    setRouteName({
      path,
      name: formatData(breadcrumbData),
    });
  }, [{ path, breadcrumbData }]);

  return null;
};

export default useSetBreadcrumbName;
