import { useEffect, useState } from 'react';
import { exportExperience } from '../../../../api/node/experiences';
import KoobModal from '../../../../components/Koob/KoobModal';
import { Button } from '../../../../components';

export default function ExperienceExportModal({
  open,
  close,
  experienceId,
  experienceName,
  exportFormat,
  currentLocale,
}) {
  const [processing, setProcessing] = useState(true);

  useEffect(() => {
    if (open) {
      setProcessing(true);
      exportExperience({
        experienceId,
        locale: currentLocale?.value ?? 'en',
        exportFormat,
      })
        .then(response => {
          const blob = new Blob([response.data], {
            type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          });
          const href = window.URL.createObjectURL(blob);

          const anchorElement = document.createElement('a');
          anchorElement.href = href;
          anchorElement.download = `${experienceName}.${exportFormat}`;
          document.body.appendChild(anchorElement);
          anchorElement.click();
          document.body.removeChild(anchorElement);
          window.URL.revokeObjectURL(href);
        })
        .finally(() => setProcessing(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <KoobModal open={open} setOpen={close}>
      <div className="p-6">
        <div className="flex flex-col space-y-6 justify-center">
          <div className="mx-auto">
            {processing ? (
              <>
                {exportFormat === 'docx' ? (
                  <svg
                    className="h-28 w-28 text-gray-300"
                    height="1em"
                    viewBox="0 0 512 512"
                  >
                    <path d="M64 464H96v48H64c-35.3 0-64-28.7-64-64V64C0 28.7 28.7 0 64 0H229.5c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3V288H336V160H256c-17.7 0-32-14.3-32-32V48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16zM176 352h32c26.5 0 48 21.5 48 48v64c0 26.5-21.5 48-48 48H176c-8.8 0-16-7.2-16-16V368c0-8.8 7.2-16 16-16zm16 128h16c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H192v96zM456 352h16c22.1 0 40 17.9 40 40v8c0 8.8-7.2 16-16 16s-16-7.2-16-16v-8c0-4.4-3.6-8-8-8H456c-4.4 0-8 3.6-8 8v80c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8v-8c0-8.8 7.2-16 16-16s16 7.2 16 16v8c0 22.1-17.9 40-40 40H456c-22.1 0-40-17.9-40-40V392c0-22.1 17.9-40 40-40zM288 392c0-22.1 17.9-40 40-40h16c22.1 0 40 17.9 40 40v80c0 22.1-17.9 40-40 40H328c-22.1 0-40-17.9-40-40V392zm40-8c-4.4 0-8 3.6-8 8v80c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8V392c0-4.4-3.6-8-8-8H328z" />
                  </svg>
                ) : (
                  <svg
                    className="h-28 w-28 text-gray-300"
                    height="1em"
                    viewBox="0 0 512 512"
                  >
                    <path d="M64 464H96v48H64c-35.3 0-64-28.7-64-64V64C0 28.7 28.7 0 64 0H229.5c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3V288H336V160H256c-17.7 0-32-14.3-32-32V48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16zM176 352h32c30.9 0 56 25.1 56 56s-25.1 56-56 56H192v32c0 8.8-7.2 16-16 16s-16-7.2-16-16V448 368c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24H192v48h16zm96-80h32c26.5 0 48 21.5 48 48v64c0 26.5-21.5 48-48 48H304c-8.8 0-16-7.2-16-16V368c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H320v96h16zm80-112c0-8.8 7.2-16 16-16h48c8.8 0 16 7.2 16 16s-7.2 16-16 16H448v32h32c8.8 0 16 7.2 16 16s-7.2 16-16 16H448v48c0 8.8-7.2 16-16 16s-16-7.2-16-16V432 368z" />
                  </svg>
                )}
              </>
            ) : (
              <svg
                className="h-28 w-28 text-green-500"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path d="M441 103c9.4 9.4 9.4 24.6 0 33.9L177 401c-9.4 9.4-24.6 9.4-33.9 0L7 265c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l119 119L407 103c9.4-9.4 24.6-9.4 33.9 0z" />
              </svg>
            )}
          </div>

          <div className="text-center text-xl font-medium">
            {processing ? (
              <div className="text-gray-400">
                Exporting &ldquo;{experienceName}&rdquo; ...
              </div>
            ) : (
              <div className="text-green-500">Download started!</div>
            )}
          </div>

          <div className="mx-auto">
            {processing ? (
              <svg
                className="h-8 w-8 animate-spin text-orange-500"
                height="1em"
                viewBox="0 0 512 512"
              >
                <path
                  className="fa-primary"
                  d="M224 32c0-17.7 14.3-32 32-32C397.4 0 512 114.6 512 256c0 46.6-12.5 90.4-34.3 128c-8.8 15.3-28.4 20.5-43.7 11.7s-20.5-28.4-11.7-43.7c16.3-28.2 25.7-61 25.7-96c0-106-86-192-192-192c-17.7 0-32-14.3-32-32z"
                />
                <path
                  className="fa-secondary opacity-40"
                  d="M256 64C150 64 64 150 64 256s86 192 192 192c70.1 0 131.3-37.5 164.9-93.6l.1 .1c-6.9 14.9-1.5 32.8 13 41.2c15.3 8.9 34.9 3.6 43.7-11.7c.2-.3 .4-.6 .5-.9l0 0C434.1 460.1 351.1 512 256 512C114.6 512 0 397.4 0 256S114.6 0 256 0c-17.7 0-32 14.3-32 32s14.3 32 32 32z"
                />
              </svg>
            ) : (
              <Button type="button" variant="simple" onClick={close}>
                Close
              </Button>
            )}
          </div>
        </div>
      </div>
    </KoobModal>
  );
}
