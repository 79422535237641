import { useEffect, useState } from 'react';
import { EXPERIENCE_PROGRAMS_DAYS_SCHEMA } from '../Utilities/ExperienceValidationSchemas';
import DayPills from 'components/DayPills';
import { Card, Container } from 'ui';
import { DayForm } from '../Partials/DayForm';
import { FieldArray, useField } from 'formik';
import { initialLocale } from '../../../../utils';
import { useTranslation } from 'react-i18next';
import KoobTabs from 'components/Koob/KoobTabs';
import { Stack } from '@tymate/margaret';
import { EditorField } from 'components/Fields';

export const ExperienceItinerary = ({ currentLocale }) => {
  const { t } = useTranslation('experiences');
  const [daysData, ,] = useField('programs');
  const [selectedDayIndex, setSelectedDayIndex] = useState(
    daysData?.value?.length ? 0 : -1,
  );

  const [{ value }, , { setValue }] = useField('summary');

  useEffect(() => {
    if (value?.length > 150) {
      const summaryTroncate = value.slice(0, 150);
      setValue(summaryTroncate);
    }
  }, [value]);

  const createDayIndex = () => {
    setSelectedDayIndex(daysData?.value?.length ?? 0);
  };

  const removeDayIndex = index => {
    if (selectedDayIndex === index) {
      setSelectedDayIndex(daysData?.value?.length - 2);
    }
  };

  const tabs = [
    {
      id: 'program',
      label: t('steps.itinerary.description'),
    },
    {
      id: 'itinerary',
      label: t('form.summary'),
    },
  ];

  const [currentTab, setCurrentTab] = useState(tabs[0]?.id);

  return (
    <Container size="full">
      <KoobTabs
        tabs={tabs}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
      />

      <div className="my-5">
        <Stack gutterSize={1.5}>
          {currentTab === 'program' && (
            <div className="w-full relative">
              <FieldArray
                name={'programs'}
                render={helpers => {
                  return (
                    <>
                      <DayPills
                        selectedDayIndex={selectedDayIndex}
                        onSelectDayIndex={setSelectedDayIndex}
                        days={daysData.value}
                        createDayIndex={() => {
                          helpers.push({});
                          createDayIndex();
                        }}
                        deleteDayIndex={index => {
                          helpers.remove(index);
                          removeDayIndex(index);
                        }}
                        translation="experiences"
                        disabled={currentLocale.value !== initialLocale.value}
                      />
                      <Card style={{ marginBottom: 32 }}>
                        <DayForm
                          index={selectedDayIndex}
                          currentLocale={currentLocale}
                        />
                      </Card>
                    </>
                  );
                }}
              />
            </div>
          )}

          {currentTab === 'itinerary' && (
            <EditorField name={'summary'} label={t('form.summary')} />
          )}
        </Stack>
      </div>
    </Container>
  );
};

export const getSchema = () => EXPERIENCE_PROGRAMS_DAYS_SCHEMA;
