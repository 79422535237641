import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { Stack } from '@tymate/margaret';
import { Container } from 'ui';
import { EXPERIENCE_SERVICE_SCHEMA } from '../Utilities/ExperienceValidationSchemas';
import { TypologyItem } from '../Steps/ExperienceTypology';
import React from 'react';
import { useField } from 'formik';

export function ExperienceExtrasAndActivities() {
  const { t } = useTranslation('experiences');
  const typeField = useField('isReservableAlone');
  const typeFieldExtra = useField('activity.isExtra');
  const [service, setService] = useState(
    typeFieldExtra?.value === false ? 'Activity' : 'Extra',
  );
  useEffect(() => {
    if (service === 'Extra') {
      typeField[2].setValue(false);
      typeFieldExtra[2].setValue(true);
    } else if (service === 'Activity') {
      typeField[2].setValue(true);
      typeFieldExtra[2].setValue(false);
    }
  }, [service]);

  return (
    <>
      <Container size="full">
        <Stack direction="column" gutterSize={1.5} size="full">
          <div className="my-8 w-full flex justify-center space-x-5">
            {[
              { label: t('form.services.extra'), value: 'Extra' },
              { label: t('form.services.activity'), value: 'Activity' },
            ].map(option => (
              <TypologyItem
                selected={service === option.value}
                onSelect={() => setService(option?.value)}
                type={option.value}
                name={t('form.services.' + option.value.toLowerCase())}
                description={t(
                  'typology.' + option.value.toLowerCase() + 'Description',
                )}
              />
            ))}
          </div>
        </Stack>
      </Container>
    </>
  );
}

export const getSchema = () => EXPERIENCE_SERVICE_SCHEMA;
