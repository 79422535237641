import { memo } from 'react';

const IcArchive = props => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    style={{ ...props.style, color: props.color, fontSize: props.size }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.008 3.583a.628.628 0 01.1-.008h.009c1.025 0 1.875.835 1.875 1.875v2.508c0 .345-.28.625-.625.625H2.617a.625.625 0 01-.625-.625v-2.5c0-1.036.837-1.875 1.875-1.875h16.141zm.117 1.242v.008H3.867a.624.624 0 00-.625.625v1.875h17.5V5.45c0-.341-.28-.62-.617-.625zm-16.7 5.189a.625.625 0 01.442-.183l16.258.002c.345 0 .625.28.625.625v7.5a3.132 3.132 0 01-3.125 3.125H6.363a3.132 3.132 0 01-3.121-3.125v-7.502c0-.166.066-.325.183-.442zm1.067 1.067v6.877c0 1.027.834 1.867 1.877 1.875h11.256c1.03 0 1.875-.845 1.875-1.875v-6.875L4.492 11.08z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.742 11.083a.623.623 0 00-.625.625c0 .332.274.619.63.625h2.503c.304 0 .566-.23.616-.523a.623.623 0 01-.008-.102.621.621 0 00-.616-.625h-2.5zm4.358.449a1.87 1.87 0 00-1.858-1.699h-2.5a1.873 1.873 0 00-1.875 1.875c0 1.033.84 1.862 1.867 1.875h2.516a1.884 1.884 0 001.875-1.875.627.627 0 00-.025-.176z"
      fill="currentColor"
    />
  </svg>
);

export default memo(IcArchive);
