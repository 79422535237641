import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      width={126}
      height={40}
      viewBox="0 0 126 40"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs>
        <linearGradient
          x1="50%"
          y1="56.677%"
          x2="50%"
          y2="-61.089%"
          id="prefix__b"
        >
          <stop stopColor="#556171" offset="0%" />
          <stop stopColor="#99A5B6" offset="100%" />
        </linearGradient>
        <path
          d="M61.547 7.318h-.527C57.215 2.83 51.357 0 44.944 0a21.265 21.265 0 00-11.096 3.092 3.858 3.858 0 00-2.865-1.279h-9.719A3.861 3.861 0 0018.19 3.34l-2.62 3.447V5.69a3.87 3.87 0 00-3.865-3.876h-7.84A3.87 3.87 0 000 5.69V34.31a3.87 3.87 0 003.865 3.876h7.84a3.87 3.87 0 003.865-3.876v-1.097l2.62 3.447a3.861 3.861 0 003.074 1.526h9.72c1.11 0 2.143-.478 2.864-1.278A21.265 21.265 0 0044.944 40c6.56 0 12.545-2.955 16.34-7.624C65.076 37.045 71.063 40 77.622 40c6.716 0 12.689-3.065 16.44-7.794a10.067 10.067 0 003.286 3.755c1.311.898 2.858 1.543 4.6 1.917 1.5.323 3.158.486 4.926.486h14.93a3.87 3.87 0 003.864-3.876V5.512a3.87 3.87 0 00-3.865-3.876h-14.93c-1.767 0-3.425.163-4.928.486-1.739.374-3.286 1.02-4.6 1.919a10.015 10.015 0 00-3.026 3.277h-.653C89.9 2.862 84.11 0 77.623 0 71.21 0 65.35 2.83 61.547 7.318"
          id="prefix__a"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <mask id="prefix__c" fill="#fff">
            <use xlinkHref="#prefix__a" />
          </mask>
          <path
            d="M61.547 7.318h-.527C57.215 2.83 51.357 0 44.944 0a21.265 21.265 0 00-11.096 3.092 3.858 3.858 0 00-2.865-1.279h-9.719A3.861 3.861 0 0018.19 3.34l-2.62 3.447V5.69a3.87 3.87 0 00-3.865-3.876h-7.84A3.87 3.87 0 000 5.69V34.31a3.87 3.87 0 003.865 3.876h7.84a3.87 3.87 0 003.865-3.876v-1.097l2.62 3.447a3.861 3.861 0 003.074 1.526h9.72c1.11 0 2.143-.478 2.864-1.278A21.265 21.265 0 0044.944 40c6.56 0 12.545-2.955 16.34-7.624C65.076 37.045 71.063 40 77.622 40c6.716 0 12.689-3.065 16.44-7.794a10.067 10.067 0 003.286 3.755c1.311.898 2.858 1.543 4.6 1.917 1.5.323 3.158.486 4.926.486h14.93a3.87 3.87 0 003.864-3.876V5.512a3.87 3.87 0 00-3.865-3.876h-14.93c-1.767 0-3.425.163-4.928.486-1.739.374-3.286 1.02-4.6 1.919a10.015 10.015 0 00-3.026 3.277h-.653C89.9 2.862 84.11 0 77.623 0 71.21 0 65.35 2.83 61.547 7.318"
            fill="url(#prefix__b)"
            mask="url(#prefix__c)"
          />
        </g>
        <g fill="#FEFEFE">
          <path d="M30.866 5.67h-9.708L11.69 18.16h-.08V5.67H3.78v28.66h7.83V21.84h.08l9.468 12.49h9.708L18.888 20z" />
          <path d="M86.3 20.364c0 4.172-3.833 7.982-8.505 7.982-4.67 0-8.504-3.81-8.504-7.982 0-.177.016-.348.024-.522-.008-.173-.024-.344-.024-.52 0-4.173 3.833-7.983 8.504-7.983 4.672 0 8.504 3.81 8.504 7.982 0 .177-.015.348-.024.521.009.174.024.345.024.522m-41.26 7.982c-4.67 0-8.504-3.81-8.504-7.982 0-.177.016-.348.024-.522-.008-.173-.024-.344-.024-.52 0-4.173 3.833-7.983 8.504-7.983 4.671 0 8.504 3.81 8.504 7.982 0 .177-.015.348-.024.521.009.174.024.345.024.522 0 4.172-3.833 7.982-8.504 7.982M77.783 3.78c-7.644 0-14.759 4.944-16.366 12.33-1.606-7.386-8.721-12.33-16.365-12.33-8.693 0-16.706 6.393-16.706 15.503 0 .188.008.373.013.56-.005.186-.013.37-.013.56 0 9.11 8.013 15.503 16.706 15.503 7.644 0 14.759-4.945 16.365-12.331 1.607 7.386 8.722 12.33 16.366 12.33 8.693 0 16.705-6.393 16.705-15.503 0-.188-.007-.373-.012-.56.005-.186.012-.37.012-.559 0-9.11-8.012-15.503-16.705-15.503M104.303 13.036c.242-.435.57-.78.97-1.023.412-.249.895-.425 1.435-.524.554-.1 1.136-.15 1.731-.15h6.207v6.929h-6.731c-1.17 0-2.142-.283-2.886-.84-.725-.541-1.092-1.447-1.092-2.691 0-.694.123-1.266.366-1.701zm10.343 15.31h-6.207c-.595 0-1.177-.05-1.731-.15a4.214 4.214 0 01-1.434-.524 2.725 2.725 0 01-.971-1.023c-.243-.435-.366-1.007-.366-1.7 0-1.245.367-2.15 1.092-2.692.744-.557 1.715-.84 2.886-.84h6.73v6.93zM106.92 5.354c-1.501 0-2.89.135-4.129.4-1.25.268-2.34.715-3.238 1.329a6.27 6.27 0 00-2.115 2.478c-.495 1.02-.746 2.293-.746 3.783 0 1.617.374 2.988 1.113 4.074.652.958 1.59 1.77 2.79 2.42l-.011.005c.003 0 .007.002.01.004-1.2.65-2.137 1.462-2.789 2.42-.739 1.086-1.113 2.457-1.113 4.074 0 1.49.251 2.763.746 3.784a6.27 6.27 0 002.115 2.477c.898.614 1.988 1.06 3.238 1.33 1.239.264 2.628.399 4.129.399h14.969V5.354h-14.97z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
