import { get } from './';

export const getAllCountries = () => get('/countries');

export const getAllCountriesByDmcId = ({ dmcId }) => {
  if (!dmcId) {
    return get('/countries');
  }

  return get(`/countries/${dmcId}`);
};
