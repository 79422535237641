import React, { useEffect } from 'react';
import { useState } from 'react';
import { get, put } from 'api/node';
import { useAsync } from 'react-async';
import { useParams } from 'react-router';
import { Routes, Route } from 'react-router-dom';
import ExperienceLayout from './Partials/ExperienceLayout';
import ExperienceForm from './Partials/ExperienceForm';
import { useSearchParams, useSnack } from 'hooks';
import { useTranslation } from 'react-i18next';
import { formatExperienceApiData } from './Utilities/ExperienceHelpers';
import PromotionsContainer from '../Promotions';
import { Spinner as Loader } from '@tymate/margaret';
import { initialLocale } from '../../../utils';
import { ExperienceCompatibility } from '../Compatibility/Compatibility';
import { ExtraRecommandation } from '../Recommandation/ExtraRecommandation';

export const getExperience = async ({ experienceId, locale }) => {
  const headers = {
    'Accept-Language': locale,
  };
  return await get(`experiences/${experienceId}`, { headers });
};

export default function ExperienceEdit() {
  const { t } = useTranslation('experiences');
  const { experienceId } = useParams();
  const [searchParams] = useSearchParams();
  const { notify, notifyError } = useSnack();
  const [saveIsLoading, setSaveIsLoading] = useState(false);

  const [currentLocale, setCurrentLocale] = useState(initialLocale);

  const {
    data: experienceData,
    isLoading,
    reload,
  } = useAsync({
    promiseFn: getExperience,
    experienceId: experienceId,
    locale: currentLocale?.value,
  });

  useEffect(() => {
    if (experienceData) {
      reload();
    }
  }, [currentLocale?.value]);

  const experienceBdd = experienceData ? experienceData?.data : [];

  const experienceInitialValues = formatExperienceApiData(experienceBdd);

  const handleSave = async experience => {
    try {
      setSaveIsLoading(true);
      await put(
        `/experiences/${experienceId}?locale=${currentLocale?.value}`,
        experience,
      );
      reload();
      notify(t('editSuccess'));
      setSaveIsLoading(false);
      reload();
      return experienceId;
    } catch (err) {
      setSaveIsLoading(false);
      if (err?.response?.data?.message) {
        notify(err?.response?.data?.message, { type: 'error' });
      } else {
        notifyError(err);
      }
    }
  };

  return (
    <Routes>
      <Route
        path=""
        element={
          <ExperienceLayout>
            <div className="relative">
              {(saveIsLoading || (isLoading && experienceInitialValues)) && (
                <div className="bg-gray-100/75 backdrop-blur-sm rounded-lg border z-20 absolute h-full w-full flex justify-center items-center">
                  <Loader />
                </div>
              )}
              {(!isLoading || experienceInitialValues) && (
                <ExperienceForm
                  initialValues={experienceInitialValues}
                  handleSave={handleSave}
                  initialStep={
                    searchParams.step && searchParams.step !== ''
                      ? parseInt(searchParams.step)
                      : 1
                  }
                  currentLocale={currentLocale}
                  setCurrentLocale={setCurrentLocale}
                />
              )}
            </div>
          </ExperienceLayout>
        }
      />
      <Route path="promotions/*" element={<PromotionsContainer />} />
      <Route path="compatibility/*" element={<ExperienceCompatibility />} />
      <Route path="recommendation/*" element={<ExtraRecommandation />} />
    </Routes>
  );
}
