import { Stack } from '@tymate/margaret';
import { FieldArray, useField } from 'formik';
import SelectCurrencyField from '../../../../components/Fields/SelectCurrencyField';
import { useTranslation } from 'react-i18next';
import {
  SegmentedControlField,
  SelectField,
  StepperField,
} from '../../../../components/Fields';
import React from 'react';
import styled, { useTheme } from 'styled-components';
import TextFieldButton from '../../../../components/Fields/TextFieldButton';
import { Button } from '../../../../components';
import VisuallyHidden from '../../../../components/VisuallyHidden';
import { IoAddCircleOutline, IoTrashOutline } from 'react-icons/io5';
import PeriodsField from '../../../../components/Fields/PeriodsField';
import { EXPERIENCE_PRICING_SCHEMA } from '../Utilities/ExperienceValidationSchemas';
import { initialLocale } from '../../../../utils';

const AddButton = styled(Button).attrs({ type: 'button' })`
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing(0.75)};
  color: ${({ theme }) => theme.textLight};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.primaryLight};
  color: ${({ theme }) => theme.primary};
  &:hover,
  &:focus,
  &:active {
    background-color: ${({ theme }) => theme.orangeLighter};
  }
`;

function AddPaxButton({ onClick, children }) {
  return (
    <AddButton onClick={() => onClick()}>
      <div className="flex flex-col space-y-1">
        <IoAddCircleOutline size={20} aria-hidden="true" />
        <div className="break-all">{children}</div>
      </div>
    </AddButton>
  );
}

function AdultPaxSection({ periodIndex }) {
  const { t } = useTranslation('experiences');
  const theme = useTheme();
  const [{ value }] = useField(`periods[${periodIndex}].adultPax`);

  return (
    <FieldArray
      name={`periods[${periodIndex}].adultPax`}
      render={({ push, remove }) => (
        <div className="flex flex-col space-y-5">
          {value?.length > 0 && (
            <div>
              {(value ?? []).map((elem, adultPaxIndex) => (
                <div className="flex mb-3">
                  <Stack gutterSize={1} alignY="end">
                    <TextFieldButton
                      type="number"
                      required
                      label={t('form.price')}
                      name={`periods[${periodIndex}].adultPax[${adultPaxIndex}].price`}
                    />

                    <TextFieldButton
                      type="number"
                      required
                      label={t('form.fromPax')}
                      errorKey={`periods[${periodIndex}].adultPax[${adultPaxIndex}].paxRange`}
                      name={`periods[${periodIndex}].adultPax[${adultPaxIndex}].paxRange[0]`}
                    />

                    <TextFieldButton
                      type="number"
                      required
                      label={t('form.toPax')}
                      errorKey={`periods[${periodIndex}].adultPax[${adultPaxIndex}].paxRange`}
                      name={`periods[${periodIndex}].adultPax[${adultPaxIndex}].paxRange[1]`}
                    />

                    <Button
                      type="button"
                      variant="secondary"
                      onClick={() => remove(adultPaxIndex)}
                      style={{
                        padding: theme.spacing(0.25),
                        margin: 'auto',
                      }}
                    >
                      <VisuallyHidden>Delete</VisuallyHidden>
                      <IoTrashOutline color={theme.textLighter} size={20} />
                    </Button>
                  </Stack>
                </div>
              ))}
            </div>
          )}

          <AddPaxButton onClick={() => push({})}>
            {t('form.addAdultPax')}
          </AddPaxButton>
        </div>
      )}
    />
  );
}

function ChildrenPaxSection({ periodIndex }) {
  const { t } = useTranslation('experiences');
  const theme = useTheme();
  const [{ value }] = useField(`periods[${periodIndex}].childrenPax`);

  return (
    <FieldArray
      name={`periods[${periodIndex}].childrenPax`}
      render={({ push, remove }) => (
        <div className="flex flex-col space-y-5">
          {value?.length > 0 && (
            <div>
              {(value ?? []).map((elem, childrenPaxIndex) => (
                <div class="flex mb-3">
                  <Stack gutterSize={1} alignY="end">
                    <TextFieldButton
                      type="number"
                      required
                      label={t('form.price')}
                      name={`periods[${periodIndex}].childrenPax[${childrenPaxIndex}].price`}
                    />

                    <TextFieldButton
                      type="number"
                      required
                      label={t('form.fromAge')}
                      errorKey={`periods[${periodIndex}].childrenPax[${childrenPaxIndex}].ageRange`}
                      name={`periods[${periodIndex}].childrenPax[${childrenPaxIndex}].ageRange[0]`}
                    />

                    <TextFieldButton
                      type="number"
                      required
                      label={t('form.toAge')}
                      errorKey={`periods[${periodIndex}].childrenPax[${childrenPaxIndex}].ageRange`}
                      name={`periods[${periodIndex}].childrenPax[${childrenPaxIndex}].ageRange[1]`}
                    />

                    <Button
                      type="button"
                      variant="secondary"
                      onClick={() => remove(childrenPaxIndex)}
                      style={{
                        padding: theme.spacing(0.25),
                        margin: 'auto',
                      }}
                    >
                      <VisuallyHidden>Delete</VisuallyHidden>
                      <IoTrashOutline color={theme.textLighter} size={20} />
                    </Button>
                  </Stack>
                </div>
              ))}
            </div>
          )}

          <AddPaxButton onClick={() => push({})}>
            {t('form.addChildrenPax')}
          </AddPaxButton>
        </div>
      )}
    />
  );
}

function ExperiencePeriodPricing({ index }) {
  const { t } = useTranslation('experiences');
  const [field, , { setValue }] = useField(`periods[${index}]`);
  const [experienceType] = useField('type');
  if (
    !field.value.perPax &&
    (field.value.adultPax || field.value.childrenPax)
  ) {
    setValue({
      ...field.value,
      adultPax: undefined,
      childrenPax: undefined,
    });
  }
  return (
    <div className="w-full">
      <div className="my-3 flex justify-between space-x-3">
        <SelectField
          label={t('form.periodType')}
          name={`periods[${index}].periodType`}
          valueField={'value'}
          required
          options={[
            {
              value: 'on_request',
              label: t('form.onRequest'),
            },
            {
              value: 'free_sale',
              label: t('form.freeSale'),
            },
            {
              value: 'stop_sale',
              label: t('form.stopSale'),
            },
          ]}
        />

        <SegmentedControlField
          name={`periods[${index}].perPax`}
          options={[
            { label: t('form.no'), value: false },
            { label: t('form.yes'), value: true },
          ]}
          label={t('form.perPax')}
          required
        />
      </div>

      {experienceType.value === 'Program' && false && (
        <div className="mt-5 flex space-x-3 items-end">
          <SegmentedControlField
            name={`periods[${index}].hasRoomSupplement`}
            options={[
              { label: t('form.no'), value: false },
              { label: t('form.yes'), value: true },
            ]}
            label={t('form.hasRoomSupplement')}
            required
          />

          {field.value.hasRoomSupplement && (
            <TextFieldButton
              type="number"
              required
              label={t('form.roomSupplement')}
              name={`periods[${index}].roomSupplement`}
            />
          )}
        </div>
      )}

      <div className="mt-5 flex justify-between">
        <TextFieldButton
          type="number"
          required
          label={t('form.price')}
          name={`periods[${index}].price`}
        />

        <div>
          <SelectCurrencyField
            name="currency"
            label={t('form.currency')}
            valueField="value"
            required
          />
        </div>

        <div>
          <StepperField
            label={t('form.minimumPeopleRequired')}
            name={`periods[${index}].minimumPeopleRequired`}
            type="number"
            required
            defaultValue={1}
          />
        </div>

        <div>
          <StepperField
            label={t('form.maximumPeopleRequired')}
            name={`periods[${index}].maximumPeopleRequired`}
            type="number"
            required
            defaultValue={1}
          />
        </div>
      </div>

      {field.value.perPax && (
        <div className="my-5 border-y flex flex-col divide-y">
          <div className="py-5">
            <AdultPaxSection periodIndex={index} />
          </div>

          <div className="py-5">
            <ChildrenPaxSection periodIndex={index} />
          </div>
        </div>
      )}
    </div>
  );
}

export function ExperiencePricing({ currentLocale = null }) {
  const { t } = useTranslation('experiences');
  const [isExtra] = useField('activity.isExtra');

  return (
    <>
      <div className="py-8 max-w-2xl mx-auto">
        <div className="w-full relative">
          {(currentLocale == null ||
            currentLocale.value !== initialLocale.value) && (
            <div className="absolute z-10 h-full w-full flex justify-center items-center">
              <div className="text-orange-500 px-3 py-2 bg-white border border-orange-500 rounded">
                {t('program.experiences.selectEnglish')}
              </div>
            </div>
          )}
          <div
            className={
              (currentLocale == null ||
                currentLocale.value !== initialLocale.value) &&
              'pointer-events-none opacity-50'
            }
          >
            <PeriodsField
              required
              name="periods"
              customPeriodFields={ExperiencePeriodPricing}
              bordered
            />
            {(isExtra?.value === false || isExtra?.value === undefined) && (
              <div className="mt-5">
                <SegmentedControlField
                  label={t('form.isReservableAlone')}
                  name="isReservableAlone"
                  options={[
                    { label: t('form.yes'), value: true },
                    { label: t('form.no'), value: false },
                  ]}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export const getSchema = () => {
  return EXPERIENCE_PRICING_SCHEMA;
};
