import React from 'react';
import ChatboxUpload from './ChatboxUpload';
import { Formik } from 'formik';
import { useMutation } from '@apollo/client';
import { CREATE_ADMIN_BOOKING_MESSAGE } from '../../../../../api/bookings';
import ChatBoxForm from '../../../../../components/Forms/ChatBoxForm';

export default function ChatboxInput({
  bookingId,
  secret,
  refetch,
  privateMode = false,
}) {
  const [createMessage] = useMutation(CREATE_ADMIN_BOOKING_MESSAGE);

  const onSubmit = async values => {
    if (!values.message || values.message.trim() === '') return;

    try {
      await createMessage({
        variables: {
          input: { ...values, private: privateMode },
        },
      });
      refetch();
    } catch (err) {}
  };

  return (
    <Formik
      initialValues={{
        adminBookingId: bookingId,
        secret: secret,
        message: '',
      }}
      onSubmit={(values, { resetForm }) =>
        onSubmit(values).then(() => resetForm())
      }
    >
      <ChatBoxForm
        costumUploaderField={
          <ChatboxUpload
            onUpload={refetch}
            secret={secret}
            privateMode={privateMode}
          />
        }
      />
    </Formik>
  );
}
