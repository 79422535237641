import { FastField } from 'formik';
import { ErrorMessageWrapper, FormField, FormLabel } from 'ui/forms';
import { formatCurrencyLabel } from '../../utils';
import Select from './Select';

const FastCurrencyField = ({ currenciesOptions, label, name, ...props }) => (
  <FormField>
    <FormLabel>{label}</FormLabel>
    <FastField name={name}>
      {({ field, meta, form }) => {
        const hasError = Boolean(meta.touched) && Boolean(meta.error);
        return (
          <>
            <Select
              {...props}
              {...field}
              hasError={hasError}
              onChange={value => {
                form.setFieldValue(name, value.value);
              }}
              renderOption={value => value?.label}
              renderSelectedValue={value =>
                value?.label || formatCurrencyLabel(value)
              }
              options={currenciesOptions}
            />
            {hasError && (
              <ErrorMessageWrapper>{meta.error}</ErrorMessageWrapper>
            )}
          </>
        );
      }}
    </FastField>
  </FormField>
);

export default FastCurrencyField;
