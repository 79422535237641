import { memo } from 'react';

const EarlyBookerIcon = ({ size = 24, color = 'currentColor', style = {} }) => {
  return (
    <svg
      viewBox="0 0 24 22"
      aria-hidden="true"
      focusable="false"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size - 2}
      {...style}
    >
      <g fill={color}>
        <path d="M4.68.22a.75.75 0 111.06 1.06c-.258.259-.29.329-.05.71l.143.219c.737 1.106.855 1.873-.093 2.821a.75.75 0 01-1.06-1.06c.257-.259.289-.329.049-.71l-.144-.219C3.848 1.935 3.73 1.168 4.68.22zm3.75 0a.75.75 0 111.06 1.06c-.258.259-.29.329-.05.71l.143.219c.737 1.106.855 1.873-.093 2.821a.75.75 0 01-1.06-1.06c.257-.259.289-.329.049-.71l-.144-.219C7.598 1.935 7.48 1.168 8.43.22zm3.75 0a.75.75 0 011.06 1.06c-.258.259-.29.329-.05.71l.143.219c.737 1.106.855 1.873-.093 2.821a.75.75 0 01-1.06-1.06c.257-.259.289-.329.049-.71l-.144-.219c-.737-1.106-.855-1.873.094-2.821zM15 6.25H3c-1.218 0-2.25.876-2.25 2.023v6.363c0 3.959 3.718 7.114 8.25 7.114l.269-.004c4.424-.127 7.981-3.242 7.981-7.11V8.273c0-1.147-1.032-2.023-2.25-2.023zM3 7.75h12c.439 0 .75.264.75.523v6.363c0 2.999-2.863 5.506-6.514 5.61l-.247.004c-3.742 0-6.739-2.543-6.739-5.614V8.273c0-.26.311-.523.75-.523z"></path>
        <path d="M18.002 7.75a5.264 5.264 0 015.248 5.252 5.263 5.263 0 01-5.034 5.243L18 18.25h-1.65a.75.75 0 01-.102-1.493l.102-.007h1.648a3.762 3.762 0 003.752-3.748 3.763 3.763 0 00-3.551-3.746L18 9.25h-1.5a.75.75 0 01-.102-1.493l.102-.007h1.502z"></path>
      </g>
    </svg>
  );
};

export default memo(EarlyBookerIcon);
