import styled, { createGlobalStyle } from 'styled-components';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const TOOLBARS = {
  simple: [
    [{ size: ['small', false, 'large', 'huge'] }],
    ['bold', 'italic'],
    [{ align: [] }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ indent: '-1' }, { indent: '+1' }],
  ],
  minimal: [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],
    [{ header: 1 }, { header: 2 }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ script: 'sub' }, { script: 'super' }],
    [{ size: ['small', false, 'large', 'huge'] }],
    ['link', 'image'],
  ],
  complete: [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],

    [{ header: 1 }, { header: 2 }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ script: 'sub' }, { script: 'super' }],
    [{ indent: '-1' }, { indent: '+1' }],
    [{ direction: 'rtl' }],

    [{ size: ['small', false, 'large', 'huge'] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    [{ align: [] }],

    ['clean'],
  ],
};

const EditorReactQuill = styled(ReactQuill)`
  width: 100%;

  ${({ fillSpace }) =>
    fillSpace &&
    `
    flex: 1;
  `}
`;

const EditorReactStyle = createGlobalStyle`
  .quill {
    box-shadow: 0 1px 2px 0 ${({ theme }) => theme.shadow};
    border-radius: 4px;

    .ql-snow {
      border-radius: 0 0 4px 4px;
    }

    .ql-toolbar {
      border-radius: 4px 4px 0 0;
      background-color: #ffffff;

      &:not([disabled]):focus,
      &:not([disabled]):active, &:not([disabled]):hover {
        outline: none;
        box-shadow: 0 1px 2px 0 ${({ theme }) => theme.shadowFocus};
      }      
    }

    .ql-container.ql-snow {
      border: 1px solid ${({ theme }) => theme.separator};

      &:not([disabled]):focus,
      &:not([disabled]):active, &:not([disabled]):hover {
        outline: none;
        box-shadow: 0 1px 2px 0 ${({ theme }) => theme.shadowFocus};
      }
    }

    .ql-editor {
      border-radius: 0 0 4px 4px;
      line-height: 1.5;
      border: 1px solid #ffffff;
      min-height: 6em;
      cursor: text;
      max-height: 20em;
      overflow-y: auto;
      background-color: #ffffff;
      ${({ fillHeight }) => fillHeight && `height: 200px;`}
    }

    .ql-toolbar.ql-snow {
      border: 1px solid ${({ theme }) => theme.separator};

    }

    .ql-snow {
      .ql-toolbar,
      &.ql-toolbar {
        button:hover,
        button:focus,
        button.ql-active,
        .ql-picker-label:hover,
        .ql-picker-label.ql-active,
        .ql-picker-item:hover,
        .ql-picker-item.ql-selected {
          color: #f5700a;
        }
      }
    }

    .ql-snow {
      .ql-toolbar,
      &.ql-toolbar {
        button:hover,
        button:focus,
        button.ql-active,
        .ql-picker-label:hover,
        .ql-picker-label.ql-active,
        .ql-picker-item:hover,
        .ql-picker-item.ql-selected {
          .ql-fill,
          &.ql-fill {
            fill: #f5700a;
          }
        }
      }
    }

    .ql-snow {
      .ql-toolbar,
      &.ql-toolbar {
        button:hover,
        button:focus,
        button.ql-active,
        .ql-picker-label:hover,
        .ql-picker-label.ql-active,
        .ql-picker-item:hover,
        .ql-picker-item.ql-selected {
          .ql-stroke,
          .ql-stroke-miter {
            stroke: #f5700a;
          }
        }
      }
    }
  }
`;

const Editor = ({
  value,
  variant = 'simple',
  fillSpace,
  fillHeight,
  onChange,
  disabled,
  onFocus,
  onBlur,
}) => {
  return (
    <>
      <EditorReactStyle fillHeight={fillHeight} />
      <EditorReactQuill
        modules={{
          toolbar: TOOLBARS[variant],
          clipboard: {
            matchVisual: false,
          },
        }}
        theme="snow"
        value={value || ''}
        onChange={onChange}
        readOnly={disabled}
        fillSpace={fillSpace}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    </>
  );
};

export default Editor;
