import React from 'react';
import { Text } from 'ui';
import { useTranslation } from 'react-i18next';
import { gql, useQuery } from '@apollo/client';
import { pickBy } from 'lodash';
import RequestToState from 'components/RequestToState';
import { Button, Modal } from '../../../../components';
import { useState } from 'react';
import { Stack } from '@tymate/margaret';
import DenyExplainationForm from './DenyExplainationForm';

const GET_ORGANIZATION = gql`
  query organization($id: ID!) {
    node(id: $id) {
      ... on Organization {
        id
        displayName
      }
    }
  }
`;

export default function Header({
  request,
  save,
  isLoading,
  isAllowedToManage,
}) {
  const { t } = useTranslation('toConnectionRequest');
  const [isOpen, setIsOpen] = useState(false);

  const { data } = useQuery(GET_ORGANIZATION, {
    variables: pickBy({
      id: request?.toorganization?.id,
    }),
  });

  const refuse = () => {
    if (!window.confirm(t('denyConfirmation'))) {
      return;
    } else {
      setIsOpen(true);
    }
  };

  return (
    <div className="bg-white border rounded-lg p-10">
      <div className="flex justify-center">
        <div className="flex gap-4 text-black text-3xl">
          <h1>{data?.node?.displayName}</h1>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-10 h-10"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M7.5 21L3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5"
            />
          </svg>

          <h1>{request?.toorganization?.displayName}</h1>
        </div>
      </div>

      <div className="flex justify-between">
        <Button
          onClick={() => refuse()}
          variant="simple"
          disabled={isLoading || !isAllowedToManage}
        >
          {t('misc:deny')}
        </Button>

        <div className="text-center">
          <Text type="h1">{t('config')}</Text>
        </div>

        <Button
          onClick={() => save('confirmed', '')}
          disabled={isLoading || !isAllowedToManage}
          variant="primary"
        >
          {t('misc:accept')}
        </Button>
      </div>

      <div className="mt-4 flex justify-center">
        <RequestToState state={request?.state} />
      </div>

      {isOpen && (
        <Modal
          isOpen
          onRequestClose={() => {
            setIsOpen(false);
          }}
          title={
            <Stack size="full" alignY="center" gutterSize={1}>
              <p>{t('denyReason')}</p>
            </Stack>
          }
        >
          <DenyExplainationForm onSubmit={values => save('refused', values)} />
        </Modal>
      )}
    </div>
  );
}
