import React from 'react';
import KoobCard from '../../../../../components/Koob/KoobCard';
import KoobPlaceholder from '../../../../../components/Koob/KoobPlaceholder';
import DocumentListItem from './DocumentListItem';

export default function DocumentList({ documents }) {
  return (
    <KoobCard>
      {documents?.length > 0 ? (
        <div className="-my-5 flex flex-col divide-y">
          {documents.map((document, key) => (
            <DocumentListItem document={document} key={key} />
          ))}
        </div>
      ) : documents ? (
        <div>No docs</div>
      ) : (
        [...Array(5)].map((_e, key) => (
          <KoobPlaceholder className="h-12 my-1" key={key} />
        ))
      )}
    </KoobCard>
  );
}
