import { Stack } from '@tymate/margaret';
import { Button } from 'components';
import { FieldArray, useField } from 'formik';
import { Fragment, createElement } from 'react';
import { IoAddCircleOutline, IoTrashOutline } from 'react-icons/io5';
import { omit } from 'lodash';
import styled, { useTheme } from 'styled-components';
import DateRangePicker from 'components/Fields/DateRangePicker';
import VisuallyHidden from 'components/VisuallyHidden';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { createPromotionPeriodFactory } from 'utils/promotions';
import { formatDate } from '../../utils';

const AddPeriodButton = styled(Button).attrs({ type: 'button' })`
  display: inline-flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing(0.75)};
  color: ${({ theme }) => theme.textLight};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.primaryLight};
  color: ${({ theme }) => theme.primary};
  &:hover,
  &:focus,
  &:active {
    background-color: ${({ theme }) => theme.orangeLighter};
  }
`;

const DayButtonSelector = ({
  day,
  selected,
  disabled = false,
  onSelect = () => {},
}) => {
  return (
    <Button
      type="button"
      variant={selected ? 'primary' : 'simple'}
      role="switch"
      aria-checked={selected}
      disabled={disabled}
      onClick={() => onSelect({ day: day?.prop, value: !selected })}
    >
      {day?.label}
    </Button>
  );
};

const LabelWrapper = styled(Stack).attrs({ as: 'label' })``;

const PeriodFieldInput = ({
  name,
  index,
  disabled = false,
  customPeriodFields,
  required,
  minDate = new Date(),
}) => {
  const [, state, { setValue }] = useField(name);
  const { t } = useTranslation('promotionForm');

  const days = [
    { prop: 'Mo', label: 'M' },
    { prop: 'Tu', label: 'T' },
    { prop: 'We', label: 'W' },
    { prop: 'Th', label: 'T' },
    { prop: 'Fr', label: 'F' },
    { prop: 'Sa', label: 'S' },
    { prop: 'Su', label: 'S' },
  ];

  const onDaySelect = useCallback(
    ({ day, value: v }) =>
      setValue({
        ...state.value,
        days: {
          ...state.value.days,
          [day]: v,
        },
      }),
    [setValue, state.value],
  );

  return (
    <Stack
      direction="column"
      alignX="stretch"
      gutterSize={0.5}
      style={{ width: '100%' }}
    >
      <Stack direction="row" alignY="center">
        <span style={{ flex: '0 0 auto' }}>
          Period {index + 1}
          {required && <span className="ml-1 text-red-500">*</span>}
        </span>
        <Stack gutterSize={0.5} direction="row" style={{ flex: '1 0 auto' }}>
          <LabelWrapper style={{ marginLeft: 'auto' }}>
            <VisuallyHidden>{t('periods.startAndEndDate')}</VisuallyHidden>
            <input
              style={{ opacity: 0, width: 0, height: 0, position: 'absolute' }}
              autoFocus
            />
            <DateRangePicker
              disabled={disabled}
              style={{ width: '100%' }}
              value={[state?.value?.startAt, state?.value?.endAt]}
              minDate={minDate}
              onChange={dates => {
                if (dates === null) {
                  setValue(omit(state.value, ['startAt', 'endAt']));
                } else {
                  const start = dates[0]
                    ? formatDate(dates[0], 'yyyy-MM-dd')
                    : null;
                  const end = dates[1]
                    ? formatDate(dates[1], 'yyyy-MM-dd')
                    : null;
                  setValue({
                    ...state.value,
                    startAt: start,
                    endAt: end,
                  });
                }
              }}
            />
          </LabelWrapper>
        </Stack>
      </Stack>
      <Stack direction="row" alignY="center" style={{ width: '100%' }}>
        <span>
          {t('periods.days')}
          {required && <span className="ml-1 text-red-500">*</span>}
        </span>
        <Stack
          alignY="end"
          alignX="center"
          direction="row"
          gutterSize={0.375}
          style={{ marginLeft: 'auto' }}
        >
          {days.map(day => (
            <DayButtonSelector
              disabled={disabled}
              key={day?.prop}
              day={day}
              selected={state.value.days[day.prop]}
              onSelect={onDaySelect}
            />
          ))}
        </Stack>
      </Stack>
      {customPeriodFields && (
        <Stack direction="row" alignY="center" style={{ width: '100%' }} id="t">
          {createElement(customPeriodFields, { index: index })}
        </Stack>
      )}
    </Stack>
  );
};

const FieldInputList = styled.div`
  list-style: none;
  padding: 0;
  margin: 0;
  color: ${({ theme }) => theme.text};
`;

const FieldInputItem = styled.div`
  padding: ${({ theme }) => theme.spacing(1)} 0;
  color: ${({ theme }) => theme.textLight};
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
  &:only-child {
    border-bottom: none;
  }
`;

const Box = styled(Stack)`
  width: 40px;
  color: ${({ theme }) => theme.textLight};
`;

const PeriodsField = ({
  name,
  disabled = false,
  customPeriodFields,
  required,
  bordered = false,
  minDate,
  canRemoveLastElement,
}) => {
  const [field] = useField(name);
  const theme = useTheme();
  const { t } = useTranslation('promotionForm');

  return (
    <div className={bordered ? 'p-8 border rounded-lg' : ''}>
      <FieldArray
        name={name}
        render={helpers => {
          return (
            <>
              <FieldInputList>
                {field?.value?.map((_, index) => (
                  <Fragment key={index}>
                    <FieldInputItem>
                      <PeriodFieldInput
                        name={`${name}.${index}`}
                        index={index}
                        disabled={disabled}
                        customPeriodFields={customPeriodFields}
                        minDate={minDate}
                        required={required}
                      />
                      {index !== 0 || canRemoveLastElement ? (
                        <Box>
                          <Button
                            disabled={disabled}
                            type="button"
                            variant="secondary"
                            onClick={() => helpers.remove(index)}
                            style={{
                              padding: theme.spacing(0.25),
                              margin: 'auto',
                            }}
                          >
                            <VisuallyHidden>Delete</VisuallyHidden>
                            <IoTrashOutline
                              color={theme.textLighter}
                              size={20}
                            />
                          </Button>
                        </Box>
                      ) : (
                        <Box />
                      )}
                    </FieldInputItem>
                  </Fragment>
                ))}
              </FieldInputList>

              <AddPeriodButton
                style={{ marginTop: theme.spacing(0.75) }}
                onClick={() => helpers.push(createPromotionPeriodFactory())}
              >
                <IoAddCircleOutline size={20} aria-hidden="true" />{' '}
                {t('periods.addPeriod')}
              </AddPeriodButton>
            </>
          );
        }}
      />
    </div>
  );
};

export default PeriodsField;
