import { Routes, Route, Navigate } from 'react-router-dom';
import EditSegment from './EditSegment';
import ArchiveSegment from './ArchiveSegment';
import CreateSegment from './CreateSegment';
import SegmentsList from './SegmentsList';
import SegmentBreadcrumb from './SegmentBreadcrumb';
import { useApp } from 'hooks';
import DetailsSettingRequest from 'components/SettingRequest/DetailsSettingRequest';

const Styles = () => {
  const { isAllowedTo } = useApp();

  return (
    <>
      <Routes>
        <Route path=":segmentId/*" element={<SegmentBreadcrumb />} />
      </Routes>

      <Routes>
        {isAllowedTo('manageExperienceSettings') && (
          <>
            <Route path="new" element={<CreateSegment />} />
            <Route path=":segmentId/edit" element={<EditSegment />} />
            <Route path=":segmentId/archive" element={<ArchiveSegment />} />
          </>
        )}
        <Route
          path=":settingRequestId/details"
          element={<DetailsSettingRequest />}
        />
        <Route path=":segmentId" element={<Navigate to=".." />} />
        <Route element={<SegmentsList />} />
      </Routes>
    </>
  );
};

export default Styles;
