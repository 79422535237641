import { useTranslation } from 'react-i18next';
import { Button } from 'components';
import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import Header from './Partials/Header';
import { get, post, put } from '../../../api/node';
import { useApp, useError, useSnack } from '../../../hooks';
import { useAsync } from 'react-async';
import { useNavigate } from 'react-router-dom';
import { MdRemoveRedEye } from 'react-icons/md';
import { isEqual } from 'lodash';
import DistributionBrowser from './Partials/DistributionBrowser';
import { Input } from 'ui';
import DistributionSelected from './Partials/DistributionSelected';
import { Spinner } from '@tymate/margaret';

const getRequest = async ({ requestId }) => {
  return await get(`ToConnectionRequest/${requestId}`);
};

const getList = async ({ filters }) => {
  return await post('/experiences/liste', filters);
};

const ToConnectionRequestExperience = () => {
  const { t } = useTranslation('toConnectionRequest');
  const { isAllowedTo, canManageToConnectionRequest, user } = useApp();
  const { notify } = useSnack();
  const { sendErrorSnack } = useError();
  const { requestId } = useParams();
  const navigate = useNavigate();
  const [filters, setFilters] = useState({});
  const [page, setPage] = useState(1);

  const isAllowedToManage = () => {
    return (
      isAllowedTo('canManageToConnectionRequest') ||
      canManageToConnectionRequest(user)
    );
  };

  const { data, isLoading } = useAsync({
    promiseFn: getRequest,
    requestId: requestId,
  });

  const request = data?.data;

  const formatExperienceData = listExperience => {
    return (listExperience || []).map(node => {
      if (node?.experience) {
        return {
          id: node.experience?.id,
          name: node.experience?.name,
          country: node.experience?.inCity?.country?.alpha2,
          city: node.experience?.inCity?.title,
          margin: node.margin,
          isMarginPercent: node.isMarginPercent,
          periods: [],
        };
      } else {
        return {
          id: node?.id,
          name: node?.name,
          country: node?.inCity?.country?.alpha2,
          city: node?.inCity?.title,
          experienceType: node?.experienceType,
          margin: null,
          isMarginPercent: null,
        };
      }
    });
  };
  useEffect(() => {
    if (request) {
      setGlobalMargin(request.margin ?? 0);
      setIsGlobalMarginPercent(request.isMarginPercent ?? false);
    }
    if (request?.requestExperiences) {
      let formattedExperience = formatExperienceData(
        request?.requestExperiences,
      );
      setSelectedExperiences(formattedExperience);
    }
  }, [request]);

  const [experiences, setExperiences] = useState([]);
  const [selectedExperiences, setSelectedExperiences] = useState(
    request?.experiences || [],
  );
  const [globalMargin, setGlobalMargin] = useState(0);
  const [isGlobalMarginPercent, setIsGlobalMarginPercent] = useState(false);
  const [saving, setSaving] = useState(false);

  const save = async (state, comment) => {
    try {
      await put(`ToConnectionRequest/${requestId}`, {
        margin: globalMargin ? parseInt(globalMargin) : 0,
        isMarginPercent: isGlobalMarginPercent,
        state: state ?? request.state,
        explaination: comment?.reason,
        toConnectionRequestExperiences: selectedExperiences.map(experience => ({
          id: experience.id,
          margin: experience.margin ? parseInt(experience.margin) : 0,
          isMarginPercent: experience.isMarginPercent,
        })),
      });

      notify(t('updated'));

      navigate(-1);
    } catch (error) {
      sendErrorSnack(error);
    } finally {
      setSaving(false);
    }
  };

  const setExperienceAttribute = (experienceId, attribute, value) => {
    setSelectedExperiences(current =>
      current.map(experience => {
        if (experience.id === experienceId) {
          return {
            ...experience,
            [attribute]: value,
          };
        }
        return experience;
      }),
    );
  };

  const isExperienceSelected = experienceId => {
    return selectedExperiences.some(e => e.id === experienceId);
  };

  const unselectExperience = experienceId => {
    setSelectedExperiences(
      selectedExperiences.filter(e => e.id !== experienceId),
    );
  };

  const selectExperience = experience => {
    if (!isExperienceSelected(experience.id)) {
      experience.margin = globalMargin;
      experience.isMarginPercent = isGlobalMarginPercent;
      experience.periods = [];
      setSelectedExperiences([...selectedExperiences, experience]);
    } else {
      unselectExperience(experience.id);
    }
  };

  const selectAllShownExperiences = experienceList => {
    const experiencesNotSelected = experienceList.filter(
      experience => !isExperienceSelected(experience.id),
    );
    experiencesNotSelected.forEach(experience => {
      experience.margin = globalMargin;
      experience.isMarginPercent = isGlobalMarginPercent;
      experience.periods = [];
    });
    setSelectedExperiences([...selectedExperiences, ...experiencesNotSelected]);
  };

  const BrowserHeadings = [
    {
      slug: 'name',
      label: t('experienceName'),
      width: '100px',
      cannotBeReordered: true,
    },
    {
      slug: 'country',
      label: t('countryName'),
      width: '60px',
      cannotBeReordered: true,
    },
    {
      slug: 'city',
      label: t('cityName'),
      width: '100px',
      cannotBeReordered: true,
    },
    {
      slug: 'experienceType',
      label: t('experienceType'),
      width: '50px',
      cannotBeReordered: true,
    },
    {
      slug: 'selected',
      label: t('enabled'),
      width: '40px',
      cannotBeReordered: true,
    },
  ];

  const selectedDatasHeadings = [
    {
      slug: 'name',
      label: t('experienceName'),
      width: '80px',
      cannotBeReordered: true,
    },
    {
      slug: 'country',
      label: t('countryName'),
      width: '60px',
      cannotBeReordered: true,
    },
    {
      slug: 'city',
      label: t('cityName'),
      width: '60px',
      cannotBeReordered: true,
    },
    {
      slug: 'margin',
      label: t('margin'),
      width: '40px',
      cannotBeReordered: true,
    },
    {
      slug: 'isMarginPercent',
      label: t('percent'),
      width: '60px',
      cannotBeReordered: true,
    },
    {
      slug: 'selected',
      label: t('enabled'),
      width: '40px',
      cannotBeReordered: true,
    },
  ];

  const tableExperiences = (experiences || []).map(node => ({
    id: node?.id,
    name: {
      value: node?.name,
    },
    country: {
      value: node?.country,
    },
    city: {
      value: node?.city,
    },
    experienceType: {
      value: node?.experienceType,
    },
    selected: {
      render: () =>
        isAllowedToManage() ? (
          <button
            className="border border-gray-200 rounded-full p-2 text-lg leading-none"
            onClick={() => {
              selectExperience(node);
            }}
          >
            {isExperienceSelected(node?.id) ? (
              <MdRemoveRedEye className="text-orange-500" />
            ) : (
              <MdRemoveRedEye className="text-gray-400" />
            )}
          </button>
        ) : null,
    },
  }));

  const experiencesSelectedDataTable = (selectedExperiences || []).map(
    (node, index) => ({
      name: {
        value: node?.name,
      },
      country: {
        value: node?.country,
      },
      city: {
        value: node?.city,
      },
      margin: {
        render: () => (
          <Input
            type="number"
            value={node?.margin}
            onChange={e =>
              setExperienceAttribute(node.id, 'margin', e.target.value)
            }
            disabled={!isAllowedToManage()}
          />
        ),
      },
      isMarginPercent: {
        render: () => (
          <input
            type="checkbox"
            checked={node?.isMarginPercent}
            onChange={() =>
              setExperienceAttribute(
                node.id,
                'isMarginPercent',
                !node.isMarginPercent,
              )
            }
            disabled={!isAllowedToManage()}
          />
        ),
      },
      selected: {
        render: () =>
          isAllowedToManage() ? (
            <button
              className="border border-gray-200 rounded-full p-2 text-lg leading-none"
              onClick={() => unselectExperience(node.id)}
            >
              <MdRemoveRedEye className="text-orange-500" />
            </button>
          ) : null,
      },
    }),
  );

  const limitPerPage = 25;

  const requestFilters = {
    cities: filters?.location?.kind === 'city' ? [filters?.location?.id] : [],
    countries:
      filters?.location?.kind === 'country' ? [filters?.location?.id] : [],
    search:
      filters?.location?.kind === 'experience' ? filters?.location?.title : '',
    type: filters?.type?.value || '',
    state: 'available',
    page: page,
    perPage: limitPerPage,
  };

  const { data: experiencesList, reload } = useAsync({
    promiseFn: getList,
    filters: requestFilters,
    watchFn: (props, prevProps) => {
      return !isEqual(props.filters, prevProps.filters);
    },
  });

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="px-4">
          <Header request={request} save={save} isLoading={saving} />
          {isAllowedToManage() && (
            <div className="my-5 flex justify-end gap-5">
              <Button type="button" variant="simple" to={-1}>
                {t('misc:cancel')}
              </Button>
              <Button
                onClick={() => save()}
                variant="primary"
                disabled={saving}
              >
                {t('misc:save')}
              </Button>
            </div>
          )}

          <div className="grid sm:grid-cols-2 gap-5">
            <DistributionSelected
              selectedDatasHeadings={selectedDatasHeadings}
              selectedDatas={experiencesSelectedDataTable}
              tabTitle={'selectedExperience'}
            />

            {isAllowedToManage() && (
              <DistributionBrowser
                browserDatas={experiences}
                setBrowerDatas={setExperiences}
                BrowserHeadings={BrowserHeadings}
                browserDatasList={experiencesList}
                selectAllShownItems={selectAllShownExperiences}
                dataTableDatas={tableExperiences}
                globalMargin={globalMargin}
                setGlobalMargin={setGlobalMargin}
                isGlobalMarginPercent={isGlobalMarginPercent}
                setIsGlobalMarginPercent={setIsGlobalMarginPercent}
                formatBrowerData={formatExperienceData}
                setFilters={setFilters}
                setPage={setPage}
                filters={filters}
                limitPerPage={25}
                reload={reload}
                tabTitle={'browseExperience'}
                kind={'experience'}
                searcTranslate={'experiences'}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ToConnectionRequestExperience;
