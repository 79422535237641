import { cva } from 'class-variance-authority';
import { Field } from 'formik';

export default function KoobInput({
  label,
  name,
  type,
  placeholder,
  value,
  onChange,
  disabled,
  ...props
}) {
  const isFormik = name && !onChange;

  const input = cva(
    [
      'form-input',
      'block w-full rounded border border-gray-300 px-2 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400',
      'focus:ring-0 focus:ring-inset hover:border-gray-400 focus:border-gray-400 focus:outline-none sm:text-sm transition',
    ],
    {
      variants: {
        disabled: {
          true: 'opacity-50 pointer-events-none cursor-not-allowed',
        },
      },
    },
  );

  return (
    <div>
      <label className="block text-sm font-medium leading-none text-gray-900">
        {label}
      </label>

      <div className="mt-1.5">
        {isFormik ? (
          <Field
            type={type}
            name={name}
            className={input({ disabled })}
            placeholder={placeholder}
            disabled={disabled}
            {...props}
          />
        ) : (
          <input
            type={type}
            name={name}
            value={value}
            onChange={e => onChange(e.target.value)}
            className={input({ disabled })}
            placeholder={placeholder}
            disabled={disabled}
            {...props}
          />
        )}
      </div>
    </div>
  );
}
