import styled from 'styled-components';
import { Button, Modal } from 'components';
import { IcArchive } from 'components/icons';
import { Stack } from '@tymate/margaret';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnack, useError } from 'hooks';
import { useMutation, useQuery, gql } from '@apollo/client';
import { useTranslation, Trans } from 'react-i18next';

const Warning = styled.div`
  color: ${({ theme }) => theme.danger};
  font-weight: bold;
`;

const THEME = gql`
  query getExperienceTheme($id: ID!) {
    node(id: $id) {
      ... on ExperienceTheme {
        id
        displayName
      }
    }
  }
`;

const ARCHIVE_SETTING = gql`
  mutation discardSetting($input: DiscardSettingInput!) {
    discardSetting(input: $input) {
      setting {
        ... on ExperienceTheme {
          id
        }
      }
      errors {
        message
        attribute
      }
    }
  }
`;

const ArchiveTheme = () => {
  const { themeId } = useParams();
  const navigate = useNavigate();

  const { t } = useTranslation('experienceTheme');
  const { notify } = useSnack();
  const { sendErrorSnack } = useError();

  const { data } = useQuery(THEME, {
    variables: { id: themeId },
  });

  const [discardSetting] = useMutation(ARCHIVE_SETTING, {
    variables: { input: { settingId: themeId } },
  });

  const handleArchive = async () => {
    try {
      const { data } = await discardSetting();

      if (data?.discardSetting?.errors?.length > 0) {
        throw data?.discardSetting?.errors?.[0];
      }

      navigate(-1);
      notify(t('archive.success'));
    } catch (err) {
      sendErrorSnack(err);
    }
  };

  const themeName = data?.node?.displayName?.replace("'", '’');

  return (
    <Modal
      isOpen
      title={t('archive.title', {
        name: themeName,
      })}
      onRequestClose={() => navigate(-1)}
      footer={
        <Stack alignX="flex-end" gutterSize={1}>
          <Button
            onClick={handleArchive}
            variant="danger"
            icon={<IcArchive size={20} />}
          >
            {t('archive.confirm')}
          </Button>
          <Button onClick={() => navigate(-1)} variant="simple">
            {t('misc:cancel')}
          </Button>
        </Stack>
      }
    >
      <Stack direction="column" gutterSize={1} alignX="center">
        <Warning>{t(`archive.heading`)}</Warning>
        <div>
          <Trans i18nKey="experienceTheme:archive.explanation" name={themeName}>
            <strong>{{ name: themeName }}</strong>
          </Trans>
        </div>
      </Stack>
    </Modal>
  );
};

export default ArchiveTheme;
