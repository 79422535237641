import { memo } from 'react';

const IcPhone = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    {...props}
    style={{ ...props.style, color: props.color, fontSize: props.size }}
  >
    <path
      d="M14.533 20.658h.009a4.615 4.615 0 005.733-.633l.642-.65h-.009a1.554 1.554 0 000-2.175l-2.725-2.717h0c-.608-.6-1.575-.6-2.175-.008h0H16c-.6.6-1.575.6-2.175 0l-.008-.008-4.35-4.35h0c-.6-.609-.6-1.575-.009-2.175h0v-.009c.6-.6.6-1.575 0-2.175L9.45 5.75 6.733 3.025h0c-.608-.6-1.575-.6-2.175-.008l-.65.641H3.9a4.603 4.603 0 00-.633 5.734V9.4l-.009-.008c2.975 4.4 6.767 8.2 11.175 11.175l.1.091z"
      stroke="currentColor"
      strokeWidth={1.25}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default memo(IcPhone);
