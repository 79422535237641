import { Autocomplete } from '@react-google-maps/api';
import { Input } from '../ui';
import { useState } from 'react';
import { useField } from 'formik';

export default function MapsPlaceSearchField({ name, multiple = false }) {
  const [value, , { setValue }] = useField(name);
  const [autocomplete, setAutocomplete] = useState(null);
  const [inputValue, setInputValue] = useState('');

  const onLoad = autocomplete => {
    setAutocomplete(autocomplete);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      const formattedPlace = {
        name: place.name,
        address: place.formatted_address,
        city: place.address_components?.find(c => c.types.includes('locality'))
          ?.long_name,
        country: place.address_components?.find(c =>
          c.types.includes('country'),
        )?.long_name,
        lat: place.geometry.location.lat(),
        lon: place.geometry.location.lng(),
        isCity: place.types.includes('locality'),
      };

      if (multiple) {
        if (value?.value) {
          setValue([...value?.value, formattedPlace]);
        } else {
          setValue([formattedPlace]);
        }
        setInputValue('');
      } else {
        setInputValue(formattedPlace.name);
        setValue(formattedPlace);
      }
    }
  };

  return (
    <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
      <Input
        type="text"
        value={inputValue}
        onChange={e => setInputValue(e.target.value)}
      />
    </Autocomplete>
  );
}
