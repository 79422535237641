import { memo } from 'react';

const IcSvgUpload = props => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    style={{ ...props.style, color: props.color, fontSize: props.size }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.375 12a4.375 4.375 0 100 8.75 4.375 4.375 0 000-8.75zm-5.625 4.375a5.625 5.625 0 1111.25 0 5.625 5.625 0 01-11.25 0z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.375 13.25c.345 0 .625.28.625.625v5a.625.625 0 11-1.25 0v-5c0-.345.28-.625.625-.625z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.817 13.433a.625.625 0 010 .884l-1.875 1.875a.625.625 0 11-.884-.884l1.875-1.875a.625.625 0 01.884 0z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.933 13.433a.625.625 0 01.884 0l1.875 1.875a.625.625 0 11-.884.884l-1.875-1.875a.625.625 0 010-.884zM3.875 3.25a.625.625 0 00-.625.625v13.75c0 .345.28.625.625.625h5a.625.625 0 110 1.25h-5A1.875 1.875 0 012 17.625V3.875C2 2.839 2.84 2 3.875 2h8.857c.498 0 .974.198 1.326.549l2.393 2.393c.351.352.549.828.549 1.325v2.608a.625.625 0 11-1.25 0V6.268a.625.625 0 00-.183-.442l-2.393-2.393a.624.624 0 00-.441-.183H3.874z"
      fill="currentColor"
    />
  </svg>
);

export default memo(IcSvgUpload);
