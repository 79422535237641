import { post } from './';
import { CLIENT_ID } from '../constants';

export const login = payload =>
  post(`/api/v1/oauth/token`, {
    ...payload,
    clientId: CLIENT_ID,
    grantType: 'password',
    scopes: 'bo',
  });

export const refreshToken = () =>
  post(`/api/v1/oauth/token`, {
    refreshToken: localStorage.getItem('refreshToken'),
    clientId: CLIENT_ID,
    grantType: 'refresh_token',
  });
