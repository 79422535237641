import { lazy, useContext } from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';
import { AuthContext } from 'contexts';
import Layout from 'components/Layout';
import AppProvider from 'providers/AppProvider';
import RoutesProvider from 'providers/RoutesProvider';
import { LoadScript } from '@react-google-maps/api';
import { GOOGLE_MAPS_API_KEY } from './constants';
import ToConnectionRequest from './containers/ToConnectionRequest';
import ExperienceSettingsRoutes from './containers/ExperiencesSettings/routes';
import ExperiencesRoutes from 'containers/Experiences/routes';
import BookingProvider from './providers/BookingProvider';
import TripDesignerProvider from 'providers/TripDesignerProvider';

const Users = lazy(() => import('containers/Users'));
const HotelsSettings = lazy(() => import('containers/HotelsSettings'));
const Hotels = lazy(() => import('containers/Hotels'));
const TravelersSettings = lazy(() => import('containers/TravelersSettings'));
const Locations = lazy(() => import('containers/Locations'));
const Organizations = lazy(() => import('containers/Organizations'));
const Billing = lazy(() => import('containers/Billing'));
const Bookings = lazy(() => import('containers/Bookings'));
const ToRequestAccess = lazy(() => import('containers/ToRequestAccess'));
const TripDesigner = lazy(() => import('containers/TripDesigner'));

const libraries = ['places'];

const App = () => {
  const { accessToken } = useContext(AuthContext);

  if (Boolean(!accessToken)) {
    return <Navigate to="/account-type" />;
  }

  return (
    <RoutesProvider>
      <AppProvider>
        <BookingProvider>
          <TripDesignerProvider>
            <Layout>
              <LoadScript
                googleMapsApiKey={GOOGLE_MAPS_API_KEY}
                language={'en'}
                libraries={libraries}
              >
                <Routes>
                  <Route path="organizations/*" element={<Organizations />} />
                  <Route path="locations/*" element={<Locations />} />
                  <Route path="users/*" element={<Users />} />
                  <Route path="hotels/*" element={<Hotels />} />
                  <Route path="experiences/*" element={<ExperiencesRoutes />} />
                  <Route path="billing/*" element={<Billing />} />
                  <Route path="bookings/*" element={<Bookings />} />
                  <Route path="trip-designer/*" element={<TripDesigner />} />
                  <Route
                    path="to-request-access/*"
                    element={<ToRequestAccess />}
                  />
                  <Route
                    path="to-connection-request/*"
                    element={<ToConnectionRequest />}
                  />
                  <Route
                    path="settings/hotels/*"
                    element={<HotelsSettings />}
                  />
                  <Route
                    path="settings/experiences/*"
                    element={<ExperienceSettingsRoutes />}
                  />
                  <Route
                    path="settings/travelers/*"
                    element={<TravelersSettings />}
                  />
                  <Navigate to="hotels" />
                </Routes>
              </LoadScript>
            </Layout>
          </TripDesignerProvider>
        </BookingProvider>
      </AppProvider>
    </RoutesProvider>
  );
};

export default App;
