import styled from 'styled-components';
import { IcHotelTower } from './icons';

const Img = styled.img`
  display: block;
  object-fit: contain;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
`;

const FacilityIcon = ({ facility, size = 20 }) => {
  if (Boolean(facility?.iconUrl)) {
    return (
      <Img size={size} src={facility?.iconUrl} alt={facility?.displayName} />
    );
  }

  return <IcHotelTower size={size} />;
};

export default FacilityIcon;
