import { Tag } from 'ui';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { IoWarningOutline } from 'react-icons/io5';
import { Tooltip } from 'components';

const getVariantDependingOnState = state => {
  const variantByState = {
    primary: ['pending'],
    green: ['confirmed', 'validated'],
    red: ['refused'],
  };

  for (const variant in variantByState) {
    if (variantByState[variant].includes(state)) {
      return { variant, state };
    }
  }
  return { variant: 'primary', state };
};

const IconState = ({ state }) => {
  const iconClasses = {
    confirmed: 'fa-solid fa-check text-green-500 text-lg',
    pending: 'fa-regular fa-circle text-orange-500 text-lg',
    refused: 'fa-regular fa-times text-red-500 text-lg',
  };

  const getIcon = () => {
    const iconClass = iconClasses[state];
    return iconClass ? <i className={`fa ${iconClass}`}></i> : null;
  };

  return <div>{getIcon()}</div>;
};

const RequestToState = ({ state, iconType }) => {
  const theme = useTheme();
  const { t } = useTranslation('toConnectionRequest');
  const tagInfo = getVariantDependingOnState(state);

  return (
    <>
      {state ? (
        <>
          {iconType ? (
            <Tooltip tip={t(state)}>
              <IconState state={state} />
            </Tooltip>
          ) : (
            <Tag variant={tagInfo.variant}>
              {state === 'pending' && (
                <IoWarningOutline size={16} color={theme.primary} />
              )}
              {t(tagInfo.state)}
            </Tag>
          )}
        </>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default RequestToState;
