import React from 'react';

export default function KoobCard({ className, padding = 'p-6', children }) {
  return (
    <div
      className={`relative bg-white ${padding} rounded-md border ${className}`}
    >
      {children}
    </div>
  );
}
