import React, { useCallback, useState } from 'react';
import { MdRemoveRedEye } from 'react-icons/md';
import { Input, Text } from '../../../../ui';
import { Select } from '../../../../components/Fields';
import { Button, DataTable } from '../../../../components';
import { useSnack } from '../../../../hooks';
import { useTranslation } from 'react-i18next';
import { useDeepCompareEffect } from 'react-use';
import { get } from 'api/node';
import { Stack } from '@tymate/margaret';
import { useEffect } from 'react';
import LocationSearchableSelect from 'containers/Experiences/List/partials/LocationSearchableSelect';

const searchLocations = ({ search }) => {
  return get(`/locations?search=${search}`);
};

export default function DistributionBrowser({
  browserDatas,
  setBrowerDatas,
  selectAllShownItems,
  globalMargin,
  setGlobalMargin,
  isGlobalMarginPercent,
  setIsGlobalMarginPercent,
  BrowserHeadings,
  dataTableDatas,
  browserDatasList,
  setFilters,
  setPage,
  filters,
  reload,
  formatBrowerData,
  kind,
  tabTitle,
  searcTranslate,
  limitPerPage = 25,
}) {
  const { t } = useTranslation('toConnectionRequest');
  const { notify } = useSnack();

  const [hasNextPage, setHasNextPage] = useState(true);

  const updateFilterValue = useCallback(
    (property, value) => {
      setFilters(current => ({
        ...current,
        [property]: value,
      }));
    },
    [setFilters],
  );

  useDeepCompareEffect(() => {
    setPage(1);
    setHasNextPage(true);
    setBrowerDatas([]);
    reload();
  }, [filters]);

  useEffect(() => {
    const formattedExperience = formatBrowerData(browserDatasList?.data);
    setBrowerDatas([...browserDatas, ...formattedExperience]);
    if (browserDatasList?.data.length > limitPerPage) {
      setHasNextPage(true);
    }
    if (browserDatasList?.data.length < limitPerPage) {
      setHasNextPage(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [browserDatasList, setHasNextPage, setBrowerDatas]);

  const handleLoadNextPage = () => {
    setPage(page => page + 1);
  };
  return (
    <div>
      <Text type="h3">{t(tabTitle)}</Text>

      <div className="my-4 p-5 border rounded">
        <div className="mb-2 flex justify-between">
          <h6 className="font-medium">
            {t('globalMargin', { unit: isGlobalMarginPercent ? '(%)' : '' })}
          </h6>

          <div className="flex items-center space-x-1">
            <input
              type="checkbox"
              checked={isGlobalMarginPercent}
              onChange={() => setIsGlobalMarginPercent(!isGlobalMarginPercent)}
            />
            <p className="text-sm">{t('usePercentMargin')}</p>
          </div>
        </div>

        <Input
          type="number"
          min={0}
          value={globalMargin}
          onChange={v => setGlobalMargin(parseFloat(v.target.value))}
        />
      </div>

      <div className="flex justify-between">
        <LocationSearchableSelect
          placeholder={t('locationsSearch', { ns: searcTranslate })}
          query={searchLocations}
          pathToEdges={['locationsSearch']}
          renderSelectedOption={value => value?.title}
          onChange={value => updateFilterValue('location', value)}
          wrapperStyle={{
            marginLeft: 0,
            width: 350,
            height: 42,
            '--location-search-trigger-height': `${42}px`,
          }}
          inputStyle={{ width: 350, height: 42 }}
          deletable={true}
          kind={kind}
          source={'distribution'}
        />
        <div className="flex align-end justify-center">
          <div style={{ marginRight: 5 }}>
            <button
              className="border border-gray-200 rounded-full p-2 text-lg leading-none"
              onClick={() => {
                if (
                  window.confirm(t('selectAllExperienceConfirmationMessage'))
                ) {
                  selectAllShownItems(browserDatas);
                  notify(t('selectedAllExperiencesuccess'));
                }
              }}
            >
              <MdRemoveRedEye class="text-gray-400" />
            </button>
          </div>

          {kind === 'experience' && (
            <Select
              value={filters?.type}
              onChange={value => {
                updateFilterValue('type', value);
              }}
              name="Topology"
              options={[
                { label: t('allExperiences'), value: undefined },
                { label: t('activity'), value: 'Activity' },
                { label: t('transfer'), value: 'Transfer' },
                { label: t('program'), value: 'Program' },
                { label: t('airTransfer'), value: 'AIR' },
                { label: t('railTransfer'), value: 'RAILS' },
                { label: t('landTransfer'), value: 'LAND' },
                { label: t('boatTransfer'), value: 'BOAT' },
              ]}
            ></Select>
          )}
        </div>
      </div>

      <DataTable
        headings={BrowserHeadings}
        data={dataTableDatas}
        isSearchable={false}
      />
      <Stack direction="column" size="full" alignX="center" gutterSize={1}>
        {hasNextPage && (
          <Button variant="simple" onClick={handleLoadNextPage}>
            {t('misc:seeMore')}
          </Button>
        )}
      </Stack>
    </div>
  );
}
