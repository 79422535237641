import { memo } from 'react';

const IcDrag = props => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    style={{ ...props.style, fontSize: props.size, color: props.color }}
  >
    <path
      d="M9.5 8a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM14.5 8a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM9.5 14a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM14.5 14a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM9.5 20a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM14.5 20a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
      fill="currentColor"
    />
  </svg>
);

export default memo(IcDrag);
