import { useField } from 'formik';
import styled from 'styled-components';
import { IoMdBed } from 'react-icons/io';
import {
  Stack,
  PopoverItem as RawPopoverItem,
  PopoverMenu as RawPopoverMenu,
} from '@tymate/margaret';
import { FormField, Text, ActionButton } from 'ui';
import { IcBin } from 'components/icons';
import Stepper from 'components/Stepper';

const PopoverMenu = styled(RawPopoverMenu)`
  position: static;
  border: solid 1px ${({ theme }) => theme.separator};
  box-shadow: 0 1px 2px 0 ${({ theme }) => theme.shadow};
  border-radius: 8px;
  max-height: 350px;
  &:hover {
    border: solid 1px ${({ theme }) => theme.darkSeparator};
  }
`;

const PopoverItem = styled(RawPopoverItem)`
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing()};
  color: ${({ theme }) => theme.textLight};
`;

const EditSelectedFacilitiesField = ({ disabled, name }) => {
  const [{ value: beds }, , { setValue }] = useField({ name });

  const handleBedCountChange = (selectedBedIndex, newBedCount) => {
    const draft = [...beds];

    draft[selectedBedIndex].bedCount = newBedCount;
    setValue(draft);
  };

  const handleDeleteBed = selectedBedIndex => {
    const draft = [...beds];

    draft.splice(selectedBedIndex, 1);
    setValue(draft);
  };

  return (
    <FormField>
      {beds.length > 0 && (
        <PopoverMenu>
          {beds.map(({ bedId, displayName, bedCount }, index) => (
            <PopoverItem key={bedId}>
              <Stack
                gutterSize={0.5}
                alignY="center"
                alingX="space-between"
                size="full"
              >
                <Stack gutterSize={0.5} alignY="center" size="full">
                  <IoMdBed size={24} />
                  <Text type="body">{displayName}</Text>
                </Stack>

                {!disabled ? (
                  <Stack gutterSize={1.5} alignY="center">
                    <Stepper
                      value={bedCount}
                      onChange={newBedCount => {
                        handleBedCountChange(index, newBedCount);
                      }}
                    />
                    <ActionButton
                      variant="danger"
                      onClick={() => handleDeleteBed(index)}
                    >
                      <IcBin />
                    </ActionButton>
                  </Stack>
                ) : (
                  <Stack>
                    <Text type="body">{bedCount}</Text>
                  </Stack>
                )}
              </Stack>
            </PopoverItem>
          ))}
        </PopoverMenu>
      )}
    </FormField>
  );
};

export default EditSelectedFacilitiesField;
