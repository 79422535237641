import moment from 'moment';

export const createPromotionPeriodFactory = ({
  startAt = undefined,
  endAt = undefined,
  days = {
    Mo: false,
    Tu: false,
    We: false,
    Th: false,
    Fr: false,
    Sa: false,
    Su: false,
  },
} = {}) => ({
  startAt,
  endAt,
  days,
});

const formatDays = days =>
  Object.entries(days).reduce((acc, curr) => {
    const [key, selected] = curr;
    const Days = {
      Mo: 'Monday',
      Tu: 'Tuesday',
      We: 'Wednesday',
      Th: 'Thursday',
      Fr: 'Friday',
      Sa: 'Saturday',
      Su: 'Sunday',
    };
    if (selected) {
      const currentDay = Days[key];
      if (typeof currentDay !== 'undefined') {
        return acc.concat(currentDay);
      }
    }
    return acc;
  }, []);

export const mapFormatToApiDaysInput = ({
  startAt,
  endAt,
  days,
  bookingPeriods,
}) => ({
  startDate: startAt,
  endDate: endAt,
  availableDays: formatDays(days),
  promotionBookingPeriods: (bookingPeriods ?? []).map(bookingPeriod => ({
    startDate: bookingPeriod?.startAt,
    endDate: bookingPeriod?.endAt,
    availableDays: formatDays(bookingPeriod?.days),
  })),
});

export const mapApiResponseToFormValues = ({
  startDate,
  endDate,
  availableDays,
  ageRanges,
  paxRanges,
  promotionBookingPeriods,
}) => {
  const Days = {
    Monday: 'Mo',
    Tuesday: 'Tu',
    Wednesday: 'We',
    Thursday: 'Th',
    Friday: 'Fr',
    Saturday: 'Sa',
    Sunday: 'Su',
  };
  return {
    startAt: startDate,
    endAt: moment(endDate).subtract(1, 'days').format('YYYY-MM-DD'),
    days: Object.keys(Days).reduce((acc, curr) => {
      acc[Days[curr]] = availableDays.includes(curr);
      return acc;
    }, {}),
    ageRanges: (ageRanges ?? []).map(range => {
      return {
        ageRange: [range.ageRange[0], range.ageRange[1]],
        value: range.value,
      };
    }),
    paxRanges: (paxRanges ?? []).map(range => {
      return {
        paxRange: [range.paxRange[0], range.paxRange[1]],
        value: range.value,
      };
    }),
    bookingPeriods: (promotionBookingPeriods?.nodes ?? []).map(
      promoBookingPeriod => ({
        startAt: promoBookingPeriod?.startDate,
        endAt: moment(promoBookingPeriod?.endDate)
          .subtract(1, 'days')
          .format('YYYY-MM-DD'),
        days: Object.keys(Days).reduce((acc, curr) => {
          acc[Days[curr]] = promoBookingPeriod?.availableDays.includes(curr);
          return acc;
        }, {}),
      }),
    ),
  };
};

export const mapUpgradedRoomApiToFormValue = item => {
  if (item) {
    return {
      label: `${item?.displayName} (${item?.count})`,
      value: item?.id,
    };
  }
};
