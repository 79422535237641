import React from 'react';

export default function ExperienceTransferTypeIcon({
  type,
  sizeClass = 'h-16 w-16',
}) {
  switch (type) {
    case 'LAND':
      return (
        <svg className={sizeClass} viewBox="0 0 512 512">
          <path d="M127.7 106.8L103.4 176H408.6l-24.2-69.2c-5.6-16-20.8-26.8-37.8-26.8H165.4c-17 0-32.1 10.7-37.8 26.8zm-79.6 82L82.3 90.9C94.7 55.6 128 32 165.4 32H346.6c37.4 0 70.7 23.6 83.1 58.9l34.3 97.9C492.6 205.4 512 236.4 512 272v80 48 56c0 13.3-10.7 24-24 24s-24-10.7-24-24V400H48v56c0 13.3-10.7 24-24 24s-24-10.7-24-24V400 352 272c0-35.6 19.3-66.6 48.1-83.2zM416 224H96c-26.5 0-48 21.5-48 48v80H464V272c0-26.5-21.5-48-48-48zM112 256a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm256 32a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z" />
        </svg>
      );
    case 'AIR':
      return (
        <svg className={sizeClass} viewBox="0 0 576 512">
          <path d="M562.7 221.2C570.3 230.8 576 242.6 576 256C576 282.9 554.4 303.1 534.2 315.2C513.1 327.9 486.9 336 466.3 336H365.9L279.1 487.8C270.6 502.8 254.7 512 237.4 512H181.2C159.1 512 144.6 491.7 150.4 471.2L189.1 336H136L97.6 387.2C91.56 395.3 82.07 400 72 400H30.03C13.45 400 0 386.6 0 369.1C0 367.2 .3891 364.4 1.156 361.7L31.36 256L1.156 150.3C.3888 147.6 0 144.8 0 142C0 125.4 13.45 112 30.03 112H72C82.07 112 91.56 116.7 97.6 124.8L136 176H189.1L150.4 40.79C144.6 20.35 159.1 0 181.2 0H237.4C254.7 0 270.6 9.23 279.1 24.19L365.9 176H466.3C486.1 176 513.3 184.4 534.3 197.2C544.9 203.7 555 211.7 562.7 221.2L562.7 221.2zM517.7 224.6C500.4 214.1 479.8 208 466.3 208H356.6C350.8 208 345.5 204.9 342.7 199.9L251.3 40.06C248.5 35.08 243.2 32 237.4 32H181.2L225.7 187.6C227 192.4 226.1 197.6 223.1 201.6C220 205.6 215.3 208 210.3 208H128C122.1 208 118.2 205.6 115.2 201.6L72 144H32.64L63.38 251.6C64.21 254.5 64.21 257.5 63.38 260.4L32.64 368H72L115.2 310.4C118.2 306.4 122.1 304 128 304H210.3C215.3 304 220 306.4 223.1 310.4C226.1 314.4 227 319.6 225.7 324.4L181.2 480H237.4C243.2 480 248.5 476.9 251.3 471.9L342.7 312.1C345.5 307.1 350.8 304 356.6 304H466.3C479.9 304 500.5 298.1 517.7 287.8C535.8 276.9 544 265.1 544 256C544 251.9 542.3 246.1 537.7 241.2C533.1 235.5 526.2 229.7 517.7 224.6L517.7 224.6zM265.2 32.12L251.3 40.06z" />
        </svg>
      );
    case 'RAIL':
      return (
        <svg className={sizeClass} viewBox="0 0 448 512">
          <path d="M192 336C192 318.3 206.3 304 224 304C241.7 304 256 318.3 256 336C256 353.7 241.7 368 224 368C206.3 368 192 353.7 192 336zM352 0C405 0 448 42.98 448 96V352C448 398 415.6 436.4 372.4 445.8L411.3 484.7C417.6 490.9 417.6 501.1 411.3 507.3C405.1 513.6 394.9 513.6 388.7 507.3L329.4 448H118.6L59.31 507.3C53.07 513.6 42.94 513.6 36.69 507.3C30.44 501.1 30.44 490.9 36.69 484.7L75.55 445.8C32.36 436.4 0 398 0 352V96C0 42.98 42.98 0 96 0H352zM352 32H96C60.65 32 32 60.65 32 96V224H416V96C416 60.65 387.3 32 352 32zM96 416H352C387.3 416 416 387.3 416 352V256H32V352C32 387.3 60.65 416 96 416z" />
        </svg>
      );
    case 'BOAT':
      return (
        <svg className={sizeClass} viewBox="0 0 576 512">
          <path d="M336 0C362.5 0 384 21.49 384 48V64H432C458.5 64 480 85.49 480 112V237.3L513.3 248.1C548.1 259.3 558 303.8 531.4 328.8L442.9 411.7C436.5 417.7 426.4 417.4 420.3 410.9C414.3 404.5 414.6 394.4 421.1 388.3L509.5 305.4C518.4 297.1 515.1 282.3 503.5 278.5L304 213.1V384C304 392.8 296.8 400 287.1 400C279.2 400 271.1 392.8 271.1 384V213.1L72.53 278.5C60.94 282.3 57.62 297.1 66.51 305.4L154.9 388.3C161.4 394.4 161.7 404.5 155.7 410.9C149.6 417.4 139.5 417.7 133.1 411.7L44.63 328.8C17.96 303.8 27.9 259.3 62.68 248.1L95.1 237.3V112C95.1 85.49 117.5 64 143.1 64H191.1V48C191.1 21.49 213.5 0 239.1 0L336 0zM352 64V48C352 39.16 344.8 32 336 32H239.1C231.2 32 223.1 39.16 223.1 48V64H352zM143.1 96C135.2 96 127.1 103.2 127.1 112V226.9L273.2 179.1C282.8 176.9 293.2 176.9 302.8 179.1L448 226.9V112C448 103.2 440.8 96 432 96H143.1zM133.8 458.8C150.3 470 171.1 479.1 191.1 479.1C212.9 479.1 233.6 470 250.1 458.8C259.9 452.1 268.2 444.8 276.1 436.9C282.6 430.4 293.3 430.4 299.8 436.9C307.8 444.8 315.1 452.1 325.8 458.8C342.3 470 363.1 479.1 383.1 479.1C404.9 479.1 425.6 470 442.2 458.8C451.9 452.1 460.2 444.8 468.1 436.9C474.6 430.4 485.3 430.4 491.8 436.9C499.8 444.8 507.1 452.1 517.8 458.8C530.6 467.5 545.9 475.4 561.9 478.6C569.6 480.1 576 486.4 576 494.2C576 504 567.1 512.1 558.3 510.4C535.2 506.5 514.7 495.4 499.8 485.2C491.8 479.8 485.1 474.4 479.1 469.1C474.8 474.4 468.1 479.8 460.2 485.2C441.5 497.9 414.3 511.1 383.1 511.1C353.7 511.1 326.4 497.9 307.8 485.2C299.8 479.8 293.1 474.4 287.1 469.1C282.8 474.4 276.1 479.8 268.2 485.2C249.5 497.9 222.3 511.1 191.1 511.1C161.7 511.1 134.4 497.9 115.8 485.2C107.8 479.8 101.1 474.4 95.96 469.1C90.82 474.4 84.12 479.8 76.19 485.2C61.25 495.4 40.81 506.5 17.69 510.4C8.015 512.1 0 504 0 494.2C0 486.4 6.421 480.1 14.13 478.6C30.07 475.4 45.38 467.5 58.16 458.8C67.94 452.1 76.16 444.8 84.07 436.9C90.64 430.4 101.3 430.4 107.8 436.9C115.8 444.8 124.1 452.2 133.8 458.8H133.8z" />
        </svg>
      );
    default:
      return null;
  }
}
