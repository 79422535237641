import { memo } from 'react';

const IcCheckmark = props => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    style={{ ...props.style, fontSize: props.size, color: props.color }}
  >
    <g fill="currentColor" fillRule="nonzero">
      <path d="M13.758 6.086a.521.521 0 01.87.567l-.052.08-5.44 6.88a1.247 1.247 0 01-1.947.059l-.08-.108-1.7-2.414a.521.521 0 01.79-.672l.06.072 1.71 2.424c.063.096.19.121.288.056l.027-.022 5.474-6.922z" />
      <path d="M10 1.666a8.334 8.334 0 100 16.668 8.334 8.334 0 000-16.668zm0 1.042a7.292 7.292 0 110 14.584 7.292 7.292 0 010-14.584z" />
    </g>
  </svg>
);

export default memo(IcCheckmark);
