import KoobCard from '../../../../components/Koob/KoobCard';
import BulkEditRequestsSelectorCheckbox from './BulkEditRequestsSelectorCheckbox';
import KoobButton from '../../../../components/Koob/KoobButton';
import KoobBadge from '../../../../components/Koob/KoobBadge';
import { Input } from '../../../../ui';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useField } from 'formik';
import KoobTitle from '../../../../components/Koob/KoobTitle';
import Select from 'react-select';
import { getMarkets } from 'api/node/markets';
import { useAsync } from 'react-async';
import { getToConnectionRequest } from 'api/node/toConnectionRequest';
import { isEqual } from 'lodash';
import KoobPlaceholder from 'components/Koob/KoobPlaceholder';

export default function BulkEditRequestsSelector() {
  const { t } = useTranslation('toConnectionRequest');
  const [{ value: selectedRequests }, , { setValue: setSelectedRequests }] =
    useField('toConnectionRequestIds');

  const [search, setSearch] = useState('');

  const [toConnectionRequestFilters, setToConnectionRequestFilters] =
    useState(null);

  const filters = {
    markets: (toConnectionRequestFilters?.markets ?? []).map(({ id }) => id),
  };

  const { data, isLoading } = useAsync({
    promiseFn: getToConnectionRequest,
    filters,
    watchFn: (props, prevProps) => {
      return !isEqual(props.filters, prevProps.filters);
    },
  });
  const connectionRequests = data?.data || [];

  const { data: marketsData } = useAsync({
    promiseFn: getMarkets,
    filters: {
      order: 'name',
      direction: 'asc',
      page: 0,
      pageSize: 0,
    },
  });

  const markets = (marketsData?.data ?? []).map(({ id, name }) => {
    return {
      id,
      displayName: name,
    };
  });

  const searchResults = connectionRequests.filter(request => {
    return (
      request?.toorganization?.displayName
        ?.toLowerCase()
        ?.includes(search.toLowerCase()) ||
      request?.state?.toLowerCase()?.includes(search.toLowerCase()) ||
      request?.type?.toLowerCase()?.includes(search.toLowerCase())
    );
  });

  const selectAll = () => {
    if (search?.length > 0) {
      setSelectedRequests([
        ...selectedRequests,
        ...searchResults.map(request => request.id),
      ]);
    } else {
      setSelectedRequests(connectionRequests.map(request => request.id));
    }
  };

  const deselectAll = () => {
    if (search?.length > 0) {
      setSelectedRequests(
        selectedRequests.filter(
          id => !searchResults.map(request => request.id).includes(id),
        ),
      );
    } else {
      setSelectedRequests([]);
    }
  };

  const updateFilterValue = useCallback(
    (property, value) => {
      setToConnectionRequestFilters(current => ({
        ...current,
        [property]: value,
      }));
    },
    [setToConnectionRequestFilters],
  );

  return (
    <KoobCard>
      <div className="flex-col space-y-5">
        <div className="flex justify-between items-center">
          <KoobTitle>{t('bulkEdit.requestSelector.title')}</KoobTitle>
        </div>

        <div className="flex space-x-3">
          <KoobButton
            onClick={() => selectAll()}
            size="sm"
            disabled={selectedRequests.length === connectionRequests.length}
          >
            {t('bulkEdit.requestSelector.selectAll')}
          </KoobButton>

          <KoobButton
            onClick={() => deselectAll()}
            size="sm"
            disabled={selectedRequests.length === 0}
          >
            {t('bulkEdit.requestSelector.deselectAll')}
          </KoobButton>

          <KoobBadge color="gray" size="sm">
            {t('bulkEdit.requestSelector.selectedCount', {
              count: selectedRequests.length,
            })}
          </KoobBadge>
        </div>

        <div className="grid grid-cols-2 gap-3">
          <Input
            name="search"
            value={search}
            onChange={e => setSearch(e.target.value)}
            placeholder={t('bulkEdit.requestSelector.search')}
          />

          <Select
            placeholder={t('bulkEdit.requestSelector.searchByMarkets')}
            name="markets"
            options={markets}
            getOptionValue={value => value?.id}
            getOptionLabel={value => value?.displayName}
            onChange={value => {
              updateFilterValue('markets', value);
            }}
            styles={{
              control: baseStyles => ({
                ...baseStyles,
                borderColor: 'none',
                boxShadow: 'none',
                '&:hover': {
                  boxShadow: '0 0 0 1px lightgrey',
                },
              }),
            }}
            className="basic-multi-select"
            isMulti
          />
        </div>

        <div className="h-56 overflow-scroll bg-gray-50 p-5 rounded-md">
          <div className="grid md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-5">
            {!isLoading ? (
              <>
                {searchResults?.map(request => (
                  <BulkEditRequestsSelectorCheckbox
                    key={request.id}
                    request={request}
                    selected={selectedRequests.includes(request.id)}
                    setSelected={selected => {
                      if (selected) {
                        setSelectedRequests([...selectedRequests, request.id]);
                      } else {
                        setSelectedRequests(
                          selectedRequests.filter(id => id !== request.id),
                        );
                      }
                    }}
                  />
                ))}
              </>
            ) : (
              <>
                {[...Array(12)].map((_e, index) => (
                  <KoobPlaceholder className="h-10" key={index} />
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </KoobCard>
  );
}
