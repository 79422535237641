import Select from 'components/Fields/Select';
import { useField } from 'formik';
import { isObject } from 'lodash';
import { FormField, FormLabel, ErrorMessageWrapper } from 'ui/forms';

const SelectField = ({
  label,
  renderOption,
  onAfterSelect,
  withModal,
  touchRequired = true,
  required,
  ...props
}) => {
  const [field, meta, { setValue, setTouched }] = useField(props);

  const hasError =
    (!touchRequired || Boolean(meta.touched)) && Boolean(meta.error);

  return (
    <FormField>
      {label && (
        <FormLabel>
          {label}
          {required && <span className="ml-1 text-red-500">*</span>}
        </FormLabel>
      )}

      <Select
        {...props}
        {...field}
        hasError={hasError}
        onChange={value => {
          setValue(value);
          setTimeout(() => setTouched(true, true));
          if (Boolean(onAfterSelect)) {
            onAfterSelect(value?.value);
          }
        }}
        renderOption={value => value?.label}
      />
      {hasError && (
        <ErrorMessageWrapper>
          {isObject(meta.error) ? meta.error?.value : meta.error}
        </ErrorMessageWrapper>
      )}
    </FormField>
  );
};

export default SelectField;
