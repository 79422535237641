import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ExperiencesList from './index';
import ExperienceCreate from './Form/ExperienceCreate';
import ExperienceEdit from './Form/ExperienceEdit';

export default function ExperiencesRoutes() {
  return (
    <Routes>
      <Route path="/" element={<ExperiencesList />} />
      <Route path="create" element={<ExperienceCreate />} />
      <Route path="edit/:experienceId/*" element={<ExperienceEdit />} />
    </Routes>
  );
}
