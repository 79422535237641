import { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { Box, media, Stack, MainNav, useMargaret } from '@tymate/margaret';
import Breadcrumb from 'components/Breadcrumb';
import NotificationsDropdown from 'components/NotificationsDropdown';
import GoToTrip from './Trip/GoToTrip';

export const HeaderBase = styled(Box).attrs({ paddingVertical: 1 })`
  min-height: 80px;
  background-color: #ffffff;
  box-shadow: inset 0 -1px 0 ${({ theme }) => theme.separator};
  padding-left: ${({ theme }) => theme.spacing()};
  padding-right: ${({ theme }) => theme.spacing()};
  display: flex;
  align-items: center;
  z-index: 10;

  ${({ variant }) =>
    variant === 'fixed' &&
    css`
      position: fixed;
      left: ${({ theme }) => theme.mainNav.width};
      top: 0;
      right: 0;
    `}

  ${media.tablet`
    left: ${({ theme }) => theme.mainNav.width};
    padding-left: ${({ theme }) => theme.spacing(2)};
    padding-right: ${({ theme }) => theme.spacing(2)};
  `}
`;

const Trigger = styled.div`
  position: relative;
  z-index: 2;
`;

const Header = (
  { leftElement = <Breadcrumb />, rightElement, children, variant },
  ref,
) => {
  const { isMobile } = useMargaret();

  return (
    <HeaderBase variant={variant} ref={ref}>
      <Stack direction="column" size="full" gutterSize={1}>
        <Stack
          size="full"
          alignX="space-between"
          alignY="center"
          gutterSize={1}
        >
          <Stack alignY="center" gutterSize={1}>
            {isMobile && (
              <Trigger>
                <MainNav.Trigger />
              </Trigger>
            )}
            <Stack>{leftElement}</Stack>
          </Stack>
          <Stack alignY="center" gutterSize={1}>
            {rightElement}
            {window.location.pathname.includes('/trip-designer/compose') && (
              <GoToTrip />
            )}
            <NotificationsDropdown />
          </Stack>
        </Stack>
        {children}
      </Stack>
    </HeaderBase>
  );
};

export default forwardRef(Header);
