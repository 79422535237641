import styled, { css, useTheme } from 'styled-components';
import { Stack, Button } from '@tymate/margaret';
import { CardTitle, SectionCard, Text } from 'ui';
import { FaBaby } from 'react-icons/fa';
import { HiOutlineUserCircle } from 'react-icons/hi';
import { HeaderBase } from 'components/Header';
import { MdChildCare } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { BsBuilding } from 'react-icons/bs';

const UI = {
  Wrapper: styled(Stack).attrs({ direction: 'row', gutterSize: 2 })``,
  CardTitle: styled(CardTitle)`
    margin-block: 0;
    display: flex;
    width: 100%;
    font-size: 1.2rem;
  `,
  UserIcon: ({ kind = 'adult', color = 'inherit' }) => {
    return (
      <Stack>
        {kind === 'infant' && (
          <FaBaby style={{ color }} aria-hidden="true" size={24} />
        )}
        {kind === 'child' && (
          <MdChildCare style={{ color }} aria-hidden="true" size={24} />
        )}
        {kind === 'adult' && (
          <HiOutlineUserCircle style={{ color }} aria-hidden="true" size={24} />
        )}
      </Stack>
    );
  },
  DetailsSectionCard: styled(SectionCard).attrs({ as: 'article' })`
    display: grid;
    grid-auto-flow: row;
    gap: ${({ theme }) => theme.spacing(0.5)};
    ul {
      padding: 0;
      list-style: none;
      padding: none;
      margin: 0;
    }
  `,
  DetailSectionItem: styled.li`
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: start;
    gap: ${({ theme }) => theme.spacing(0.75)};
    padding-block: ${({ theme }) => theme.spacing(0.5)};
    padding-inline: 0;
    &:not(:last-of-type) {
      border-block-end: 1px solid rgba(0, 0, 0, 0.12);
    }
    &:last-of-type {
      padding-bottom: 0;
    }
  `,
  DetailsSectionItemInner: styled.div`
    display: grid;
    grid-template-columns: 1fr auto;
    column-gap: 10px;
    > * {
      margin: 0;
    }
    time {
      grid-column: 2;
    }
    .full {
      grid-column: 1 / -1;
    }
  `,
  Text: styled.p`
    margin-top: 0;
    padding: 0;
    color: ${({ theme }) => theme.textLight};
    ${({ isName }) =>
      isName
        ? css`
            font-weight: bold;
            font-size: 1rem;
            color: ${({ theme }) => theme.text};
          `
        : undefined};
  `,
  WarningButtonLink: styled(Button).attrs({ variant: 'warning' })`
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing(0.25)};
  `,
  ActionsZone: styled.div`
    display: flex;
    gap: ${({ theme }) => theme.spacing(1)};
    grid-area: 1 / 2 / 2 / 2;
  `,
  HeadingConfirmed: styled(HeaderBase).attrs({
    size: 'full',
    alignX: 'center',
  })`
    display: grid;
    grid-template-columns: 1fr auto;
    background-color: white;
  `,
};

export const CrossedPrice = styled(Text)`
  text-decoration: line-through;
  color: ${({ theme }) => theme.textLighter};
`;

export const SupplierTag = styled(Stack)`
  padding: ${({ theme }) => theme.spacing(0.25)}
    ${({ theme }) => theme.spacing(0.75)};
  color: ${({ theme }) => theme.orange};
  border: solid 1px currentColor;
  border-radius: ${({ theme }) => theme.borderRadius.default};
`;

export const NoteCard = styled(Stack)`
  color: ${({ theme }) => theme.textLight};
  padding: ${({ theme }) => theme.spacing()};
  border-radius: ${({ theme }) => theme.borderRadius.default};
  border: solid 1px rgba(0, 0, 0, 0.12);
  background-color: rgba(0, 0, 0, 0.08);
`;

export const RoomTagItem = styled(Stack).attrs({
  direction: 'row',
  gutterSize: 0.25,
})`
  border-radius: 1rem;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.separator};
  min-width: 70px;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing(0.25)}
    ${({ theme }) => theme.spacing(0.5)};
`;

export const RoomTag = ({ value, icon: Icon }) => {
  if (!Icon) return null;
  return (
    <RoomTagItem>
      <Icon size={18} /> <span>{value}</span>
    </RoomTagItem>
  );
};

export const TourOperator = ({ operator, children }) => {
  const { t } = useTranslation('bookings');
  const theme = useTheme();
  if (operator?.networkName || operator?.operatorTourName) {
    return (
      <Stack direction="column" size="full" gutterSize={0.5}>
        {operator?.operatorTourName && (
          <Stack alignX="start" direction="column" gutterSize={0.5}>
            <Text type="body" color="gray">
              {t('tourOperatorName')}
            </Text>
            <Stack gutterSize={1} direction="row" alignY="center" size="full">
              <HiOutlineUserCircle stroke={theme.textLighter} size={24} />
              <strong>{operator?.operatorTourName}</strong>
            </Stack>
          </Stack>
        )}
        {operator?.networkName && (
          <Stack alignX="start" direction="column" gutterSize={0.5}>
            <Stack>{t('networkName')}</Stack>
            <Stack gutterSize={1} direction="row" size="full">
              <BsBuilding fill={theme.textLighter} size={24} />
              <strong>{operator?.networkName}</strong>
            </Stack>
          </Stack>
        )}
      </Stack>
    );
  }
  return children;
};

export default UI;
