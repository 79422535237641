import { useMutation } from '@apollo/client';
import { CREATE_ADMIN_BOOKING_DOCUMENT } from '../../../../api/bookings';
import UploadDocumentForm from 'components/Forms/UploadDocumentForm';

export default function UploadDocument({
  bookingId,
  secret,
  children,
  loaderClass,
  onUpload,
  privateMode,
}) {
  const [createFile] = useMutation(CREATE_ADMIN_BOOKING_DOCUMENT);

  const onSubmit = async (values, methods) => {
    methods.setSubmitting(true);
    try {
      await createFile({
        variables: {
          input: { ...values, private: privateMode },
        },
      });
      onUpload();
    } catch (error) {}
    methods.setSubmitting(false);
  };

  const initialValues = {
    adminBookingId: bookingId,
    secret: secret,
    document: null,
  };

  return (
    <UploadDocumentForm
      onUpload={onSubmit}
      initialValues={initialValues}
      loaderClass={loaderClass}
    >
      {children}
    </UploadDocumentForm>
  );
}
