import { useTranslation } from 'react-i18next';
import BulkEditor from './BulkEditor';
import LocationAwareSearch from '../../../../components/LocationAwareSearch';
import FilterHotels from '../../../../components/FilterHotels';
import { gql, useQuery } from '@apollo/client';
import { isNil, pickBy } from 'lodash';
import { useApp, useSearchParams } from '../../../../hooks';
import { decodeId } from '../../../../utils';

const GET_HOTELS = gql`
  query getHotels(
    $endCursor: String
    $stars: Int
    $search: String
    $order: HotelOrderCriteria
    $locationId: ID
    $kindsIds: [ID!]
    $state: String
    $boundingBox: HotelBoundingBox
    $discarded: Boolean
  ) {
    hotels(
      first: 100
      after: $endCursor
      stars: $stars
      displayName: $search
      order: $order
      locationId: $locationId
      kindsIds: $kindsIds
      state: $state
      boundingBox: $boundingBox
      discarded: $discarded
    ) {
      edges {
        node {
          id
          displayName
          address
          stars
          adminBookingsCount
          lat
          lon
          canReadHotel
          canUpdateHotel
          canChangeStateHotel
          showcased
          connectedChannelManager
          primaryAttachment {
            id
            imageUrl(size: MEDIUM)
          }
          kinds {
            nodes {
              id
              displayName
            }
          }
          customCategories {
            nodes {
              id
              displayName
            }
          }
        }
      }
      pageInfo {
        hasPreviousPage
        startCursor
        hasNextPage
        endCursor
      }
    }
  }
`;

export default function BulkEditorHotels() {
  const { t } = useTranslation('toConnectionRequest');
  const { currentOrganizationId } = useApp();
  const [searchParams] = useSearchParams();
  const { search, sort, order, kindsIds, searchLocation, state } = searchParams;

  const stars = Boolean(searchParams.stars) ? Number(searchParams.stars) : null;

  const { data, loading } = useQuery(GET_HOTELS, {
    variables: pickBy(
      {
        order: Boolean(sort)
          ? {
              column: sort,
              direction: order || 'DESC',
            }
          : null,
        search,
        stars,
        state,
        locationId: searchLocation?.id,
        kindsIds: Boolean(kindsIds)
          ? (kindsIds ?? []).map(({ value }) => value)
          : undefined,
        organizationId: currentOrganizationId,
        discarded: false,
      },
      value => !isNil(value),
    ),
  });

  return (
    <BulkEditor
      title={t('bulkEdit.editor.hotelsTitle')}
      filters={
        <>
          <LocationAwareSearch name="search" />

          <FilterHotels />
        </>
      }
      loading={loading}
      results={
        data?.hotels?.edges?.map(({ node }) => ({
          id: decodeId(node.id),
          idRails: node.id,
          image: node.primaryAttachment?.imageUrl,
          name: node.displayName,
          description: node.address,
          stars: node.stars,
        })) ?? []
      }
      type="hotel"
    />
  );
}
