import { Container, ExperiencesGrid } from 'ui';
import { Button } from 'components';
import { Stack } from '@tymate/margaret';
import { useTranslation } from 'react-i18next';
import EmptyState from 'components/EmptyState';
import ExperienceCard from './ExperienceCard';
import Spinner from 'components/Spinner';

const ExperiencesList = ({
  experiences,
  onLoadNextPage,
  hasNextPage,
  isEmpty,
  isLoading,
}) => {
  const { t } = useTranslation('experiences');
  return (
    <Container>
      <Stack className="relative" size="full" direction="column" gutterSize={1}>
        {isLoading && <Spinner />}
        {isEmpty && !isLoading && (
          <EmptyState variant="full">{t('emptyStateMessage')}</EmptyState>
        )}
        <Stack direction="column" size="full" alignX="center" gutterSize={1}>
          <ExperiencesGrid>
            {experiences?.map(experience => (
              <ExperienceCard key={experience.id} experience={experience} />
            ))}
          </ExperiencesGrid>
          {hasNextPage && !isEmpty && (
            <Button variant="simple" onClick={onLoadNextPage}>
              {t('misc:seeMore')}
            </Button>
          )}
        </Stack>
      </Stack>
    </Container>
  );
};

export default ExperiencesList;
