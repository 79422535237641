import Modal from './Modal';
import { useEffect, useRef, useState } from 'react';
import {
  getOrganizationCountry,
  updateOrganizationCountry,
} from '../api/node/organizationCountry';
import { IcCancel } from './icons';
import { Button } from 'components';
import { Stack } from '@tymate/margaret';
import Spinner from './Spinner';
import { useParams } from 'react-router-dom';
import { useError } from '../hooks';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { TextField } from './Fields';
import * as Yup from 'yup';
import { FieldArray, Form, Formik } from 'formik';

const schema = Yup.object({
  emails: Yup.array().of(Yup.string().email().required()),
});

const Container = styled.div`
  margin-bottom: 10px;
  margin-left: 10px;
  width: 45%;
`;

const EmailsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 15px;
  margin-bottom: 15px;
`;

const OrganizationCountryBookingReceptionEmailsModal = ({
  isOpen,
  setOpen,
  countryId,
}) => {
  const [initialEmails, setInitialEmails] = useState(['']);
  const [isLoading, setLoading] = useState(true);
  const { organizationId } = useParams();
  const { sendErrorSnack } = useError();
  const { t } = useTranslation('organization');
  const formikRef = useRef(null);
  const formikArrayRef = useRef(null);

  useEffect(() => {
    if (countryId && isOpen) {
      setLoading(true);
      getOrganizationCountry(organizationId, countryId)
        .then(result => {
          if (result.status === 200) {
            setInitialEmails(result.data.bookingReceptionEmails);
          }
        })
        .catch(error => {
          sendErrorSnack(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [organizationId, countryId, isOpen]);

  const addEmail = () => {
    if (formikArrayRef.current) {
      formikArrayRef.current.push('');
    }
  };
  const handleSubmit = () => {
    if (formikRef.current) {
      updateOrganizationCountry(
        organizationId,
        countryId,
        formikRef.current.values.emails,
      )
        .then(() => {
          setOpen(false);
        })
        .catch(error => {
          sendErrorSnack(error);
        });
    }
  };
  const submit = () => {
    if (formikRef.current) {
      formikRef.current.submitForm();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      title={t('form.bookingReceptionEmailModal.title')}
      onRequestClose={() => {
        setOpen(false);
      }}
      footer={
        <Stack alignX="flex-end" gutterSize={1}>
          <Button onClick={addEmail} variant="primary">
            {t('form.bookingReceptionEmailModal.addEmail')}
          </Button>
          <Button onClick={submit} variant="primary">
            {t('form.bookingReceptionEmailModal.save')}
          </Button>
        </Stack>
      }
    >
      <Formik
        initialValues={{ emails: initialEmails }}
        enableReinitialize
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {formik => {
          formikRef.current = formik;

          if (isLoading) {
            return <Spinner />;
          }

          return (
            <Form>
              <FieldArray
                name="emails"
                render={arrayHelper => {
                  formikArrayRef.current = arrayHelper;
                  return (
                    <EmailsWrapper>
                      {formik.values.emails.map((email, index) => (
                        <Container key={`${countryId}-${index}`}>
                          <TextField
                            name={`emails.${index}`}
                            type="email"
                            value={email}
                            suffixIcon={
                              <div className="p-1 -mr-1 bg-white/75 backdrop-blur-sm rounded-full">
                                <IcCancel
                                  onClick={() => arrayHelper.remove(index)}
                                />
                              </div>
                            }
                            onChange={event => {
                              const newValue = event.target.value
                                .trim()
                                .toLowerCase();
                              formik.setFieldValue(`emails.${index}`, newValue);
                            }}
                          />
                        </Container>
                      ))}
                    </EmailsWrapper>
                  );
                }}
              ></FieldArray>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default OrganizationCountryBookingReceptionEmailsModal;
