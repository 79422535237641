import styled, { css } from 'styled-components';
import { ButtonReset, Stack, Box } from '@tymate/margaret';
import { fontStyles } from './theme';
import RawTextareaAutosize from 'react-textarea-autosize';

export const FormErrorMessage = styled.span`
  display: block;
  padding-top: ${({ theme }) => theme.spacing(0.5)};
  color: ${({ error, theme }) => (error ? theme.error : theme.warning)};
  font-size: 14px;
`;

export const Input = styled.input`
  padding: ${({ theme }) => theme.spacing(0.25)}
    ${({ theme }) => theme.spacing(0.5)};
  border: 1px solid ${({ theme }) => theme.separator};
  flex: 1;
  border-radius: 4px;
  transition: border-color 100ms ease;
  min-height: 36px;
  width: 100%;
  box-shadow: 0 1px 2px 0 ${({ theme }) => theme.shadow};
  background-color: #ffffff;

  ${({ type }) =>
    type === 'password' &&
    css`
      padding-right: 44px;
    `}

  &:not([disabled]):focus,
  &:not([disabled]):active, &:not([disabled]):hover {
    outline: none;
    border: 1px solid ${({ theme }) => theme.darkSeparator};
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.disabled};
    color: #6d6d6d;
  }

  ::placeholder {
    color: ${({ theme }) => theme.textLight};
  }

  ${({ hasError, theme }) =>
    hasError &&
    css`
      &:not([disabled]),
      &:not([disabled]):hover,
      &:not([disabled]):active {
        border: 1px solid ${theme.danger};
      }
    `}
`;

export const TextArea = styled(RawTextareaAutosize)`
  padding: ${({ theme }) => theme.spacing(0.5)};
  ${({ theme }) => theme.spacing()};
  border: 1px solid ${({ theme }) => theme.separator};
  flex: 1;
  border-radius: ${({ theme }) => theme.borderRadius.large};
  transition: border-color 100ms ease;
  resize: none;
  min-height: 88px;
  width: 100%;

  &:not([disabled]):focus,
  &:not([disabled]):active,
  &:not([disabled]):hover {
    outline: none;
    border: 1px solid ${({ theme }) => theme.darkSeparator};
  }

  &:disabled {
    background-color: rgba(0, 0, 0, 0.08);
    color: #6d6d6d;
  }

  ::placeholder {
    color: ${({ theme }) => theme.textLight};
  }

  ${({ hasError, theme }) =>
    hasError &&
    css`
      &:not([disabled]),
      &:not([disabled]):hover,
      &:not([disabled]):active {
        border: 1px solid ${theme.danger};
      }
    `}
`;

export const FormGroup = styled.div`
  > * {
    margin-bottom: ${({ theme }) => theme.spacing(1.5)};
  }
`;

export const FieldValidation = ({ status }) => (
  <FormLabel
    hasError={Boolean(status.isError)}
    hasSucceed={!Boolean(status.isError)}
  >
    {status?.message}
  </FormLabel>
);

export const FormLabel = styled(Box).attrs({ as: 'label', marginBottom: 0.5 })`
  ${fontStyles.bodySmall};

  text-align: left;
  margin-bottom: ${({ theme }) => theme.spacing(0.5)};
  font-weight: 600;
  color: ${({ theme }) => theme.text};

  ${({ variant }) =>
    variant === 'bare' &&
    `
      margin-bottom: 0;
    `}
`;

export const FormField = styled(Stack).attrs({
  direction: 'column',
  size: 'full',
  alignX: 'stretch',
})`
  flex: 1;
  position: relative;
`;

export const InlineFormField = styled(Stack).attrs({
  direction: 'row',
  alignY: 'center',
})``;

export const PasswordButton = styled(ButtonReset)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: ${({ theme }) => theme.spacing(0.75)};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.textLight};
`;

export const InputIconButton = styled(ButtonReset)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: ${({ theme }) => theme.spacing(0.75)};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.textLight};
`;
export const ErrorMessage = styled.div`
  ${fontStyles.bodySmall};
  padding-top: ${({ theme }) => theme.spacing()};
  padding-bottom: ${({ theme }) => theme.spacing()};
  color: ${({ theme }) => theme.danger};
`;

export const HelperText = styled.div`
  ${fontStyles.bodySmall};
  color: ${({ theme }) => theme.textLight};
  padding-top: ${({ theme }) => theme.spacing(0.5)};
`;

export const ErrorMessageWrapper = styled.div`
  ${fontStyles.bodySmall};

  color: ${({ theme }) => theme.danger};
  position: absolute;
  top: 100%;
`;
