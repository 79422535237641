import { memo } from 'react';

const IcView = props => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    style={{ ...props.style, color: props.color, fontSize: props.size }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 8.186a3.646 3.646 0 013.647 3.643v.004A3.646 3.646 0 1112 8.186zm2.605 3.644a2.604 2.604 0 100 .003v-.003z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 5.688c3.578-.056 7.18 2.419 9.407 4.866l.001.002a1.906 1.906 0 010 2.552l-.001.002c-2.175 2.393-5.769 4.924-9.406 4.867-3.636.057-7.23-2.475-9.402-4.867l-.002-.002a1.906 1.906 0 010-2.552l.002-.002C4.822 8.108 8.423 5.632 12 5.688zm8.636 5.566c-2.148-2.36-5.488-4.577-8.627-4.524h-.018c-3.137-.053-6.477 2.165-8.62 4.524a.865.865 0 000 1.157c2.097 2.309 5.428 4.579 8.621 4.525h.018c3.194.053 6.525-2.215 8.625-4.526a.865.865 0 000-1.156z"
      fill="#000"
    />
  </svg>
);

export default memo(IcView);
