import { memo } from 'react';

const IcAddCircle = props => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    style={{ ...props.style, color: props.color, fontSize: props.size }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 7.625c.345 0 .625.28.625.625v3.125h3.125a.625.625 0 110 1.25h-3.125v3.125a.625.625 0 11-1.25 0v-3.125H8.25a.625.625 0 110-1.25h3.125V8.25c0-.345.28-.625.625-.625z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 3.25a8.75 8.75 0 100 17.5 8.75 8.75 0 000-17.5zM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12z"
      fill="currentColor"
    />
  </svg>
);

export default memo(IcAddCircle);
