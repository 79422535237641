import { get, put } from './';
export const getOrganizationCountry = (organizationId, countryId) => {
  return get(`/organizations/${organizationId}/countries/${countryId}`);
};
export const updateOrganizationCountry = (
  organizationId,
  countryId,
  emails,
) => {
  return put(`/organizations/${organizationId}/countries/${countryId}`, {
    bookingReceptionEmails: emails,
  });
};
