import { memo } from 'react';

const IcHyperlink = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    {...props}
    style={{ ...props.style, color: props.color, fontSize: props.size }}
  >
    <path
      d="M10.082 9.5l-.01-.01c-.85-.96-2.06-1.5-3.34-1.5H5.21c-2.49 0-4.5 2.01-4.5 4.5 0 2.48 2.01 4.5 4.5 4.49h1.5-.01c1.27-.01 2.48-.55 3.33-1.5m3.888-5.98h-.01c.84-.96 2.05-1.5 3.33-1.5h1.5-.01c2.48-.01 4.5 2.01 4.5 4.49 0 2.48-2.02 4.5-4.5 4.5h-1.5l-.01-.001a4.517 4.517 0 01-3.34-1.5M6.75 12.5h10.5"
      stroke="currentColor"
      strokeWidth={1.25}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default memo(IcHyperlink);
