const { gql } = require('@apollo/client');

export const GET_COUNTRIES = gql`
  query getCountries($search: String, $organizationId: ID, $locale: String) {
    countries(
      first: 25
      title: $search
      organizationId: $organizationId
      locale: $locale
    ) {
      edges {
        node {
          id
          alpha2
          title
        }
      }
    }
  }
`;

export const GET_REGIONS = gql`
  query getRegions($search: String) {
    regions(first: 25, title: $search) {
      edges {
        node {
          id
          title
        }
      }
    }
  }
`;

export const GET_CITIES = gql`
  query getCities($search: String, $organizationId: ID, $countryId: ID) {
    cities(
      first: 25
      title: $search
      organizationId: $organizationId
      countryId: $countryId
    ) {
      edges {
        node {
          id
          title
          region {
            id
            title
          }
          country {
            id
            title
          }
        }
      }
    }
  }
`;

export const CREATE_COUNTRY = gql`
  mutation createCountry($input: CreateCountryInput!) {
    createCountry(input: $input) {
      country {
        id
        alpha2
      }
    }
  }
`;

export const CREATE_CITY = gql`
  mutation createCity($input: CreateCityInput!) {
    createCity(input: $input) {
      city {
        id
        code
      }
    }
  }
`;

export const DELETE_CITY = gql`
  mutation deleteCity($input: DeleteCityInput!) {
    deleteCity(input: $input) {
      errors {
        message
      }
    }
  }
`;

export const REGENERATE_COUNTRY = gql`
  mutation regenerateCountry($input: RegenerateCountryInput!) {
    regenerateCountry(input: $input) {
      country {
        id
      }
      errors {
        message
        attribute
      }
    }
  }
`;

export const GET_LOCATIONS_SEARCH = gql`
  query locationsSearch($search: String!) {
    locationsSearch(search: $search) {
      hotels {
        nodes {
          id
          displayName
        }
      }
      cities {
        nodes {
          id
          title
        }
      }
      countries {
        nodes {
          id
          title
        }
      }
      regions {
        nodes {
          id
          title
        }
      }
      experiences {
        nodes {
          id
          name
          experiencableType
        }
      }
    }
  }
`;
