import { memo } from 'react';

const IcMessages = props => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    style={{ ...props.style, color: props.color, fontSize: props.size }}
  >
    <path
      d="M20.125 2H3.875C2.839 2 2 2.84 2 3.875V16.375L2.006 16.522C2.08 17.489 2.889 18.25 3.875 18.25H5.75V21.375L5.757 21.469C5.823 21.924 6.364 22.165 6.75 21.875L11.583 18.25H20.125C21.161 18.25 22 17.41 22 16.375V3.875C22 2.839 21.16 2 20.125 2ZM3.875 3.25H20.125C20.47 3.25 20.75 3.53 20.75 3.875V16.375C20.75 16.72 20.47 17 20.125 17H11.375L11.275 17.008C11.175 17.024 11.081 17.064 11 17.125L7 20.125V17.625C7 17.28 6.72 17 6.375 17H3.875C3.53 17 3.25 16.72 3.25 16.375V3.875C3.25 3.53 3.53 3.25 3.875 3.25Z"
      fill="currentColor"
    />
    <path
      d="M17.625 7.625C17.97 7.625 18.25 7.905 18.25 8.25C18.25 8.564 18.019 8.824 17.717 8.868L17.625 8.875H6.375C6.03 8.875 5.75 8.595 5.75 8.25C5.75 7.936 5.981 7.676 6.283 7.632L6.375 7.625H17.625ZM15.125 11.375C15.47 11.375 15.75 11.655 15.75 12C15.75 12.314 15.519 12.574 15.217 12.618L15.125 12.625H6.375C6.03 12.625 5.75 12.345 5.75 12C5.75 11.686 5.981 11.426 6.283 11.382L6.375 11.375H15.125Z"
      fill="currentColor"
    />
  </svg>
);

export default memo(IcMessages);
