import CreateSettingRequest from 'components/SettingRequest/CreateSettingRequest';
import { Routes, Route, Navigate } from 'react-router-dom';

const SettingRequests = () => {
  return (
    <>
      <Routes>
        <Route
          path="new/:settingName"
          element={<CreateSettingRequest kind={'experience'} />}
        />
        <Route path="new/" element={<Navigate to={-1} />} />
        <Route path="/" element={<Navigate to={-1} />} />
      </Routes>
    </>
  );
};

export default SettingRequests;
