import React from 'react';
import ExperiencesWizard from './ExperiencesWizard';
import { useApp } from '../../../../hooks';
import { formatExperienceFormPayload } from '../Utilities/ExperienceHelpers';
import { useQuery } from '@apollo/client';
import { GET_ORGANIZATION_CANCELLATION_POLICIES } from 'api/organizations';

export default function ExperienceForm({
  initialValues,
  handleSave,
  initialStep = 0,
  currentLocale,
  setCurrentLocale,
}) {
  const { currentOrganizationIdInt, currentOrganizationId } = useApp();

  const { data: orga } = useQuery(GET_ORGANIZATION_CANCELLATION_POLICIES, {
    variables: { id: currentOrganizationId, locale: currentLocale?.value },
  });
  const organizationCancellationPolicies = orga?.node?.cancellationPolicies;

  const newInitialValues = initialValues ?? {
    duration: 0,
    state: 'in_progress',
    organizationId: '' + currentOrganizationIdInt,
    pictures: [],
    mediaLinks: [],
    guidingLanguages: [],
    extras: [],
    cancellationPolicies: organizationCancellationPolicies,
  };

  return (
    <ExperiencesWizard
      initialExperience={newInitialValues}
      initialStep={initialStep}
      handleSave={values => {
        return formatExperienceFormPayload(values).then(payload => {
          return handleSave(payload);
        });
      }}
      currentLocale={currentLocale}
      setCurrentLocale={setCurrentLocale}
    />
  );
}
