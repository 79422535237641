import * as Yup from 'yup';
import React, { useEffect } from 'react';
import { get } from 'api/node';
import PromotionForm from 'components/Forms/PromotionForm';
import { useAsync } from 'react-async';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { Stack } from '@tymate/margaret';
import {
  SegmentedControlField,
  SelectField,
  TextField,
} from 'components/Fields';
import { useParams } from 'react-router-dom';
import { Formik, useField, useFormikContext } from 'formik';
import {
  arePromotionPeriodsValid,
  formatPromotionFormPayload,
} from '../Form/Utilities/ExperienceHelpers';
import { BenefitsField } from 'components/Fields/PromotionParamsField';
import { useSnack } from 'hooks';
import i18n from '../../../i18n';
import {
  createPromotionPeriodFactory,
  mapApiResponseToFormValues,
} from '../../../utils/promotions';

const getOrganizationsLinked = ({ experienceId }) =>
  get(`/experiences/${experienceId}/organizations`);

let parametersTypesOptions;

const PromotionTypeField = ({ name }) => {
  const { t } = useTranslation('promotionForm');
  const { promotionId } = useParams();

  return (
    <div className="w-full">
      <div className="my-3 flex justify-between space-x-3">
        <SelectField
          disabled={Boolean(promotionId)}
          name={`${name}.kind`}
          options={parametersTypesOptions}
          label={t('promotionTypes.label')}
        />
        <SegmentedControlField
          name="benefit.promoPerRoom"
          options={[
            { label: t('form.no', { ns: 'experiences' }), value: true },
            { label: t('form.yes', { ns: 'experiences' }), value: false },
          ]}
          label={t('promoPerPax')}
          required
        />
      </div>
    </div>
  );
};

const AdultPaxField = ({ index, periodIndex }) => {
  const { t } = useTranslation('experiences');
  return (
    <Stack direction="row" gutterSize={4} className="my-5">
      <TextField
        type="number"
        name={`periods[${periodIndex}].paxRanges[${index}].paxRange[0]`}
        label={t('form.minimumPaxRange')}
        disabled
      />
      <TextField
        type="number"
        name={`periods[${periodIndex}].paxRanges[${index}].paxRange[1]`}
        label={t('form.maximumPaxRange')}
        disabled
      />
      <TextField
        type="number"
        name={`periods[${periodIndex}].paxRanges[${index}].value`}
        label={t('form.promotionPrice')}
        required
      />
    </Stack>
  );
};

const ChildrenPaxField = ({ index, periodIndex }) => {
  const { t } = useTranslation('experiences');
  return (
    <Stack direction="row" gutterSize={4} className="my-5">
      <TextField
        type="number"
        name={`periods[${periodIndex}].ageRanges[${index}].ageRange[0]`}
        label={t('form.minimumAgeRange')}
        disabled
      />
      <TextField
        type="number"
        name={`periods[${periodIndex}].ageRanges[${index}].ageRange[1]`}
        label={t('form.maximumAgeRange')}
        disabled
      />
      <TextField
        type="number"
        name={`periods[${periodIndex}].ageRanges[${index}].value`}
        label={t('form.promotionPrice')}
        required
      />
    </Stack>
  );
};
const BenefitPerPax = ({ promotionPeriod, periodIndex }) => {
  return (
    <>
      {(promotionPeriod.paxRanges ?? []).map((_, index) => (
        <AdultPaxField periodIndex={periodIndex} index={index} key={index} />
      ))}
      {(promotionPeriod.ageRanges ?? []).map((_, index) => (
        <ChildrenPaxField periodIndex={periodIndex} index={index} key={index} />
      ))}
    </>
  );
};

const BenefitParamsField = ({ currency, experiencePeriods }) => {
  const { values } = useFormikContext();
  let kindSnakeCase = values?.benefit?.kind?.value;
  if (kindSnakeCase) {
    kindSnakeCase = kindSnakeCase.replace('Benefits::', '');
    kindSnakeCase =
      kindSnakeCase[0].toLowerCase() +
      kindSnakeCase
        .slice(1, kindSnakeCase.length)
        .replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
  }

  const [{ value: periods }, , { setValue: setPeriods }] = useField({
    name: `periods`,
  });
  useEffect(() => {
    var modified = false;
    const newPeriods = [];
    (periods ?? []).forEach(period => {
      const promotionPeriod = { ...period };
      newPeriods.push(promotionPeriod);
      const experiencePeriodMatching = (experiencePeriods ?? []).filter(
        expPeriod => {
          const periodStart = new Date(expPeriod.startAt);
          const periodEnd = new Date(expPeriod.endAt);
          const promoStart = new Date(promotionPeriod.startAt);
          const promoEnd = new Date(promotionPeriod.endAt);
          return promoStart <= periodEnd && promoEnd >= periodStart;
        },
      )[0];
      if (!experiencePeriodMatching || values?.benefit?.promoPerRoom) {
        if ((promotionPeriod.ageRanges ?? []).length !== 0) {
          promotionPeriod.ageRanges = [];
          modified = true;
        }
        if ((promotionPeriod.paxRanges ?? []).length !== 0) {
          promotionPeriod.paxRanges = [];
          modified = true;
        }
      } else {
        if (
          !promotionPeriod.paxRanges ||
          promotionPeriod.paxRanges.length !==
            (experiencePeriodMatching.adultPax ?? []).length
        ) {
          promotionPeriod.paxRanges = experiencePeriodMatching.adultPax ?? [];
          modified = true;
        } else {
          promotionPeriod.paxRanges.forEach((paxRange, index) => {
            if (
              paxRange.paxRange[0] !==
                experiencePeriodMatching.adultPax[index].paxRange[0] ||
              paxRange.paxRange[1] !==
                experiencePeriodMatching.adultPax[index].paxRange[1]
            ) {
              paxRange[0] =
                experiencePeriodMatching.adultPax[index].paxRange[0];
              paxRange[1] =
                experiencePeriodMatching.adultPax[index].paxRange[1];
              modified = true;
            }
          });
        }
        if (
          !promotionPeriod.ageRanges ||
          promotionPeriod.ageRanges.length !==
            (experiencePeriodMatching.childrenPax ?? []).length
        ) {
          promotionPeriod.ageRanges =
            experiencePeriodMatching.childrenPax ?? [];
          modified = true;
        } else {
          promotionPeriod.ageRanges.forEach((ageRange, index) => {
            if (
              ageRange.ageRange[0] !==
                experiencePeriodMatching.childrenPax[index].ageRange[0] ||
              ageRange.ageRange[1] !==
                experiencePeriodMatching.childrenPax[index].ageRange[1]
            ) {
              ageRange[0] =
                experiencePeriodMatching.childrenPax[index].ageRange[0];
              ageRange[1] =
                experiencePeriodMatching.childrenPax[index].ageRange[1];
              modified = true;
            }
          });
        }
      }
    });
    if (modified) {
      setPeriods(newPeriods);
    }
  }, [periods, setPeriods, experiencePeriods, values?.benefit?.promoPerRoom]);
  return (
    <>
      <PromotionTypeField name="benefit" />
      {values?.benefit?.kind ? (
        <>
          <BenefitsField
            currency={currency}
            name="benefit"
            type={kindSnakeCase}
          />
          {!values.benefit.promoPerRoom &&
            values.periods.map((period, index) => (
              <div className="p-8 m-8 border rounded-lg" key={index}>
                <span style={{ flex: '0 0 auto' }}>Period {index + 1}</span>
                <BenefitPerPax
                  promotionPeriod={period}
                  key={index}
                  periodIndex={index}
                />
              </div>
            ))}
        </>
      ) : null}
    </>
  );
};

const getPromotions = ({ experienceId }) =>
  get(`/promotions/${experienceId}?state=available`);

const PromotionWizard = ({
  experience,
  promotion,
  handleSave,
  hasUnsavedData,
  setHasUnsavedData,
  isDuplicated,
}) => {
  const theme = useTheme();
  const { t } = useTranslation('promotionForm');
  const { experienceId } = useParams();
  const { notify } = useSnack();
  const { data } = useAsync({
    promiseFn: getOrganizationsLinked,
    experienceId: experienceId,
  });

  const { data: promotions } = useAsync({
    promiseFn: getPromotions,
    experienceId: experienceId,
  });

  parametersTypesOptions = [
    {
      label: t('promotionTypes.amount'),
      value: 'Benefits::Amount',
    },
    {
      label: t('promotionTypes.percent'),
      value: 'Benefits::Percent',
    },
    {
      label: t('promotionTypes.supplement'),
      value: 'Benefits::Supplement',
    },
    {
      label: t('promotionTypes.supplement_percent'),
      value: 'Benefits::SupplementPercent',
    },
  ];

  const organizations = data?.data;
  const promotionsThatCanBeLinked = (promotions?.data ?? []).filter(
    elem => elem.id !== promotion?.id,
  );

  const EXPERIENCE_PERIOD_SCHEMA_VALIDATION = Yup.object().shape({
    startAt: Yup.date().required(i18n.t('errors:required')),
    endAt: Yup.date().required(i18n.t('errors:required')),
    days: Yup.object()
      .shape({
        Mo: Yup.bool().required(i18n.t('errors:required')),
        Tu: Yup.bool().required(i18n.t('errors:required')),
        We: Yup.bool().required(i18n.t('errors:required')),
        Th: Yup.bool().required(i18n.t('errors:required')),
        Fr: Yup.bool().required(i18n.t('errors:required')),
        Sa: Yup.bool().required(i18n.t('errors:required')),
        Su: Yup.bool().required(i18n.t('errors:required')),
      })
      .required(i18n.t('errors:required')),
    paxRanges: Yup.array().of(
      Yup.object().shape({
        paxRange: Yup.array()
          .required(t('errors:required'))
          .min(1, t('errors:minLength', { length: 1 })),
        value: Yup.number()
          .min(0, t('isPositive'))
          .test('is-required', t('errors:required'), function (value) {
            return value >= 0;
          }),
      }),
    ),
    ageRanges: Yup.array().of(
      Yup.object().shape({
        ageRange: Yup.array()
          .required(t('errors:required'))
          .min(1, t('errors:minLength', { length: 1 })),
        value: Yup.number()
          .min(0, t('isPositive'))
          .test('is-required', t('errors:required'), function (value) {
            return value >= 0;
          }),
      }),
    ),
  });

  const BENEFIT_SCHEMA = Yup.object().shape({
    kind: Yup.object()
      .shape({
        value: Yup.mixed()
          .oneOf([
            'Benefits::Amount',
            'Benefits::Percent',
            'Benefits::Supplement',
            'Benefits::SupplementPercent',
          ])
          .required(t('errors:required')),
      })
      .required(t('errors:required')),
    value: Yup.number().required(t('errors:required')),
    unit: Yup.mixed()
      .oneOf(['per_night', 'per_night'])
      .required(t('errors:required')),
    promoPerRoom: Yup.boolean().required(t('errors:required')),
  });

  const VALIDATION_SCHEMA = Yup.object().shape({
    name: Yup.string().required(t('errors:required')),
    state: Yup.mixed().oneOf(['draft', 'operational', 'archived']),
    periods: Yup.array()
      .of(EXPERIENCE_PERIOD_SCHEMA_VALIDATION)
      .required(t('errors:required')),
    cumulativePromotions: Yup.array().of(
      Yup.object().shape({
        id: Yup.string().required(),
      }),
    ),
    benefit: BENEFIT_SCHEMA,
    organizations: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.string().required(),
        }),
      )
      .min(1, t('errors:minLength', { length: 1 }))
      .required(t('errors:required')),
  });

  const promotionInitialValues = {
    name: promotion?.name ?? '',
    state: isDuplicated ? 'draft' : promotion?.state ?? 'draft',
    kind: promotion?.kind ?? 'basic_deal',
    experienceId: experience?.id ?? '',
    applicableTo: 'to',
    periods: promotion
      ? (promotion?.availablePeriods || [])?.map(period =>
          mapApiResponseToFormValues({
            startDate: period.startAt,
            endDate: period.endAt,
            availableDays: period.days,
            ageRanges: period.ageRanges,
            paxRanges: period.paxRanges,
          }),
        )
      : [createPromotionPeriodFactory()],
    cumulativePromotions: promotion?.cumulativePromotions ?? [],
    benefit: {
      id: promotion?.benefit?.id,
      kind: parametersTypesOptions?.find(
        item => item?.value === promotion?.benefit?.kind,
      ),
      value: promotion?.benefit?.value ?? 0,
      currency: experience?.currency,
      promoPerRoom: promotion?.benefit?.promoPerRoom ?? true,
      unit: promotion?.benefit?.unit ?? 'per_night',
    },
    organizations:
      promotion?.organizations?.map(elem => {
        return {
          id: elem.id,
          displayName: elem.displayName,
        };
      }) ?? [],
  };

  const handleSubmit = (values, methods) => {
    if (
      arePromotionPeriodsValid({
        promotion: values,
        experience: experience,
      })
    ) {
      const formattedValues = formatPromotionFormPayload(values);
      return handleSave(formattedValues, methods);
    } else {
      notify('Invalid periods', { type: 'error' });
      return false;
    }
  };
  return (
    <Formik
      initialValues={promotionInitialValues}
      enableReinitialize
      onSubmit={handleSubmit}
      validationSchema={VALIDATION_SCHEMA}
    >
      {({ values, isSubmitting, touched, isValid, dirty }) => (
        <PromotionForm
          applicableTo={'to'}
          setUnsavedData={setHasUnsavedData}
          toOrganizations={organizations}
          values={values}
          isSubmitting={isSubmitting}
          touched={touched}
          isValid={isValid}
          dirty={dirty}
          promotionsThatCanBeLinked={promotionsThatCanBeLinked}
          style={{
            gridTemplateAreas: `
          'top top'
          'deal deal'
          'period period'
          'promotionParams promotionParams'
          'cancellationPolicies cancellationPolicies'
        `,
            marginBottom: hasUnsavedData ? theme.spacing(3) : 0,
          }}
          promotionField={
            <BenefitParamsField
              currency={values?.benefit?.currency}
              experiencePeriods={experience?.periods}
            />
          }
          isDuplicate={isDuplicated}
          promotion={promotion}
        />
      )}
    </Formik>
  );
};

export default PromotionWizard;
