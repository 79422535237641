import { useNavigate } from 'react-router-dom';
import { Stack } from '@tymate/margaret';
import { Button } from 'components';
import { FormGroup } from 'ui/forms';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { TextField } from 'components/Fields';
import PasswordStrength, {
  validatePassword,
} from 'components/PasswordStrength';
import UserInfo from 'components/UserInfo';
import { useSearchParams, useSnack, useError } from 'hooks';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import AuthLayout from 'components/AuthLayout';

const GET_INVITED_USER = gql`
  query invitedUser($invitationToken: String!) {
    invitedUser(invitationToken: $invitationToken) {
      id
      email
      firstName
      lastName
      organization {
        id
        displayName
      }
    }
  }
`;

const ACTIVATE_ACCOUNT = gql`
  mutation acceptInvitation($input: AcceptInvitationInput!) {
    acceptInvitation(input: $input) {
      user {
        id
        invitationAcceptedAt
      }
      errors {
        message
      }
    }
  }
`;

const Signup = () => {
  const [{ invitation_token: invitationToken }] = useSearchParams();
  const { t } = useTranslation(['auth', 'errors']);
  const navigate = useNavigate();
  const { notify } = useSnack();
  const { sendErrorSnack } = useError();
  const { data } = useQuery(GET_INVITED_USER, {
    variables: { invitationToken },
  });
  const [activateAccount] = useMutation(ACTIVATE_ACCOUNT);

  const invitedUser = data?.invitedUser || {};

  const handleSignup = async values => {
    try {
      const { data } = await activateAccount({
        variables: { input: values },
      });

      if (data?.acceptInvitation?.errors?.length > 0) {
        throw data?.acceptInvitation?.errors?.[0];
      }

      notify(t('signup.succeeded'));
      navigate('/account-type');
    } catch (err) {
      sendErrorSnack(err);
    }
  };

  return (
    <AuthLayout title={t('signup.title')} subtitle={t('signup.subtitle')}>
      <Formik
        enableReinitialize
        initialValues={{
          firstName: invitedUser?.firstName ?? '',
          lastName: invitedUser?.lastName ?? '',
          password: '',
          passwordConfirmation: '',
          invitationToken,
        }}
        validationSchema={Yup.object().shape({
          password: Yup.string().required(t('errors:required')),
          passwordConfirmation: Yup.string()
            .oneOf([Yup.ref('password')], t('errors:passwordDoNotMatch'))
            .required(t('errors:required')),
        })}
        validate={validatePassword}
        onSubmit={handleSignup}
      >
        {({ isSubmitting, values, touched }) => (
          <Form>
            <UserInfo
              {...invitedUser}
              company={invitedUser?.organization?.displayName}
            />
            <FormGroup>
              <Stack direction="column" gutterSize={0.5}>
                <TextField
                  type="password"
                  label={t('password')}
                  name="password"
                  autoComplete="off"
                />
                <PasswordStrength
                  password={values.password}
                  isTouched={touched.password}
                />
              </Stack>
              <TextField
                type="password"
                label={t('passwordConfirmation')}
                name="passwordConfirmation"
              />
            </FormGroup>
            <Stack marginTop={1.5}>
              <Button
                type="submit"
                variant="primaryBig"
                size="full"
                isLoading={isSubmitting}
              >
                {t('signup.button')}
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    </AuthLayout>
  );
};

export default Signup;
