import { useQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnack, useError, useApp } from 'hooks';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'components';
import { put } from 'api/node';
import { Card, Container, Text } from 'ui';
import { SETTING_REQUEST } from 'api/settingRequest';
import DetailsSettingRequestForm from 'components/Forms/DetailsSettingRequestForm';
import { useState } from 'react';
import DenyExplainationForm from './DenyExplainationForm';
import { Stack } from '@tymate/margaret';
import RequestToState from 'components/RequestToState';

const DetailsSettingRequest = () => {
  const { settingRequestId } = useParams();
  const { t } = useTranslation('settingRequest');
  const navigate = useNavigate();
  const { notify } = useSnack();
  const { sendErrorSnack } = useError();
  const [isOpen, setIsOpen] = useState(false);
  const { isAllowedTo } = useApp();

  const refuse = () => {
    if (!window.confirm(t('denyConfirmation'))) {
      return;
    } else {
      setIsOpen(true);
    }
  };
  const { data: request } = useQuery(SETTING_REQUEST, {
    variables: { id: settingRequestId },
    fetchPolicy: 'no-cache',
  });

  const save = async (state, comment) => {
    try {
      await put(`settingRequest/${settingRequestId}`, {
        state: state ?? request.state,
        refusedExplaination: comment?.reason,
      });
      notify(t('updated'));
      navigate(-1);
    } catch (error) {
      sendErrorSnack();
    }
  };

  return (
    <>
      <div className="px-4">
        <div className="bg-white border rounded-lg p-10">
          <div className="flex justify-center">
            <div className="flex gap-4 text-black text-3xl">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-10 h-10"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M5.32894 3.27158C6.56203 2.8332 7.99181 3.10749 8.97878 4.09446C9.96603 5.08171 10.2402 6.51202 9.80129 7.74535L20.646 18.5902L18.5247 20.7115L7.67887 9.86709C6.44578 10.3055 5.016 10.0312 4.02903 9.04421C3.04178 8.05696 2.76761 6.62665 3.20652 5.39332L5.44325 7.63C6.02903 8.21578 6.97878 8.21578 7.56457 7.63C8.15035 7.04421 8.15035 6.09446 7.56457 5.50868L5.32894 3.27158ZM15.6963 5.15512L18.8783 3.38736L20.2925 4.80157L18.5247 7.98355L16.757 8.3371L14.6356 10.4584L13.2214 9.04421L15.3427 6.92289L15.6963 5.15512ZM8.62523 12.9333L10.7465 15.0546L5.7968 20.0044C5.21101 20.5902 4.26127 20.5902 3.67548 20.0044C3.12415 19.453 3.09172 18.5793 3.57819 17.99L3.67548 17.883L8.62523 12.9333Z"
                />
              </svg>
            </div>
          </div>

          <div className="flex justify-between">
            <div>
              {isAllowedTo('manageHotelSettings') &&
                request?.node?.state === 'pending' && (
                  <Button onClick={() => refuse()} variant="simple">
                    {t('misc:deny')}
                  </Button>
                )}
            </div>
            <div className="text-center">
              <Text type="h1">{t('settingDetails')}</Text>
            </div>
            <div>
              {isAllowedTo('manageHotelSettings') &&
                request?.node?.state === 'pending' && (
                  <Button
                    onClick={() => save('confirmed', '')}
                    variant="primary"
                  >
                    {t('misc:accept')}
                  </Button>
                )}
            </div>
          </div>

          <div className="mt-4 flex justify-center">
            <RequestToState state={request?.node?.state} />
          </div>

          {isOpen && (
            <Modal
              isOpen
              onRequestClose={() => {
                setIsOpen(false);
              }}
              title={
                <Stack size="full" alignY="center" gutterSize={1}>
                  <p>{t('denyReason')}</p>
                </Stack>
              }
            >
              <DenyExplainationForm
                onSubmit={values => save('refused', values)}
              />
            </Modal>
          )}
        </div>
        <div className="my-5 flex justify-end gap-5">
          <Button type="button" variant="simple" to={-1}>
            {t('misc:cancel')}
          </Button>
        </div>

        <Container size="full">
          <Card>
            <DetailsSettingRequestForm settingRequest={request?.node} />
          </Card>
        </Container>
      </div>
    </>
  );
};

export default DetailsSettingRequest;
