import { camelCase } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSnack } from 'hooks';

const useError = () => {
  const { t } = useTranslation('errors');
  const { notify } = useSnack();

  const formatError = ({ attribute, message }) =>
    Boolean(attribute)
      ? t(`${camelCase(`${attribute}_${message}`)}`)
      : t('error');

  const handleSendErrorSnack = err =>
    notify(formatError(err), { type: 'error' });

  return {
    formatError,
    sendErrorSnack: handleSendErrorSnack,
  };
};

export default useError;
