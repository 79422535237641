import { useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Container, Card } from 'ui';
import { useSnack, useError } from 'hooks';
import { useTranslation } from 'react-i18next';
import { Header } from 'components';
import ExperienceSegmentForm from '../../../components/Forms/ExperienceSegmentForm';

const SEGMENT = gql`
  query getExperienceSegment($id: ID!) {
    node(id: $id) {
      ... on ExperienceSegment {
        id
        displayName
      }
    }
  }
`;

const UPDATE_SEGMENT = gql`
  mutation updateExperienceSegment($input: UpdateExperienceSegmentInput!) {
    updateExperienceSegment(input: $input) {
      experienceSegment {
        id
        displayName
      }
      errors {
        attribute
        message
      }
    }
  }
`;

const EditSegment = () => {
  const { segmentId } = useParams();
  const { state: selectedLocale } = useLocation();
  const { t } = useTranslation('experienceSegment');
  const navigate = useNavigate();
  const { notify } = useSnack();
  const { sendErrorSnack } = useError();

  const [currentLocale, setCurrentLocale] = useState(selectedLocale);

  const { data } = useQuery(SEGMENT, {
    variables: { id: segmentId, locale: currentLocale?.value },
    fetchPolicy: 'no-cache',
  });

  const [updateSegment] = useMutation(UPDATE_SEGMENT);

  const handleUpdateSegment = async values => {
    try {
      const { data } = await updateSegment({
        variables: {
          locale: currentLocale?.value,
          input: { experienceSegmentId: segmentId, ...values },
        },
      });

      if (data?.updateExperienceSegment?.errors?.length > 0) {
        throw data?.updateExperienceSegment?.errors?.[0];
      }

      navigate('/settings/experiences/segments', { state: currentLocale });
      notify(t('editSegmentSuccess'));
    } catch (err) {
      sendErrorSnack(err);
    }
  };

  return (
    <>
      <Header />

      <Container size="narrow">
        <Card>
          <ExperienceSegmentForm
            onSubmit={handleUpdateSegment}
            segment={data?.node}
            queryLocale={currentLocale}
            updateQueryLocale={setCurrentLocale}
          />
        </Card>
      </Container>
    </>
  );
};

export default EditSegment;
