import { useTranslation } from 'react-i18next';

const useUserRights = () => {
  const { t } = useTranslation('user');
  return [
    {
      label: t('right.noAccess'),
      value: null,
    },
    {
      label: t('right.write'),
      value: 'write',
    },
    {
      label: t('right.read'),
      value: 'read',
    },
  ];
};

export default useUserRights;
