import styled, { css } from 'styled-components';
import { Avatar as AvatarMgt } from '@tymate/margaret';

const Avatar = styled(AvatarMgt)`
  background-color: ${({ theme }) => theme.primaryLight};
  color: ${({ theme }) => theme.primary};
  ${props =>
    props.shape === 'square' &&
    css`
      border-radius: 0;
      width: 250px;
      height: 192px;
    `};
`;

export default Avatar;
