import styled from 'styled-components';
import { Container } from 'ui';
import { Wrapper } from 'ui/auth';
import background from 'images/koob-login-background.jpg';
import logo from 'images/logo-koob.svg';

const Background = styled.div`
  background-image: url(${background});
  flex: 1;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  height: 100vh;
`;

const Logo = styled.img`
  align-self: start;
  margin-bottom: ${({ theme }) => theme.spacing(2.5)};
`;

const Content = styled.div`
  flex: 1;
`;

const Sidebar = styled.div`
  background-color: #ffffff;
  width: 420px;
`;

const BoxTitle = styled.h1`
  font-size: ${({ theme }) => theme.spacing(2)};
  margin-top: 0;
  font-weight: 700;
  line-height: 1.3;
`;

const BoxSubtitle = styled.p`
  color: ${({ theme }) => theme.textLight};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

const AuthLayout = ({ title, subtitle, children }) => (
  <Wrapper>
    <Sidebar>
      <Container hasVerticalPadding>
        <Logo src={logo} alt="KOOB" />

        <Content>
          <BoxTitle>{title}</BoxTitle>
          <BoxSubtitle>{subtitle}</BoxSubtitle>
          {children}
        </Content>
      </Container>
    </Sidebar>
    <Background />
  </Wrapper>
);

export default AuthLayout;
