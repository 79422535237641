import { memo } from 'react';

const IcAddCircleMulti = props => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    style={{ ...props.style, color: props.color, fontSize: props.size }}
  >
    <g
      clipPath="url(#add-circle-multi_svg__clip0)"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
    >
      <path d="M6.682 3.504a.75.75 0 01-.37.994 8.249 8.249 0 000 15.002.75.75 0 01-.624 1.364 9.75 9.75 0 01-4.806-4.806l.68-.311-.68.311C-1.36 11.162.792 5.377 5.688 3.134a.75.75 0 01.994.37z" />
      <path d="M14.25 3.75a8.25 8.25 0 100 16.5 8.25 8.25 0 000-16.5zM4.5 12c0-5.385 4.365-9.75 9.75-9.75S24 6.615 24 12s-4.365 9.75-9.75 9.75S4.5 17.385 4.5 12z" />
      <path d="M14.25 6.75a.75.75 0 01.75.75v3.75h3.75a.75.75 0 010 1.5H15v3.75a.75.75 0 01-1.5 0v-3.75H9.75a.75.75 0 010-1.5h3.75V7.5a.75.75 0 01.75-.75z" />
    </g>
    <defs>
      <clipPath id="add-circle-multi_svg__clip0">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default memo(IcAddCircleMulti);
